import { Injectable , Component, OnInit , EventEmitter, Input, Output , NgZone } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { auth } from 'firebase';
import { Router } from '@angular/router';
import { AngularFirestore } from '@angular/fire/firestore';
import { LogService } from '../logging/log.service';
import 'firebase/firestore';
import * as firebase from 'firebase/app'; 
import { Subject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FireAuthService {
  private stateChange = new Subject<any>();
    
  constructor(public zone: NgZone , private angularFireAuth: AngularFireAuth,
    private router: Router,
    public db:AngularFirestore,
    private logger:LogService) {
    this.logger.info("FireAuthService constructor");
    //this.registerAuthStateChange();
   }

  authStateChangeListener(){
    this.logger.info("authStateChangeListener");
    var unsubscribe = firebase.auth().onAuthStateChanged((user) => {
      this.broadcastAuthStateChange(user);
      unsubscribe();
      if (user === null) {
        this.logger.debug("User not logged in");
      }
    });
  }

  registerAuthStateChange() {
    this.logger.info("registerAuthStateChange");
    var subscribe = firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        // User is signed in.
        let uid = user.uid;
        let unsubscribe = this.db.collection('MTUsers').doc(uid).ref
        .onSnapshot((doc:any) => {
          if (doc.exists) {
          let curData = doc.data();
            this.logger.info('onSnapshot-Received doc snapshot :' + doc.data().email);

            //move to login screen
            //boardcast the event to componentsx
            this.broadcastAuthStateChange(curData);
            // Stop listening to changes
            unsubscribe();
          } else {
            this.logger.debug('onSnapshot- Doc /MTUsers/' + uid + '  does not exist');
            //this.broadcastAuthStateChange(false);
          }
        }, (err) => {
          this.logger.error(`onSnapshot-Encountered error: ${err}`);
          // Stop listening to changes
          unsubscribe();
        });
      }else{
        //move to login screen / assume logout has happened 
        //this.broadcastAuthStateChange(false);
        // Stop listening to changes
        //unsubscribe();
      }
    });
  }

  broadcastAuthStateChange(data) {
    this.stateChange.next(data);
  }

  clearAuthStateChange() {
    this.stateChange.next();
  }

  subscribeFirebaseAuthStateChange(): Observable<any> {
    return this.stateChange.asObservable();
  }

  doFirebaseAuth(googleUserProfile){
    this.logger.info("doFirebaseAuth");
    this.registerAuthStateChange();
    var promise = new Promise((resolve, reject) => {
      //var credential = auth.GoogleAuthProvider.credential(googleUserProfile.getAuthResponse().id_token);
      var credential = auth.GoogleAuthProvider.credential(googleUserProfile);
      this.angularFireAuth.signInWithCredential(credential)
      .then(res => {
        //this.logger.debug("Result", res);
        this.logger.debug("Firebase sign in complete");
        this.logger.debug(res.user.displayName);
        this.logger.debug(res.user.email);
        //this.logger.debug(res.user.photoURL);
        //firebase auth is successfull 
        resolve(res)
      }).catch((err) => reject(err));
    });
    return promise;
  }

  logout(){
    this.logger.info("logout");
    var promise = new Promise((resolve, reject) => {
      firebase.auth().signOut().then((res) => {
        this.logger.debug("firebase logout success");
        resolve(res)
      }).catch((err) => {
        this.logger.error("firebase logout failed :",err);
        reject(err);
      })
    });

    return promise;
  }

}
