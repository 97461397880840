import { Component, OnInit, NgZone} from '@angular/core';
import Tabulator from 'tabulator-tables';
import { TablePreviewService, PAGINATION_SIZE } from 'src/app/shared/services/tablePreview/table-preview.service';
import { Router } from '@angular/router';
import { MigrationDataService } from 'src/app/shared/services/migrationDataService/migration-data.service';
import { SessionHandlerService } from 'src/app/shared/services/sessionHandler/session-handler.service';
import { OverlayService } from 'src/app/shared/services/overlay/overlay.service';
import { LogService } from 'src/app/shared/services/logging/log.service';
import { MatSpinnerOverlayComponent } from 'src/app/shared/components/mat-spinner-overlay/mat-spinner-overlay.component';
import { CanComponentDeactivate } from 'src/app/shared/services/deactivate-guard/can-deactivate-guard.service';
import { ConfirmationDialogComponent } from 'src/app/shared/components/dialog/confirmation-dialog/confirmation-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs/internal/Observable';
import { ERROR_STRING } from 'src/app/shared/services/firestore/firestore.service';
import { FirestoreService} from './../../../shared/services/firestore/firestore.service';

const RingModeStrings = {
  RINGROTARY:"Rotating",
  RINGIDLE:"Rotating",
  RINGGROUP:"Simultaneous",
  RINGHUNT:"Sequential"
}

const ALL_GROUPS_STATE = {
DEFAULT:0,
ALL_HUNTGROUPS:1,
ALL_PAGINGGROUPS:2
}

@Component({
  selector: 'app-hunt-groups-preview',
  templateUrl: './hunt-groups-preview.component.html',
  styleUrls: ['../../../../assets/customcss/table-component.css']
})
export class HuntGroupsPreviewComponent implements OnInit , CanComponentDeactivate {

  searchInput:any;
  searchOption = "name";
  huntGroupTable : Tabulator;
  readonly TABLE_PROMPT_MSG = 'Are you sure you want to close current Migration?';
  id:any;
  companyName:string;
  showErrorWarningBlock = false;  
  columnNames: any[] = [];
  readonly TIMER_INTERVAL = 60000;
  public errorWarningList = [];
  private isReadyToClose: boolean = false;
  readonly TABLE_DATA_UNSAVED_PROMPT_MSG = 'Closing the current migration will discard changes in the \
  table.\nAre you sure you want to close current migration?';
  readonly TABLE_DATA_SAVE_FAILED_MSG = 'Changes made in the table could not be saved at this moment.\n';
  readonly CLOSE_TABLE_MSG = 'Do you still want to close the table view or wait for sometime and retry?';
  readonly CLOSE_MIGRATION_MSG = 'Do you still want to close current migration or wait for sometime and retry?';s
  public GROUP_STATUS = ALL_GROUPS_STATE;
  public groupStatus:any;
  public errorCount = 0;
  private finalList = new Map()
  constructor(private tablePreviewService:TablePreviewService,
    private router: Router ,
    public zone: NgZone,public dialog: MatDialog,
    private migrationDataService:MigrationDataService, private session:SessionHandlerService,
    private firestoreService: FirestoreService,
    private overlay:OverlayService, private logger:LogService) {
      this.id = this.session.cardId;
      this.companyName = this.session.companyName;
      this.isReadyToClose = false;
      this.groupStatus = this.GROUP_STATUS.DEFAULT;
      this.tablePreviewService.getCardClosureStatus().subscribe((data:boolean)=>{
        this.isReadyToClose = data;
      });
     }

  ngOnInit(): void { 
    console.debug("Call Queues and Paging groups tablePreview: ngOnInit");
    this.firestoreService.getIPOSummaryData(this.id)
    .then((result:any) => {          
        this.migrationDataService.IPOSummaryData = JSON.parse(result);
    })
    this.columnNames = [
        { title: "Paging Group?", field: "isPagingEnabled" , headerSort:true, sorter:"boolean", width:"10%", formatter:(cell, formatterParams, onRendered)=>{
         // return '<span style="margin-left:-10%;"><input type="checkbox" checked='+cell.getValue()+' onClick="changeHGRole(this)"/></span>';
          return '<span style="margin-left:20%;padding-top:0.6%;"><input type="checkbox" class="switch" '+(cell.getRow().getData().isPagingEnabled?'checked':'')+'/></span>';
        },cellClick:(e, cell)=>{ 
          this.changeHuntGroupRole(cell);
        }},        
        { title: "Group Name", field: "name" , headerSort:true, sorter:"alphanum"},
        { title: "Extension", field: "extension" , headerSort:true, sorter:"number", width:"8%",
          formatter:(cell, formatterParams, onRendered)=>{
            var value = cell.getValue();
            if(!value){
              return '<i class="material-icons" style="font-size:16px;margin-left:-10%;color:rgb(49,49,49,.59);">remove</i>';
            }
            var res = (this.tablePreviewService.assignedExtnList.includes(value.toString()) || this.tablePreviewService.reservedExtensionsList.includes(value));
            if( res !== true )
            {
              return '<span style="margin-left:-10%;">'+value+'</span>';
            }
            else
            {
              return "<span class='highlight-bg' style='margin-left:-10%;'>" + value + "</span>";
            }
          },hozAlign:"center"},        
        { title: "Members", field: "totalMembers" , headerSort:true, sorter:"number", width:"8%", formatter:(cell, formatterParams, onRendered)=>{
          if(cell.getRow().getData().isPagingEnabled)
           return '<i class="material-icons" style="font-size:16px;margin-left:-30%;color:rgb(49,49,49,.59);padding-top:0.7%;">remove</i>';
          else
            return '<span style="margin-left:-30%;">'+cell.getValue()+'</span>';
        },hozAlign:"center"},
        { title: "Devices", field: "devices.length" , headerSort:true, sorter:"number", width:"7%", formatter:(cell, formatterParams, onRendered)=>{
          if(cell.getRow().getData().isPagingEnabled)
            return '<span style="margin-left:-10%;">'+cell.getValue()+'</span>';
          else
            return '<i class="material-icons" style="margin-left:-10%;font-size:16px;color:rgb(49,49,49,.59);padding-top:0.7%;">remove</i>';           
        },hozAlign:"center"},
        { title: "Enable Overflow", field: "isCallQueueMigrationEnabled" , headerSort:true, sorter:"boolean", width:"11%", formatter:(cell, formatterParams, onRendered)=>{
            return '<span style="margin-left:20%;padding-top:0.6%;"><input type="checkbox" [disabled]='+(cell.getRow().getData().overflowGroup.length===0) + ' class="switch"' +((cell.getRow().getData().isCallQueueMigrationEnabled && cell.getRow().getData().overflowGroup.length > 0)?'checked':'')+'/></span>';
        },cellClick:(e, cell)=>{
               this.changeOverflowGroupMigrationFlag(cell);
        }},
        { title: "Route to Ext", field: "isRoutingMigrationEnabled" , headerSort:true, sorter:"boolean", width: "11%", formatter:(cell, formatterParams, onRendered)=>{
            return '<span style="margin-left:20%;padding-top:0.6%;"><input type="checkbox" [disabled]='+(cell.getRow().getData().overflowGroup.length>0) + ' class="switch"' +((cell.getRow().getData().isRoutingMigrationEnabled && cell.getRow().getData().overflowGroup.length > 0)?'checked':'')+'/></span>';
        },cellClick:(e, cell)=>{
          this.changeRoutingMigrationFlag(cell);
        }},
        { title: "Overflow Group", headerSort:true, sorter:"alphanum",hozAlign:"center", field: "overflowGroup", width:"14%", formatter:this.hypenFormatter},
        { title: "Route Calls to members", field: "ringMode" , headerSort:true, sorter:"alphanum", formatter:this.replaceRingModeStrings},
        { title: "Site Name", field: "siteName", headerSort:true, sorter:"alphanum",formatter:(cell, formatterParams, onRendered)=>{
          if(cell.getRow().getData().isPagingEnabled)
           return '<i class="material-icons" style="font-size:16px;margin-left:25%;color:rgb(49,49,49,.59);padding-top:0.7%;">remove</i>';
          else{
            if(cell.getValue() === "")
              return '<span style="padding-left:10%;">MainSite</span>';
            else
              return '<span style="padding-left:10%;">'+cell.getValue()+'</span>';
          }
        }},
      ];
  } 
  
  canDeactivate():boolean | Observable<boolean> | Promise<boolean>{
    if(this.isReadyToClose)
     return true;
    const dialogRef = this.dialog.open(ConfirmationDialogComponent,{
        data:{
          message: this.TABLE_DATA_UNSAVED_PROMPT_MSG,
          buttonText: {
            ok: 'Ok',
            cancel: 'Cancel'
          }
        }
      });
    return dialogRef.afterClosed();
  }

  private hypenFormatter = (cell, formatterParams, onRendered)=>{
    cell.getElement().style.whiteSpace = "pre-wrap";
    var val = cell.getValue().join(", ");
    if( val == undefined || val == null || val.trim() == "")
      return '<i class="material-icons" style="font-size:16px;margin-left:10%;color:rgb(49,49,49,.59);">remove</i>';
    else
      //return '<span style="margin-left:10%;">'+val+'</span>';  
      return val;
  }

  private changeOverflowGroupMigrationFlag = (cell)=> {
    let groupData = cell.getRow().getData();
        this.tablePreviewService.changeOverflowMigrationFlags([groupData], 'callQueueMigration').then(()=>{
            this.huntGroupTable.replaceData(this.tablePreviewService.tableHuntGroupList);
            this.updateFooterOnPgLoaded();
            this.fetchErrorWarningData();
            //this.calculateAllGroupsStatus();
          })
  }

  private changeRoutingMigrationFlag = (cell)=> {
    let groupData = cell.getRow().getData();
        console.log("updating groupData's RoutingMigrationFlag ");
        this.tablePreviewService.changeOverflowMigrationFlags([groupData], 'routingMigration').then(()=>{
            this.huntGroupTable.replaceData(this.tablePreviewService.tableHuntGroupList);
            this.updateFooterOnPgLoaded();
            this.fetchErrorWarningData();
            //this.calculateAllGroupsStatus();
          })
  }

  private changeHuntGroupRole = (cell)=> {
    let groupData = cell.getRow().getData();
    this.tablePreviewService.changeHGRole([groupData]).then(()=>{
      this.huntGroupTable.replaceData(this.tablePreviewService.tableHuntGroupList);
      this.updateFooterOnPgLoaded();
      this.fetchErrorWarningData();
      this.calculateAllGroupsStatus();
    })
  }

  private replaceRingModeStrings = (cell, formatterParams, onRendered)=> {
    if(cell.getRow().getData().isPagingEnabled){
      return '<i class="material-icons" style="font-size:16px;margin-left:20%;padding-left:20%;color:rgb(49,49,49,.59);padding-top:0.7%;">remove</i>';
    }else{
      var val = cell.getValue().toUpperCase();
      return '<span style="padding-left:13%;">'+ (RingModeStrings[val] || cell.getValue()) +'</span>';
    }    
  }

  ngAfterViewInit(){    
    this.overlay.openSpinner(this.TIMER_INTERVAL,this.timeupCallbackfunc,MatSpinnerOverlayComponent);
    this.tablePreviewService.initializeTableData(this.id)
    .then(()=>{        
      this.huntGroupTable = new Tabulator("#hunt-groups-tabulator-div",{	layout:"fitColumns",      //fit columns to width of table
      data:this.tablePreviewService.tableHuntGroupList, //added for footer displayRow count
      maxHeight:"100%",
      autoResize:true,
      resizableColumns:false,
      resizableRows:false, 
      responsiveLayout:'hide',  //hide columns that dont fit on the table
      tooltips:true,            //show tool tips on cells
      addRowPos:"top",          //when adding a new row, add it to the top of the table
      history:true,             //allow undo and redo actions on the table
      pagination:"local",       //paginate the data
      paginationSize:PAGINATION_SIZE,
      footerElement:this.getFooterElement(),
      dataFiltered: this.updateFooterOnFiltered,
      pageLoaded: this.updateFooterOnPgLoaded,
      movableColumns:false,
      initialSort:[             //set the initial sort order of the data
        {column:"name", dir:"asc"}
      ],
      //groupBy: "siteName"
    });
    this.huntGroupTable.setColumns(this.columnNames);
    this.huntGroupTable.setData(this.tablePreviewService.tableHuntGroupList);
    this.fetchErrorWarningData();
    this.calculateAllGroupsStatus();
    this.overlay.closeSpinner();
    let colHeaders = document.getElementsByClassName("tabulator-col");
    for(let i=2;i<colHeaders.length-1;i++){
      colHeaders[i].classList.add("centerAlign");
    }
  })
  .catch((error) =>{
    this.logger.debug(error)
    this.overlay.closeSpinner();
  });  
}

  ngOnDestroy(){
  }

  timeupCallbackfunc(ol){
    ol.showSpinner = false;
    ol.closeSpinner();
  }

  fetchErrorWarningData(){ 
    this.errorCount = 0;
    this.errorWarningList = [];
   let HGList = Array.from(this.tablePreviewService.huntGroupWarningsList);
   let PGList = Array.from(this.tablePreviewService.pagingGroupsWarningsList);
   HGList.forEach((element) =>
   {    
        if(element [0] === "Common Notifications")
          this.finalList.set(element [0], element[1].concat(PGList[0][1]));
        else 
          this.finalList.set(element [0],element[1]);
    })
    let tempErrorList = Array.from(this.finalList);
    tempErrorList.forEach((site) =>{
      if(site[1].length > 0)
      this.errorWarningList.push(site);
  })
    this.errorWarningList.forEach((site) =>{
      if(site[0] === "")
          site[0] = "Main Site";
      this.errorCount += site[1].length;
    })
  }

  updateFilter(){
    if(this.searchInput !== undefined){
      var typeVal = "like";
      this.huntGroupTable.setFilter(this.searchOption, typeVal, this.searchInput);        
    }
  }

  getFooterElement(){
    return '<span style="float:left;" class="customFooter" id="footerNote"></span>';
  }

  updateFooterOnPgLoaded = (pageNum=1)=>{
    if( this.huntGroupTable){
      var el = document.getElementById("footerNote");
      let currPageNum = this.huntGroupTable.getPage();
      if(currPageNum){
        let startIdx = ((currPageNum-1)*PAGINATION_SIZE)+1;
        let endIdx = startIdx + this.huntGroupTable.rowManager.displayRowsCount - 1;
        el.innerHTML = startIdx + ' - '+ endIdx +' of '+this.tablePreviewService.tableHuntGroupList.length +' Groups';
      }
    }
  }

  updateFooterOnFiltered = (filters, rows)=>{
    var el = document.getElementById("footerNote");
    if(rows.length){      
      if(filters.length != 0 ){
        var displayRowsCount = this.huntGroupTable.rowManager.displayRowsCount;
        if(filters[0].value.trim() =="" || displayRowsCount >  rows.length){
          displayRowsCount =  rows.length;
        }
        var selectedCount = displayRowsCount < PAGINATION_SIZE ? displayRowsCount : PAGINATION_SIZE;
        el.innerHTML = '1-'+ selectedCount +' of '+rows.length +' Groups';
      }else{
        if( this.huntGroupTable){
          let currPageNum = this.huntGroupTable.getPage();
          if(currPageNum){
            el.innerHTML = '1-'+this.huntGroupTable.rowManager.displayRowsCount+' of '+this.tablePreviewService.tableHuntGroupList.length +' Groups';
          }
        }
      }
    }else{
      el.innerHTML = "";
    }
  }

  closeMigrationActivity(){
    this.session.alertModal(this.onClose.bind(this), this.TABLE_PROMPT_MSG);
  }

  onClose() {
    this.logger.debug("TablePreview - Close current Migration Activity");
    this.migrationDataService.clearData();
    this.tablePreviewService.saveHGnPGTableData(this.id)
    .then((res) => {
        this.isReadyToClose = true;
        this.logger.debug("table data saved");
        this.zone.run(() => { this.router.navigate(['/home/migration']);});
    }).catch((error) => {
        this.logger.debug("saving table data failed. ",error);
        if(error === ERROR_STRING.DOC_DOES_NOT_EXIST){
          this.zone.run(() => { 
            this.isReadyToClose = true;
            this.router.navigate(['/home/migration']);
          });
        }else{
          this.session.alertModal(()=>{
            this.zone.run(() => { 
                this.isReadyToClose = true;
                this.router.navigate(['/home/migration']);
            });
        }, 
            this.TABLE_DATA_SAVE_FAILED_MSG + this.CLOSE_MIGRATION_MSG,'Close','Retry');
        }        
    });
  }


  getErrorWarningList(){
    if(this.errorWarningList.length != 0){
        this.displayErrorWarningBlock();
    }else{
        this.logger.debug("Warning list is empty");
    }
  }

  displayErrorWarningBlock(){
    document.getElementById("hunt-groups-tabulator-div").style.display = "none";
    document.getElementById("tableDoneBtn").style.display = "none";
    this.showErrorWarningBlock = true;
  } 

  displayTable(){
    document.getElementById("hunt-groups-tabulator-div").style.display = "block";
    document.getElementById("tableDoneBtn").style.display = "inline-block";
    this.showErrorWarningBlock = false;
  }

  closeErrorWarningBlock(){
    this.displayTable(); 
  }

  done (){
    this.logger.debug("done");
    this.tablePreviewService.saveHGnPGTableData(this.id)
    .then((res) => {
        this.logger.debug("table data saved");
        this.overlay.closeSpinner();
        this.zone.run(() => { 
            this.isReadyToClose = true;
            this.router.navigate(['/home/migration/config-summary/system-data']); 
        });
    }).catch((error) => {
        this.logger.debug("saving table data failed. ",error);
        this.overlay.closeSpinner();
        if(error === ERROR_STRING.DOC_DOES_NOT_EXIST){
          this.onCloseRedirectToSummaryPg();
        }else{
          this.session.alertModal(this.onCloseRedirectToSummaryPg.bind(this), 
          this.TABLE_DATA_SAVE_FAILED_MSG + this.CLOSE_TABLE_MSG ,'Close','Retry');
        }
      }); 
  }

  onCloseRedirectToSummaryPg(){
    this.zone.run(() => { 
        this.isReadyToClose = true;
        this.router.navigate(['/home/migration/config-summary/system-data']); 
    });
  }

  changeAllToPG(isPagingEnabled){
    this.logger.info("Enable paging in all the groups: "+isPagingEnabled);
    let grpArr = this.huntGroupTable.getData().filter((entry)=>{
      return !entry.isPagingEnabled === isPagingEnabled;
    });
    this.tablePreviewService.changeHGRole(grpArr).then(()=>{
      this.huntGroupTable.replaceData(this.tablePreviewService.tableHuntGroupList);
      this.updateFooterOnPgLoaded();
      this.fetchErrorWarningData();
      this.calculateAllGroupsStatus();
    })    
  }

  //Below function will be used later when the option to select/deselect all toggles will be added
  migrateOverflowGroupsUsingNewAPI(isOverflowEnabled){
    this.logger.info("Enable migration of overflow groups into overflow groups section for all call queues: "+isOverflowEnabled);
    let grpArr = this.huntGroupTable.getData().filter((entry)=>{
      return !entry.isCallQueueMigrationEnabled === isOverflowEnabled;
    });
    this.tablePreviewService.changeOverflowMigrationFlags(grpArr, 'callQueueMigration').then(()=>{
      this.huntGroupTable.replaceData(this.tablePreviewService.tableHuntGroupList);
      this.updateFooterOnPgLoaded();
      this.fetchErrorWarningData();
      this.calculateAllGroupsStatus();
    })    
  }

  //Below function will be used later when the option to select/deselect all toggles will be added
  migrateOverflowGroupsUsingRoutingMethod(isOverflowEnabled){
    this.logger.info("Enable paging in all the groups: "+isOverflowEnabled);
    let grpArr = this.huntGroupTable.getData().filter((entry)=>{
      return !entry.isRoutingMigrationEnabled === isOverflowEnabled;
    });
    this.tablePreviewService.changeOverflowMigrationFlags(grpArr, 'routingMigration').then(()=>{
      this.huntGroupTable.replaceData(this.tablePreviewService.tableHuntGroupList);
      this.updateFooterOnPgLoaded();
      this.fetchErrorWarningData();
      this.calculateAllGroupsStatus();
    })    
  }

  calculateAllGroupsStatus(){
    let pgCount = this.tablePreviewService.tableHuntGroupList.filter((entry)=>{return entry.isPagingEnabled}).length;
    let totalGroupCount = this.tablePreviewService.tableHuntGroupList.length;
    let hgCount = totalGroupCount - pgCount;
    if(pgCount === totalGroupCount){
      this.groupStatus = this.GROUP_STATUS.ALL_PAGINGGROUPS;
    }else if(hgCount === totalGroupCount){
      this.groupStatus = this.GROUP_STATUS.ALL_HUNTGROUPS;
    }else{
      this.groupStatus = this.GROUP_STATUS.DEFAULT;
    }
  }

}
