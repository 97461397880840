import { Component, OnInit, NgZone} from '@angular/core';
import Tabulator from 'tabulator-tables';
import { TablePreviewService,PAGINATION_SIZE } from 'src/app/shared/services/tablePreview/table-preview.service';
import { Router } from '@angular/router';
import { MigrationDataService } from 'src/app/shared/services/migrationDataService/migration-data.service';
import { SessionHandlerService } from 'src/app/shared/services/sessionHandler/session-handler.service';
import { OverlayService } from 'src/app/shared/services/overlay/overlay.service';
import { LogService } from 'src/app/shared/services/logging/log.service';
import { MatSpinnerOverlayComponent } from 'src/app/shared/components/mat-spinner-overlay/mat-spinner-overlay.component';
import {MatDialog} from '@angular/material/dialog';
import { Observable} from 'rxjs';
import { ConfirmationDialogComponent } from 'src/app/shared/components/dialog/confirmation-dialog/confirmation-dialog.component';
import { FirestoreService } from 'src/app/shared/services/firestore/firestore.service';

/*const STATE = {
  DISABLE:0,
  ENABLE:1
}*/

const footer_labels = {
  total :"Total",
  displaying:"Displaying",
  selected:"Selected"
}

@Component({
  selector: 'app-incoming-numbers-preview',
  templateUrl: './incoming-numbers-preview.component.html',
  styleUrls: ['../../../../assets/customcss/table-component.css']
})
export class IncomingNumbersPreviewComponent implements OnInit {

  searchInput:any;
  searchOption = "Entire Table";
  incomingNumbersTable : Tabulator;
  readonly TABLE_PROMPT_MSG = 'Are you sure you want to close current Migration?';
  readonly TABLE_DATA_UNSAVED_PROMPT_MSG = 'Closing the current migration will discard changes in the \
  table.\nAre you sure you want to close current migration?';
  readonly TABLE_DATA_SAVE_FAILED_MSG = 'Changes made in the table could not be saved at this moment.\n';
  readonly CLOSE_TABLE_MSG = 'Do you still want to close the table view or wait for sometime and retry?';
  id:any;
  companyName:string;
  showErrorWarningBlock = false; 
  showAutoConfigSettings = false; 
  columnNames: any[] = [];
  readonly TIMER_INTERVAL = 60000;
  public errorWarningList = [];
  allowEdit:boolean = false;
  public errorcount = 0;
  /*public RULE_STATUS = STATE;
  public ruleStatus:any=STATE.ENABLE;*/
  private isReadyToClose: boolean = false;

  private _isRowSelected : boolean = false;
  public get isRowSelected() : boolean {
      return this._isRowSelected;
  }
  public set isRowSelected(v : boolean) {
      this._isRowSelected = v;
      if(this._isRowSelected){
          document.getElementById("deleteICRBtn").classList.remove("btn_disabled");
      }else{
          document.getElementById("deleteICRBtn").classList.add("btn_disabled");
      }
  } 

  constructor(private tablePreviewService:TablePreviewService,
    private firestoreService:FirestoreService,
    private router: Router , 
    public zone: NgZone, 
    public dialog: MatDialog,
    private migrationDataService:MigrationDataService, private session:SessionHandlerService,
    private overlay:OverlayService, private logger:LogService) {
      this.id = this.session.cardId;
      this.companyName = this.session.companyName;
      this.allowEdit = true;
      this.searchOption = "Entire Table";
      this.isReadyToClose = false;
      this.tablePreviewService.getCardClosureStatus().subscribe((data:boolean)=>{
        this.isReadyToClose = data;
    });
  }

  canDeactivate():boolean | Observable<boolean> | Promise<boolean>{
    if(this.isReadyToClose)
     return true;
     if( this.tablePreviewService.incomingNumbers.length > 0 )
     {
      const dialogRef = this.dialog.open(ConfirmationDialogComponent,{
        data:{
          message: this.TABLE_DATA_UNSAVED_PROMPT_MSG,
          buttonText: {
            ok: 'Ok',
            cancel: 'Cancel'
          }
        }
      });
      return dialogRef.afterClosed();
     }
     else 
      return true;
}

  ngOnInit(): void {
    this.logger.debug("Incoming Numbers tablePreview: ngOnInit");
    this.tablePreviewService.getCompanyPhNumbers()
    .then(async()=>{
      await this.defineColumns();
    })
  }

  ngOnDestroy(){
  }

  async defineColumns(){
    try{
      if (this.tablePreviewService._comPhData  &&this.tablePreviewService._comPhData.length > 1) {
        this.columnNames = [
          { title:"<input type='checkbox' class='select-all-row' aria-label='select all rows'>" , field:"isSelected", formatter: function(cell, formatterParams, onRendered) {
              let isSelected = cell.getValue();
              if(isSelected)
                  cell.getTable().selectRow(cell.getRow());
              return '<input type="checkbox" class="select-row" aria-label="select this row" '+(isSelected?'checked':'')+'/>';
            },
            headerSort: false,headerFilter: false,cssClass: 'text-center',tooltips: false,resizable: false,width:"2%",
            cellClick: this.selectICR,headerClick: this.selectUnselectAll
          }, 
          { title: "Auto-Receptionists", field: "name" , headerSort:true, sorter:"alphanum", width:"15%", formatter:(cell, formatterParams, onRendered)=>{
            return '<span style="margin-left:15%;">'+cell.getValue()+'</span>';
          }},          
          { title: "Working Hours", field: "timeProfile" , headerSort:true, sorter:"alphanum", width:"15%", formatter:(cell, formatterParams, onRendered)=>{
            return '<span style="margin-left:15%;">'+cell.getValue()+'</span>';
          }},
          { title: "IP Office Incoming Call Route", field: "icrNumber" , headerSort:true, sorter:"alphanum", width:"20%", formatter:(cell, formatterParams, onRendered)=>{
            return '<span style="margin-left:15%;"> <span style="color:grey";>'+cell.getValue()+'</span></span>';
          }},
          { title: "Company Numbers", field: "companyNumbers" , headerSort:true, sorter:"alphanum", editor:"select",  editorParams: this.tablePreviewService.acoPhData, cellEdited: this.changePhoneAssignment.bind(this) },
          { title: "Site Name", field: "siteName", headerSort:true, sorter:"alphanum" }
        ];   
      } else {
        this.columnNames = [
          { title:"<input type='checkbox' class='select-all-row' aria-label='select all rows'>" , field:"isSelected", formatter: function(cell, formatterParams, onRendered) {
            let isSelected = cell.getValue();
            if(isSelected)
            cell.getTable().selectRow(cell.getRow());
            return '<input type="checkbox" class="select-row" aria-label="select this row" '+(isSelected?'checked':'')+'/>';
          },
            headerSort: false,headerFilter: false,cssClass: 'text-center',tooltips: false,resizable: false,width:"2%",
            cellClick: this.selectICR,headerClick: this.selectUnselectAll
          },
          { title: "Auto-Receptionists", field: "name" , headerSort:true, sorter:"alphanum", width:"15%", formatter:(cell, formatterParams, onRendered)=>{
            return '<span style="margin-left:15%;">'+cell.getValue()+'</span>';
          }},          
          { title: "Working Hours", field: "timeProfile" , headerSort:true, sorter:"alphanum", width:"15%", formatter:(cell, formatterParams, onRendered)=>{
            return '<span style="margin-left:15%;">'+cell.getValue()+'</span>';
          }},
          { title: "IP Office Incoming Call Route", field: "icrNumber" , headerSort:true, sorter:"alphanum", width:"15%", formatter:(cell, formatterParams, onRendered)=>{
            return '<span style="margin-left:15%;"> <span style="color:grey";>'+cell.getValue()+'</span></span>';
          }},
          { title: "Company Numbers", field: "companyNumbers" , headerSort:true, sorter:"alphanum"},
          { title: "Site Name", field: "siteName", headerSort:true, sorter:"alphanum" }
        ];   
      }
    }catch(error){
      this.logger.error("Error while reading comp phone number data: ",error);
    }
  }

  ngAfterViewInit(){
        this.overlay.openSpinner(this.TIMER_INTERVAL,this.timeupCallbackfunc,MatSpinnerOverlayComponent);
        //change fetchGreetingsData to promise based
       // this.firestoreService.fetchGreetingsData(this.id);
        this.tablePreviewService.initializeTableData(this.id)
        .then(async()=>{
        await this.defineColumns();
        this.incomingNumbersTable = new Tabulator("#incoming-numbers-tabulator-div",{	layout:"fitDataFill",      //fit columns to width of table
            data:this.tablePreviewService.incomingNumbers,  
            maxHeight:"100%",
            autoResize:false,
            resizableColumns:false,
            resizableRows:false, 
            responsiveLayout:'hide',  //hide columns that dont fit on the table
            tooltips:true,            //show tool tips on cells
            addRowPos:"top",          //when adding a new row, add it to the top of the table
            history:true,             //allow undo and redo actions on the table
            pagination:"local",       //paginate the data
            paginationSize:PAGINATION_SIZE,
            footerElement:this.getFooterElement(),
            dataFiltered: this.updateFooterOnFiltered,
            pageLoaded: this.updateFooterOnPgLoaded,
            movableColumns:false,
            initialSort:[             //set the initial sort order of the data
                {column:"name", dir:"asc"}
            ]
        });
        this.incomingNumbersTable.setColumns(this.columnNames);
        this.incomingNumbersTable.setData(this.tablePreviewService.incomingNumbers);
        this.fetchErrorWarningData();
        this.overlay.closeSpinner();
        
        let colHeaders = document.getElementsByClassName("tabulator-col");
        for(let i=1;i<colHeaders.length;i++){
          colHeaders[i].classList.add("centerAlign");
        }        
        
      }).catch((error)=>{
        this.logger.debug(error)
        this.overlay.closeSpinner();
      });
  }

  timeupCallbackfunc(ol){
    ol.showSpinner = false;
    ol.closeSpinner();
  }

  selectICR = (e, cell) => {
    let element = cell.getElement();
    let chkbox = element.querySelector('.select-row');
    let selectAllRow = document.querySelector('.select-all-row') as HTMLInputElement;
    if (cell.getData().isSelected) {
      cell.getRow().deselect();
      selectAllRow.checked = false;
    } else {
      cell.getRow().select(); 
      let displayRows = cell.getRow().getTable().rowManager.displayRows[1];
      let selectedRowCountInCurrPg  = displayRows.filter((row:any)=>{return row.getData().isSelected === true}).length;
     //Excluding the selected row
      if(selectedRowCountInCurrPg === displayRows.length - 1){
        selectAllRow.checked = true;
      }
    }
    chkbox.checked = !cell.getData().isSelected;
    cell.getData().isSelected = !cell.getData().isSelected;
    let totalSelectedRows = cell.getRow().getTable().getSelectedRows().length;
    let el = document.getElementById("selectedRowsCount");
    if(totalSelectedRows > 0){
        el.innerHTML = 'Selected : '+ totalSelectedRows;
        this.isRowSelected = true;
    }else{
        el.innerHTML = '';
        this.isRowSelected = false;
    }
  }

selectUnselectAll = (e, column) => {
    let displayRows = column.getTable().rowManager.displayRows[1], isSelected ;
    let selectedRowCountInCurrPg  = displayRows.filter((row:any)=>{return row.getData().isSelected === true}).length;
    if (selectedRowCountInCurrPg !== displayRows.length && displayRows.length !== 0) {
      document.querySelectorAll('.select-row,.select-all-row').forEach((cb:any) => cb.checked = true);
      column.getTable().selectRow(displayRows);
      isSelected = true;
    } else {
      document.querySelectorAll('.select-row,.select-all-row').forEach((cb:any) => cb.checked = false);
      column.getTable().deselectRow(displayRows);
      isSelected = false;
    }
    displayRows.forEach((row:any) => {
        row.getData().isSelected = isSelected;
    });
    let totalSelectedRows = column.getTable().getSelectedRows().length;
    let el = document.getElementById("selectedRowsCount");
    if(totalSelectedRows > 0){
        el.innerHTML = 'Selected : '+ totalSelectedRows;
        this.isRowSelected = true;
    }else{
        el.innerHTML = '';
        this.isRowSelected = false;
    }
}

displayDelICRModal(){
  const dialogRef = this.dialog.open(ConfirmationDialogComponent,{
      data:{
        message: this.incomingNumbersTable.getSelectedRows().length + ' out of '+
                 this.incomingNumbersTable.getDataCount() +
                 ' ICRs are going to be deleted. Any references to these Company Rules will be removed. This operation cannot be undone. Do you want to continue?',
        buttonText: {
          ok: 'Confirm',
          cancel: 'Cancel'
        },
        isWarning: true
      }
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if(confirmed){
        this.deleteCompanyRules();
      }
    });
}


deleteCompanyRules(){
  let deleteICRList = this.incomingNumbersTable.getSelectedRows().map((row)=>{
      return row.getData();
  });

 
 // var delList = deleteICRList.map((entry:any)=>{return entry.name});
  this.tablePreviewService.removeICRsFromTable(deleteICRList).then(()=>{
     //Unselect the checkbox in the header after the users are deleted
     this.incomingNumbersTable.replaceData(this.tablePreviewService.incomingNumbers);
     let selectAllRow = document.querySelector('.select-all-row') as HTMLInputElement;
     selectAllRow.checked = false;
     this.fetchErrorWarningData();
     this.updateFooterLabels();
  })
}

updateFooterLabels = ()=>{
  document.getElementById("totalRowsCount").innerHTML = footer_labels.total + " : " + this.tablePreviewService.incomingNumbers.length.toString();
  document.getElementById("displayingRowsCount").innerHTML = footer_labels.displaying + " : " + this.incomingNumbersTable.rowManager.displayRowsCount;
  let selectedRowsCount = this.incomingNumbersTable.getSelectedRows().length;
  if(selectedRowsCount > 0){
      document.getElementById("selectedRowsCount").innerHTML = footer_labels.selected + " : " + selectedRowsCount.toString();
  }
}

  updateFilter(){
    if(this.searchInput !== undefined){
        var filterVal = this.searchOption;
        var typeVal = "like";
        if(filterVal){
            if(filterVal == "Entire Table"){
                //TODO: We need to setfilter for all fields

                this.incomingNumbersTable.setFilter([[                
                  {field:"name", type:typeVal, value:this.searchInput},
                  {field:"timeProfile", type:typeVal, value:this.searchInput},
                  {field:"icrNumber", type:typeVal, value:this.searchInput},
                  {field:"companyNumbers", type:typeVal, value:this.searchInput},
                  {field:"siteName", type:typeVal, value:this.searchInput}
                ]]);
              }
            else{
                this.incomingNumbersTable.setFilter(filterVal, typeVal, this.searchInput);
            }
        }
    }
  }

  getFooterElement(){
      return '<span style="float:left;" class="customFooter" id="totalRowsCount">Total : '+this.tablePreviewService.incomingNumbers.length+'</span>'+
       '<span style="float:left;margin-left:2%;" class="customFooter" id="displayingRowsCount"></span>'+
       '<span style="float:left;margin-left:2%;" class="customFooter" id="selectedRowsCount"></span>';
  }

  updateFooterOnPgLoaded = (pageNum)=>{
    let displayRows = [], totalSelectedRows = 0;
    if( this.incomingNumbersTable){
      let el = document.getElementById("displayingRowsCount");
      el.innerHTML = 'Displaying : '+ this.incomingNumbersTable.rowManager.displayRowsCount;
      
      displayRows = this.incomingNumbersTable.rowManager.displayRows[1];
      totalSelectedRows = this.incomingNumbersTable.getSelectedRows().length;
      
      let selectedRowCountInCurrPg  = displayRows.filter((row:any)=>{return row.getData().isSelected === true}).length;
      let selectedAllRows = document.querySelector('.select-all-row') as HTMLInputElement;
      if(selectedRowCountInCurrPg !== 0 && selectedRowCountInCurrPg === displayRows.length){
        selectedAllRows.checked = true;
      }else{
        selectedAllRows.checked = false;
      }
    }
    let el = document.getElementById("selectedRowsCount");
    if(totalSelectedRows > 0){
      el.innerHTML = 'Selected : '+ totalSelectedRows;
      this.isRowSelected = true;
    }else{
      el.innerHTML = '';
      this.isRowSelected = false;
    }
  }
 
  updateFooterOnFiltered = (filters, rows)=>{
    var el = document.getElementById("displayingRowsCount");
    if(filters != 0){
        var displayRowsCount = this.incomingNumbersTable.rowManager.displayRowsCount;
        var selectedCount = displayRowsCount < PAGINATION_SIZE ? displayRowsCount : PAGINATION_SIZE;
        el.innerHTML = 'Displaying : '+ selectedCount;
    }else{
        if(this.incomingNumbersTable){
            el.innerHTML = 'Displaying : '+ this.incomingNumbersTable.rowManager.displayRowsCount;
        }
    }
    if(this.incomingNumbersTable){
        let displayRows = this.incomingNumbersTable.rowManager.displayRows[1];
        let selectedRowCountInCurrPg  = displayRows.filter((row:any)=>{return row.getData().isSelected === true}).length;
        let selectedAllRows = document.querySelector('.select-all-row') as HTMLInputElement;
        if(selectedRowCountInCurrPg === displayRows.length && displayRows.length !== 0){
            selectedAllRows.checked = true;
        }else{
            selectedAllRows.checked = false;
        }
        let totalSelectedRows = this.tablePreviewService.incomingNumbers.filter((entry:any)=>{return entry.isSelected === true}).length;//this.migrationPreviewTable.getSelectedRows().length;
        let el = document.getElementById("selectedRowsCount");
        if(totalSelectedRows > 0){
            el.innerHTML = 'Selected : '+ totalSelectedRows;
            this.isRowSelected = true;
        }else{
            el.innerHTML = '';
            this.isRowSelected = false;
        }
    }
  }

  fetchErrorWarningData(){
    this.errorcount = 0;
    this.errorWarningList = [];
    let tempErrorList = Array.from(this.tablePreviewService.icrAADestinationNotFoundWarningList);
    tempErrorList.forEach((site) =>{
      if(site[1].length > 0)
      this.errorWarningList.push(site);
    })
    this.errorWarningList.forEach((site) =>{
      if(site[0] === "")
      site[0] = "MainSite";
      this.errorcount += site[1].length;
    })        
  }

  getErrorWarningList(){
    if(this.errorWarningList.length != 0){
        this.displayErrorWarningBlock();
    }else{
        this.logger.debug("Warning list is empty");
    }
  }

  displayErrorWarningBlock(){
    document.getElementById("incoming-numbers-tabulator-div").style.display = "none";
    document.getElementById("tableDoneBtn").style.display = "none";
    this.showErrorWarningBlock = true;
  } 

  closeErrorWarningBlock(){
    this.displayTable(); 
  }

  closeMigrationActivity(){
    this.session.alertModal(this.onClose.bind(this), this.TABLE_PROMPT_MSG);
  }

  onClose() {
    this.logger.debug("TablePreview - Close current Migration Activity");
    this.migrationDataService.clearData();
    this.tablePreviewService.saveCompanyData(this.id)
    .then((res) => {
        this.logger.debug("table data saved");
        //this.tablePreviewService.isTableUpdated(this.id);
        this.zone.run(() => { this.router.navigate(['/home/migration']);});
    }).catch((error) => {
        this.logger.debug("saving table data failed. ",error);
    });
    
  }



  displayTable(){
    document.getElementById("incoming-numbers-tabulator-div").style.display = "block";
    document.getElementById("tableDoneBtn").style.display = "inline-block";
    this.showErrorWarningBlock = false;
  }

 onCloseRedirectToSummaryPg(){
        this.zone.run(() => { 
            this.isReadyToClose = true;
            this.router.navigate(['/home/migration/config-summary/user-data']); 
        });
    }

  done (){
    this.overlay.openSpinner(this.TIMER_INTERVAL,this.timeupCallbackfunc,MatSpinnerOverlayComponent);
        this.tablePreviewService.saveCompanyData(this.id)
            .then((res) => {
             // this.tablePreviewService.isTableUpdated(this.id);
                this.overlay.closeSpinner();
                this.isReadyToClose = true;
                this.zone.run(() => { 
                    this.router.navigate(['/home/migration/config-summary/system-data']); 
                });
            }).catch((error) => {
                this.logger.debug("saving table data failed. ",error);
                this.overlay.closeSpinner();
                this.session.alertModal(this.onCloseRedirectToSummaryPg.bind(this), 
                    this.TABLE_DATA_SAVE_FAILED_MSG + this.CLOSE_TABLE_MSG ,'Close','Retry');
            });                  
  }
  changePhoneAssignment(data){
    var cellValue = data._cell.value;
    this.logger.debug("changePhoneAssignmentTo: ", cellValue);
    this.incomingNumbersTable.replaceData(this.tablePreviewService.incomingNumbers);
  }
}
