import { Component, OnInit, NgZone} from '@angular/core';
import Tabulator from 'tabulator-tables';
import { TablePreviewService,PAGINATION_SIZE } from 'src/app/shared/services/tablePreview/table-preview.service';
import { Router } from '@angular/router';
import { MigrationDataService } from 'src/app/shared/services/migrationDataService/migration-data.service';
import { SessionHandlerService } from 'src/app/shared/services/sessionHandler/session-handler.service';
import { OverlayService } from 'src/app/shared/services/overlay/overlay.service';
import { LogService } from 'src/app/shared/services/logging/log.service';
import { MatSpinnerOverlayComponent } from 'src/app/shared/components/mat-spinner-overlay/mat-spinner-overlay.component';

@Component({
    selector: 'app-park-location-preview',
    templateUrl: './park-location-preview.component.html',
    styleUrls: ['../../../../assets/customcss/table-component.css']
})
export class ParkLocationPreviewComponent implements OnInit {
    searchInput:any;
    searchOption = "Entire Table";
    parkLocationTable : Tabulator;
    readonly TABLE_PROMPT_MSG = 'Are you sure you want to close current Migration?';
    id:any;
    companyName:string;
    showErrorWarningBlock = false;  
    columnNames: any[] = [];
    readonly TIMER_INTERVAL = 60000;
    public errorWarningList = [];

    constructor(private tablePreviewService:TablePreviewService, private router: Router, public zone: NgZone,
        private migrationDataService:MigrationDataService, private session:SessionHandlerService,
        private overlay:OverlayService, private logger:LogService) {
            this.id = this.session.cardId;
            this.companyName = this.session.companyName;
            this.searchOption = "Entire Table";
        }

    ngOnInit(): void {
        this.logger.debug("Park-Location tablePreview: ngOnInit");
        this.columnNames = [
            { title: "Extension Number", field: "groupExtension", headerSort:true, sorter:"alphanum", width: "12%", formatter:(cell, formatterParams, onRendered)=>{
            var value = cell.getValue();
            if(!value){
              return '<i class="material-icons" style="font-size:16px;margin-left:-10%;color:rgb(49,49,49,.59);">remove</i>';
            }
            var res = (this.tablePreviewService.assignedExtnList.includes(value.toString()) || this.tablePreviewService.reservedExtensionsList.includes(parseInt(value)));
            if( res !== true )
            {
              return '<span style="margin-left:10%;">'+value+'</span>';
            }
            else
            {
              return "<span class='highlight-bg' style='margin-left:10%;'>" + value + "</span>";
            }
            }},
            { title: "Group Name", field: "groupName", headerSort:true, sorter:"alphanum"},
            { title: "Total Location Members", field: "members.length", headerSort:true, sorter:"alphanum", width: "18%", formatter:(cell, formatterParams, onRendered)=>{
                return '<span style="margin-left:15%;">'+cell.getValue()+'</span>'
            }}
        ]
    }

    ngAfterViewInit() {
        this.overlay.openSpinner(this.TIMER_INTERVAL, this.timeupCallbackfunc, MatSpinnerOverlayComponent);
        this.tablePreviewService.initializeTableData(this.id).then(() => {
            this.parkLocationTable = new Tabulator("#park-location-tabulator-div",{ layout: "fitDataFill",      //fit columns to width of table
                data:this.tablePreviewService.tableParkLocationList,
                maxHeight:"100%",
                autoResize:true,
                resizableColumns:false,
                resizableRows:false,
                responsiveLayout:'hide',  //hide columns that dont fit on the table
                tooltips:true,            //show tool tips on cells
                addRowPos:"top",          //when adding a new row, add it to the top of the table
                history:true,             //allow undo and redo actions on the table
                pagination:"local",       //paginate the data
                paginationSize:PAGINATION_SIZE,
                footerElement:this.getFooterElement(),
                dataFiltered: this.updateFooterOnFiltered,
                pageLoaded: this.updateFooterOnPgLoaded,
                movableColumns:false,
                initialSort: [             //set the initial sort order of the data
                    { column: "name", dir: "asc" }
                ]
            });
            this.parkLocationTable.setColumns(this.columnNames);
            this.parkLocationTable.setData(this.tablePreviewService.tableParkLocationList);
            this.fetchErrorWarningData();
            this.overlay.closeSpinner();
            let colHeaders = document.getElementsByClassName("tabulator-col");
            for (let i=1;i<colHeaders.length;i++) {
                colHeaders[i].classList.add("centerAlign");
            }
        }).catch((error) => {
            this.logger.debug(error);
            this.overlay.closeSpinner();
        });
    }

    ngOnDestroy(){
    }

    updateFilter(){
        if(this.searchInput !== undefined){
            let filterVal = this.searchOption;
            var typeVal = "like";
            if (filterVal) {
                if (filterVal === "Entire Table") {
                    this.parkLocationTable.setFilter([[
                        {field:"groupExtension", type:typeVal, value:this.searchInput},
                        {field:"groupName", type:typeVal, value:this.searchInput}
                    ]]);
                } else {
                    this.parkLocationTable.setFilter(this.searchOption, typeVal, this.searchInput);
                }
            }
        }
    }

    timeupCallbackfunc(ol){
        ol.showSpinner = false;
        ol.closeSpinner();
    }

    fetchErrorWarningData(){
        this.errorWarningList = this.tablePreviewService.parkLocationWarningsList;
    }

    getFooterElement(){
        return '<span style="float:left;" class="customFooter" id="footerNote"></span>';
    }

    updateFooterOnPgLoaded = (pageNum) => {
        if (this.parkLocationTable) {
            var el = document.getElementById("footerNote");
            let currPageNum = this.parkLocationTable.getPage();
            if (currPageNum) {
                let startIdx = ((currPageNum-1)*PAGINATION_SIZE)+1;
                let endIdx = startIdx + this.parkLocationTable.rowManager.displayRowsCount - 1;
                el.innerHTML = startIdx + ' - ' + endIdx + ' of ' +this.tablePreviewService.tableParkLocationList.length +' Park-Location';
            }
        }
    }

    updateFooterOnFiltered = (filters, rows) => {
        var el = document.getElementById("footerNote");
        if (rows.length) {
            if (filters.length != 0) {
                if (this.searchOption === "Entire Table") {
                    var displayRowsCount = this.parkLocationTable.rowManager.displayRowsCount;
                    if (filters[0][0].value.trim() == "" || displayRowsCount > rows.length) {
                        displayRowsCount = rows.length;
                    }
                } else {
                    var displayRowsCount = this.parkLocationTable.rowManager.displayRowsCount;
                    if (filters[0].value.trim() == "" || displayRowsCount > rows.length) {
                        displayRowsCount = rows.length;
                    }

                    var selectedCount = displayRowsCount < PAGINATION_SIZE ? displayRowsCount : PAGINATION_SIZE;
                    el.innerHTML = '1-' + selectedCount + ' of ' + rows.length + ' Park-Location';
                }
            } else {
                if (this.parkLocationTable) {
                    let currPageNum = this.parkLocationTable.getPage();
                    if (currPageNum) {
                        //TO DO: Change tableUserList to parkLocation
                        el.innerHTML = '1-' + this.parkLocationTable.rowManager.displayRowsCount + ' of ' + this.tablePreviewService.tableParkLocationList.length + ' Park-Location';
                    }
                }
            }
        } else {
            el.innerHTML = "";
        }
    }

    closeMigrationActivity() {
        this.session.alertModal(this.onClose.bind(this), this.TABLE_PROMPT_MSG);
    }

    onClose() {
        this.logger.debug("TablePreview - Close current Migration Activity");
        this.migrationDataService.clearData();
        this.tablePreviewService.saveTableData(this.id)
            .then((res) => {
                this.logger.debug("table data saved");
                this.zone.run(() => { this.router.navigate(['/home/migration']); });
            }).catch((error) => {
                this.logger.debug("saving table data failed. ", error);
            });
    }

    getErrorWarningList() {
        if (this.errorWarningList.length != 0) {
            this.displayErrorWarningBlock();
        } else {
            this.logger.debug("Warning list is empty");
        }
    }

    displayErrorWarningBlock() {
        document.getElementById("park-location-tabulator-div").style.display = "none";
        document.getElementById("tableDoneBtn").style.display = "none";
        this.showErrorWarningBlock = true;
    }

    displayTable() {
        document.getElementById("park-location-tabulator-div").style.display = "block";
        document.getElementById("tableDoneBtn").style.display = "inline-block";
        this.showErrorWarningBlock = false;
    }

    closeErrorWarningBlock() {
        this.displayTable();
    }

    done() {
        this.logger.debug("done");
        this.zone.run(() => { this.router.navigate(['/home/migration/config-summary/system-data']); });
    }
}
