    <div class="tabInfo">    
        <mat-label class="NotificationsLabel">
            Notifications Report
        </mat-label>
        <div style="margin: top 1.2emvw; font-size: 1vw;">
        <p>click on "Download Report" to download the Notifications(warnings/errors) for User Data and System data that includes Call Queues,
            Paging Only, Auto-Receptionists and Working Hours.
        </p>
        </div>
        <br>
        <br>
        <div class="NotificationsLabel">
        <a (click)="triggerDownloadReport()">Download Report</a>
        </div>
        <!-- <div style="position: absolute; opacity: 0; pointer-events:none;width: 70%;height: 0%!important;overflow: hidden;">
            <div id="notificationsReport" style="width: 70%;margin-top: 0.5vw;">
                <br><br><br><br>
                <div style="display: flex;flex-direction: row;justify-content: center;align-content: center;">
                    <span style="justify-self: center;align-self: center; font-size: 1.8vw;">Notifications Report</span>
                </div>
                <br>
                <div class="downloadReportFont_0_9vw" style="text-align: left;display: flex;flex-direction: column">
                    <span class="downloadReportMargin_0_7vw">Company Name : {{companyName}}</span>
                    <span class="downloadReportMargin_0_7vw">Report Downloaded on : {{downloadTime}}<hr></span>
                    <br>
                    <br>
                </div>
                <div style="display: flex;flex-direction: row;justify-content: center;align-content: center">
                    <span class = "mainTitles">System Summary Warnings</span>
                </div>
                <div>
                    <ul>
                        <span *ngFor="let row of systemSummaryWarning" class="content"><li>{{row}}</li><br></span>                          
                    </ul>
                    <hr>
                    <br>
                </div>
                     <div style="display: flex;flex-direction: row;justify-content: center;align-content: center;">
                    <span class = "mainTitles">User Data Notifications</span>
                </div>
                <div>
                    <div class = "featureTitles">
                        <span class="titles"><u>Basic Information</u></span>
                        <span *ngIf="basicInfoError.length !== 0" class="errors">Errors</span>
                        <ul>
                        <span *ngFor="let row of basicInfoError" class="content"><li>{{row}}</li><br></span>                          
                        </ul>
                        <span *ngIf="basicInfoWarnings.length !== 0" class="warnings">Warnings</span>
                        <ul>
                        <span *ngFor="let row of basicInfoWarnings" class="content"><li>{{row}}</li><br></span>
                        </ul>
                    </div>
                    <div class = "featureTitles">
                        <span class="titles"><u>Advanced Information</u></span>
                        <span *ngIf="advInfoWarnings.length !== 0" class="warnings">Warnings</span>
                        <ul>
                        <span *ngFor="let row of advInfoWarnings" class="content"><li>{{row}}</li><br></span>
                        </ul>  
                    </div>
                    <div style="display: flex;flex-direction: row;justify-content: center;align-content: center;">
                        <span *ngIf="aaWarnings.length !== 0 && huntGroupWarnings.length !== 0 && pagingGroupWarnings.length && tpWarnings.length !== 0 && icrWarnings.length !== 0" class="mainTitles">System Data Warnings</span>
                    </div>
                    <div *ngIf="huntGroupWarnings.length !== 0" class = "featureTitles">
                        <span *ngIf="huntGroupWarnings.length !== 0" class="titles"><u>Hunt Groups</u></span>
                        <span *ngIf="huntGroupWarnings.length !== 0" class="warnings">Warnings</span>
                        <ul>
                        <span *ngFor="let row of huntGroupWarnings"class="content"><li>{{row}}</li><br></span>
                        </ul>  
                    </div>
                    <div *ngIf="pagingGroupWarnings.length !== 0" class = "featureTitles">
                        <span *ngIf="pagingGroupWarnings.length !== 0"class="titles"><u>Paging Groups</u></span>
                        <span *ngIf="pagingGroupWarnings.length !== 0" class="warnings">Warnings</span>
                        <ul>
                        <span *ngFor="let row of pagingGroupWarnings" class="content"><li>{{row}}</li><br></span>
                        </ul>  
                    </div>
                    <div *ngIf="tpWarnings.length !== 0"  class = "featureTitles">
                        <span *ngIf="tpWarnings.length !== 0" class="titles"><u>Working Hours</u></span>
                        <span *ngIf="tpWarnings.length !== 0" class="warnings">Warnings</span>
                        <ul>
                        <span *ngFor="let row of tpWarnings"  class="content"><li>{{row}}</li><br></span>
                        </ul>  
                    </div>
                    <div  *ngIf="aaWarnings.length !== 0" class = "featureTitles">
                        <span *ngIf="aaWarnings.length !== 0" class="titles"><u>Auto-Receptionists</u></span>
                        <span *ngIf="aaWarnings.length !== 0" class="warnings">Warnings</span>
                        <ul>
                        <span *ngFor="let row of aaWarnings" class="content"><li>{{row}}</li><br></span>
                        </ul>  
                    </div>
                    <div  *ngIf="icrWarnings.length !== 0" class = "featureTitles">
                        <span *ngIf="icrWarnings.length !== 0" class="titles"><u>Company Numbers</u></span>
                        <span *ngIf="icrWarnings.length !== 0" class="warnings">Warnings</span>
                        <ul>
                        <span *ngFor="let row of icrWarnings" class="content"><li>{{row}}</li><br></span>
                        </ul>  
                    </div>
                </div>
            </div>            
        </div> -->
    </div>
