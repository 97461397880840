import { Component, OnInit, NgZone} from '@angular/core';
import Tabulator from 'tabulator-tables';
import { TablePreviewService,PAGINATION_SIZE } from 'src/app/shared/services/tablePreview/table-preview.service';
import { Router } from '@angular/router';
import { MigrationDataService } from 'src/app/shared/services/migrationDataService/migration-data.service';
import { SessionHandlerService } from 'src/app/shared/services/sessionHandler/session-handler.service';
import { OverlayService } from 'src/app/shared/services/overlay/overlay.service';
import { LogService } from 'src/app/shared/services/logging/log.service';
import { MatSpinnerOverlayComponent } from 'src/app/shared/components/mat-spinner-overlay/mat-spinner-overlay.component';

@Component({
  selector: 'app-auto-attendants-preview',
  templateUrl: './auto-attendants-preview.component.html',
  styleUrls: ['../../../../assets/customcss/table-component.css']
})
export class AutoAttendantsPreviewComponent implements OnInit {

  searchInput:any;
  searchOption = "name";
  autoAttendantsTable : Tabulator;
  readonly TABLE_PROMPT_MSG = 'Are you sure you want to close current Migration?';
  id:any;
  companyName:string;
  showErrorWarningBlock = false;  
  columnNames: any[] = [];
  readonly TIMER_INTERVAL = 60000;
  public errorWarningList = [];
  public errorcount = 0;
  constructor(private tablePreviewService:TablePreviewService,
    private router: Router , 
    public zone: NgZone,
    private migrationDataService:MigrationDataService, private session:SessionHandlerService,
    private overlay:OverlayService, private logger:LogService) {
      this.id = this.session.cardId;
      this.companyName = this.session.companyName;
  }

  ngOnInit(): void {
    this.logger.debug("Auto-Receptionists tablePreview: ngOnInit");
    this.columnNames = [
        { title: "Auto-Receptionists", field: "name" , headerSort:true, sorter:"alphanum", width:"15%", formatter:(cell, formatterParams, onRendered)=>{
            return '<span style="margin-left:10%;">'+cell.getValue()+'</span>';
        }},        
        { title: "Menu Availability", field: "menuAvailability" , headerSort:true, sorter:"boolean", width:"15%" , formatter:this.tickFormatter,align:"center"},
        { title: "IVR Prompts", field: "ivrPrompt" , headerSort:true, sorter:"boolean",width:"15%", formatter:this.tickFormatter, align:"center" },
        { title: "Site Name", field: "siteName", headerSort:true, sorter:"alphanum" ,formatter:(cell, formatterParams, onRendered)=>{
          if(cell.getValue() === "")
           return '<span">MainSite</span>';
          //to be removed 
          else if(cell.getValue() === undefined){
            return "";
          }
          else
            return '<span>'+cell.getValue()+'</span>';
         }
        }
    ];
  }

  private tickFormatter = (cell, formatterParams, onRendered)=>{
    var val = cell.getValue();
    if( val == undefined || val == null || !val)
      return '<i class="material-icons" style="font-size:16px;margin-left:-10%;color:rgb(49,49,49,.59);">remove</i>';
    else
      return '<i class="material-icons" style="font-size:16px;margin-left:-10%;color:rgb(49,49,49,.59);">check</i>';
  }


  ngAfterViewInit(){    
     this.overlay.openSpinner(this.TIMER_INTERVAL,this.timeupCallbackfunc,MatSpinnerOverlayComponent);
        this.tablePreviewService.initializeTableData(this.id).then(()=>{
        this.autoAttendantsTable = new Tabulator("#auto-attendants-tabulator-div",{	layout:"fitDataFill",      //fit columns to width of table
          data:this.tablePreviewService.tableAutoAttendantList,
          maxHeight:"100%",
          autoResize:true,
          resizableColumns:false,
          resizableRows:false, 
          responsiveLayout:'hide',  //hide columns that dont fit on the table
          tooltips:true,            //show tool tips on cells
          addRowPos:"top",          //when adding a new row, add it to the top of the table
          history:true,             //allow undo and redo actions on the table
          pagination:"local",       //paginate the data
          paginationSize:PAGINATION_SIZE,
          footerElement:this.getFooterElement(),
          dataFiltered: this.updateFooterOnFiltered,
          pageLoaded: this.updateFooterOnPgLoaded,
          movableColumns:false,
          initialSort:[             //set the initial sort order of the data
            {column:"name", dir:"asc"}
          ]
        });
        this.autoAttendantsTable.setColumns(this.columnNames);
        this.autoAttendantsTable.setData(this.tablePreviewService.tableAutoAttendantList);
        this.fetchErrorWarningData();
        this.overlay.closeSpinner();
        let colHeaders = document.getElementsByClassName("tabulator-col");
        for(let i=1;i<colHeaders.length;i++){
          colHeaders[i].classList.add("centerAlign");
        }
      }).catch((error)=>{
        this.logger.debug(error)
        this.overlay.closeSpinner();
      });
  }

  ngOnDestroy(){
  }

  timeupCallbackfunc(ol){
    ol.showSpinner = false;
    ol.closeSpinner();
  }

  fetchErrorWarningData(){ 
    this.errorcount = 0;
    this.errorWarningList = [];
    let tempErrorList = Array.from(this.tablePreviewService.autoAttendantsWarningsList);
    tempErrorList.forEach((site) =>{
      if(site[1].length > 0)
      this.errorWarningList.push(site);
})
    this.errorWarningList.forEach((site) =>{
      if(site[0] === "")
      site[0] = "MainSite";
      this.errorcount += site[1].length;
    })      
  }

  updateFilter(){
    if(this.searchInput !== undefined){
      var typeVal = "like";
      this.autoAttendantsTable.setFilter(this.searchOption, typeVal, this.searchInput); 
    }
  }

  getFooterElement(){
    return '<span style="float:left;" class="customFooter" id="footerNote"></span>';
  }

  updateFooterOnPgLoaded = (pageNum)=>{
    if( this.autoAttendantsTable){
      var el = document.getElementById("footerNote");
      let currPageNum = this.autoAttendantsTable.getPage();
      if(currPageNum){
        let startIdx = ((currPageNum-1)*PAGINATION_SIZE)+1;
        let endIdx = startIdx + this.autoAttendantsTable.rowManager.displayRowsCount - 1;
        //TO DO: Change tableUserList to tableAutoAttendants
        el.innerHTML = startIdx + ' - '+ endIdx +' of '+this.tablePreviewService.tableAutoAttendantList.length +' Auto-Receptionists';
      }
    }
  }

  updateFooterOnFiltered = (filters, rows)=>{
    var el = document.getElementById("footerNote");
    if(rows.length){      
      if(filters.length != 0 ){
        var displayRowsCount = this.autoAttendantsTable.rowManager.displayRowsCount;
        if(filters[0].value.trim() =="" || displayRowsCount >  rows.length){
          displayRowsCount =  rows.length;
        }
        var selectedCount = displayRowsCount < PAGINATION_SIZE ? displayRowsCount : PAGINATION_SIZE;
        el.innerHTML = '1-'+ selectedCount +' of '+rows.length +' Auto-Receptionists';
      }else{
        if( this.autoAttendantsTable){
          let currPageNum = this.autoAttendantsTable.getPage();
          if(currPageNum){
            //TO DO: Change tableUserList to tableAutoAttendants
            el.innerHTML = '1-'+this.autoAttendantsTable.rowManager.displayRowsCount+' of '+this.tablePreviewService.tableAutoAttendantList.length +' Auto-Receptionists';
          }
        }
      }
    }else{
      el.innerHTML = "";
    }
  }

  closeMigrationActivity(){
    this.session.alertModal(this.onClose.bind(this), this.TABLE_PROMPT_MSG);
  }

  onClose() {
    this.logger.debug("TablePreview - Close current Migration Activity");
    this.migrationDataService.clearData();
    this.tablePreviewService.saveTableData(this.id)
    .then((res) => {
        this.logger.debug("table data saved");
        this.zone.run(() => { this.router.navigate(['/home/migration']);});
    }).catch((error) => {
        this.logger.debug("saving table data failed. ",error);
    });
  }


  getErrorWarningList(){
    if(this.errorWarningList.length != 0){
        this.displayErrorWarningBlock();
    }else{
        this.logger.debug("Warning list is empty");
    }
  }

  displayErrorWarningBlock(){
    document.getElementById("auto-attendants-tabulator-div").style.display = "none";
    document.getElementById("tableDoneBtn").style.display = "none";
    this.showErrorWarningBlock = true;
  } 

  displayTable(){
    document.getElementById("auto-attendants-tabulator-div").style.display = "block";
    document.getElementById("tableDoneBtn").style.display = "inline-block";
    this.showErrorWarningBlock = false;
  }

  closeErrorWarningBlock(){
    this.displayTable(); 
  }

  done (){
    this.logger.debug("done");
    this.zone.run(() => { this.router.navigate(['/home/migration/config-summary/system-data']); });    
  }
}
