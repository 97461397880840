import { Injectable } from '@angular/core';
import { FirestoreService } from 'src/app/shared/services/firestore/firestore.service';
import { LogService } from '../logging/log.service';

@Injectable({
  providedIn: 'root'
})
export class MigrationDataService {

    private customerCompanyName:string;
    private csvFileName:string;

    //Upload csv component dependent properties
    private ipoSummaryData:any;

    private phoneTypeData:any;
    private acoSummaryData:any;

    //TablePreview component dependent properties
    private tablePreviewData:any;
    private acoImportData:any;

    //Upload csv, TablePreview component dependent properties
    private ipoUserData:any;
    private ipoCsvData:any;
    private ipoTimeProfile:any;
    private ipoHuntGroup:any;
    private autoAttendant:any
    private incomingCallRoute:any;
   // private pagingGroup: any;   
    private shortCodes: any;
    private steeringCodes: any;
    private ipoBcaData:any;
    private ipoUserAppearanceData:any;
    private ipoCallParkData:any
    private morrisonData:any ;
    private siteDetails: any;
    private zeroDialData: any;
    private userRestrictionData : any;
    private site_csv_map = new Map();
    private multiSiteEnabled = false;
    
    constructor(private firestoreService: FirestoreService,
        private logger:LogService) {
        this.logger.info("MigrationDataService constructor"); 
    }

    set ipoCSVFileName(data: string) {
        this.csvFileName = data;
    }

    get ipoCSVFileName(): string {
        return this.csvFileName;
    }

    get IPOUserData(): any {
        return this.ipoUserData;
    }

    set IPOUserData(data: any) {
        this.ipoUserData = data;
    }

    set IPOTimeProfile(data: any){
        this.ipoTimeProfile = data;
    }

    get IPOTimeProfile(): any{
        return this.ipoTimeProfile;
    }

    set IPOHuntGroup(data: any){
        this.ipoHuntGroup = data;
    }

    get IPOHuntGroup(): any{
        return this.ipoHuntGroup;
    }

    set AutoAttendant(data: any){
        this.autoAttendant = data;
    }

    get AutoAttendant(): any {
        return this.autoAttendant;
    }

    set IncomingCallRoute(data: any){
        this.incomingCallRoute = data;
    }

    get IncomingCallRoute():any {
        return this.incomingCallRoute;
    }

    set UserRestrictionData(data: any){
        this.userRestrictionData = data;
    }

    get UserRestrictionData():any {
        return  this.userRestrictionData;
    }
   
    // get PagingGroup(): any {
    //     return this.pagingGroup;
    // }

    // set PagingGroup(value: any) {
    //     this.pagingGroup = value;
    // }

    get ShortCodes(): any {
        return this.shortCodes;
    }

    set ShortCodes(value: any) {
        this.shortCodes = value;
    }

    get SteeringCodes(): any {
        return this.steeringCodes;
    }

    set SteeringCodes(value: any) {
        this.steeringCodes = value;
    }

    get IPOBCAData(): any{
        return this.ipoBcaData;
    }

    set IPOBCAData(value: any) {
        this.ipoBcaData = value;
    }

    get IPOUserAppearanceData(): any{
        return this.ipoUserAppearanceData;
    }

    set IPOUserAppearanceData(value: any) {
        this.ipoUserAppearanceData = value;
    }

    get IPOCallParkData(): any{
        return this.ipoCallParkData;
    }

    set IPOCallParkData(value: any) {
        this.ipoCallParkData = value;
    }

    get Morrison(): any {
        return this.morrisonData;
    }

    set Morrison(value: any) {
        this.morrisonData = value;
    }


    get IPOCsvData(): any {
        return this.ipoCsvData;
    }

    set IPOCsvData(data: any) {
        this.ipoCsvData = data;
    }

    get ACOImportData(): any{
        return this.acoImportData;
    }

    set ACOImportData(data: any){
        this.acoImportData = data;
    }

    get TablePreviewData(): any{
        return this.tablePreviewData;
    }

    set TablePreviewData(data: any){
        this.tablePreviewData = data;
    }

    get IPOSummaryData(): any {
        return this.ipoSummaryData;
    }

    set IPOSummaryData(data: any) {
        this.ipoSummaryData = data;
    }

    get ACOSummaryData(): any {
        return this.acoSummaryData;
    }

    set ACOSummaryData(acoSummaryData: any) {
        this.acoSummaryData = acoSummaryData;
    }

    get getCustomerCompanyName(): string {
        return this.customerCompanyName;
    }

    set setCustomerCompanyName(customerCompanyName: string){
        this.customerCompanyName = customerCompanyName;
    } 

    get SiteDetails(): any {
        return this.siteDetails;
    }
    set SiteDetails(value: any) {
        this.siteDetails = value;
    }  

    get ZeroDialData(): any {
        return this.zeroDialData;
    }

    set ZeroDialData(data: any) {
        this.zeroDialData = data;
    }

    public get MultiSiteEnabled() {
        return this.multiSiteEnabled;
    }
    public set MultiSiteEnabled(value) {
        this.multiSiteEnabled = value;
    }

    public readIPOData(cardId): any {
        var promise = new Promise((resolve, reject) => {
            if(this.ipoCsvData === undefined || this.ipoCsvData === null){
                this.logger.debug("getIpoUserData from DB")
                this.firestoreService.readIPOCfgData(cardId)
                .then((result:any) => {
                    this.ipoCsvData = JSON.parse(result);
                    this.ipoUserData = this.ipoCsvData['IPOUsers'];
                    resolve();
                })
                .catch(error => reject(error));
            }
            resolve();
        });

        return promise;
    }
   
    public readACOImportData(cardId): any {
        var promise = new Promise((resolve, reject) => {
            if(this.acoImportData === undefined || this.acoImportData === null){
                this.logger.debug("readACOImportData from DB")
                this.firestoreService.getAcoImportData(cardId)
                .then((result:any) => {
                    resolve(result);
                })
                .catch(error => reject(error));
            }
            resolve();
        });

        return promise;
    }

    public readTablePreviewData(cardId): any {
        var promise = new Promise((resolve, reject) => {
            if(this.tablePreviewData === undefined || this.tablePreviewData === null){
                this.logger.debug("readTablePreviewData from DB")
                this.firestoreService.readTableData(cardId)
                .then((result:any) => {
                    resolve(result);
                })
                .catch(error => reject(error));
            }
            reject("Table data unavailable from DB");
        });
        
        return promise;
    }

    public getPhoneTypeData(): any {
        return this.phoneTypeData;
    }

    public setPhoneTypeData(phoneTypeData: any): void {
        this.phoneTypeData = phoneTypeData;
    } 
    

    public clearData(){
        //reset all local variables to null upon triggering of migration / aborting the migration process
        this.customerCompanyName = null;
        this.csvFileName = null;
        this.ipoSummaryData = null;
        this.phoneTypeData = null;
        this.acoSummaryData = null;
        this.tablePreviewData = null;
        this.acoImportData = null;
        this.ipoUserData = null;
        this.ipoCsvData = null;
        this.ipoTimeProfile = null;
        this.ipoHuntGroup = null;
        this.autoAttendant = null;    
        this.incomingCallRoute = null;
      //  this.pagingGroup = null;
        this.shortCodes = null;
        this.steeringCodes = null;
        this.ipoBcaData = null;
        this.ipoUserAppearanceData = null;
        this.ipoCallParkData = null;
        this.morrisonData = null;
        this.siteDetails = null;
        this.zeroDialData = null;
        this.site_csv_map.clear(); 
        this.UserRestrictionData = null;

    }   
    
    public async modifyDataDocForAllFeatures(cardId ,successSites){
        successSites.forEach(successSite => {
            let mapSite = this.siteDetails.find((siteMap) => siteMap.siteName === successSite.value.response.name);
            if(mapSite){
                mapSite.siteId = successSite.value.response.id;
                mapSite.siteExtension = successSite.value.response.extensionNumber;
                mapSite.siteTimeZone = {
                    id: successSite.value.response.regionalSettings.timezone.id,
                    description: successSite.value.response.regionalSettings.timezone.description,
                    bias: successSite.value.response.regionalSettings.timezone.bias
                };
                mapSite.homeCountry = successSite.value.response.regionalSettings.homeCountry.name;
                if(!this.site_csv_map.has(successSite.value.response.name)){
                    this.site_csv_map.set(successSite.value.response.name, {
                        siteId : successSite.value.response.id,
                        siteExtension : successSite.value.response.extensionNumber,
                        siteName: successSite.value.response.name
                    })
                }
            }
        });
        const csvFileNameToDB = await this.firestoreService.setSiteDetails(cardId, this.SiteDetails);
        this.IPOCsvData.IPOUsers.forEach(user => {
            if(this.site_csv_map.has(user.Filename.split(".csv")[0])){
                user.SiteId = this.site_csv_map.get(user.Filename.split(".csv")[0]).siteId;
                user.Sitename = this.site_csv_map.get(user.Filename.split(".csv")[0]).siteName;
                //extension can also be added if required.
            }else{
                //ACOS-1719 add default siteName as MainSite for all features 
                //this is to supported searching in tabulator
                user.Sitename = "MainSite";
            }
        });

        this.IPOHuntGroup.HuntGroup.forEach(hg => {
            if(this.site_csv_map.has(hg.Filename.split(".csv")[0])){
                hg.SiteId = this.site_csv_map.get(hg.Filename.split(".csv")[0]).siteId;
                hg.Sitename = this.site_csv_map.get(hg.Filename.split(".csv")[0]).siteName;
                //extension can also be added if required.
            }else{
                hg.Sitename = "MainSite";
            }
        });

        if (this.IncomingCallRoute.IncomingCallRoute && this.IncomingCallRoute.IncomingCallRoute.length) {
            this.incomingCallRoute.IncomingCallRoute.forEach(eachICR => {
                if(this.site_csv_map.has(eachICR.Filename.split(".csv")[0])){
                    eachICR.SiteId = this.site_csv_map.get(eachICR.Filename.split(".csv")[0]).siteId;
                    eachICR.Sitename = this.site_csv_map.get(eachICR.Filename.split(".csv")[0]).siteName;
                }else{
                    eachICR.Sitename = "MainSite";
                }
            })
        }

        this.IPOTimeProfile.TimeProfile.forEach(tp => {
            if(this.site_csv_map.has(tp.Filename.split(".csv")[0])){
                tp.SiteId = this.site_csv_map.get(tp.Filename.split(".csv")[0]).siteId;
                tp.Sitename = this.site_csv_map.get(tp.Filename.split(".csv")[0]).siteName;
                //extension can also be added if required.
            }else{
                tp.Sitename = "MainSite";
            }
        });
        this.ZeroDialData.forEach(system => {
            if(this.site_csv_map.has(system.fileName.split(".csv")[0])){
                system.siteId = this.site_csv_map.get(system.fileName.split(".csv")[0]).siteId;
            }
        });
        
        if(this.AutoAttendant.AutoAttendant && this.AutoAttendant.AutoAttendant.length){
            this.autoAttendant.AutoAttendant.forEach(eachAA => {
                const siteName = eachAA.Filename.split(".csv")[0];
                if(siteName && this.site_csv_map.has(siteName)){
                    eachAA.SiteId = this.site_csv_map.get(siteName).siteId;
                    eachAA.Sitename = this.site_csv_map.get(siteName).siteName;
                    eachAA.siteName = eachAA.Sitename;
                }else{
                    eachAA.Sitename = "MainSite"; 
                }
            })
        }

        if (this.UserRestrictionData.UserRestrictionData && this.UserRestrictionData.UserRestrictionData.length) {
            this.UserRestrictionData.UserRestrictionData.forEach(userRight => {
                if(this.site_csv_map.has(userRight.Filename.split(".csv")[0])){
                    userRight.SiteId = this.site_csv_map.get(userRight.Filename.split(".csv")[0]).siteId;
                    userRight.Sitename = this.site_csv_map.get(userRight.Filename.split(".csv")[0]).siteName;
                }else{
                    userRight.Sitename = "MainSite";
                }
            })
        }
        return ;
    }

}
