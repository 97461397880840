<div class="contentBlock migrationDataBlock">
    <div class="contentInfo">
        <button class="closeBtn"
            mat-icon-button
            matTooltip="close"
            (click)= "closeMigrationActivity()">
            <mat-icon style="font-size: 0.8vw;line-height: 1vw;">close</mat-icon>
        </button>
        <div class="migrationStepInfo">
            <span class="migrationStep">Step 3/6 : ACO Account Details</span>
            <span class="migrationCompany">{{companyName}}</span>
          </div>
    </div>

    <div class="contentMain">
        <mat-form-field class="migrationInputFormField" *ngIf="showAccId && !showErrorModal && !showSitesConfig">
            <mat-label>Account ID</mat-label>
            <input matInput type="text" [(ngModel)]="accountId" matTooltip="Enter the account Id of Avaya Cloud Office" required=false>
        </mat-form-field>
        <div style="display: flex;flex-direction: row;width: 35%!important;font-size: 1vw;" *ngIf="showAccId && !showErrorModal && !showSitesConfig">
            <mat-checkbox  id="multiSiteCheckBox" style="color: rgba(0,0,0,.54)" [(ngModel)]="isMultisiteEnabled"
            (change)="multiSiteChange($event.checked)" >Multi-Site Migration</mat-checkbox>
            <a style="text-decoration: underline;color: rgba(0,0,0,.54);font-size: 1vw;margin-left: 20%;"id="multiSiteCheckBox" (click)="showSitesDetails()" *ngIf="isMultisiteEnabled">Configure Sites</a>
        </div>

        <span class="errorMsg" *ngIf="showRCSignInErrMsg">
            <mat-icon class="errorMsgIcon">error</mat-icon>
            {{rcSignInErrorMsg}}
            <a style="text-decoration: underline;" (click)="triggerRcAuth()">Service Web</a>
        </span>

        <span class="errorMsg" *ngIf="siteFailure  && !showErrorModal">
            <mat-icon style=" font-size: 130%;color: #DA291C;">error</mat-icon>
            Creation of site(s) failed,
            <a style="text-decoration: underline;" (click)="viewErrorModal()">View details</a>
        </span> 
        
        <div class="errorWarningBlock" *ngIf=showSitesConfig>
          <div id="siteDetailsContainer" style="width: 100%;height: 88%; overflow-x: hidden;overflow-y: auto;">

          </div>
          <button class="tableButton " (click)="setSiteAddress()">
            Done
          </button>
        </div>
               
        <div id="errorWarningBlock" class="errorWarningBlock" *ngIf=showErrorModal>
            <div style="width: 100%;height: 88%; overflow-x: hidden;overflow-y: auto;">
              <table>
                <tr class="errorWarningRow" *ngFor="let item of notifications">
                  <td>
                    {{item.msg}}
                  </td>
                  <td *ngIf="item.severity == SEVERITY.WARNING">
                    <mat-icon style=" font-size: 1.1vw;color: #FF8200F7;">
                      warning
                    </mat-icon>
                  </td>
                  <td *ngIf="item.severity == SEVERITY.ERROR">
                    <mat-icon style=" font-size: 1.1vw;color: #DA291C;">
                      error
                    </mat-icon>
                  </td>
                </tr>
              </table>
            </div>
            <button class="tableButton " style="margin-top: 1%;" (click)="closeErrorWarningBlock()">
              Close
            </button>
        </div>
        
    </div>

    <div class="navigation">
        <button mat-button class="arrowBack" matSuffix mat-icon-button (click) = "navigateBack()">
            <mat-icon>arrow_back</mat-icon>
        </button>
        <button mat-button class="arrowForward" matSuffix mat-icon-button aria-label="Clear" (click)= "handleACOLogin()">
            <mat-icon>arrow_forward</mat-icon>
        </button>
    </div>
</div>

