import { Injectable } from "@angular/core";
import { LogService } from "../logging/log.service";
import * as jsPDF from "jspdf";
import { SessionHandlerService } from "src/app/shared/services/sessionHandler/session-handler.service";

export enum TYPE {
  TEXT,
  LINE
}
export enum ALIGN {
  LEFT="left",
  RIGHT="right",
  CENTER="center",
  JUSTIFY="justify"
}

export enum LINE_STYLE {
  STROKE="S",
  FILL = "F",
  FILL_n_STROKE = "DF"
}

export enum FONT_STYLES {
  NORMAL = "normal", 
  ITALIC = "italic",
  BOLD = "bold",
  BOLD_ITALIC = "bolditalic"
}
export enum  COLOR {
  ERROR = "red",
  WARNING = "green",
  NONE = "black"
}
export enum FONT_CLASS {
  MAIN_TITLE,
  TITLE,   
  CONTENT,
  HEADING,
  ERROR,
  WARNING,
  MAINCONTENT
}
export enum FONT_FAMILIES {
  "HELVETICA" = "helvetica",
  "COURIER" = "courier",
  "TIMES" ="times"
}

var main_title = {
  size : 18,
  align : ALIGN.CENTER,
  style : FONT_STYLES.BOLD,
  class : FONT_CLASS.MAIN_TITLE
}

var title = {
  size : 16,
  align : ALIGN.CENTER,
  style : FONT_STYLES.BOLD,
  class : FONT_CLASS.TITLE
}

var heading = {
  size : 14,
  align : ALIGN.LEFT,
  style : FONT_STYLES.BOLD,
  class : FONT_CLASS.HEADING
}

var normal_text = {
  size : 10,
  align : ALIGN.LEFT,
  style : FONT_STYLES.NORMAL,
  class : FONT_CLASS.CONTENT
}

var main_content = {
  size : 10,
  align : ALIGN.LEFT,
  style : FONT_STYLES.NORMAL,
  class : FONT_CLASS.MAINCONTENT
}

var error_heading = {
  size : 14,
  align : ALIGN.LEFT,
  style : FONT_STYLES.BOLD,
  class : FONT_CLASS.ERROR
}

var warning_heading = {
  size : 14,
  align : ALIGN.LEFT,
  style : FONT_STYLES.BOLD,
  class : FONT_CLASS.WARNING
}

export interface content{
  type:TYPE;
  color:string;
  align:string
}
export interface textStruct extends content{
  text:Array<string>;
  x:number;
  y:number;
  size:number;
  fontClass:FONT_CLASS;
  style:FONT_STYLES;
  fontFamily:FONT_FAMILIES;
}
export interface lineStruct extends content{
  x1:number;
  y1:number;
  x2:number;
  y2:number;
  weight:number;
}

export function getTextContent():textStruct{
  var textCont = {
      text:[],
      x:-1,
      y:-1,
      size : normal_text.size,
      fontClass:FONT_CLASS.CONTENT,
      style : FONT_STYLES.NORMAL,
      fontFamily : FONT_FAMILIES.HELVETICA,
      type : TYPE.TEXT,
      align : ALIGN.LEFT,
      color:COLOR.NONE        
  }
  return textCont;
}


export function setTextContPr(data):textStruct{
  switch(data.fontClass){        
      case FONT_CLASS.MAIN_TITLE:{
          data.size = main_title.size;
          data.style = main_title.style;
          data.align = main_title.align;
      }
      break;
      case FONT_CLASS.TITLE:{
          data.size = title.size;
          data.style = title.style;
          data.align = title.align;
      }
      break;
      case FONT_CLASS.HEADING:{
          data.size = heading.size;
          data.style = heading.style;
          data.align = heading.align;
      }
      break;
      case FONT_CLASS.MAINCONTENT:{
        data.size = main_content.size;
        data.style = main_content.style;
        data.align = main_content.align;
      }
      break;
      case FONT_CLASS.ERROR:{
        data.size = error_heading.size;
        data.style = error_heading.style;
        data.align = error_heading.align;
      }
      break;
      case FONT_CLASS.WARNING:{
        data.size = warning_heading.size;
        data.style = warning_heading.style;
        data.align = warning_heading.align;
      }
      break;
      default:{}
          break;
  }
  return data;
}

export function getLineContent():lineStruct{
  var lineCont = {
      x1:-1,
      y1:-1,
      x2:-1,
      y2:-1,
      type : TYPE.LINE,
      align : ALIGN.LEFT,
      color:COLOR.NONE,
      weight:DEFAULT_LINE_WEIGHT
  }
  return lineCont;
}

export const REPORT_NAME = {
  MIGRATION_REPORT : "migrationStatusReport",
  NOTIFICATION_REPORT : "notificationsReport",
  DELETION_REPORT : "deletionStatusReport"
}
const DEFAULT_FONT = FONT_FAMILIES.HELVETICA;
const DEFAULT_LINE_WEIGHT = 2;

@Injectable({
  providedIn: 'root'
})
export class DownloadNotificationService {
  private id: any;
  public reportData: any;
  public basicInfoError: any;
  public basicInfoWarnings: any;
  public advInfoWarnings: any;
  public huntGroupWarnings: any;
  public pagingGroupWarnings: any;
  public parkLocWarnings: any;
  public aaWarnings: any;
  public tpWarnings: any;
  public icrWarnings:any;
  public systemSummaryWarning: any;
  public downloadTime:Date;

  constructor(
    private logger: LogService,
    private session: SessionHandlerService
  ) {
    this.logger.debug("File Upload service");
    this.id = this.session.cardId;
    this.logger.debug("NotificationDownloadComponent constructor: ", this.id);
  }
  
  generatePDF(id, companyName,reportData=[]) {
    this.logger.debug("generate Notification PDF");
    var filename;
    this.downloadTime = new Date();
    var doc = new jsPDF("p", "mm", "a4");
    let pageWidth = Math.floor(doc.internal.pageSize.getWidth());
    let pageHeight = Math.floor(doc.internal.pageSize.getHeight()) - 15;
    let centerPt = pageWidth/2;
    let leftMargin = 10, contentLeftMargin = leftMargin+ 10;
    let rightMargin = pageWidth-10 ;
    let pageYIdx = 10,page = 1,yStartIndex = 15;
    doc.setFontSize(10);
    doc.text("Page "+page++ , 185, 6);
    //================================================================================
    for(let i=0;i<reportData.length;i++){      
      if(pageYIdx > pageHeight){
        doc.addPage();
        doc.setFontSize(10);
        doc.text("Page "+page++ , 185, 6);
        pageYIdx = yStartIndex;
      }
      let data = reportData[i];
      pageYIdx += 4;
      switch(data.type){
        case TYPE.TEXT : {
          data.y = pageYIdx;      
          switch(data.fontClass){
            case FONT_CLASS.MAIN_TITLE:
            case FONT_CLASS.TITLE:
            {
              data.x = data.x == -1 ? centerPt : data.x;
              pageYIdx += 2;
            }
            break;
            case FONT_CLASS.HEADING:{
              data.x = data.x == -1 ? leftMargin : data.x;
              //pageYIdx += 2;
            }
            break;
            case FONT_CLASS.ERROR:{
              data.color = data.color == COLOR.NONE ? COLOR.ERROR : data.color;
              data.style = data.style == FONT_STYLES.NORMAL ? FONT_STYLES.BOLD :data.style;
              data.x = data.x === -1 ? leftMargin : data.x;
            }break;
            case FONT_CLASS.WARNING:{
              data.color = data.color == COLOR.NONE ? COLOR.WARNING : data.color;
              data.style = data.style == FONT_STYLES.NORMAL ? FONT_STYLES.BOLD :data.style;
              data.x = data.x === -1 ? leftMargin : data.x;
            }
            break;
            case FONT_CLASS.MAINCONTENT:{
              data.x = data.x === -1 ? leftMargin : data.x;
            }
            case FONT_CLASS.CONTENT:
            default:{
              if(data.x === -1){
                switch(data.align){
                  case ALIGN.CENTER: 
                  data.x = centerPt;
                  break;
                  case ALIGN.LEFT:
                  default:
                  data.x = contentLeftMargin;
                }
              }
              //data.x = data.x == -1 ? contentLeftMargin : data.x;
            }break;
          }
          if(data.fontFamily == undefined || data.fontFamily == null)
          data.fontFamily = DEFAULT_FONT;
          if(data.style == undefined || data.style == null)
          data.fontFamily = FONT_STYLES.NORMAL;
          let j =0;
          let contLen = rightMargin - contentLeftMargin - 10;
          for(j=0;j<data.text.length;j++){
            if(pageYIdx > pageHeight){
              doc.addPage();
              doc.setFontSize(10);
              doc.text("Page "+page++ , 185, 6);
              pageYIdx = yStartIndex;
            }
            doc.setFontSize(data.size);
            doc.setFont(data.fontFamily,data.style);
            doc.setTextColor(data.color);
            data.y = pageYIdx;            
            let tempArr = data.text[j].split("\n");
            let txtArr:any=[];
            let count = 0;
            for(let index=0;index<tempArr.length;index++){
              //This logic is to restrict the strings within the page width and if the number of characters exceed the 
              //page width, move the rest of the string to next line
              let stringUnitWidth = Math.ceil(doc.getStringUnitWidth(tempArr[index])*data.size*10/25.6);
              //stringUnitWidth gives the string length in unit points.Refer the jspdf documentation for getStringUnitWidth
              //We are multiplying the result by font size and then dividing it by 25.6 to convert into mm.
              if(stringUnitWidth > contLen){
                // txtArr = txtArr.concat(textCutter(tempArr[index],contLen));
                txtArr = txtArr.concat(doc.splitTextToSize(tempArr[index],contLen));
                count = txtArr.length;
              }else
              txtArr[count++] = tempArr[index];
            }
            
            if(txtArr.length > 1){
              for(let index=0;index<txtArr.length;index++){
                if(pageYIdx > pageHeight){
                  doc.addPage();
                  doc.setFontSize(10);
                  doc.text("Page "+page++ , 185, 6);
                  pageYIdx = yStartIndex;
                }
                data.y = pageYIdx;
                txtArr[index] = txtArr[index].trim();
                if(index == 1){
                  //This to to indent the next line after the bullet point 
                  data.x = data.x + 2;
                }
                doc.text(txtArr[index],data.x,data.y,);
                pageYIdx = pageYIdx + 6;
              }
              data.x -= 2;
              pageYIdx -= 6;
            }else
            doc.text(data.text[j],data.x,data.y,data.align);            
            pageYIdx = pageYIdx + 8;
          }
        }break;
        case TYPE.LINE : {
          data.x1 = data.x1 == -1 ? leftMargin : data.x1;
          data.y1 = pageYIdx;
          data.x2 = data.x2 == -1 ? rightMargin : data.x2;
          data.y2 = pageYIdx;
          data.color = data.color == null || data.color == undefined ? COLOR.NONE : data.color;
          data.weight = data.weight == null || data.color == undefined || data.color < 1 ? DEFAULT_LINE_WEIGHT : data.weight;
          doc.setDrawColor(data.color);
          doc.line(data.x1,data.y1,data.x2,data.y2);
          pageYIdx += 5;
        }break;
        default:{          
        }
        break;
      }
    }
    //================================================================================
    if (id === REPORT_NAME.MIGRATION_REPORT)
      filename = companyName + "_" + this.downloadTime + ".pdf";
    else if (id === REPORT_NAME.NOTIFICATION_REPORT)
      filename =  "Notifications-" + companyName + "_" + this.downloadTime + ".pdf";
    else if (id === REPORT_NAME.DELETION_REPORT)
      filename = "Clear_ACO_Configuration-"+companyName+"_"+this.downloadTime +".pdf";
    doc.save(filename);
  }    
}

/* Use this function only if splitTextToSize doesnot work
function textCutter(text,n){
  var cutat = -1;
  var textArr=[];
  let len = text.length;
  for(let i=0;i<=len-1;){
    let frmIdx = n+i;
    if(frmIdx > len-1){
      frmIdx = len;
    }
    cutat= text.lastIndexOf(' ',frmIdx);
    if(cutat == i && cutat < frmIdx){
      cutat = frmIdx;
    }
    if(cutat!=-1){
      textArr.push(text.substring(i,cutat));
      i = cutat;
    }else if(cutat == -1 && frmIdx == len){
      textArr.push(text.substring(i,len));
      i = len;
    }else{
      //This is the case where the line contains has a string of length greater than page width
      //we are left with no choice but to break this long word
      cutat = i+n;
      textArr.push(text.substring(i,cutat));
      i = cutat;
    }
    if(i>=text.length){
      break;
    }
  } 
  return textArr; 
}*/
