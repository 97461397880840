<div class="contentBlock  migrationDataBlock">
    <div class="contentInfo">
        <button class="closeBtn"
            mat-icon-button
            matTooltip="close"
            (click)= "closeMigrationActivity()">
            <mat-icon style=" font-size: 0.8vw;line-height: 1vw;">close</mat-icon>
        </button>
        <div class="migrationStepInfo">
            <span class="migrationStep">Step 2/6 : Import CSV</span>
            <span class="migrationCompany">{{companyName}}</span>
        </div>
    </div>
    <div class="contentMain parent-flex">
        <mat-form-field class="migrationInputFormField" *ngIf="!showErrorModal">
            <mat-label>Upload File (configuration.csv/.zip)</mat-label>
            <input accept=".csv,.zip" style="display: none" type="file" multiple (click)="fileInput.value = null" (change)="onFileChanged($event)" #fileInput> 
            <input matInput type="text"  [(ngModel)]="csvfilename">
            <button mat-button  matSuffix mat-icon-button (click)="fileInput.click()">
                <mat-icon>unarchive</mat-icon>
            </button>
        </mat-form-field>
        <span class="errorMsg" *ngIf="isParseFailed  && !showErrorModal">
            <mat-icon style=" font-size: 130%;color: #DA291C;">error</mat-icon>
            Unable to extract configuration data,  
            <a style="text-decoration: underline;" (click)="viewErrorModal()">View details</a>
        </span>
        <span class="warningMsg" *ngIf="showWarning && !showErrorModal">
            <mat-icon style=" font-size: 130%;color: #FF8200F7">warning</mat-icon>
            Duplicate Configurations found,
            <a style="text-decoration: underline;" (click)="viewErrorModal()">View details</a>
        </span>
                 
        <!-- <div class="errorModal" *ngIf="showErrorModal">
            <mat-icon style=" font-size: 100%;margin-left:auto;margin-top:2%" (click) = "closeErrorModal()">close</mat-icon>
            <p class="errorModalHeader">
                <mat-icon style=" font-size: 140%;color: #DA291C;margin-top: 2%;"> error</mat-icon>
                &nbsp;&nbsp;Error - Unable to parse the CSV File
            </p>
            <p class="errorModalMsg">Error Details : {{errorMsg}}</p>
            <p class="errorModalMsg">SyntaxError: {{syntaxErrorMsg}}</p>
        </div> -->
        <div id="errorWarningBlock" class="errorWarningBlock" *ngIf=showErrorModal>
            <div style="width: 100%;height: 88%; overflow-x: hidden;overflow-y: auto;">
              <table style="width: 90%;max-height: 100%;margin-top: 0%;">
                <tr class="errorWarningRow" *ngFor="let item of notifications">
                  <td>
                    {{item.msg}}
                  </td>
                  <td *ngIf="item.severity == SEVERITY.WARNING">
                    <mat-icon style=" font-size: 1.1vw;color: #FF8200F7;">
                      warning
                    </mat-icon>
                  </td>
                  <td *ngIf="item.severity == SEVERITY.ERROR">
                    <mat-icon style=" font-size: 1.1vw;color: #DA291C;">
                      error
                    </mat-icon>
                  </td>
                </tr>
              </table>
            </div>
            <button class="tableButton " style="margin-top: 1%;" (click)="closeErrorWarningBlock()">
              Close
            </button>
        </div>
    </div>
    
    <div class="navigation" *ngIf="!showErrorModal">
        <button mat-button class="arrowBack" matSuffix mat-icon-button (click)= "navigateBack()">
            <mat-icon>arrow_back</mat-icon>
        </button>
        <button mat-button class="arrowForward" matSuffix mat-icon-button (click)= "navigateNext()" *ngIf = "csvfilename && !isParseFailed">
            <mat-icon>arrow_forward</mat-icon>
        </button>
    </div>
</div>