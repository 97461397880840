export const environment = {
    production: true,
    firebaseConfig : {
      apiKey: "AIzaSyAKcnLl0NUQ5hsLphZ3Oga9j5jb69TDOLE",
      authDomain: "aco-services.firebaseapp.com",
      databaseURL: "https://aco-services.firebaseio.com",
      projectId: "aco-services",
      storageBucket: "aco-services.appspot.com",
      messagingSenderId: "925524248329",
      appId: "1:925524248329:web:2207555f7709969f66b5d8",
      measurementId: "G-0Q6HTD9PK8"
    },
    client_id : "925524248329-rj34jj8jk1ce8fi2r442137g7210kmbq.apps.googleusercontent.com" ,
    cookie_policy: 'single_host_origin',
    scope: 'profile email',
    well_known_endpoint: 'https://storage.googleapis.com/aco-services.appspot.com/.well-known/mt',
    bucket_name: 'aco-services.appspot.com',
    avaya_domain: 'avaya.com',
    app_version: '0.14.1.1'
};