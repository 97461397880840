<div class="contentBlock migrationHelpBlock">
    <div style="display: flex;flex-direction: column;width: 100%;align-items: baseline;">
        <p class="downloadText" style="font-size:1.2vw;margin-top:5vh">
            User Guide
        </p>
        <p class="text">
            This document describes the capabilities of the Avaya Cloud Office Migration Tool, including the process of data migration from IP Office to Avaya Cloud Office™. All the information required for migration of IP Office configuration, the tool functionalities, newly added migration features, configuration clean-up, warnings and errors are all updated in the user guide. The document is available for logged-in users only. Use your Avaya handle to log in to Documentation Center.
        </p>        
        <div style="display:flex;flex-direction:row;margin-top:1vh;margin-left:4vw;">
            <button class="button" style="margin-left:2vw;width:100%;" mat-icon-button matTooltip="View" (click)="redirectToOnlineGuide()">
                <span class="btnSpan">View</span>           
            </button>            
        </div>        
    </div>
    <div class="errorBlock" *ngIf="isGuideInvalid">
        <p style="text-align: center;margin-top:-1%;display:contents">
            <mat-icon style="font-size: 1.5vw;color: #DA291C;margin-right:0.4vw">
                error
            </mat-icon>
            {{userDisplayMsg}}
        </p>
    </div>    
</div>