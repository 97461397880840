import { Injectable} from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import 'firebase/firestore';
import * as firebase from 'firebase/app'; 
import { Subject, Observable } from 'rxjs'; 
import { rejects } from 'assert';
import { LogService } from '../logging/log.service';

const DBPATH = {
    MTUSERS: "/MTUsers/",
    PROFILE: "/Profile",
    USER: "/user",
    ENTITLEMENT: "/entitlement",
    CARDS: "/Cards/",
    CARD_INFO: "/CardInfo",
    CARDINFO_OWNER: "/CardInfo/owner",
    CARDINFO_STATUS: "/CardInfo/status",
    CARDINFO_IPOFFICE: "/CardInfo/ipoffice",
    CARDINFO_ACO: "/CardInfo/aco",
    CARDINFO_ACOAUTH: "/CardInfo/acoauth",
    CARDINFO_SUMMARY: "/CardInfo/summary",
    TRIGGERS: "/Triggers",
    TRIGGERS_ACOIMPORT: "/Triggers/acoImport",
    TRIGGERS_ACOEXPORT: "/Triggers/acoExport",
    TRIGGERS_CLEARACO: "/Triggers/clearACO",
    STATUSLISTENERS: "/StatusListeners",
    STATUS_ACOAUTH: "/StatusListeners/acoAuth",
    STATUS_ACOIMPORTSTATUS: "/StatusListeners/acoImportStatus",
    STATUS_ACOEXPORTSTATUS: "/StatusListeners/acoExportStatus",
    STATUS_FEATURESTATUS: "/StatusListeners/acoFeatureStatus",
    STATUS_NOTIFICATIONS : "/StatusListeners/notifications",
    CONFIGURATIONS: "/Configurations/",
    IPOCFG: "/IpoCfg",
    IPOCFG_CSV: "/IpoCfg/csv",
    IPOCFG_SUMMARY: "/IpoCfg/summary",
    IPOCFG_NORM: "/IpoCfg/normalized",
    IPOCFG_HGROUP: "/IpoCfg/huntGroup",
    IPOCFG_SHORTCODES: "/IpoCfg/shortCodes",
    IPOCFG_STEERINGCODES: "/IpoCfg/steeringCodes",
    IPOCFG_AUTOATTENDANT: "/IpoCfg/autoAttendant",
    IPOCFG_PGROUP: "/IpoCfg/pagingGroup",
    IPOCFG_AA_ICR: '/IpoCfg/autoAttendantIcr/',
    IPOCFG_PRECONFIG_ICR: '/IpoCfg/preConfigIcr/',
    IPOCFG_BCA: '/IpoCfg/bca',
    IPOCFG_APPEARANCEDATA: '/IpoCfg/userAppearance',
    IPOCFG_DLGGROUP: '/IpoCfg/dlgGroup',
    DEVICE_EXT_LIST: '/AcoImportCfg/deviceInfo',
    ACOIMPORT_CFG: "/AcoImportCfg/uaextns",
    ACOIMPORT_ASSIGNEDEXTNS: "/AcoImportCfg/AssignedExtensions",
    ACOIMPORT_FEATURES_CFG: "/AcoImportCfg/uafeatures",
    ACOIMPORT_COMPH_DATA: '/AcoImportCfg/compPhNumbers',
    ACOEXPORT_CFGDATA: "/AcoExportCfg/userCfgData",
    ACOEXPORT_CFGTABLEDATA: "/AcoExportCfg/userCfgTableData",
    ACOEXPORT_CFGEMAILDATA: "/AcoExportCfg/userCfgEmailData",
    ACOEXPORT_BLFEXTENSIONIDDATA: "/AcoExportCfg/userBlfEnablePresenceData",
    ACOEXPORT_BLFEXTNDATA: "/AcoExportCfg/userBlfExtnData",
    ACOEXPORT_USER_BH_DATA: "/AcoExportCfg/userBHoursData",
    ACOEXPORT_TIMEPROFILES: "/AcoExportCfg/timeProfiles",
    ACOEXPORT_CFG_HG_PG_TABLEDATA:"/AcoExportCfg/userCfgHgPgTableData",
    ACOEXPORT_USER_CALLFORWARDDATA: "/AcoExportCfg/user/CallFwd/userCallFwdData",
    ACOEXPORT_HGUSERDATA: "/AcoExportCfg/user/HGroup/hGroupUserData",
    ACOEXPORT_CALLPICKUPDATA : "/AcoExportCfg/user/HGroup/pickUpMemberData",
    ACOEXPORT_EXTNSMAP: "/AcoExportCfg/extensionsMap",
    ACOIMPORTCFG_SUMMARY: "/AcoImportCfg/summary",
    FUNCTIONRESULTS_ASSIGNUSERS: "/FunctionResults/assignUsers",
    TASKRUNS: "/TaskRuns",
    GREETINGSSTATUS:"/GreetingsCfg/greetingsStatus",
    VMPROEXTRACTDOC:"/GreetingsCfg/vmproExtract",
    SYSMONSTATUS:"/SysmonCfg/sysmonStatus",
    ACOEXPORT_TASKCHAIN: "/AcoExportCfg/taskChain",
    ACOEXPORT_TASKCHAIN_FINAL: "/AcoExportCfg/finalTaskChain",
    ACOEXPORT_USER_CONTACTS: "/AcoExportCfg/user/Contacts/personalContactsData",
    ACOEXPORT_PGUSERDATA: "/AcoExportCfg/user/PGroup/pGroupUserData",
    // ACOEXPORT_USER_UPDATERINGONMONITOREDCALLDATA: "/AcoExportCfg/ringOnMonitoredCallData",
    ACOEXPORT_MORRISON_UNCONDITIONAL_FORWARDING: "/AcoExportCfg/morrison/UnConditionalForward/CfgData",
    ACOEXPORT_MORRISON_UNCONDITIONAL_FORWARDING_RESDATA: "/AcoExportCfg/morrison/UnConditionalForward/CfgResData",
    ACOEXPORT_MORRISON_TEXT_TO_SPEECH: "/AcoExportCfg/morrison/TextToSpeech/CfgData",
    ACOEXPORT_MORRISON_TEXT_TO_SPEECH_RESDATA: "/AcoExportCfg/morrison/TextToSpeech/CfgResData",
    MORRISONCFGCSV: '/IpoCfg/morrisonCsv/',
    ACOEXPORT_DELETED_USERS_CFGDATA: "/AcoExportCfg/deletedUsersCfgData",
    ACOEXPORT_AA_ICR: "/AcoExportCfg/system/AutoAttendant/updatedAutoAttendantIcr",
    ACOEXPORT_DELETED_ICR_CFGDATA: "/AcoExportCfg/deletedICRCfgData",
    ACOEXPORT_DTMF0 : "/AcoExportCfg/system/ZeroDial/DTMF0",
    ACOEXPORT_DELETEDUSERDTMF0 : "/AcoExportCfg/system/ZeroDial/DeletedUserDTMF0",
    ACOEXPORT_MSG_ONLY: "/AcoExportCfg/user/MessageOnly/MessageOnlyData",
    ACOEXPORT_LAST_RUN_OPERATION: "/AcoExportCfg/lastRunOperation",
    CLEAR_ACO_TASKCHAIN: "/ClearConfig/taskChain",
    CLEAR_ACO_FINAL_TASKCHAIN: "/ClearConfig/finalTaskChain",
    STATUS_CLEAR_ACO_CONFIG: '/StatusListeners/clearAcoConfigStatus/',
    STATUS_CLEAR_ACO_CONFIG_FEATURE_STATUS: '/StatusListeners/acoFeatureDeletionStatus/',    
    ACOEXPORT_PARK_LOCATION_CFG_DATA: '/AcoExportCfg/user/ParkLocation/parkLocationCfgStatus',
    ACOEXPORT_SITESMAP: '/AcoExportCfg/siteMap',
    ACOEXPORT_ICRTOPMENU: '/AcoExportCfg/system/AutoAttendant/icrTopMenu'
}
  
export const FEATURE_NAME = {
  USERS: "Users",
  MESSAGE_ONLY: "messageOnly",
  USER_CONTACTS: "UserContacts",
  USER_BHOURS: "UserBHours",
  USER_BLF: "UserBLF",
  CALL_FORWARD: "CallForward",
  HUNT_GROUP: "HuntGroup",
  PAGING_GROUP: "PagingGroup",
  PARK_LOCATION: "ParkLocation",
  AUTO_ATTENDANT_PROMPTS: "AutoAttendantPrompts",
  AUTO_ATTENDANT: "AutoAttendant",
  COMPANY_RULES: "CompanyRules",
  USER_VM_WORKHOURS: "UserVmWorkHours",
  USER_VM_AFTERHOURS: "UserVmAfterHours",
  USER_VM_CUSTOMHOURS: "UserVmCustomHours",
  //USER_UPDATERINGONMONITOREDCALLDATA: "ringOnMonitoreCallData",
  COMPLEX_IVR_PROMPTS:"ComplexIvrPrompts",
  ZERO_DIAL: "ZeroDial",
  MAC_UPDATE: "MacUpdate",
  POST_MIGRATION: "PostMigration",
  CLEAR_COMPANY_RULES:"ClearCompanyRules",
  CLEAR_AUTO_ATTENDANT: "ClearAutoAttendant",
  CLEAR_AUTO_ATTENDANT_PROMPTS: "ClearAutoAttendantPrompts",
  CLEAR_COMPLEX_IVR_PROMPTS:"ClearComplexIvrPrompts",
  CLEAR_PARK_LOCATIONS: "ClearParkLocations",
  CLEAR_PICKUP_MEMBERS: "ClearPickupMembers",
  CLEAR_HUNT_GROUP: "ClearHuntGroups",
  CLEAR_PAGING_GROUP: "ClearPagingGroups",
  MOVE_ASSIGNED_USERS_TO_MAINSITE:"moveAssignedUserToMainSite",
  CLEAR_ASSIGNED_USERS: "ClearAssignedUsers", 
  CLEAR_MULTI_SITES: "ClearMultiSites"
}

//Should be maintained as TASK QUEUE ORDER
export const TASK_QUEUE_PRIORITY = [
    FEATURE_NAME.USERS,
    FEATURE_NAME.MESSAGE_ONLY,
    FEATURE_NAME.USER_CONTACTS,
    FEATURE_NAME.USER_BHOURS,
    FEATURE_NAME.USER_BLF,
    FEATURE_NAME.CALL_FORWARD,
    FEATURE_NAME.HUNT_GROUP,
    FEATURE_NAME.PAGING_GROUP,
    FEATURE_NAME.PARK_LOCATION,
    FEATURE_NAME.AUTO_ATTENDANT_PROMPTS,
    FEATURE_NAME.AUTO_ATTENDANT,
    FEATURE_NAME.COMPANY_RULES,
    FEATURE_NAME.USER_VM_WORKHOURS,
    FEATURE_NAME.USER_VM_AFTERHOURS,
    FEATURE_NAME.USER_VM_CUSTOMHOURS,
   // FEATURE_NAME.USER_UPDATERINGONMONITOREDCALLDATA,
    FEATURE_NAME.COMPLEX_IVR_PROMPTS,
    FEATURE_NAME.ZERO_DIAL,
    FEATURE_NAME.MAC_UPDATE,
    FEATURE_NAME.POST_MIGRATION
]

export const CLEAR_ACO_TASK_QUEUE_PRIORITY = [
  FEATURE_NAME.CLEAR_COMPANY_RULES,
  FEATURE_NAME.CLEAR_AUTO_ATTENDANT,
  FEATURE_NAME.CLEAR_AUTO_ATTENDANT_PROMPTS,
  FEATURE_NAME.CLEAR_COMPLEX_IVR_PROMPTS,
  FEATURE_NAME.CLEAR_PARK_LOCATIONS, 
  FEATURE_NAME.CLEAR_PICKUP_MEMBERS, 
  FEATURE_NAME.CLEAR_HUNT_GROUP,
  FEATURE_NAME.CLEAR_PAGING_GROUP,
  FEATURE_NAME.MOVE_ASSIGNED_USERS_TO_MAINSITE,  
  FEATURE_NAME.CLEAR_ASSIGNED_USERS,
  FEATURE_NAME.CLEAR_MULTI_SITES
]

export const ERROR_STRING = {
  USER_NOT_FOUND : "User not logged in",
  DOC_DOES_NOT_EXIST : "Document does not exist"
} 

export const PROCESS = {
  MIGRATION : "Migration",
  DELETION : "Deletion"
}

const EMPTY_STRING = '';

export enum MIGRATION_STAGE{
    PREVIEWSUMMARY,
    PREPARETABLE,
    DATAMIGRATION,
    COMPLETE,
    MORRISONSUMMARY
}

export interface Result{
  type:string,
  user:object,
  reason:string
}
export enum REQUEST{
  NEWREQ=0,
  APPROVE=1,
  REJECT=2
}

export enum MAILTYPE{
  APPROVED=0,
  NOTIFY_REQUEST=1,
  NOTIFY_APPROVAL=2,
  NOTIFY_REJECT=3
}

export  enum STATUS{
  SUCCESS=0,
  FAILURE=1
}

export enum TASK_NAME{
    CHAIN = 0,
    USER_ASSIGN = 1,
    MESSAGE_ONLY = 2,
    USER_CONTACTS = 3,
    USER_BUSINESS_HOURS = 4,
    USER_CALLFORWARD = 5,
    USER_BLF_ENABLEPRESENCE = 6,
    USER_UPDATEBLFDATA = 7,
    HUNT_GROUP_USERDATA = 8,
    EXTENSIONS_MAP = 9,
    PAGING_GROUP_USERDATA = 10,
    PARK_LOCATION_DATA = 11,
    UPLOAD_ZERO_DIAL = 12,
    CALL_PICKUP = 13,
    MAC_UPDATE_FOR_EXTENSIONS = 14,
    //USER_UPDATERINGONMONITOREDCALLDATA = 10
    MORRISON_UNCONDITIONAL_FORWARDING = 15,
    MORRISON_TEXT_TO_SPEECH = 16,
    FINAL_TASK_CHAIN = 17
}

export const SKIPPED_FEATURE_NAME = {
  MESSAGE_ONLY: "messageOnly",
  BHOURS: "User Working Hours",
  BLF: "update Presence Data",
  CONTACTS: 'Contacts Configuration',
  TWINNING: "Enable Mobile User Ring Group configuration",
  HuntGroup: "Call Queues configuration",
  PAGINGGROUP: "Paging Only extension configuration",
  PARKLOCATION: "Park Locations Configuration",
  AA_PROMPTS : "Auto-Receptionist prompt migration",
  AA : "Auto-Receptionist Keys and menu migration",
  VM_BHOURS: "User and Call Queues voicemail configuration for Working hours",
  VM_AHOURS: "User voicemail configuration for after hours",
  VM_CHOURS: "User voicemail configuration for custom hours",
  COMPLEX_IVR_PROMPTS: "Not used call flow prompts upload",
  ZERO_DIAL: "Upload Zero Dial feature",
  MAC_UPDATE: "MAC Update For User Extensions",
};

export const TRIGGER_STATUS = {
  START:"start",
  DONE:"done",
  NOTYET:"not yet",
  UNKNOWN:"unknown"
};

const MAX_LIMIT = 99;
const CARD_VALIDITY = 5; //days


@Injectable({
  providedIn: 'root'
})
export class FirestoreService { 

    private subject = new Subject<any>();
    private greetingsDataAvblSubj = new Subject<any>();
    private sysmonDataAvblSubj = new Subject<any>();
    private delProgSubject = new Subject<any>();
    constructor(public db:AngularFirestore,
        private logger:LogService) {
        this.logger.info("FirestoreService constructor");
    }
    rcAuth(cardId = null){
      var promise = new Promise((resolve, reject) => {
        //var cardRcAuth = firebase.functions().httpsCallable('rcAuth');
        firebase.auth().currentUser.getIdToken(true)
        .then((idToken) => {
            var cardRcAuth = firebase.functions().httpsCallable('acoAuthAndCardCreator');
            cardRcAuth({ cardId: cardId, groupId: null, idToken }).then((res) => {
                this.logger.debug(JSON.stringify(res.data));
                resolve(res.data);
            }).catch((error) => { //Handle Error
                reject(error);
            });
        })
        .catch((error) => { //Handle Error
            reject(error);
        });
      });
      return promise;
    }

    deleteUserCard(cardId){
      this.logger.debug("deleteUserCard :",cardId);
      var promise = new Promise((resolve, reject) => {
        firebase.auth().currentUser.getIdToken(true)
        .then((idToken) => {
            let passArgs = {
                cardId: cardId,
                idToken
            };
            this.getSiteMap(cardId)
            .then((sitesData:any)=>{
                let siteMap = JSON.parse(sitesData.data);
                if(siteMap.length > 0){
                  return this.validateSiteDeletion(cardId,passArgs);
                }
                return ;
            }) 
            /* this.setTaskChain(cardId,["ClearMultiSites"],false,PROCESS.DELETION)
            .then((res)=>{ 
              return firebase.functions().httpsCallable('clearNotMigratedSites')(passArgs);
            }).then((res) => {               
              return this.deleteNotMigratedSites(cardId);
            }) */.then((data) => {
              return firebase.functions().httpsCallable('removeCard')(passArgs);
            }).then((data) => {
              this.logger.debug('User card deleted Successfully :',cardId);
              resolve(data);
            }).catch((error) => {
              this.logger.error("User card deletion failed :"+error.code + ' - ' + error.message);
              reject(error);
            });
        }).catch((error) => {
            reject(error);
        });
      });
      return promise;
  }

  validateSiteDeletion(cardId , passArgs){
    this.logger.debug('validateSiteDeletion: ',cardId);
    return new Promise<void>((resolve, reject) =>{
      this.setTaskChain(cardId,["ClearMultiSites"],false,PROCESS.DELETION)
      .then((res)=>{
        return firebase.functions().httpsCallable('clearNotMigratedSites')(passArgs);
      }).then((res) => {               
        return this.deleteNotMigratedSitesStatusListener(cardId);
      }).then(()=>{
        this.logger.debug('Site deletion success :',cardId);
        resolve();
      }).catch((err) =>{
        reject();
      })
    })
  }

  deleteNotMigratedSitesStatusListener(cardId){
    this.logger.debug("deleteNotMigratedSites :",cardId);
    return new Promise<void>((resolve, reject) =>{
      const clearACOConfigStatusPath =  [DBPATH.CARDS, cardId, DBPATH.STATUS_CLEAR_ACO_CONFIG].join("");
      var clearACOCfgRef = this.db.doc(clearACOConfigStatusPath);
      clearACOCfgRef.get().toPromise().then((doc:any) => {
          var curdata = doc.data();
          if(curdata.status == 0 || curdata.status == 1){
              this.logger.debug("Site Deletion completed already with status: ", curdata.desc);
              resolve();
          }
          else{
              const unsubscribe = this.db.doc(clearACOConfigStatusPath).ref.
              onSnapshot((doc:any) =>{
                var curdata = doc.data();
                if (curdata.status == 0 || curdata.status == 1) {
                    this.logger.debug("Site Deletion completed, status: ", curdata.status);
                    if(unsubscribe){
                    unsubscribe();
                    }
                    resolve();
                    //return firebase.functions().httpsCallable('removeCard')(passArgs);
                }
                else{
                    this.logger.debug("Site Deletion progress update: ", curdata.progress);
                }
              });
          }
      }).catch(()=>{
        reject();
      });
    })     
  }

    triggerEmailNotification(mailType:MAILTYPE , userData:any){
      /* if(userData !== null && userData !== undefined){ */
        var promise = new Promise((resolve, reject) => {
          this.logger.info("triggerEmailNotification : mail type :" +mailType + "\nuser details:: name: "+userData.name+ " , email :"+userData.email);
    
          firebase.auth().currentUser.getIdToken(true)
          .then((idToken) => {
            let passArgs = {
              type: mailType,   
              user: {
                name: userData.name,
                email: userData.email
              },         
              idToken
            };

            var notifier = firebase.functions().httpsCallable('notifier');
            notifier(passArgs).then((res) => {
              if(res.data.status == STATUS.SUCCESS){
                this.logger.info("Email notification is successful:: type:"+mailType+"\nuser details:: name: "+userData.name+ " , email :"+userData.email);
                resolve();
              }else{
                this.logger.error("Email notification failed:: type:"+mailType+"\nuser details:: name: "+userData.name+ " , email :"+userData.email);
                reject("Email Notification Failed");
              }
            }) 
          }).catch((error) => {
              this.logger.error("triggerEmailNotification failed:: ",error);
              reject(error)
          }); 
        });
        return promise;

      /* }else{
        this.logger.error("triggerEmailNotification : user data is empty");
      } */
    }

    triggerFeaturePrerequisites(cardId){
        this.logger.debug("triggerFeaturePrerequisites");
        var promise = new Promise((resolve, reject) => {
          firebase.auth().currentUser.getIdToken(true)
          .then((idToken) => {
              var cardRcAuth = firebase.functions().httpsCallable('readFeaturesPrerequisite');
              cardRcAuth({ cardId: cardId, groupId: null, idToken }).then((res) => {
                  this.logger.debug("received feature prerequisites");
                  resolve(res.data);
              }).catch((error) => { //Handle Error
                  reject(error);
              });
          })
          .catch((error) => { //Handle Error
              reject(error);
          });
        });
        return promise;
    }


    getFeaturePrerequisite(cardId) {
        this.logger.debug("getFeaturePrerequisite");
        var promise = new Promise((resolve, reject) => {
            const acoImportCfgPath =  [DBPATH.CONFIGURATIONS, cardId, DBPATH.ACOIMPORT_FEATURES_CFG].join("");
            var importCfgRef = this.db.doc(acoImportCfgPath);
            importCfgRef.get().toPromise().then((doc:any) => {
                if (doc.exists) {
                    let curdata = doc.data();
                    resolve(curdata);
                }
                else{
                    resolve();
                }
            })
            .catch((error) => {
                this.logger.error("Error getting document:", error);
                reject(error);
            });
        });
        return promise;
    }

    updateMarkAsCompleteToAnalytics(cardId){
      //update the bigquery ledger with markAsComplete event
      var promise = new Promise((resolve, reject) => {
      var userId = firebase.auth().currentUser.uid;
      
        firebase.auth().currentUser.getIdToken(true).then((idToken) => {
          let passArgs = {
            cardId: cardId,
            userId,
            idToken
          };

          var ledgerEntry = firebase.functions().httpsCallable('ledgerEntry');
          ledgerEntry(passArgs).then((res) => {         
            this.logger.debug('ledgerEntry completed : ' + JSON.stringify(res.data));
            resolve();
          })
        }).catch((error) => {
            this.logger.error(error);
            reject();
        });
      });
      return promise;
    }

    getUserDetails() {
      var promise = new Promise((resolve, reject) => {
        var user = firebase.auth().currentUser;
        if (user) {
          var userDocRef = this.db.collection(DBPATH.MTUSERS).doc(user.uid);
          userDocRef.get().toPromise().then((doc:any) => {
            if(doc.exists){
              let curdata = doc.data();
              resolve(curdata);
            }else{
                this.logger.error("User Document not found for "+user.uid)
            }
           
          }).catch((error) => {
            reject(error);
          });
        }else{
            this.logger.error("Firebase user is not logged In");
        }
      });
      return promise;

    }

    getUserProfileDetails(){
      var promise = new Promise((resolve, reject) => {
        var user = firebase.auth().currentUser;
        if(user){
          const userProfilePath = [DBPATH.MTUSERS , user.uid , DBPATH.PROFILE ,DBPATH.USER].join("");
          firebase.firestore().doc(userProfilePath).get().then((doc:any) => {
            if(doc.exists){
              let curdata = doc.data();
              resolve(curdata);
            }else{
                this.logger.error("User Document not found for "+user.uid)
            }
          }).catch((error) => {
            reject(error);
          });
        }
      });
      return promise;
    }

    updateUserProfileDetails(companyName){
      var promise = new Promise((resolve, reject) => {
        var user = firebase.auth().currentUser;
        if(user){
          var updateCompanyName = {
            company : companyName
          }
          const userProfilePath = [DBPATH.MTUSERS , user.uid , DBPATH.PROFILE ,DBPATH.USER].join("");
          firebase.firestore().doc(userProfilePath).update(updateCompanyName).then((doc:any) => {
            resolve();
          }).catch((error) => {
            reject(error);
          });
        }
      });
      return promise;
    }

    getRecentMigrationData(){
      this.logger.info(" getRecentMigrationData ");
      var promise = new Promise((resolve, reject) => {
        var user = firebase.auth().currentUser;

        if(user){
          var collectiongroup = firebase.firestore().collectionGroup('CardInfo').where('userId', '==', user.uid)
          .limit(MAX_LIMIT).get().then((querySnapshot) => {
            querySnapshot.forEach(function(doc) {
              var curdata = doc.data();
              this.logger.debug(doc.id, ' => ', doc.data());
            });
          }).catch((error) => {
            this.logger.error(error);
          })       
        }
      });
      return promise;
    }

    onAcoAuthChange(cardId) {
      this.logger.info(" onAcoAuthChange ");
      var promise = new Promise((resolve, reject) => {
        const acoAuthPath =  [DBPATH.CARDS, cardId, DBPATH.STATUS_ACOAUTH].join("");
        const unsubscribe = this.db.doc(acoAuthPath).ref.
        onSnapshot((doc:any) =>{
            if(doc.exists){
              var curdata = doc.data();
              
              if(curdata && curdata.status != ""){
                resolve(curdata);
                if(unsubscribe){
                  unsubscribe();
                }
              } 
            }else{
              reject("doc not found");
              if(unsubscribe){
                unsubscribe();
              }
            }
        });
      });
      return promise;
    }

    getFeatureStatusDoc(cardId){
      this.logger.info("getFeatureStatusDoc");
      var promise = new Promise((resolve, reject) => { 
          const acoImportStatusPath =  [DBPATH.CARDS, cardId, DBPATH.STATUS_FEATURESTATUS].join("");
          this.db.doc(acoImportStatusPath).get().toPromise()
          .then((doc:any) => {
            var curdata = doc.data();
            //this.logger.debug(JSON.stringify(curdata));
            resolve(curdata);
          }).catch((error) => {
            this.logger.error("Error getting getFeatureStatusDoc:", error);
            reject(error);
          });
      });
      return promise;
    }

    getDeletionFeatureStatusDoc(cardId){
      this.logger.info("getDeletionFeatureStatusDoc");
      var promise = new Promise((resolve, reject) => { 
          const acoClearConfigFeatureStatusPath =  [DBPATH.CARDS, cardId, DBPATH.STATUS_CLEAR_ACO_CONFIG_FEATURE_STATUS].join("");
          this.db.doc(acoClearConfigFeatureStatusPath).get().toPromise()
          .then((doc:any) => {
            var curdata = doc.data();
            //this.logger.debug("get deletion feature status : "+JSON.stringify(curdata));
            resolve(curdata);
          }).catch((error) => {
            this.logger.error("Error getting getClearACOConfigFeatureStatusDoc:", error);
            reject(error);
          });
      });
      return promise;
    }

   setNotificationData(cardId,systemSummaryNotifications,configDataNotifications)
   {
      this.logger.info("setNotificationData");
      var notificationCgfData;
       notificationCgfData = {
        systemSummaryNotifications : systemSummaryNotifications,
        configSummaryNotifications : configDataNotifications,
      }
      if(systemSummaryNotifications === null)
      {
        notificationCgfData = {
          configSummaryNotifications : configDataNotifications
        }
      }
      let promise = new Promise((resolve, reject) => {
        const notificationsCfgPath =  [DBPATH.CARDS, cardId, DBPATH.STATUS_NOTIFICATIONS].join("");
        this.db.doc(notificationsCfgPath).update(notificationCgfData)
        .then(() => {
            this.logger.debug("set Notification Data successfully");
            resolve();
        }).catch((error) => {
            this.logger.error("Error setting Notification data", error);
            reject(error);
        });
      })
      return promise;
    }

    getNotificationData(cardId){
      this.logger.info("getNotificationData");
      var promise = new Promise((resolve, reject) => { 
          const notificationsCfgPath =   [DBPATH.CARDS, cardId, DBPATH.STATUS_NOTIFICATIONS].join("");
          this.db.doc(notificationsCfgPath).get().toPromise()
          .then((doc:any) => {
            var curdata = doc.data();
            //this.logger.debug(JSON.stringify(curdata));
            resolve(curdata);
          }).catch((error) => {
            this.logger.error("Error getting NotificationData:", error);
            reject(error);
          });
      });
      return promise;
    }


    getExtensionMap(cardId){
      this.logger.info("getExtensionMap");
      var promise = new Promise((resolve, reject) => { 
          const acoExportExtenisonMapPath =  [DBPATH.CONFIGURATIONS, cardId, DBPATH.ACOEXPORT_EXTNSMAP].join("");
          this.db.doc(acoExportExtenisonMapPath).get().toPromise()
          .then((doc:any) => {
            var curdata = doc.data();
            //this.logger.debug(JSON.stringify(curdata));
            resolve(curdata);
          }).catch((error) => {
            this.logger.error("Error getting getExtensionMap:", error);
            reject(error);
          });
      });
      return promise;
    }

    getSiteMap(cardId){
      this.logger.info("getSiteMap");
      var promise = new Promise((resolve, reject) => { 
          const acoExportSiteMapPath =  [DBPATH.CONFIGURATIONS, cardId, DBPATH.ACOEXPORT_SITESMAP].join("");
          this.db.doc(acoExportSiteMapPath).get().toPromise()
          .then((doc:any) => {
            var curdata = doc.data();
            //this.logger.debug(JSON.stringify(curdata));
            resolve(curdata);
          }).catch((error) => {
            this.logger.error("Error getting getSiteMap:", error);
            reject(error);
          });
      });
      return promise;
    }

    triggerAcoImport(cardId){
      this.logger.info("triggerAcoImport");
      var promise = new Promise((resolve, reject) => {
          var acoImportTrigger = {
            trigger : "start"
          }
          var acoImportStatusData = {
            status: -1,
            desc: ""
          }
          const acoImportStatusPath =  [DBPATH.CARDS, cardId, DBPATH.STATUS_ACOIMPORTSTATUS].join("");
          this.db.doc(acoImportStatusPath).set(acoImportStatusData);

          const acoImportTriggerPath =  [DBPATH.CARDS, cardId, DBPATH.TRIGGERS_ACOIMPORT].join("");
          this.db.doc(acoImportTriggerPath).set(acoImportTrigger).then(() => {
           resolve();
          }).catch((error) => {
            this.logger.error("Error getting document:", error);
            reject(error);
          });
      });
      return promise;
    }

    checkAcoImportStatus(cardId){
        this.logger.debug(" checkAcoImportStatus ");
      var promise = new Promise((resolve, reject) => {

        const acoImportStatusPath =  [DBPATH.CARDS, cardId, DBPATH.STATUS_ACOIMPORTSTATUS].join("");
        var acoImportCfgRef = this.db.doc(acoImportStatusPath);
        acoImportCfgRef.get().toPromise().then((doc:any) => {
          var data = doc.data();
          if(data.status == 0 || data.status == 1){
            //call getAcoImportCfg function
            this.logger.debug("acImportStatus is ", data.desc);
            resolve(data);
          }
          else{
            const unsubscribe = this.db.doc(acoImportStatusPath).ref.
            onSnapshot((doc:any) =>{
              var curdata = doc.data();
              if (curdata.status == 0 || curdata.status == 1) {
                resolve(curdata);
                if(unsubscribe){
                  unsubscribe();
                }
              } 
            });
          }
        }).catch(function (error) {
            this.logger.error("Error getting document:", error);
          reject();
        });

      });
      return promise;
      
    }

    getAssignedExtensions(cardId){
      this.logger.debug("getAcoImportData ");
      var promise = new Promise((resolve, reject) => {
        const acoImportCfgPath =  [DBPATH.CONFIGURATIONS, cardId, DBPATH.ACOIMPORT_ASSIGNEDEXTNS].join("");
        var importCfgRef = this.db.doc(acoImportCfgPath);
        importCfgRef.get().toPromise().then((doc) => {
            if (doc.exists) {
              var curdata = doc.data();
              resolve(curdata);
            }
            else{
               return;
            }
            })
            .catch((error) => {
                this.logger.error("Error getting document:", error);
                reject(error);
            });
      });
      return promise;
    }
    getAcoImportData(cardId) {
        this.logger.debug("getAcoImportData ");
      var promise = new Promise((resolve, reject) => {
        const acoImportCfgPath =  [DBPATH.CONFIGURATIONS, cardId, DBPATH.ACOIMPORT_CFG].join("");
        var importCfgRef = this.db.doc(acoImportCfgPath);
        importCfgRef.get().toPromise().then((doc:any) => {
            if (doc.exists) {
                resolve(doc);
            }
            else{
                reject("document not found");
            }
            })
            .catch((error) => {
                this.logger.error("Error getting document:", error);
                reject(error);
            });
      });
      return promise;
    }
   /* getACOCompanyNumbers(cardId) {
      this.logger.debug("getAcoImportData ");
    var promise = new Promise((resolve, reject) => {
      const acoImportCfgPath =  [DBPATH.CONFIGURATIONS, cardId, DBPATH.ACOIMPORT_COMPH_DATA].join("");
      var importCfgRef = this.db.doc(acoImportCfgPath);
      importCfgRef.get().toPromise().then((doc) => {
          if (doc.exists) {
              resolve(doc);
          }
          else{
              reject("document not found");
          }
          })
          .catch((error) => {
              this.logger.error("Error getting document:", error);
              reject(error);
          });
    });
    return promise;
  }*/
    
      getCardDetails(cardId){
      this.logger.debug("getCardDetails ");
      var promise = new Promise((resolve, reject) => {
        const acoPath =  [DBPATH.CARDS, cardId, DBPATH.CARDINFO_ACO].join("");
        var cardRef = this.db.doc(acoPath);
        cardRef.get().toPromise().then((doc:any) => {
          if (doc.exists) {
            var curdata = doc.data();
            resolve(curdata);
          }else{
            reject("doc does not exist : "+cardId);
          }
        }).catch(function (error) {
          this.logger.error("Error getting document:", error);
          reject();
        });
      });
      return promise;
    }
    getCardCount(listLength, lastDoc={},queryVar,value){
      //this.logger.debug("Mark as Migrated Card Count");
      let promise = new Promise((resolve, reject) => {
        var user = firebase.auth().currentUser;
        if (user) {
          const cardpath =  [DBPATH.MTUSERS, user.uid, DBPATH.CARDS].join("");
          var queryRef = firebase.firestore().collection(cardpath).where(queryVar, "==", value);
          if(Object.keys(lastDoc).length === 0){
            let docRef = queryRef.limit(listLength).get()
            .then((querySnapshot) => {
              resolve(querySnapshot);
            })
            .catch((error) =>{
              this.logger.error("Error getting collection:", error);
              reject(error);
            });   
          }
          else{
            let docRef = queryRef.startAfter(lastDoc).limit(listLength).get()
            .then((querySnapshot) => { 
              resolve(querySnapshot);
            })
            .catch((error) =>{
              this.logger.error("Error getting collection:", error);
              reject(error);
            });               
          }
        }else{
          reject();
          this.logger.error(ERROR_STRING.USER_NOT_FOUND)
        }
      });
      return promise;
    }

    fetchAllCards(listLength, lastDoc={}){
      this.logger.debug("fetchAllCards");
      var promise = new Promise((resolve, reject) => {
        var user = firebase.auth().currentUser;
        if (user) {
          const cardPath =  [DBPATH.MTUSERS, user.uid, DBPATH.CARDS].join("");
          let queryRef = firebase.firestore().collection(cardPath).orderBy('updated','desc');
          if(Object.keys(lastDoc).length === 0){
            let docRef = queryRef.limit(listLength).get()
            .then((querySnapshot) => {
              resolve(querySnapshot);
            })
            .catch((error) =>{
              this.logger.error("Error getting collection:", error);
              reject(error);
            });   
          }
          else{
            let docRef = queryRef.startAfter(lastDoc).limit(listLength).get()
            .then((querySnapshot) => { 
              resolve(querySnapshot);
            })
            .catch((error) =>{
              this.logger.error("Error getting collection:", error);
              reject(error);
            });               
          }
        }else{
          reject();
          this.logger.error(ERROR_STRING.USER_NOT_FOUND)
        }
      });
      return promise;
    }

    getCardSummary(cardId){
      this.logger.debug("getCardSummary for card :",cardId);
      var promise = new Promise((resolve, reject) => {
        var user = firebase.auth().currentUser;
        if (user) {
          const cardsummaryPath =  [DBPATH.CARDS, cardId, DBPATH.FUNCTIONRESULTS_ASSIGNUSERS , DBPATH.TASKRUNS ].join("");
          var cardRef = firebase.firestore().collection(cardsummaryPath);
            cardRef.limit(MAX_LIMIT).get().then((querySnapshot) => {
                  const tempDoc = []
                  querySnapshot.forEach((doc:any) => {
                      tempDoc.push({ id: doc.id, ...doc.data() })
                  })
                  resolve(tempDoc);
                })
                .catch((error) => {
                  this.logger.error("Error getting collection:", error);
                  reject();
            });  
        }else{
          reject();
          this.logger.error(ERROR_STRING.USER_NOT_FOUND)
        }
      });
      return promise;
    }

    fetchCardsByCompanyName(companyName,listLength){
      this.logger.info("fetchCardsByCompanyName : ",companyName);
      var len = companyName.length;
      var startWith = companyName.slice(0, len-1);
      var lastChar = companyName.slice(len-1, len);

      var nextName = startWith + String.fromCharCode(lastChar.charCodeAt(0) + 1);
      var promise = new Promise((resolve, reject) => {
        var user = firebase.auth().currentUser;
        if (user) {
          const cardPath =  [DBPATH.MTUSERS, user.uid, DBPATH.CARDS].join("");
          let cardRef = firebase.firestore().collection(cardPath)
          .where('company', '>=', companyName)
          .where('company', '<', nextName)
          .limit(listLength);
          cardRef.get().then((querySnapshot) =>{
              const tempDoc = []
              querySnapshot.forEach((doc:any) => {
                  tempDoc.push({ id: doc.id, ...doc.data() })
              })
              this.logger.debug("Cards collection complete");
              resolve(tempDoc);
          }).catch((error) => {
            this.logger.error("Error getting collection:", error);
            reject();
          });
        }else{
          reject();
        }
      });
      return promise;
    }
    

    setIPOCfgData(cardId, ipoCfgData){
        this.logger.debug("setIPOCfgData ");
        var ipoData = JSON.stringify(ipoCfgData);

        var ipoDataList = [];
        var ipoUserList = ipoCfgData['IPOUsers'];
        this.logger.debug("IPOCSVData length(in bytes): ", ipoData.length);
        //this.logger.debug("IPOCSVData: ", ipoData);
        if(ipoData.length > 800000){
            this.logger.debug("Splitting CSV data as size is more than 800KB");
            let tempUserList = JSON.parse(JSON.stringify(ipoUserList));
            let splitSize = 2;
            let csvSpliceLength = JSON.stringify(tempUserList.splice(0, (ipoUserList.length/splitSize)));
            while(csvSpliceLength.length > 800000){
              this.logger.debug("slipt size ", splitSize);
              splitSize = splitSize *2;
              tempUserList = JSON.parse(JSON.stringify(ipoUserList));
              csvSpliceLength = JSON.stringify(tempUserList.splice(0, (ipoUserList.length/splitSize)));
            }
            const splitValue = ipoUserList.length/splitSize;
            this.logger.debug("splitValue ", splitValue);
            //Based on splitValue , CSV data is uploaded to firestore 
            while (ipoUserList.length > 0){
                var tempData = ipoUserList.splice(0, splitValue);
                this.logger.debug("Size of split data: ", tempData.length);
                ipoDataList.push({'IPOUsers': tempData});
            }
        }
        else{
            ipoDataList.push({'IPOUsers': ipoUserList});
        }


        var promises = [];
        var idx = 0;
        ipoDataList.forEach((tempData) => {
            var ipoCsvData = {
                data : JSON.stringify(tempData)
            }

            promises.push(new Promise((resolve, reject) => {
                const ipoCfgCsvPath =  [DBPATH.CONFIGURATIONS, cardId, DBPATH.IPOCFG_CSV, idx].join("");
                this.db.doc(ipoCfgCsvPath).set(ipoCsvData, {merge: true})
                .then(() =>{
                    this.logger.debug("Updated ipo data %d successfully", idx);
                    resolve();
                }).catch((error) => {
                    this.logger.debug("Error setting ipo config document:", error);
                    reject(error);
                });
            }));

            idx++;
        });

        return Promise.all(promises)
        .then(() => {
            this.logger.debug("Updated all CSV data");
            var data = {csvDataLen : idx};
            new Promise((resolve, reject) => {
                const ipoCfgPath =  [DBPATH.CONFIGURATIONS, cardId, DBPATH.IPOCFG_CSV].join("");
                this.db.doc(ipoCfgPath).set(data, {merge: true})
                .then(() =>{
                    this.logger.debug("Updated csvDataLen: ", idx);
                    resolve();
                }).catch((error) => {
                    this.logger.debug("Error setting csvDataLen", error);
                    reject(error);
                });
            });
        })
        .catch((error) => {
            Promise.reject(error);
        })
    }

    readDeletedUsersCfgData(cardId){
      this.logger.info("readDeleteUsersData");
      let _promise = new Promise((resolve, reject) => {
        const acoExportCfgPath =  [DBPATH.CONFIGURATIONS, cardId, DBPATH.ACOEXPORT_DELETED_USERS_CFGDATA].join("");
        this.db.doc(acoExportCfgPath).get().toPromise()
        .then((doc:any) => {
            if(doc.exists){
              this.logger.debug("fetched deleted users successful");
              resolve(doc.data());
            }
            else{
                this.logger.debug("Deleted users data not available");
                resolve(null);
            }
        }).catch((error) => {
            this.logger.error("Error getting deleted users data:", error);
            reject(error);
        });
    });
    return _promise;
      
    }

    setDeletedUsersCfgData(cardId, usersData, idx = 0){
      this.logger.info("writeDeleteUsersData")
      let deletedUsersData = JSON.stringify(usersData);
      let delUsrCfgData = {
          data : deletedUsersData,
          totalData: usersData.length,
          index : idx
      }    
      let _promise = new Promise((resolve, reject) => {
          const delUsrsCfgPath =  [DBPATH.CONFIGURATIONS, cardId, DBPATH.ACOEXPORT_DELETED_USERS_CFGDATA].join("");
          this.db.doc(delUsrsCfgPath).set(delUsrCfgData)
          .then(() => {
              this.logger.debug("deleted users cfg data set successfully");
              resolve();
          }).catch((error) => {
              this.logger.error("Error setting deleted users data:", error);
              reject(error);
          });
      })
      return _promise;
    }

    setDeletedICRCfgData(cardId, icrData){
      this.logger.info("writeDeletedICRData");
      let deletedICRData = JSON.stringify(icrData);
      let delICRCfgData = {
          data : deletedICRData,
          totalData: icrData.length
      }    
      let _promise = new Promise((resolve, reject) => {
          const delICRCfgPath =  [DBPATH.CONFIGURATIONS, cardId, DBPATH.ACOEXPORT_DELETED_ICR_CFGDATA].join("");
          this.db.doc(delICRCfgPath).set(delICRCfgData)
          .then(() => {
              this.logger.debug("deleted ICR cfg data set successfully");
              resolve();
          }).catch((error) => {
              this.logger.error("Error setting deleted ICR data:", error);
              reject(error);
          });
      })
      return _promise;
    }

    readIPOCfgData(cardId){
        this.logger.info("readIPOCfgData ");
        var promises = [];
        var promise = new Promise((resolve, reject) => {
            const acoImportCfgPath =  [DBPATH.CONFIGURATIONS, cardId, DBPATH.IPOCFG_CSV].join("");
            var cardRef = this.db.doc(acoImportCfgPath);
            cardRef.get().toPromise().then((doc:any) => {
              if (doc.exists) {
                var curdata = doc.data();
                resolve(curdata);
              }else{
                reject("IPO config doc does not exist : "+ cardId);
              }
            }).catch(error => {
              this.logger.error("Error getting IPO Config document:", error);
              reject();
            });
          })
          .then((result:any) => {
                this.logger.debug("Fetching CSV datalen: ", result.csvDataLen);
                for(var idx=0; idx<result.csvDataLen; idx++){
                    var csvData = [];
                    var ipoCfgCsvPath =  [DBPATH.CONFIGURATIONS, cardId, DBPATH.IPOCFG_CSV, idx].join("");
                    var cardRef = this.db.doc(ipoCfgCsvPath);
                    promises.push(
                        cardRef.get().toPromise().then((doc:any) =>{
                            var curdata = doc.data();
                            var dataObj = JSON.parse(curdata.data);
                            return Promise.resolve(dataObj['IPOUsers']);
                        })       
                        .catch((error) => {
                            this.logger.error("Error setting ipo config document:", error);
                            return Promise.reject(error);
                        })
                    );
                }

                return (Promise.all(promises).then((results: any) => {
                    var totalUsers = [];
                    results.forEach( csvData => {
                        totalUsers= [...totalUsers, ...csvData];
                    });
                    var data = JSON.stringify({'IPOUsers' :totalUsers});
                    return Promise.resolve(data);
                })
                .catch((error) => {
                    this.logger.error("Unable to fetch CSV data");
                    return Promise.reject(error);
                }));

          })
          
          return promise;
    }


    readTimeProfilesData(cardId){
        this.logger.info("readTimeProfilesData");
        let _promise = new Promise((resolve, reject) => {
            const acoExportCfgPath =  [DBPATH.CONFIGURATIONS, cardId, DBPATH.ACOEXPORT_TIMEPROFILES].join("");
            this.db.doc(acoExportCfgPath).get().toPromise()
            .then((doc:any) => {
                if(doc.exists){
                  this.logger.debug("fetched mapped timeprofiles successful");
                  resolve(doc.data());
                }
                else{
                    this.logger.debug("No Working Hours data available");
                    resolve(null);
                }
            }).catch((error) => {
                this.logger.error("Error getting timeprofiles data:", error);
                reject(error);
            });
        });
        return _promise;
    }

    setTimeProfilesData(cardId, timeProfiles){
        this.logger.info("setTimeProfilesData");
        let acoTPData = JSON.stringify(timeProfiles);
        let acoTimeProfilesData = {
            data : acoTPData,
            totalData: timeProfiles.length
        }    
        let _promise = new Promise((resolve, reject) => {
            const acoExportCfgPath =  [DBPATH.CONFIGURATIONS, cardId, DBPATH.ACOEXPORT_TIMEPROFILES].join("");
            this.db.doc(acoExportCfgPath).set(acoTimeProfilesData)
            .then(() => {
                this.logger.debug("aco Working Hours data set successfully");
                resolve();
            }).catch((error) => {
                this.logger.error("Error setting aco Working Hours data:", error);
                reject(error);
            });
        })
        return _promise;
    }

    setHuntGroupData(cardId, hGroupData){
        this.logger.info("setCallQueueData");
        let ipoHGData = JSON.stringify(hGroupData);
        let ipoHGroupData = {
            data : ipoHGData,
            totalData: hGroupData.length
        }    
        let _promise = new Promise((resolve, reject) => {
            const ipoHGCfgPath =  [DBPATH.CONFIGURATIONS, cardId, DBPATH.IPOCFG_HGROUP].join("");
            this.db.doc(ipoHGCfgPath).set(ipoHGroupData)
            .then(() => {
                this.logger.debug("ipo Call Queues data set successfully");
                resolve();
            }).catch((error) => {
                this.logger.error("Error setting ipo Call Queues data:", error);
                reject(error);
            });
        })
        return _promise;
    }

    setShortCodeData(cardId, sCodesData) {
      this.logger.info("setShortCodesData");
      let shortCodesData = JSON.stringify(sCodesData);
      let shortCodes = {
        data : shortCodesData,
        totalData : sCodesData.length
      }
      let _promise = new Promise<void>((resolve, reject) => {
        const ipoShortCodesPath = [DBPATH.CONFIGURATIONS, cardId, DBPATH.IPOCFG_SHORTCODES].join("");
        this.db.doc(ipoShortCodesPath).set(shortCodes)
        .then(() => {
            this.logger.debug("ipo short codes data set successfully");
            resolve();
        }).catch((error) => {
            this.logger.error("Error setting ipo short codes data:", error);
            reject(error);
        });
      })
      return _promise;
    }

    readShortCodeData(cardId) {
      this.logger.info("readShortCodeData");
      let _promise = new Promise((resolve, reject) => {
          const acoExportCfgPath = [DBPATH.CONFIGURATIONS, cardId, DBPATH.IPOCFG_SHORTCODES].join("");
          this.db.doc(acoExportCfgPath).get().toPromise()
          .then((doc:any) => {
              if (doc.exists) {
                this.logger.debug("fetched short codes data successfully");
                resolve(doc.data());
              }
              else {
                this.logger.debug("No short codes data available");
                resolve(null);
              }
            }).catch((error) => {
              this.logger.error("Error getting short codes data:", error);
              reject(error);
          });
        });
      return _promise;
    }

    setSteeringCodeData(cardId, sCodesData) {
      this.logger.info("setSteeringCodesData");
      let steeringCodeData = JSON.stringify(sCodesData);
      let steeringCodes = {
        data : steeringCodeData,
        totalData : sCodesData.length
      }
      let _promise = new Promise<void>((resolve, reject) => {
        const ipoSteeringCodePath = [DBPATH.CONFIGURATIONS, cardId, DBPATH.IPOCFG_STEERINGCODES].join("");
        this.db.doc(ipoSteeringCodePath).set(steeringCodes)
        .then(() => {
            this.logger.debug("ipo steering codes data set successfully");
            resolve();
        }).catch((error) => {
            this.logger.error("Error setting ipo steering code data:", error);
            reject(error);
        });
      })
      return _promise;
    }

    readSteeringCodeData(cardId) {
      this.logger.info("readSteeringCodeData");
      let _promise = new Promise((resolve, reject) => {
          const acoExportCfgPath = [DBPATH.CONFIGURATIONS, cardId, DBPATH.IPOCFG_STEERINGCODES].join("");
          this.db.doc(acoExportCfgPath).get().toPromise()
          .then((doc:any) => {
              if (doc.exists) {
                this.logger.debug("fetched steering codes data successfully");
                resolve(doc.data());
              }
              else {
                this.logger.debug("No steering codes data available");
                resolve(null);
              }
            }).catch((error) => {
              this.logger.error("Error getting steering codes data:", error);
              reject(error);
          });
        });
      return _promise;
    }

    setBCAData(cardId, bcaData) {
      this.logger.info("SetBCAData");
      let BCAData = JSON.stringify(bcaData);
      let bcaConfiguration = {
        data : BCAData,
        totalData : bcaData.length
      }
      let _promise = new Promise<void>((resolve, reject) => {
        const ipoBCADataPath = [DBPATH.CONFIGURATIONS, cardId, DBPATH.IPOCFG_BCA].join("");
        this.db.doc(ipoBCADataPath).set(bcaConfiguration)
        .then(() => {
          this.logger.debug("ipo BCA data set successfully");
          resolve();
        }).catch((error) => {
          this.logger.error("Error setting ipo BCA data: ", error);
          reject(error);
        });
      })
      return _promise;
    }

    readBCAData(cardId) {
      this.logger.info("readBCAData");
      let _promise = new Promise((resolve, reject) => {
        const acoExportCfgPath = [DBPATH.CONFIGURATIONS, cardId, DBPATH.IPOCFG_BCA].join("");
        this.db.doc(acoExportCfgPath).get().toPromise()
        .then((doc:any) => {
          if(doc.exists) {
            this.logger.debug("successfully fetched BCA config data");
            resolve(doc.data());
          } else {
            this.logger.debug("No BCA config data available");
            resolve(null);
          }
        }).catch((error) => {
          this.logger.error("Error getting BCA config data: ", error);
          reject(error);
        });
      });
      return _promise;
    }

    setUserAppearanceData(cardId, userAppData) {
      this.logger.debug("setUserAppearanceData");
      let userAppearanceData = JSON.stringify(userAppData);
      let appearanceData = {
        data: userAppearanceData,
        totalData: userAppData.length
      }
      let _promise = new Promise<void>((resolve, reject) => {
        const ipoAppearanceData = [DBPATH.CONFIGURATIONS, cardId, DBPATH.IPOCFG_APPEARANCEDATA].join("");
        this.db.doc(ipoAppearanceData).set(appearanceData)
        .then(() => {
          this.logger.debug("ipo user appearance data set successfully");
          resolve();
        }).catch((error) => {
          this.logger.error("Error settings ipo appearance data");
          reject(error);
        });
      })
      return _promise;
    }

    readUserAppearanceData(cardId) {
      this.logger.info("readUserAppearanceData");
      let _promise = new Promise((resolve, reject) => {
        const acoExportCfgPath = [DBPATH.CONFIGURATIONS, cardId, DBPATH.IPOCFG_APPEARANCEDATA].join("");
        this.db.doc(acoExportCfgPath).get().toPromise()
        .then((doc:any) => {
          if (doc.exists) {
            this.logger.debug("successfully fetch user appearance data");
            resolve(doc.data());
          } else {
            this.logger.debug("No appearance data available");
            resolve(null);
          }
        }).catch((error) => {
          this.logger.error("Error fetching user appearance data: ", error);
          reject(error);
        });
      });
      return _promise;
    }

    setDLGData(cardId, dlgGrpData) {
      this.logger.debug("setDLGData");
      let dlgGroupData = JSON.stringify(dlgGrpData);
      let delegateGroupData = {
        data: dlgGroupData,
        totalData: dlgGrpData.length
      }
      let _promise = new Promise<void>((resolve, reject) => {
        const ipoDlgDataPath = [DBPATH.CONFIGURATIONS, cardId, DBPATH.IPOCFG_DLGGROUP].join("");
        this.db.doc(ipoDlgDataPath).set(delegateGroupData)
        .then(() => {
          this.logger.debug("ipo delegated group data set successfully");
          resolve();
        }).catch((error) => {
          this.logger.error("Error setting ipo delegated group data");
          reject(error);
        });
      })
      return _promise;
    }

    setMorrisonData(cardId, data)
    {
      this.logger.info("setMorrisonData");
        let morrisonExtension = JSON.stringify(data.extensions);
        let morrisonAccesscode = data.accessCode;
        let dataCount =  data.extensions.length;
        let morrison = {
            accesscode : morrisonAccesscode,
            data : morrisonExtension,
            totalData : dataCount
        }    
        let _promise = new Promise((resolve, reject) => {
            const morrisonPath =  [DBPATH.CONFIGURATIONS, cardId, DBPATH.MORRISONCFGCSV].join("");
            this.db.doc(morrisonPath).set(morrison)
            .then(() => {
                this.logger.debug("Morrison data set successfully");
                resolve();
            }).catch((error) => {
                this.logger.error("Error setting Morrison data", error);
                reject(error);
            });
        })
        return _promise;
    }

    readHuntGroupData(cardId){
        this.logger.info("readHuntGroupData");
        let _promise = new Promise((resolve, reject) => {
            const acoExportCfgPath =  [DBPATH.CONFIGURATIONS, cardId, DBPATH.IPOCFG_HGROUP].join("");
            this.db.doc(acoExportCfgPath).get().toPromise()
            .then((doc:any) => {
                if(doc.exists){
                  this.logger.debug("fetched Call Queues data successfully");
                  resolve(doc.data());
                }
                else{
                    this.logger.debug("No Call Queues data available");
                    resolve(null);
                }
            }).catch((error) => {
                this.logger.error("Error getting Call Queues data:", error);
                reject(error);
            });
        });
        return _promise;
    }

    setAutoAttendantData(cardId, aAttendantData){
        this.logger.info("setAutoAttendantData");
        let ipoAAData = JSON.stringify(aAttendantData);
        let ipoAutoAttendantData = {
            data : ipoAAData,
            totalData: aAttendantData.length
        }    
        let _promise = new Promise((resolve, reject) => {
            const ipoAACfgPath =  [DBPATH.CONFIGURATIONS, cardId, DBPATH.IPOCFG_AUTOATTENDANT].join("");
            this.db.doc(ipoAACfgPath).set(ipoAutoAttendantData)
            .then(() => {
                this.logger.debug("ipo Auto-Receptionists data set successfully");
                resolve();
            }).catch((error) => {
                this.logger.error("Error setting ipo Auto-Receptionists data:", error);
                reject(error);
            });
        })
        return _promise;
    }
    
    readAutoAttendantData(cardId){
        this.logger.info("readAutoAttendantData");
        let _promise = new Promise((resolve, reject) => {
            const ipooExportCfgPath =  [DBPATH.CONFIGURATIONS, cardId, DBPATH.IPOCFG_AUTOATTENDANT].join("");
            this.db.doc(ipooExportCfgPath).get().toPromise()
            .then((doc:any) => {
                if(doc.exists){
                  this.logger.debug("fetched Auto-Receptionistst data successfully");
                  resolve(doc.data());
                }
                else{
                    this.logger.debug("No Auto-Receptionists data available");
                    resolve(null);
                }
            }).catch((error) => {
                this.logger.error("Error getting Auto-Receptionists data:", error);
                reject(error);
            });
        });
        return _promise;
    }

    setPagingGroupData(cardId, pagingGroupData){
      this.logger.info("setPagingGroupData");
      let ipoPGData = JSON.stringify(pagingGroupData);
      let ipoPagingGroupData = {
          data : ipoPGData,
          totalData: pagingGroupData.length
      }    
      let _promise = new Promise((resolve, reject) => {
          const ipoPGCfgPath =  [DBPATH.CONFIGURATIONS, cardId, DBPATH.IPOCFG_PGROUP].join("");
          this.db.doc(ipoPGCfgPath).set(ipoPagingGroupData)
          .then(() => {
              this.logger.debug("ipo paging group data set successfully");
              resolve();
          }).catch((error) => {
              this.logger.error("Error setting ipo paging group data:", error);
              reject(error);
          });
      })
      return _promise;
    }

    readPagingGroupData(cardId){
      this.logger.info("readPagingGroupData");
      let _promise = new Promise((resolve, reject) => {
          const acoImportCfgPath =  [DBPATH.CONFIGURATIONS, cardId, DBPATH.IPOCFG_PGROUP].join("");
          this.db.doc(acoImportCfgPath).get().toPromise()
          .then((doc:any) => {
              if(doc.exists){
                this.logger.debug("fetched paging group data successfully");
                resolve(doc.data());
              }
              else{
                  this.logger.debug("No paging group data available");
                  resolve(null);
              }
          }).catch((error) => {
              this.logger.error("Error getting paging group data:", error);
              reject(error);
          });
      });
      return _promise;
    }

    setAA_ICRData(cardId, filteredICRData){
        this.logger.info("setAA_ICRData");
        let aaICRData = JSON.stringify(filteredICRData);
        let ipoAA_ICRData = {
            data : aaICRData,
            topMenu: '' // This field will be updated by CFs on run
        }    
        let _promise = new Promise((resolve, reject) => {
            const ipoAA_ICRCfgPath =  [DBPATH.CONFIGURATIONS, cardId, DBPATH.IPOCFG_AA_ICR].join("");
            this.db.doc(ipoAA_ICRCfgPath).set(ipoAA_ICRData)
            .then(() => {
                this.logger.debug("ipo AA ICR data set successfully");
                resolve();
            }).catch((error) => {
                this.logger.error("Error setting AA ICR data:", error);
                reject(error);
            });
        })
        return _promise;
    } 
    setPreconfig_ICRData(cardId, filteredICRData){
      this.logger.info("setAA_ICRData");
      let preconfigICRData = JSON.stringify(filteredICRData);
      let ipoAA_ICRData = {
          data : preconfigICRData,
          topMenu: '' // This field will be updated by CFs on run
      }    
      let _promise = new Promise((resolve, reject) => {
          const ipoPreconfig_ICRCfgPath =  [DBPATH.CONFIGURATIONS, cardId, DBPATH.IPOCFG_PRECONFIG_ICR].join("");
          this.db.doc(ipoPreconfig_ICRCfgPath).set(ipoAA_ICRData,{merge: true})
          .then(() => {
              this.logger.debug("ipo Preconfig ICR data set successfully");
              resolve();
          }).catch((error) => {
              this.logger.error("Error setting ipo Preconfig ICR data:", error);
              reject(error);
          });
      })
      return _promise;
  } 
    updateAA_ICRData(cardId, filteredICRData,isUpdated, topMenuList?:any){
      this.logger.info("updateAA_ICRData");
      let aaICRData = JSON.stringify(filteredICRData);
      let ipoAA_ICRData = {} as any;
      ipoAA_ICRData.data = aaICRData;
      ipoAA_ICRData.topMenu = '';// This field will be updated by CFs on run
      ipoAA_ICRData.isUpdated = isUpdated;

      if (topMenuList !== undefined && topMenuList.length > 0) ipoAA_ICRData.topMenuList = JSON.stringify(topMenuList);

      let _promise = new Promise((resolve, reject) => {
          const ipoAA_ICRCfgPath =  [DBPATH.CONFIGURATIONS, cardId, DBPATH.ACOEXPORT_AA_ICR].join("");
          this.db.doc(ipoAA_ICRCfgPath).set(ipoAA_ICRData, {merge:true})
          .then(() => {
              this.logger.debug("ipo AA ICR data set successfully");
              resolve();
          }).catch((error) => {
              this.logger.error("Error setting AA ICR data:", error);
              reject(error);
          });
      })
      return _promise;
  }

    readAA_ICRData(cardId){
        this.logger.info("readAA_ICRData");
        let _promise = new Promise((resolve, reject) => {
            const acoExportCfgPath =  [DBPATH.CONFIGURATIONS, cardId, DBPATH.ACOEXPORT_AA_ICR].join("");
            this.db.doc(acoExportCfgPath).get().toPromise()
            .then((doc:any) => {
                if(doc.exists){
                  this.logger.debug("fetched AA ICR data successfully");
                  resolve(doc.data());
                }
                else{
                    this.logger.debug("No AA ICR data available");
                    resolve(null);
                }
            }).catch((error) => {
                this.logger.error("Error getting AA ICR data:", error);
                reject(error);
            });
        });
        return _promise;
    }

    readOriginalAA_ICRData(cardId){
      this.logger.info("readAA_ICRData");
      let _promise = new Promise((resolve, reject) => {
          const acoExportCfgPath =  [DBPATH.CONFIGURATIONS, cardId, DBPATH.IPOCFG_AA_ICR].join("");
          this.db.doc(acoExportCfgPath).get().toPromise()
          .then((doc:any) => {
              if(doc.exists){
                this.logger.debug("fetched AA ICR data successfully");
                resolve(doc.data());
              }
              else{
                  this.logger.debug("No AA ICR data available");
                  resolve(null);
              }
          }).catch((error) => {
              this.logger.error("Error getting AA ICR data:", error);
              reject(error);
          });
      });
      return _promise;
  }

    readComPhNumberData(cardId){
        this.logger.info("readComPhNumberData");
        let _promise = new Promise((resolve, reject) => {
            const acoExportCfgPath =  [DBPATH.CONFIGURATIONS, cardId, DBPATH.ACOIMPORT_COMPH_DATA].join("");
            this.db.doc(acoExportCfgPath).get().toPromise()
            .then((doc:any) => {
                if(doc.exists){
                  this.logger.debug("fetched Company Phone data successfully");
                  resolve(doc.data());
                }
                else{
                    this.logger.debug("No Company Phone data available");
                    resolve(null);
                }
            }).catch((error) => {
                this.logger.error("Error getting Company Phone data:", error);
                reject(error);
            });
        });
        return _promise;
    }

    setACOExportData(cardId, acoExportData){
      this.logger.debug("setACOExportData ");
        var acoData = JSON.stringify(acoExportData);
        var acoUserData = {
            data : acoData,
            totalData: acoExportData.length
        }

        var promise = new Promise((resolve, reject) => {
            const acoExportCfgPath =  [DBPATH.CONFIGURATIONS, cardId, DBPATH.ACOEXPORT_CFGDATA].join("");
            this.db.doc(acoExportCfgPath).set(acoUserData)
            .then(() => {
                this.logger.debug("aco export data set successfully");
                resolve();
            }).catch((error) => {
                this.logger.error("Error setting aco export data:", error);
                reject(error);
            });
        });
        return promise;
    }

    setBlfExtensionIdData(cardId , blfExntPresenceData){
      this.logger.debug("getACOExportCFGData ");
        var acoBLFExtnIdData = {
            data : JSON.stringify(blfExntPresenceData),
            totalData : blfExntPresenceData.length
        }
        var promise = new Promise((resolve, reject) => {
            const acoExportCfgPath =  [DBPATH.CONFIGURATIONS, cardId, DBPATH.ACOEXPORT_BLFEXTENSIONIDDATA].join("");
            this.db.doc(acoExportCfgPath).set(Object.assign({},acoBLFExtnIdData))
            .then(() => {
                this.logger.debug("setting BLF ExtensionID presence data is successful");
                resolve();
            }).catch((error) => {
                this.logger.error("Error setting BLF ExtensionID presence data:", error);
                reject(error);
            });
        });
        return promise;
    }

    setBlfData(cardId , blfData){
      this.logger.debug("setBlfData ");
        var acoBLFData = {
            data : JSON.stringify(blfData),
            totalData : blfData.length
        }
        var promise = new Promise((resolve, reject) => {
            const acoExportCfgPath =  [DBPATH.CONFIGURATIONS, cardId, DBPATH.ACOEXPORT_BLFEXTNDATA].join("");
            this.db.doc(acoExportCfgPath).set(Object.assign({},acoBLFData))
            .then(() => {
                this.logger.debug("setting blf data is successful");
                resolve();
            }).catch((error) => {
                this.logger.error("Error setting blf data:", error);
                reject(error);
            });
        });
        return promise;
    }

   /* setRingOnMonitoredCallData(cardId , ringOnMonitoredCall)
    {
       this.logger.debug("getACOExportCFGData ");
         var acoBLFRingData = {
             data : JSON.stringify(ringOnMonitoredCall),
             totalData : ringOnMonitoredCall.length
         }
         var promise = new Promise((resolve, reject) => {
             const acoExportCfgPath =  [DBPATH.CONFIGURATIONS, cardId, DBPATH.ACOEXPORT_USER_UPDATERINGONMONITOREDCALLDATA].join("");
             this.db.doc(acoExportCfgPath).set(Object.assign({},acoBLFRingData))
             .then(() => {
                 this.logger.debug("setting BLF Ring data is successful");
                 resolve();
             }).catch((error) => {
                 this.logger.error("Error setting BLF Ring data:", error);
                 reject(error);
             });
         });
         return promise;
     }*/
    setACOMigrationData(tasksList, cardId = ""){
        let batch = this.db.firestore.batch();
        let promises = Promise.all(
            tasksList.map(task => {
              if(task.path !== "/Configurations/"+cardId+"/AcoExportCfg/userCfgData"){
                let docRef = this.db.firestore.doc(task.path);
                if(task.data !== undefined && task.data.length !== 0){
                  let docData = JSON.stringify(task.data);
                  let exportData = {
                      data : docData,
                      totalData: task.data.length
                  }
                  batch.set(docRef, exportData , {merge:true});
                }
              }                
            })
        )
        return (promises
        .then(() => batch.commit()));
    }

    getTasksTemplate(cardId){
        let tasks = [];
        let promise = new Promise((resolve, reject) => {
            tasks[TASK_NAME.CHAIN] = {};
            tasks[TASK_NAME.CHAIN].path = [DBPATH.CONFIGURATIONS, cardId, DBPATH.ACOEXPORT_TASKCHAIN].join("");
            tasks[TASK_NAME.FINAL_TASK_CHAIN] = {};
            tasks[TASK_NAME.FINAL_TASK_CHAIN].path = [DBPATH.CONFIGURATIONS, cardId, DBPATH.ACOEXPORT_TASKCHAIN_FINAL].join("");
            tasks[TASK_NAME.USER_ASSIGN] = {};
            tasks[TASK_NAME.USER_ASSIGN].path =  [DBPATH.CONFIGURATIONS, cardId, DBPATH.ACOEXPORT_CFGDATA].join("");
            tasks[TASK_NAME.MESSAGE_ONLY] = {};
            tasks[TASK_NAME.MESSAGE_ONLY].path =  [DBPATH.CONFIGURATIONS, cardId, DBPATH.ACOEXPORT_MSG_ONLY].join("");
            tasks[TASK_NAME.USER_CONTACTS] = {};
            tasks[TASK_NAME.USER_CONTACTS].path =  [DBPATH.CONFIGURATIONS, cardId, DBPATH.ACOEXPORT_USER_CONTACTS].join("");
            tasks[TASK_NAME.USER_BUSINESS_HOURS] = {};
            tasks[TASK_NAME.USER_BUSINESS_HOURS].path =  [DBPATH.CONFIGURATIONS, cardId, DBPATH.ACOEXPORT_USER_BH_DATA].join("");
            tasks[TASK_NAME.USER_CALLFORWARD] = {};
            tasks[TASK_NAME.USER_CALLFORWARD].path =  [DBPATH.CONFIGURATIONS, cardId, DBPATH.ACOEXPORT_USER_CALLFORWARDDATA].join("");
            tasks[TASK_NAME.USER_BLF_ENABLEPRESENCE] = {};
            tasks[TASK_NAME.USER_BLF_ENABLEPRESENCE].path =  [DBPATH.CONFIGURATIONS, cardId, DBPATH.ACOEXPORT_BLFEXTENSIONIDDATA].join("");
            tasks[TASK_NAME.USER_UPDATEBLFDATA] = {};
            tasks[TASK_NAME.USER_UPDATEBLFDATA].path =  [DBPATH.CONFIGURATIONS, cardId, DBPATH.ACOEXPORT_BLFEXTNDATA].join("");
            tasks[TASK_NAME.HUNT_GROUP_USERDATA] = {};
            tasks[TASK_NAME.HUNT_GROUP_USERDATA].path = [DBPATH.CONFIGURATIONS, cardId, DBPATH.ACOEXPORT_HGUSERDATA].join("");
            tasks[TASK_NAME.EXTENSIONS_MAP] = {};
            tasks[TASK_NAME.EXTENSIONS_MAP].path = [DBPATH.CONFIGURATIONS, cardId, DBPATH.ACOEXPORT_EXTNSMAP].join("");
            tasks[TASK_NAME.PAGING_GROUP_USERDATA] = {};
            tasks[TASK_NAME.PAGING_GROUP_USERDATA].path = [DBPATH.CONFIGURATIONS, cardId, DBPATH.ACOEXPORT_PGUSERDATA].join("");
            tasks[TASK_NAME.PARK_LOCATION_DATA] = {};
            tasks[TASK_NAME.PARK_LOCATION_DATA].path = [DBPATH.CONFIGURATIONS, cardId, DBPATH.ACOEXPORT_PARK_LOCATION_CFG_DATA].join("");
            //tasks[TASK_NAME.UPLOAD_ZERO_DIAL] = {};
           // tasks[TASK_NAME.UPLOAD_ZERO_DIAL].path = [DBPATH.CONFIGURATIONS, cardId, DBPATH.ACOEXPORT_DTMF0].join("");
            tasks[TASK_NAME.CALL_PICKUP] = {};
            tasks[TASK_NAME.CALL_PICKUP].path = [DBPATH.CONFIGURATIONS, cardId, DBPATH.ACOEXPORT_CALLPICKUPDATA].join("");
            tasks[TASK_NAME.MAC_UPDATE_FOR_EXTENSIONS] = {};
            tasks[TASK_NAME.MAC_UPDATE_FOR_EXTENSIONS].path = [DBPATH.CONFIGURATIONS, cardId, DBPATH.SYSMONSTATUS].join("");
           // tasks[TASK_NAME.USER_UPDATERINGONMONITOREDCALLDATA] = {};
           // tasks[TASK_NAME.USER_UPDATERINGONMONITOREDCALLDATA].path = [DBPATH.CONFIGURATIONS, cardId, DBPATH.ACOEXPORT_USER_UPDATERINGONMONITOREDCALLDATA].join("");
            tasks[TASK_NAME.MORRISON_UNCONDITIONAL_FORWARDING] = {};
            tasks[TASK_NAME.MORRISON_UNCONDITIONAL_FORWARDING].path = [DBPATH.CONFIGURATIONS, cardId, DBPATH.ACOEXPORT_MORRISON_UNCONDITIONAL_FORWARDING].join("");
            tasks[TASK_NAME.MORRISON_TEXT_TO_SPEECH] = {};
            tasks[TASK_NAME.MORRISON_TEXT_TO_SPEECH].path = [DBPATH.CONFIGURATIONS, cardId, DBPATH.ACOEXPORT_MORRISON_TEXT_TO_SPEECH].join("");
            resolve(tasks);
        })

        return promise;
    }
    
    setACOTrigger(cardId, triggerValue,opName){
      this.logger.debug("setACOExportTrigger ");
        var acoTriggerData = {
            trigger : triggerValue
        }
        let acoTriggerPath;
        if(opName == PROCESS.MIGRATION)
          acoTriggerPath =  [DBPATH.CARDS, cardId, DBPATH.TRIGGERS_ACOEXPORT].join("");
        else if(opName == PROCESS.DELETION){
          acoTriggerPath = [DBPATH.CARDS, cardId, DBPATH.TRIGGERS_CLEARACO].join("");
        }

        var promise = new Promise((resolve, reject) => {
            this.db.doc(acoTriggerPath).set(acoTriggerData, {merge: true})
            .then(() => {
                this.logger.debug("aco trigger set successfully");
                resolve();
            }).catch((error) => {
                this.logger.error("Error setting aco  data:", error);
                reject(error);
            });
        });
        return promise;
    }

    setACOExportLastRunOperation(cardId, value){
      this.logger.debug("setACOExportLastRunOperation ");
        var acoExportLastRunOpData = {
          data : value
        }
        const acoExportPath =  [DBPATH.CONFIGURATIONS, cardId, DBPATH.ACOEXPORT_LAST_RUN_OPERATION].join("");

        var promise = new Promise((resolve, reject) => {
            this.db.doc(acoExportPath).set(acoExportLastRunOpData, {merge: true})
            .then(() => {
                this.logger.debug("aco export last run operation data set successfully");
                resolve();
            }).catch((error) => {
                this.logger.error("Error setting aco export last run operation data:", error);
                reject(error);
            });
        });
        return promise;
    }

    getACOExportLastRunOperation(cardId){
      this.logger.debug("getACOExportLastRunOperation ");
        var promise = new Promise((resolve, reject) => {
          const acoExportPath =  [DBPATH.CONFIGURATIONS, cardId, DBPATH.ACOEXPORT_LAST_RUN_OPERATION].join("");

          this.db.doc(acoExportPath)
          .get().toPromise().then((doc:any) => {
            if (doc.exists) {
              var curdata = doc.data();
              if(curdata.data !== undefined)
                  resolve(curdata.data);
            }
            resolve("");
          }).catch((error)=> {
            this.logger.error("Error getting document:", error);
            reject();
          });
      });
      return promise;
    }

    resetACOExportStatus(cardId){
      this.logger.debug("resetACOExportStatus ");
      var acoExportStatus = {
          desc : "",
          progress : 0,
          status : -1 
      }
      var promise = new Promise((resolve, reject) => {
          const acoExportStatusPath =  [DBPATH.CARDS, cardId, DBPATH.STATUS_ACOEXPORTSTATUS].join("");
          var acoExportCfgRef = this.db.doc(acoExportStatusPath);
          acoExportCfgRef.set(acoExportStatus , {merge: true}).then(() => {
            resolve();
          })   
          .catch((error) => {
              this.logger.error("Unable to reset ACO Export status");
              reject(error);
          });
      });
      return promise;
    }

    setTableData(cardId, tableData){
      this.logger.debug("setTableData ");
        var tempData = JSON.stringify(tableData);
        var acoTableData = {
            data : tempData
        }

        var promise = new Promise((resolve, reject) => {
            const acoExportCfgPath =  [DBPATH.CONFIGURATIONS, cardId, DBPATH.ACOEXPORT_CFGTABLEDATA].join("");
            this.db.doc(acoExportCfgPath).set(acoTableData , {merge: true})
            .then(() => {
                this.logger.debug("Table data set successfully");
                resolve();
            }).catch((error) => {
                this.logger.error("Error setting aco table data:", error);
                reject(error);
            });
        });
        return promise;
    }

    setHGnPGTableData(cardId, tableData){
      this.logger.debug("Set Call Queues and Paging Only table data ");
        var tempData = JSON.stringify(tableData);
        var acoTableData = {
            data : tempData
        }
        var promise = new Promise((resolve, reject) => {
            const acoExportCfgPath =  [DBPATH.CONFIGURATIONS, cardId, DBPATH.ACOEXPORT_CFG_HG_PG_TABLEDATA].join("");
            this.db.doc(acoExportCfgPath).get().toPromise().then((doc:any)=>{
              if(doc.exists){
                this.db.doc(acoExportCfgPath).set(acoTableData , {merge: true})
                .then(() => {
                  this.logger.debug("Call Queues and Paging Only table data set successfully");
                  resolve();
                }).catch((error) => {
                  this.logger.error("Error setting aco Call Queues and Paging Only table data:", error);
                  reject(error);
                });
              }else{
                reject(ERROR_STRING.DOC_DOES_NOT_EXIST);
              }
            })
            
        });
        return promise;
    }

    readTableData(cardId){
      this.logger.debug("readTableData ");
        var promise = new Promise((resolve, reject) => {
            const acoImportCfgPath =  [DBPATH.CONFIGURATIONS, cardId, DBPATH.ACOEXPORT_CFGTABLEDATA].join("");
            this.db.doc(acoImportCfgPath)
            .get().toPromise().then((doc:any) => {
              if (doc.exists) {
                var curdata = doc.data();
                if(curdata.data !== undefined)
                    resolve(curdata.data);
              }
              resolve("");
            }).catch(function (error) {
              this.logger.error("Error getting document:", error);
              reject();
            });
        });
        return promise;
    }

    readHGnPGTableData(cardId) {
      this.logger.debug("read Call Queues and paging only table data ");
      var promise = new Promise((resolve, reject) => {
          const acoImportCfgPath =  [DBPATH.CONFIGURATIONS, cardId, DBPATH.ACOEXPORT_CFG_HG_PG_TABLEDATA].join("");
          this.db.doc(acoImportCfgPath)
          .get().toPromise().then((doc:any) => {
            if (doc.exists) {
              var curdata = doc.data();
              if(curdata.data !== undefined)
                  resolve(curdata.data);              
            }
            resolve("");           
          }).catch(function (error) {
            this.logger.error("Error getting document:", error);
            reject();
          });
      });
      return promise;
    }

    setUserEmailData(cardId , emailData){
      this.logger.debug("setUserEmailData ");
        var tempData = JSON.stringify(emailData);
        var acoEmailData = {
            data : tempData
        }

        var promise = new Promise((resolve, reject) => {
            const acoExportCfgPath =  [DBPATH.CONFIGURATIONS, cardId, DBPATH.ACOEXPORT_CFGEMAILDATA].join("");
            this.db.doc(acoExportCfgPath).set(acoEmailData , {merge: false})
            .then(() => {
                this.logger.debug("Email data set successfully");
                resolve();
            }).catch((error) => {
                this.logger.error("Error setting Email data:", error);
                reject(error);
            });
        });
        return promise;
    }

    getUserEmailData(cardId){
      this.logger.debug("getUserEmailData ");
        var promise = new Promise((resolve, reject) => {
            const acoExportCfgPath =  [DBPATH.CONFIGURATIONS, cardId, DBPATH.ACOEXPORT_CFGEMAILDATA].join("");
            let userEmailDocRef  = this.db.doc(acoExportCfgPath);
            userEmailDocRef.get().toPromise().then((doc:any) =>{
              let curData = null;
                if(doc.exists){
                  curData = doc.data();
                  resolve(curData.data);
                }else{
                  resolve(curData);
                } 
            }).catch((error) => {
                this.logger.error("Error getting Email data:", error);
                reject(error);
            });
        });
        return promise;
    }

    getUserCardDetails(cardId){
      //to get a particular cardId doc under users
      this.logger.info("getUserCardDetails : ",cardId);
      var promise = new Promise((resolve, reject) => {
        var user = firebase.auth().currentUser;
        if (user) {
          const cardDocPath =  [DBPATH.MTUSERS, user.uid, DBPATH.CARDS,cardId].join("");
          let cardRef = this.db.doc(cardDocPath)
          cardRef.get().toPromise().then((doc:any) =>{
              let curData = doc.data();
              resolve(curData);
          }).catch((error) => {
            this.logger.error("Error getting User Card doc:", error);
            reject();
          });
        }else{
          this.logger.error(ERROR_STRING.USER_NOT_FOUND);
          reject();
        }
      });
      return promise;
    }

    updateStage(cardId, migrationStage:MIGRATION_STAGE){
      this.logger.debug("updateStage ");
        var aco = {
            stage : migrationStage,
            updated : firebase.firestore.FieldValue.serverTimestamp()
        }

        var promise = new Promise((resolve, reject) => {
          var user = firebase.auth().currentUser;
          if (user) {
            const cardStatusPath =  [DBPATH.MTUSERS, user.uid, DBPATH.CARDS , cardId].join("");
            var cardRef = this.db.doc(cardStatusPath);
            cardRef.set(aco , {merge: true})
                .then((result: any) =>resolve())
                .catch(error => reject(error));
          }else{
            this.logger.error("firebase auth user does not exist ");
          }
        });
        return promise;
    }

    updateMarkAsComplete(cardId){
      this.logger.debug("updateCompanyName ");
      let markAsComplete = {
        markAsCompleted : true,
        updated : new Date()
      }

      var promise = new Promise((resolve, reject) => {
        var user = firebase.auth().currentUser;
        if (user) {
          const cardStatusPath =  [DBPATH.MTUSERS, user.uid, DBPATH.CARDS , cardId].join("");
          var cardRef = this.db.doc(cardStatusPath);
          cardRef.set(markAsComplete , {merge: true}).then(() => {
            this.logger.debug("updating markAsCompleted successful: ",cardId);
            resolve();
          })   
          .catch((error) => {
              this.logger.error("Unable to update markAsCompleted:",cardId);
              reject(error);
          });
        }else{
          this.logger.error(ERROR_STRING.USER_NOT_FOUND);
        }
      });
      return promise;
    }

    updateCompanyName(cardId , companyName){
      this.logger.debug("updateCompanyName ");
        let companyNameUpdate = {
          company : companyName,
          updated : firebase.firestore.FieldValue.serverTimestamp()
        }

        var promise = new Promise((resolve, reject) => {
          var user = firebase.auth().currentUser;
          if (user) {
            const cardStatusPath =  [DBPATH.MTUSERS, user.uid, DBPATH.CARDS , cardId].join("");
            //const cardStatusPath =  [DBPATH.CARDS, cardId, DBPATH.CARDINFO_STATUS].join("");
            var cardRef = this.db.doc(cardStatusPath);
            cardRef.set(companyNameUpdate , {merge: true}).then(() => {
              resolve();
            })   
            .catch((error) => {
                this.logger.error("Unable to update company name");
                reject(error);
            });
          }else{
            this.logger.error("firebase auth user does not exist ");
          }
        });
        return promise;

    }

    updateGreetingsTriggerStatus(cardId,status,fileName:string,fileSize?:number){
      this.logger.debug("Updating greetings trigger status doc:"+status);
      var greetingsStatusData = {
                                  trigger:status,
                                  zipFilePathName:fileName,
                                  zipFileSize:fileSize
                                }
      var promise = new Promise((resolve,reject)=>{
        var user = firebase.auth().currentUser;
        if(user){
          const greetingsStatusPath =  [DBPATH.CONFIGURATIONS, cardId, DBPATH.GREETINGSSTATUS].join("");
          this.db.doc(greetingsStatusPath).update(greetingsStatusData)
            .then(() =>{
                this.logger.debug("Updated greeting trigger status document successfully");
                resolve();
            })
            .catch((error) => {
                this.logger.error("Error setting greetings trigger status document:", error);
                reject(error);
            });
        }else{
            this.logger.error("firebase auth user does not exist ");
        }
      });
      return promise;
    }

    updateSysmonTriggerStatus(cardId,status,fileName:string,fileSize?:number){
      this.logger.debug("Updating sysmon trigger status doc:"+status);
      var sysmonStatusData = {
                                  trigger:status,
                                  filePathName:fileName,
                                  fileSize:fileSize
                                }
      var promise = new Promise((resolve,reject)=>{
        var user = firebase.auth().currentUser;
        if(user){
          const sysmonStatusPath =  [DBPATH.CONFIGURATIONS, cardId, DBPATH.SYSMONSTATUS].join("");
          this.db.doc(sysmonStatusPath).update(sysmonStatusData)
            .then(() =>{
                this.logger.debug("Updated sysmon trigger status document successfully");
                resolve();
            })
            .catch((error) => {
                this.logger.error("Error setting sysmon trigger status document:", error);
                reject(error);
            });
        }else{
            this.logger.error("firebase auth user does not exist ");
        }
      });
      return promise;
    }

    getGreetingsFileDetails(cardId){
      this.logger.debug("Get greetings trigger status document:");
      const greetingsStatusPath =  [DBPATH.CONFIGURATIONS, cardId, DBPATH.GREETINGSSTATUS].join("");
      var promise = new Promise((resolve,reject)=>{
        this.db.doc(greetingsStatusPath).get().toPromise().then((doc:any) => {
              if (doc.exists) {
                var data = doc.data();
                this.logger.debug("trigger status: "+data["trigger"]);
                if(data["trigger"] == TRIGGER_STATUS.DONE ||
                    data["trigger"] == TRIGGER_STATUS.START){
                  resolve({name:data["zipFilePathName"],size:data["zipFileSize"],trigger:data["trigger"]});
                }else{
                  resolve(null);
                }                
              }else{
                reject("Greetings trigger doc does not exist : "+ cardId);
              }
            }).catch(error => {
              this.logger.error("Error getting greetings trigger status document:", error);
              reject(error);
            });
      });
      return promise;
    }

    getSysmonFileDetails(cardId){
      this.logger.debug("Get sysmon SIP file status document:");
      const sysmonStatusPath =  [DBPATH.CONFIGURATIONS, cardId, DBPATH.SYSMONSTATUS].join("");
      var promise = new Promise((resolve,reject)=>{
        this.db.doc(sysmonStatusPath).get().toPromise().then((doc:any) => {
              if (doc.exists) {
                var data = doc.data();
                this.logger.debug("trigger status: "+data["trigger"]);
                if(data["trigger"] == TRIGGER_STATUS.DONE ||
                    data["trigger"] == TRIGGER_STATUS.NOTYET){
                    
                  resolve({name:data["filePathName"],size:data["fileSize"],status:data["trigger"]});
                }else{
                  resolve(null);
                }                
              }else{
                reject("sysmon SIP file trigger doc does not exist : "+ cardId);
              }
            }).catch(error => {
              this.logger.error("Error getting sysmon SIP file trigger status document:", error);
              reject(error);
            });
      });
      return promise;
    }

    getVMProDocData(cardId){
      this.logger.debug("Get VMPro Extract doc data:");
      const vmProExtractDocPath =  [DBPATH.CONFIGURATIONS, cardId, DBPATH.VMPROEXTRACTDOC].join("");
      var promise = new Promise((resolve,reject)=>{
        this.db.doc(vmProExtractDocPath).get().toPromise().then((doc:any) => {
              if (doc.exists) {
                resolve(doc.data());
              }else{
                reject("VM Pro doc does not exist : "+ cardId);
              }
            }).catch(error => {
             // this.logger.error("Error getting VM Pro document:", error);
              reject(error);
            });
      });
      return promise;

    }

    fetchDeviceExtList(cardId) {
      this.logger.debug("get device Id extension Id map data: ");
      const deviceExtListPath = [DBPATH.CONFIGURATIONS, cardId, DBPATH.DEVICE_EXT_LIST].join("");
      var promise = new Promise((resolve, reject) => {
        this.db.doc(deviceExtListPath).get().toPromise().then((doc:any) => {
          if (doc.exists) {
            resolve(doc.data());
          } else {
            reject("Device Id extension Id map data does not exist : "+ cardId);
          }
        }).catch(error => {
          reject(error);
        });
      });
      return promise;
    }
    
    getCardStatus(cardId){
      //this.logger.debug("getCardStatus ");
      var promise = new Promise((resolve, reject) => {
        const cardStatusPath =  [DBPATH.CARDS, cardId, DBPATH.CARDINFO_STATUS].join("");
        var cardRef = this.db.doc(cardStatusPath);
        cardRef.get().toPromise().then((doc:any) => {
          if(doc.exists)
          {
            let curdata = doc.data();
            resolve(curdata);
          }else{
            reject("doc does not exist : "+cardId);
          }
        })   
        .catch((error) => {
            reject(error);
        });
    });
    return promise;
    }

    setMTUserCardData(cardId, ipoSummaryData, acoSummaryData,readyFields:any=null){
        const defaultValue = 0;
        var ipoFieldsForMTUserCard = (readyFields === null) ? {
            ipoSysName: (typeof ipoSummaryData.IPOSummary.SY_Name === 'undefined')?EMPTY_STRING:ipoSummaryData.IPOSummary.SY_Name,
            ipoSysRel: (typeof ipoSummaryData.IPOSummary.UN_MAJOR === 'undefined' || ipoSummaryData.IPOSummary.UN_MINOR === 'undefined')?
                EMPTY_STRING:(ipoSummaryData.IPOSummary.UN_MAJOR + '.' + ipoSummaryData.IPOSummary.UN_MINOR),
            ipoSysType: (typeof ipoSummaryData.IPOSummary.UN_TYPE === 'undefined')?EMPTY_STRING: ipoSummaryData.IPOSummary.UN_TYPE,
            ipoTimezone: (typeof ipoSummaryData.IPOSummary.TimeZoneString === 'undefined')?EMPTY_STRING:
                ipoSummaryData.IPOSummary.TimeZoneString,
            ipoCountry: '',
            totalIpoUsers: (typeof ipoSummaryData.IPOSummary.No_Of_Users === 'undefined')?defaultValue:
                ipoSummaryData.IPOSummary.No_Of_Users,
            totalAcoUsers: (typeof acoSummaryData.total === 'undefined')?defaultValue:acoSummaryData.total,
            acoCompany: (typeof acoSummaryData.company === 'undefined')?EMPTY_STRING:acoSummaryData.company,
            acoCountry: (typeof acoSummaryData.country === 'undefined')?EMPTY_STRING:acoSummaryData.country,
            acoDidExtn: (typeof acoSummaryData.did_count === 'undefined')?defaultValue:acoSummaryData.did_count,
            acoDlExtn: (typeof acoSummaryData.dl_count === 'undefined')?defaultValue:acoSummaryData.dl_count,
            acoAccountId: (typeof acoSummaryData.accountID === 'undefined')?EMPTY_STRING:acoSummaryData.accountID
        }:readyFields;

        var user = firebase.auth().currentUser;
        var promise = new Promise((resolve, reject) => {
            if(user.uid){
                const cardSummaryPath =  [DBPATH.MTUSERS, user.uid, DBPATH.CARDS, cardId].join("");
                this.db.doc(cardSummaryPath).update(ipoFieldsForMTUserCard)
                .then(() =>{
                    this.logger.debug("Updated MTUser's card details successfully");
                    resolve();
                })
                .catch((error) => {
                    this.logger.error("Error setting MTUser's card details:", error);
                    reject(error);
                });
            }
            else{
                reject("user id does not exist");
            }
        });
        return promise;
    }

    setIPOSummaryData(cardId, ipoSummaryData){
        this.logger.info("setIPOSummaryData ");
        var ipoData = JSON.stringify(ipoSummaryData);
        var summaryData = {
            data : ipoData
        }
        var promise = new Promise((resolve, reject) => {
            const ipoSummaryPath =  [DBPATH.CONFIGURATIONS, cardId, DBPATH.IPOCFG_SUMMARY].join("");
            this.db.doc(ipoSummaryPath).set(summaryData, {merge: true})
            .then(() =>{
                this.logger.debug("Updated ipo summary successfully");
                resolve();
            })
            .catch((error) => {
                this.logger.error("Error setting ipo summary document:", error);
                reject(error);
            });
        });
        return promise;
    }
    setDTMFData(cardId,DTMFList){
      let data = {
        data : JSON.stringify(DTMFList)
    }
      this.logger.info("set DTMF Data");
      var promise = new Promise((resolve, reject) => {
          const DTMFPath =  [DBPATH.CONFIGURATIONS, cardId, DBPATH.ACOEXPORT_DTMF0].join("");
          this.db.doc(DTMFPath).set(data, {merge: true})
          .then(() =>{
              this.logger.debug("Updated DTMF Document successfully");
              resolve();
          })
          .catch((error) => {
              this.logger.error("Error setting DTMF document:", error);
              reject(error);
          });
      });
      return promise;
  }

  getDTMFData(cardId)
  {
    var promise = new Promise((resolve, reject) => {
      const DTMFPath =  [DBPATH.CONFIGURATIONS, cardId, DBPATH.ACOEXPORT_DTMF0].join("");
      var cardRef = this.db.doc(DTMFPath);
      cardRef.get().toPromise().then((doc:any) => {
        if(doc.exists)
        {
          let curdata = doc.data();
          resolve(curdata);
        }
      })
      .catch((error) => {
          reject(error);
      });
  });
  return promise;
  }

  setDeletedUserDTMFData(cardId, deletedUserDTMFList) {
    let data = {
      data: JSON.stringify(deletedUserDTMFList)
    }
    this.logger.info("set Deleted User DTMF Data");
    var promise = new Promise((resolve, reject) => {
      const DeletedUserDTMFPath = [DBPATH.CONFIGURATIONS, cardId, DBPATH.ACOEXPORT_DELETEDUSERDTMF0].join("");
      this.db.doc(DeletedUserDTMFPath).set(data, { merge: true })
        .then(() => {
          this.logger.debug("Updated Deleted User DTMF Document successfully");
          resolve();
        })
        .catch((error) => {
          this.logger.error("Error setting Deleted User DTMF document:", error);
          reject(error);
        });
    });
    return promise;
  }

  getDeletedUserDTMFData(cardId) {
    var promise = new Promise((resolve, reject) => {
      const DeletedUserDTMFPath = [DBPATH.CONFIGURATIONS, cardId, DBPATH.ACOEXPORT_DELETEDUSERDTMF0].join("");
      var cardRef = this.db.doc(DeletedUserDTMFPath);
      cardRef.get().toPromise().then((doc: any) => {
        if (doc.exists) {
          let curdata = doc.data();
          resolve(curdata);
        }
      })
        .catch((error) => {
          reject(error);
        });
    });
    return promise;
  }

  setMessageOnlyData(cardId,MessageOnlyExtns){
    this.logger.info("set MessageOnly Extensions");
    var msgOnlyData = {
        extensions:MessageOnlyExtns
    }
    var promise = new Promise((resolve, reject) => {
        const MsgOnlyPath =  [DBPATH.CONFIGURATIONS, cardId, DBPATH.ACOEXPORT_MSG_ONLY].join("");
        this.db.doc(MsgOnlyPath).set(msgOnlyData, {merge: true})
        .then(() =>{
            this.logger.debug("Updated Message Only Extensions successfully");
            resolve();
        })
        .catch((error) => {
            this.logger.error("Error Message Only document:", error);
            reject(error);
        });
    });
    return promise;
}
  getMessageOnlyData(cardId)
  {
    var promise = new Promise((resolve, reject) => {
      const MsgOnlyPath =  [DBPATH.CONFIGURATIONS, cardId, DBPATH.ACOEXPORT_MSG_ONLY].join("");
      var cardRef = this.db.doc(MsgOnlyPath);
      cardRef.get().toPromise().then((doc:any) => {
        if(doc.exists)
        {
          let curdata = doc.data();
          resolve(curdata);
        }
      })   
      .catch((error) => {
          reject(error);
      });
  });
  return promise;
  }
 
  getPreconfig_ICRData(cardId)
  {
    this.logger.info("get Preconfig_ICRData");
      let _promise = new Promise((resolve, reject) => {
          const acoExportCfgPath =  [DBPATH.CONFIGURATIONS, cardId, DBPATH.IPOCFG_PRECONFIG_ICR].join("");
          this.db.doc(acoExportCfgPath).get().toPromise()
          .then((doc:any) => {
              if(doc.exists){
                this.logger.debug("fetched Preconfig ICR data successfully");
                resolve(doc.data());
              }
              else{
                  this.logger.debug("No Preconfig ICR data available");
                  resolve(null);
              }
          }).catch((error) => {
              this.logger.error("Error getting Preconfig ICR data:", error);
              reject(error);
          });
      });
      return _promise;
  }


    setIPOCardInfoData(cardId, ipoSummaryData){
        const defaultValue = 0;
        var ipoFieldsForCard = {
            sysName: (typeof ipoSummaryData.IPOSummary.SY_Name === 'undefined')?EMPTY_STRING:ipoSummaryData.IPOSummary.SY_Name,
            sysRel: (typeof ipoSummaryData.IPOSummary.UN_MAJOR === 'undefined' || ipoSummaryData.IPOSummary.UN_MINOR === 'undefined')?
                EMPTY_STRING:(ipoSummaryData.IPOSummary.UN_MAJOR + '.' + ipoSummaryData.IPOSummary.UN_MINOR),
            sysType: (typeof ipoSummaryData.IPOSummary.UN_TYPE === 'undefined')?EMPTY_STRING: ipoSummaryData.IPOSummary.UN_TYPE,
            totalUsers: (typeof ipoSummaryData.IPOSummary.No_Of_Users === 'undefined')?defaultValue:
                ipoSummaryData.IPOSummary.No_Of_Users,
            maxExtLen: (typeof ipoSummaryData.IPOSummary.Max_Extension_Length === 'undefined')?defaultValue:
                ipoSummaryData.IPOSummary.Max_Extension_Length,
            profiles: (typeof ipoSummaryData.IPOSummary.User_Profile === 'undefined')?EMPTY_STRING:
                ipoSummaryData.IPOSummary.User_Profile
        };

        var user = firebase.auth().currentUser;
        var promise = new Promise((resolve, reject) => {
            if(user.uid){
                const cardSummaryPath =  [DBPATH.CARDS, cardId, DBPATH.CARDINFO_IPOFFICE].join("");
                this.db.doc(cardSummaryPath).update(ipoFieldsForCard)
                .then(() =>{
                    this.logger.debug("Updated ipoffice card  info successfully");
                    resolve();
                })
                .catch((error) => {
                    this.logger.error("Error setting ipoffice card details:", error);
                    reject(error);
                });
            }
            else{
                reject("user id does not exist");
            }
        });
        return promise;
    }

    getIPOSummaryData(cardId){
        this.logger.info("getIPOSummaryData ");
        var promise = new Promise((resolve, reject) => {
            const ipoSummaryPath =  [DBPATH.CONFIGURATIONS, cardId, DBPATH.IPOCFG_SUMMARY].join("");
            var cardRef = this.db.doc(ipoSummaryPath);
            cardRef.get().toPromise().then((doc:any) => {
              if (doc.exists) {
                var curdata = doc.data();
                resolve(curdata.data);
              }else{
                reject("IPO summary doc does not exist : "+ cardId);
              }
            }).catch(error => {
              this.logger.error("Error getting IPO summary document:", error);
              reject(error);
            });
          });
          return promise;
    }

    setACOSummaryData(cardId, acoSummaryData,isRefreshed){
        this.logger.info("setACOSummaryData ");
        var acoData = JSON.stringify(acoSummaryData);
        var summaryData = {
            data : acoData,
            isRefreshed : isRefreshed
        }
        var promise = new Promise((resolve, reject) => {
            const acoSummaryPath =  [DBPATH.CONFIGURATIONS, cardId, DBPATH.ACOIMPORTCFG_SUMMARY].join("");
            this.db.doc(acoSummaryPath).set(summaryData)
            .then(() =>{
                this.logger.debug("Updated aco summary successfully");
                resolve();
            }).catch((error) => {
                this.logger.error("Error setting aco summary document:", error);
                reject(error);
            });
        });
        return promise;
    }

    getACOSummaryData(cardId){
        this.logger.info("getACOSummaryData ");
        var promise = new Promise((resolve, reject) => {
            const ipoSummaryPath =  [DBPATH.CONFIGURATIONS, cardId, DBPATH.ACOIMPORTCFG_SUMMARY].join("");
            var cardRef = this.db.doc(ipoSummaryPath);
            cardRef.get().toPromise().then((doc:any) => {
              if (doc.exists) {
               // var curdata = doc.data();
                resolve(doc.data());
              }else{
                reject("ACO summary doc does not exist : "+ cardId);
              }
            }).catch(error => {
              this.logger.error("Error getting ACO summary document:", error);
              reject(error);
            });
          });
          return promise;
    }

    setMTUserCardStats(cardId, ipoStatsData){
        this.logger.info("setMTUserCardStats ");
        var user = firebase.auth().currentUser;
        var promise = new Promise((resolve, reject) => {
            if(user.uid){
                const cardSummaryPath =  [DBPATH.MTUSERS, user.uid, DBPATH.CARDS, cardId].join("");
                this.db.doc(cardSummaryPath).update(ipoStatsData)
                .then(() =>{
                    this.logger.debug("Updated MTUser's card stats details successfully");
                    resolve();
                })
                .catch((error) => {
                    this.logger.error("Error setting MTUser's card stats details:", error);
                    reject(error);
                });
            }
            else{
                reject("user id does not exist");
            }
        });
        return promise;
    }

    requestUserPermission(companyName ){
      this.logger.info("requestUserPermission ");
        let promise = new Promise((resolve, reject) => {
          var user = firebase.auth().currentUser;
          if (user) {
            let userProfile = {
              company : companyName,
              requestAccess : 1
            }
            const userProfilePath = [DBPATH.MTUSERS , user.uid , DBPATH.PROFILE ,DBPATH.USER].join("");
            this.db.doc(userProfilePath).set(userProfile, {merge: true}).then(() => {
              resolve();
            }).catch((error) => {
              reject(error);
            });
          }
        });
        return promise;
    }

    updateUserProfilePhotoURL(PhotoURL ){
      this.logger.info("updateUserProfilePhotoURL ");
        let promise = new Promise((resolve, reject) => {
          var user = firebase.auth().currentUser;
          if (user) {
            let userProfilePhotoURL = {
              photoUrl : PhotoURL
            }
            const userProfilePath = [DBPATH.MTUSERS , user.uid , DBPATH.PROFILE ,DBPATH.USER].join("");
            this.db.doc(userProfilePath).set(userProfilePhotoURL, {merge: true}).then(() => {
              resolve();
            }).catch((error) => {
              reject(error);
            });
          }
        });
        return promise;
    }


    readACOMigrationStatus(cardId){
        let promise = new Promise((resolve, reject) => {
            const acoExportStatusPath =  [DBPATH.CARDS, cardId, DBPATH.STATUS_ACOEXPORTSTATUS].join("");
            var acoExportCfgRef = this.db.doc(acoExportStatusPath);
            acoExportCfgRef.get().toPromise().then((doc:any) => {
                var curdata = doc.data();
                resolve(curdata);
            })
            .catch((error) => {
                reject(error);
            });
        });

        return promise;
    }


    checkACODeletionStatus(cardId){
      this.logger.info("checkACODeletionStatus");
      const clearACOConfigStatusPath =  [DBPATH.CARDS, cardId, DBPATH.STATUS_CLEAR_ACO_CONFIG].join("");
        var clearACOCfgRef = this.db.doc(clearACOConfigStatusPath);
        clearACOCfgRef.get().toPromise().then((doc:any) => {
            var curdata = doc.data();
            if(curdata.status == 0 || curdata.status == 1){
                this.logger.debug("Deletion complete already with status: ", curdata.desc);
                this.sendDelProgMessage(curdata);
            }
            else{
                const unsubscribe = this.db.doc(clearACOConfigStatusPath).ref.
                onSnapshot((doc:any) =>{
                var curdata = doc.data();
                if (curdata.status == 0 || curdata.status == 1) {
                    this.logger.debug("Deletion complete, status: ", curdata.status);
                    this.sendDelProgMessage(curdata);
                    if(unsubscribe){
                    unsubscribe();
                    }
                }
                else{
                    this.logger.debug("Deletion progress update: ", curdata.progress);
                    this.sendDelProgMessage(curdata);
                } 
                });
            }
        });

    }

    checkACOMigrationStatus(cardId){
      this.logger.info("checkACOMigrationStatus ");

        const acoExportStatusPath =  [DBPATH.CARDS, cardId, DBPATH.STATUS_ACOEXPORTSTATUS].join("");
        var acoExportCfgRef = this.db.doc(acoExportStatusPath);
        acoExportCfgRef.get().toPromise().then((doc:any) => {
            var curdata = doc.data();
            if(curdata.status == 0 || curdata.status == 1){
                this.logger.debug("Migration complete already with status: ", curdata.desc);
                this.sendMessage(curdata);
            }
            else{
                const unsubscribe = this.db.doc(acoExportStatusPath).ref.
                onSnapshot((doc:any) =>{
                var curdata = doc.data();
                if (curdata.status == 0 || curdata.status == 1) {
                    this.logger.debug("Migration complete, status: ", curdata.status);
                    this.sendMessage(curdata);
                    if(unsubscribe){
                    unsubscribe();
                    }
                }
                else{
                    this.logger.debug("Migration progress update: ", curdata.progress);
                    this.sendMessage(curdata);
                } 
                });
            }
        });
      
    }

    readFailureReport(cardId){
      this.logger.info("readFailureReport ");
      var promise = new Promise((resolve, reject) => {
        const assignUsersResultsPath =  [DBPATH.CARDS, cardId, DBPATH.FUNCTIONRESULTS_ASSIGNUSERS].join("");
        var cardRef = this.db.doc(assignUsersResultsPath);
        cardRef.get().toPromise().then((doc:any) => {
          if (doc.exists) {
            var curdata = doc.data();
            resolve(curdata.data);
          }else{
            reject("Migration report read failure : "+ cardId);
          }
        }).catch(error => {
          this.logger.error("Migration report read failure:", error);
          reject("Migration report read failure : "+ error);
        });
      });
      return promise;
      
    }

    readTrigger(cardId,opName){
        this.logger.info("readTrigger ");
        var promise = new Promise((resolve, reject) => {
            var user = firebase.auth().currentUser;
            if (user) {
              let acoTriggerPath;
              if(opName == PROCESS.MIGRATION)
                acoTriggerPath =  [DBPATH.CARDS, cardId, DBPATH.TRIGGERS_ACOEXPORT].join("");
              else 
                acoTriggerPath = [DBPATH.CARDS, cardId, DBPATH.TRIGGERS_CLEARACO].join("");
              var cardRef = this.db.doc(acoTriggerPath);
              cardRef.get().toPromise().then((doc:any) => {
                if (doc.exists) {
                  var curdata = doc.data();
                  resolve(curdata);
                }else{
                  reject("ACO  trigger doc does not exist : "+ cardId);
                }
              }).catch(error => {
                this.logger.error("Error getting aco trigger document:", error);
                reject(error);
              });
            }else{
                this.logger.error("User is not logged in");
              reject(ERROR_STRING.USER_NOT_FOUND);
            }
        });
        return promise;
    }

    readMTUserInfo(){
        this.logger.info("readMTUserInfo ");
          let promise = new Promise((resolve, reject) => {
            var user = firebase.auth().currentUser;
            if (user) {
              const userProfilePath = [DBPATH.MTUSERS , user.uid].join("");
              this.db.doc(userProfilePath).get().toPromise().then((doc: any) => {
                if(doc.exists){
                    var curdata = doc.data();
                    resolve(curdata);
                }
                reject('MT User Doc does not exist');
              }).catch((error) => {
                    this.logger.error("Unable to read MT user doc: ", error);
                    reject(error);
              });
            }
          });
          return promise;
    }

    sendMessage(data) {
        this.subject.next(data);
    }

    clearMessage() {
        this.subject.next();
    }

    getMessage(): Observable<any> {
        return this.subject.asObservable();
    } 

    sendDelProgMessage(data) {
         this.delProgSubject.next(data);
    }

    clearDelProgMessage() {
        this.delProgSubject.next();
    }

    getDeletionProgMessage(): Observable<any> {
         return this.delProgSubject.asObservable();
    } 
   

    getUserCount(type:REQUEST){
      var count=0;
      return new Promise((resolve,reject)=>{
        if(type == REQUEST.APPROVE || type == REQUEST.REJECT){
          var mtUsersRef = firebase.firestore().collection(DBPATH.MTUSERS);
          var mtusersCollection = mtUsersRef.where('authorized',"==",type);
          var promise = this.convertCollectionToArr(mtusersCollection);
          promise.then((usersDoc:Array<Object>)=>{
            usersDoc.forEach(user=>{
              if(!user["role"].includes("admin"))
                 count++;
            });
            resolve(count);
          }).catch((error) => {
            this.logger.error("MTUsers:"+error);
            reject(error);
          });
        }
        else{
          reject(null);
        }
      });
      
    }

    getMergedUserList(type:REQUEST,lastViewedDocId="",listLength=MAX_LIMIT){
        var mtusersColRef= firebase.firestore().collection(DBPATH.MTUSERS);
        var profilesCollection = firebase.firestore().collectionGroup('Profile');
        var userDocRef,profilesDocRef;
        var lastViewedUserDoc,lastViewedProfileDoc ;
        return new Promise((resolve,reject)=>{
          if(lastViewedDocId != ""){
            mtusersColRef.doc(lastViewedDocId).get().then((doc:any)=>{
              lastViewedUserDoc = doc;
              const userProfilePath = [DBPATH.MTUSERS , lastViewedDocId , DBPATH.PROFILE ,DBPATH.USER].join("");
              this.db.doc(userProfilePath).get().toPromise().then((profDoc:any) => {
                if(profDoc.exists){
                    lastViewedProfileDoc = profDoc;         
                    userDocRef = mtusersColRef.startAfter(lastViewedUserDoc).limit(listLength).where("authorized","==",type);
                    profilesDocRef = profilesCollection.startAfter(lastViewedProfileDoc).limit(listLength);
                    this.mergeDocs(userDocRef,profilesDocRef,type).then((mergedDocs)=>{
                      resolve(mergedDocs);
                    }).catch(err=>{
                      reject(err);
                    })                    
                }else{
                    this.logger.error("User Document not found for "+lastViewedDocId);
                }  
              }).catch((error) => {
                reject(error);
              });  
            })
          }else{
              userDocRef = mtusersColRef.limit(listLength).where("authorized","==",type);
              profilesDocRef = profilesCollection.limit(listLength);
              this.mergeDocs(userDocRef,profilesDocRef,type).then((mergedDocs)=>{
                resolve(mergedDocs);
              }).catch(err=>{
                reject(err);
              })
          }
        })
        

  }


  mergeDocs(userDocRef,profileDocRef,type){
    var mergedDoc$ = [];
    return new Promise((resolve,reject)=>{
      var usrPromise = this.convertCollectionToArr(userDocRef);
      usrPromise.then((usersDoc:Array<Object>)=>{
        var profPromise = this.convertCollectionToArr(profileDocRef);
        profPromise.then((profDoc:Array<Object>)=>{
          usersDoc.forEach(user=>{
            if(!user["role"].includes("admin")){
              var profile = profDoc.find(x=>x["email"]==user["email"]);
              if(profile != null){
                if(type != REQUEST.NEWREQ || profile["requestAccess"] == 1 ){
                  mergedDoc$.push({
                    email:user["email"],
                    name:user["displayName"],
                    photoUrl:profile["photoUrl"],                  
                    company:profile["company"],
                    requestAccess:profile["requestAccess"],
                    id:user["id"]
                  })
                }
              }
            }
          })
          resolve(mergedDoc$);  
        }).catch((error) => {
          this.logger.error("Profiles:"+error);
          reject(error);
        });            
      }).catch((error) => {
        this.logger.error("MTUsers:"+error);
        reject(error);
      });
   })
  }


  addressRequest(user,val,isNewRequest){
    this.logger.info("Address Request: "+ REQUEST[val].toLocaleLowerCase());
    var id = user.id;
    let res = {} as Result;
    let promise = new Promise((resolve,reject)=>{
      var userDocRef = this.db.collection(DBPATH.MTUSERS).doc(id);
      userDocRef.set({authorized:val},{merge:true}).then(()=>{
        if(!isNewRequest){
          res.reason=REQUEST[val].toLocaleLowerCase()+" request success for user: " +user.name;
          res.type="SUCCESS"
          res.user=user;
          resolve(res);
          return;
        }
        this.changeRequestAccessVal(id).then(()=>{
          this.logger.info("Request access :")
          res.reason=REQUEST[val].toLocaleLowerCase()+" request access success for user: " +user.name;
          res.type="SUCCESS"
          res.user=user;
          resolve(res);
        }).catch((error) => {
          res.reason=REQUEST[val].toLocaleLowerCase()+" request access failed for user: "+error;
          res.type="PARTIALLY FAILED"
          res.user=user;
          reject(res);
        });
      }).catch((error) => {
        res.reason="Authorization failed for user: "+error;
        res.type="FAILED"
        res.user=user;
        reject(res);
      });      
    });
    return promise;
  }

  changeRequestAccessVal(id){
    let promise = new Promise((resolve, reject) => {   
        const userProfilePath = [DBPATH.MTUSERS , id , DBPATH.PROFILE ,DBPATH.USER].join("");
        this.db.doc(userProfilePath).set({requestAccess:2}, {merge: true}).then(() => {
          resolve();
        }).catch((error) => {
          reject(error);
        });
    });
    return promise;
  }


    convertCollectionToArr(collection){
      return new Promise((resolve,reject)=>{
      collection.limit(MAX_LIMIT).get()
        .then((querySnapshot) => {
          const tempDoc = []
          querySnapshot.forEach((doc:any) => {
            if(doc.id)
              tempDoc.push({id:doc.id,...doc.data() })
            else
            tempDoc.push({...doc.data() })
          })
          resolve(tempDoc);
        })
        .catch(function (error) {
          reject("Error getting collection: "+error);
        })
      
      });
    }

    getGreetingsData(cardId){
      this.logger.debug("Get Greetings Data:");
      const greetingsStatusPath =  [DBPATH.CONFIGURATIONS, cardId, DBPATH.GREETINGSSTATUS].join("");
      var promise = new Promise((resolve,reject)=>{
        this.db.doc(greetingsStatusPath).get().toPromise().then((doc:any) => {
          if (doc.exists) {
            var data = doc.data();
            if(data["trigger"] == TRIGGER_STATUS.DONE){
              resolve(data);
            }else if(data["trigger"] == TRIGGER_STATUS.START){
              reject("Greetings zip file conversion is not yet triggered");
            }else{
              if(data["zipFilePathName"]!= undefined && data["zipFilePathName"].trim() == "")
                reject("Greetings file is not uploaded");
              else{
                reject("Error while parsing greetings document data");
              }                
            }
          }else{
            reject("Greetings doc does not exist : ");
          }
        }).catch(error => {
          this.logger.error("Error getting greetings trigger status document:", error);
          reject(error);
        });
      });
      return promise;
    }
   
    fetchGreetingsData(cardId){
      this.logger.debug("Fetch Greetings Data:");
      const greetingsStatusPath =  [DBPATH.CONFIGURATIONS, cardId, DBPATH.GREETINGSSTATUS].join("");
        this.db.doc(greetingsStatusPath).ref.onSnapshot((doc:any)=>{
          if(doc.exists ){
            let data = doc.data();
            if(data && (data["trigger"] == TRIGGER_STATUS.DONE ||
                data["trigger"] == TRIGGER_STATUS.START)) 
            {
                this.sendGreetingsDataAvlblMsg(data);
            }
          }         
        })
    }

    fetchSysmonData(cardId){
      this.logger.debug("Fetch Sysmon Data:");
      const sysmonStatusPath =  [DBPATH.CONFIGURATIONS, cardId, DBPATH.SYSMONSTATUS].join("");
        this.db.doc(sysmonStatusPath).ref.onSnapshot((doc:any)=>{
          if(doc.exists ){
            let data = doc.data();
            if(data && (data["trigger"] == TRIGGER_STATUS.DONE ||
                data["trigger"] == TRIGGER_STATUS.START)) 
            {
                this.sendSysmonDataAvlblMsg(data);
            }
          }         
        })
    }

    readSysmonData(cardId){
      this.logger.debug("Read Sysmon Data:");
      var promise = new Promise((resolve, reject) => {
        const sysmonStatusPath = [DBPATH.CONFIGURATIONS, cardId, DBPATH.SYSMONSTATUS].join("");
        var cardRef = this.db.doc(sysmonStatusPath);
        cardRef.get().toPromise().then((doc:any) => {
          if (doc.exists) {
            var curdata = doc.data();
            resolve(curdata);
          }else {
            reject("Sysmon file data read error : "+cardId);
          }
        }).catch(error => {
          this.logger.error("Sysmon file read failure : ", error);
          reject("Sysmon file read fialure : "+ error);
        });
      });
      return promise;
    }

    sendGreetingsDataAvlblMsg(data) {
       this.greetingsDataAvblSubj.next(data);
    }

    clearGreetingsDataAvlblMsg() {
        this.greetingsDataAvblSubj.next();
    }

    getGreetingsDataAvlblMsg(): Observable<any> {
        return this.greetingsDataAvblSubj.asObservable();
    }

    sendSysmonDataAvlblMsg(data) {
       this.sysmonDataAvblSubj.next(data);
    }

    clearSysmonDataAvlblMsg() {
        this.sysmonDataAvblSubj.next();
    }

    getSysmonDataAvlblMsg(): Observable<any> {
        return this.sysmonDataAvblSubj.asObservable();
    }

    readDoc(cardId){
      this.logger.info(`readDoc cardId:${cardId}`);
      var promises = [];
      var promise = new Promise((resolve, reject) => {
          const morrisonCsvPath =  [DBPATH.CONFIGURATIONS, cardId, DBPATH.MORRISONCFGCSV].join("");
          var cardRef = this.db.doc(morrisonCsvPath);
          cardRef.get().toPromise().then((doc:any) => {
            if (doc.exists) {
              var curdata = doc.data();
              resolve(curdata);
            }else{
              reject("readDoc- doc does not exist : "+ cardId);
            }
          }).catch(error => {
            this.logger.error("Error getting document:", error);
            reject();
          });
      });
      return promise;                                                            
    } 

    readTextToSpeechResDataDoc(cardId){
      this.logger.info(`readTextToSpeechResDataDoc cardId:${cardId}`);
      var promises = [];
      var promise = new Promise((resolve, reject) => {
          const textToSpeechResDataPath =  [DBPATH.CONFIGURATIONS, cardId, DBPATH.ACOEXPORT_MORRISON_TEXT_TO_SPEECH_RESDATA].join("");
          var cardRef = this.db.doc(textToSpeechResDataPath);
          cardRef.get().toPromise().then((doc:any) => {
            if (doc.exists) {
              var curdata = doc.data();
              resolve(curdata);
            }else{
              resolve(null);
            }
          }).catch(error => {
            this.logger.error("Error getting readTextToSpeechResDataDoc document:", error);
            reject();
          });
      });
      return promise;                                                            
    }

    readUnconditionalForwardingResDataDoc(cardId){
      this.logger.info(`readUnconditionalForwardingResDataDoc cardId:${cardId}`);
      var promises = [];
      var promise = new Promise((resolve, reject) => {
          const unconditionalForwardingResDataPath =  [DBPATH.CONFIGURATIONS, cardId, DBPATH.ACOEXPORT_MORRISON_UNCONDITIONAL_FORWARDING_RESDATA].join("");
          var cardRef = this.db.doc(unconditionalForwardingResDataPath);
          cardRef.get().toPromise().then((doc:any) => {
            if (doc.exists) {
              var curdata = doc.data();
              resolve(curdata);
            }else{
              resolve(null);
            }
          }).catch(error => {
            this.logger.error("Error getting readUnconditionalForwardingResDataDoc document:", error);
            reject();
          });
      });
      return promise;                                                            
    }

    setMTUserCardDetailsForMorrison(cardId , mtUserCardDetails){
      this.logger.debug("setMTUserCardDetailsForMorrison");
      var user = firebase.auth().currentUser;
      var promise = new Promise((resolve, reject) => {
          if(user.uid){
              const cardSummaryPath =  [DBPATH.MTUSERS, user.uid, DBPATH.CARDS, cardId].join("");
              this.db.doc(cardSummaryPath).update(mtUserCardDetails)
              .then(() =>{
                  this.logger.debug("Updated MTUser's card details successfully for morrison");
                  resolve();
              })
              .catch((error) => {
                  this.logger.error("Error setting MTUser's card details for morrison:", error);
                  reject(error);
              });
          }
          else{
              reject("user id does not exist");
          }
      });
      return promise;
    }

    getPrerequisiteInfo(cardId){
      var promise = new Promise((resolve, reject) => {
        firebase.auth().currentUser.getIdToken(true)
        .then((idToken) => {
          let passArgs = {
            idToken: idToken,
            cardId: cardId
        };
        var readACOInfo = firebase.functions().httpsCallable('readACOInfo');
        readACOInfo(passArgs)
        .then((res) => {        
            this.logger.debug('Received result -' + JSON.stringify(res.data));
            resolve(res.data);
        })
        })
        .catch((error) => {
          this.logger.error(error);
            reject(error);
        });
      });
      return promise;
    }

    updatePreConfigDownloadCount(cardId, downloadCount){
      this.logger.debug("updatePreConfigDownloadCount");
      let downloadCountUpdate = {
        downloadPreConfigCount : downloadCount,
        updated : new Date()
      }

      var promise = new Promise((resolve, reject) => {
        var user = firebase.auth().currentUser;
        if (user) {
          const cardStatusPath =  [DBPATH.MTUSERS, user.uid, DBPATH.CARDS , cardId].join("");
          var cardRef = this.db.doc(cardStatusPath);
          cardRef.set(downloadCountUpdate , {merge: true}).then(() => {
            this.logger.debug("updating downloadCount successful: ",cardId);
            resolve('');
          }).catch((error) => {
            this.logger.error("Unable to update downloadCount:",cardId);
            reject(error);
          });
        }else{
          this.logger.error(ERROR_STRING.USER_NOT_FOUND);
        }
      });
      return promise;
    }

    readBigQueryCardDetails(cardId){
      this.logger.debug("readBigQueryCardDetails");
      var promise = new Promise((resolve, reject) => {
        var user = firebase.auth().currentUser;
        const cardStatusPath =  [DBPATH.MTUSERS, user.uid, DBPATH.CARDS , cardId].join("");
        var cardRef = this.db.doc(cardStatusPath);
        cardRef.get().toPromise().then((doc:any) => {
          if (doc.exists) {
            var curdata = doc.data();
            resolve(curdata);
          }else{
            resolve(null);
          }
        }).catch(error => {
          this.logger.error("Error getting readBigQueryCardDetails document:", error);
          reject();
        });
      });
      return promise; 
    }

    readCallFwdData(cardId){
      this.logger.info("readCallFwdData ");
      var promise = new Promise((resolve, reject) => {
          var user = firebase.auth().currentUser;
          if (user) {
            const callFwdDataPath =  [DBPATH.CONFIGURATIONS, cardId, DBPATH.ACOEXPORT_USER_CALLFORWARDDATA].join("");
            var cardRef = this.db.doc(callFwdDataPath);
            cardRef.get().toPromise().then((doc:any) => {
              if (doc.exists) {
                var curdata = doc.data();
                resolve(curdata);
              }else{
                //reject("CallFwd data doc does not exist : "+ cardId);
                resolve([]);
              }
            }).catch(error => {
              this.logger.error("Error getting CallFwd data document:", error);
              //reject(error);
              resolve([]);
            });
          }else{
              this.logger.error("User is not logged in");
              //reject(ERROR_STRING.USER_NOT_FOUND);
              resolve([]);
          }
      });
      return promise;
    }

     getTaskChain(cardId , isFinalTaskChain, opName){
      this.logger.info("getTaskChain isFinalTaskChain:",isFinalTaskChain);
      var promise = new Promise((resolve, reject) => {
        var user = firebase.auth().currentUser;
        if (user) {
          let taskChainPath = "";
          if(opName === PROCESS.MIGRATION){
            if(isFinalTaskChain){
              taskChainPath = [DBPATH.CONFIGURATIONS, cardId, DBPATH.ACOEXPORT_TASKCHAIN_FINAL].join("");
            }else{
              taskChainPath = [DBPATH.CONFIGURATIONS, cardId, DBPATH.ACOEXPORT_TASKCHAIN].join("");
            }
          }else if(opName === PROCESS.DELETION){
            taskChainPath = [DBPATH.CONFIGURATIONS, cardId, DBPATH.CLEAR_ACO_TASKCHAIN].join("");
          }
           
          var cardRef = this.db.doc(taskChainPath);
          cardRef.get().toPromise().then((doc:any) => {
            if (doc.exists) {
              var curdata = doc.data();
              resolve(curdata);
            }else{
              resolve([]);
            }
          }).catch(error => {
            this.logger.error("Error getting taskChain data:", error);
            resolve([]);
          });
        }else{
            this.logger.error("User is not logged in");
            resolve([]);
        }
      });
      return promise;
    }

    //gets triggered once all features are selected
    setTaskChain(cardId, taskChain , isFinalTaskChain, opName){
      this.logger.info("setFinalTaskChain")
      let taskChainData = JSON.stringify(taskChain);
      let taskChainDocData = {
          data : taskChainData,
          totalData: taskChain.length
      }    
      let _promise = new Promise((resolve, reject) => {
          let taskChainPath = "";
          if(opName === PROCESS.MIGRATION){
            if(isFinalTaskChain){
              taskChainPath = [DBPATH.CONFIGURATIONS, cardId, DBPATH.ACOEXPORT_TASKCHAIN_FINAL].join("");
            }else{
              taskChainPath = [DBPATH.CONFIGURATIONS, cardId, DBPATH.ACOEXPORT_TASKCHAIN].join("");
            } 
          }else if(opName === PROCESS.DELETION){
            taskChainPath = [DBPATH.CONFIGURATIONS, cardId, DBPATH.CLEAR_ACO_TASKCHAIN].join("");
          }
          this.db.doc(taskChainPath).set(taskChainDocData)
          .then(() => {
              this.logger.debug("final task chainData is set data set successfully");
              resolve();
          }).catch((error) => {
              this.logger.error("Error setting final task chainData:", error);
              reject(error);
          });
      })
      return _promise;
    }

    batchWrite(batchData){
      return new Promise((resolve, reject) => {
          try {
              //console.log('Batch write data size -', batchData.length);
              if (!batchData.length) {
                  return reject();
              }
  
              let batch = this.db.firestore.batch()
              let docRef = [];
              batchData.forEach((writeRequest, index) => {
  
                  switch (writeRequest.op) {
                      /* case 'create':
                          docRef[index] = this.db.collection(writeRequest.path).doc();
                          batch.create(docRef[index], writeRequest.data);
                          break; */
  
                      case 'set':
                          docRef[index] = this.db.doc(writeRequest.path);
                          batch.set(docRef[index], writeRequest.data);
                          break;
  
                      case 'set-merge':
                          docRef[index] = this.db.doc(writeRequest.path);
                          batch.set(docRef[index], writeRequest.data, { merge: true });
                          break;
  
                      case 'update':
                          docRef[index] = this.db.doc(writeRequest.path);
                          batch.update(docRef[index], writeRequest.data);
                          break;
  
                      case 'delete':
                          docRef[index] = this.db.doc(writeRequest.path);
                          batch.delete(docRef[index]);
                          break;
  
                      default:
                          reject("Invalid write operation");
                          break;
                  }
              });
  
              batch.commit().then((res) => {
                  return resolve("write operations is successful");
              }).catch((err:any) => {
                  return reject("write opertaions failed: ");
              });
          } catch (error) {
              console.error('batchWrite -', error);
              //return reject(utils.buildResult(constants.STATUS.BATCHWRITE_FAILURE, {}, error));
              return reject();
          }
      });
    }

    updateSkippedFeatureStatus(skippedFeatureList , cardId , status){
      this.logger.debug("updateSkippedFeatureStatus");
      let _promise = new Promise((resolve, reject) => {
        let skippedFeatureData = [];
        if(skippedFeatureList.length !== 0){
          skippedFeatureList.forEach(feature => {

            let data = {
              name : "",
              status : status,
              detail : "",
              total : 0,
              totalFailed : 0,
              failedDesc : [],
              retry : "false"
            }

            switch(feature){
              case FEATURE_NAME.MESSAGE_ONLY:
                data.name = SKIPPED_FEATURE_NAME.MESSAGE_ONLY;
                break;
                case FEATURE_NAME.USER_CONTACTS:
                  data.name = SKIPPED_FEATURE_NAME.CONTACTS;
                  break;
                case FEATURE_NAME.USER_BHOURS:
                  data.name = SKIPPED_FEATURE_NAME.BHOURS;
                    break;
                case FEATURE_NAME.USER_BLF:
                  data.name = SKIPPED_FEATURE_NAME.BLF;
                  break;
                case FEATURE_NAME.CALL_FORWARD:
                  data.name = SKIPPED_FEATURE_NAME.TWINNING;
                  break;
                case FEATURE_NAME.HUNT_GROUP:
                  data.name = SKIPPED_FEATURE_NAME.HuntGroup;
                  break;
                case FEATURE_NAME.PAGING_GROUP:
                  data.name = SKIPPED_FEATURE_NAME.PAGINGGROUP;
                  break;
                case FEATURE_NAME.PARK_LOCATION:
                  data.name = SKIPPED_FEATURE_NAME.PARKLOCATION;
                  break;
                case FEATURE_NAME.AUTO_ATTENDANT_PROMPTS:
                  data.name = SKIPPED_FEATURE_NAME.AA_PROMPTS;
                  break;
                case FEATURE_NAME.AUTO_ATTENDANT:
                  data.name = SKIPPED_FEATURE_NAME.AA;
                  break;
                case FEATURE_NAME.USER_VM_WORKHOURS:
                  data.name = SKIPPED_FEATURE_NAME.VM_BHOURS;
                  break;
                case FEATURE_NAME.USER_VM_AFTERHOURS:
                  data.name = SKIPPED_FEATURE_NAME.VM_AHOURS;
                  break;
                case FEATURE_NAME.USER_VM_CUSTOMHOURS:
                  data.name = SKIPPED_FEATURE_NAME.VM_CHOURS;
                  break;
                case FEATURE_NAME.COMPLEX_IVR_PROMPTS:
                  data.name = SKIPPED_FEATURE_NAME.COMPLEX_IVR_PROMPTS;
                  break;
                case FEATURE_NAME.ZERO_DIAL:
                  data.name = SKIPPED_FEATURE_NAME.ZERO_DIAL;
                  break;
                case FEATURE_NAME.MAC_UPDATE:
                  data.name = SKIPPED_FEATURE_NAME.MAC_UPDATE;
                  break;
                default : 
                  this.logger.debug("skipped feature is invalid");
                  break;
            }
            skippedFeatureData.push(data);
          });

          let featureStatusDoc = {
            data : JSON.stringify(skippedFeatureData),
          } 
          const featureStatusDocPath =  [DBPATH.CARDS, cardId, DBPATH.STATUS_FEATURESTATUS].join("");
          this.db.doc(featureStatusDocPath).set(featureStatusDoc)
          .then(() => {
              this.logger.debug("setting feature status doc success");
              resolve();
          }).catch((error) => {
              this.logger.error("Error setting feature status doc:", error);
              reject(error);
          });
        }else{
          resolve();
        }
      });
      return _promise;
    }

    setSiteDetails(cardId , siteDetails){
      this.logger.debug("setSiteDetails - ", siteDetails.length);
      let promise = new Promise((resolve, reject) => {
        var user = firebase.auth().currentUser;
        if (user) {
          let siteDetailsData = {
            data : JSON.stringify(siteDetails),
            totalData: siteDetails.length
        } 
          const siteDetailsPath = [DBPATH.CONFIGURATIONS , cardId , DBPATH.ACOEXPORT_SITESMAP].join("");
          this.db.doc(siteDetailsPath).set(siteDetailsData, {merge: false}).then(() => {
            resolve();
          }).catch((error) => {
            reject(error);
          });
        }
      });
      return promise;
    }

    getSiteDetails(cardId) {
      this.logger.debug("Fetch site details");
      let promise = new Promise((resolve, reject) => {
        const siteDetailsPath = [DBPATH.CONFIGURATIONS, cardId, DBPATH.ACOEXPORT_SITESMAP].join("");
        var cardRef = this.db.doc(siteDetailsPath);
        cardRef.get().toPromise().then((doc:any) => {
          if (doc.exists) {
            var curData = doc.data();
            resolve (curData);
          } else {
            resolve(null);
          }
        }).catch(error => {
          this.logger.error("Exception while fetching site details, err");
          reject();
        });
      });
      return promise;
    }
    
    createSite(cardId){
      this.logger.debug("createSite");
      var promise = new Promise((resolve, reject) => {
        //var cardRcAuth = firebase.functions().httpsCallable('rcAuth');
        firebase.auth().currentUser.getIdToken(true)
        .then((idToken) => {
            var createSites = firebase.functions().httpsCallable('createMultiSites');
            createSites({ cardId: cardId, groupId: null, idToken }).then((res) => {
                this.logger.debug(JSON.stringify(res));
                resolve(res);
            }).catch((error) => { //Handle Error
                reject(error);
            });
        })
        .catch((error) => { //Handle Error
            reject(error);
        });
      });
      return promise;
    }
  }

  