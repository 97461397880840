import { FireAuthService } from './shared/services/firebaseAuth/fire-auth.service';
import { GSignInService } from './shared/services/googleAPI/g-sign-in.service';
import { LogService } from './shared/services/logging/log.service';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireAnalyticsModule } from '@angular/fire/analytics';

import { AngularFireAuthModule } from "@angular/fire/auth";
import { environment } from '../environments/environment';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './shared/components/login/login.component';
import { ProfileInfoComponent } from './shared/components/profile-info/profile-info.component';
import { MigrationCardsComponent } from './user/components/migration-cards/migration-cards.component';
import { CustomerCompanyInfoComponent } from './user/components/customer-company-info/customer-company-info.component';
import { UploadCSVComponent } from './user/components/upload-csv/upload-csv.component';
import { RCAuthComponent } from './user/components/rcauth/rcauth.component';
import { MigrationSummaryComponent } from './user/components/migration-summary/migration-summary.component';
import { MigrationPreviewComponent } from './user/components/migration-preview/migration-preview.component';
import { TablePreviewComponent } from './user/components/table-preview/table-preview.component';
import { HomeComponent } from './shared/components/home/home.component';
import { StartMigrationComponent } from './user/components/start-migration/start-migration.component';
import { DashboardComponent } from './shared/components/dashboard/dashboard.component';
import { FaqComponent } from './shared/components/faq/faq.component';

//UI Material components 
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatCardModule} from '@angular/material/card';
import {MatIconModule} from '@angular/material/icon';
import {MatTableModule} from '@angular/material/table';
import {MatButtonModule} from '@angular/material/button';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MatInputModule} from '@angular/material/input';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import { FormsModule } from '@angular/forms';
import {MatFormFieldModule} from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import {OverlayModule} from '@angular/cdk/overlay';
import { MatSpinnerOverlayComponent } from './shared/components/mat-spinner-overlay/mat-spinner-overlay.component';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatSnackBar} from '@angular/material/snack-bar';
import { MatExpansionModule, MatAccordion } from '@angular/material/expansion';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { HttpClientModule } from '@angular/common/http';
import { NgxSpinnerModule } from 'ngx-spinner';
import { AdminHomeComponent } from './admin/components/admin-home/admin-home.component';
import { MembersHomeComponent } from './admin/components/members/members-home/members-home.component';
import { NewRequestsComponent } from './admin/components/members/new-requests/new-requests.component';
import { RejectedRequestsComponent } from './admin/components/members/rejected-requests/rejected-requests.component';
import { ApprovedRequestsComponent } from './admin/components/members/approved-requests/approved-requests.component';
import { AboutComponent } from './shared/components/about/about.component';
import { ConfirmationDialogComponent } from './shared/components/dialog/confirmation-dialog/confirmation-dialog.component';
import { PromptDialogComponent } from './shared/components/dialog/prompt-dialog/prompt-dialog.component';
import { NgxLinkifyjsService} from 'ngx-linkifyjs';
import { ConfigSummaryComponent } from './user/components/config-summary/config-summary/config-summary.component';
import { UploadGreetingsFileComponent } from './user/components/config-summary/upload-greetings-file/upload-greetings-file.component';
import { UserDataComponent } from './user/components/config-summary/user-data/user-data.component';
import { SystemDataComponent } from './user/components/config-summary/system-data/system-data.component';
import { NotificationDownloadComponent } from './user/components/config-summary/notification-data/download-notifications.component';
import { AdvancedInfoTablePreviewComponent } from './user/components/advanced-info-table-preview/advanced-info-table-preview.component';
import { HuntGroupsPreviewComponent } from './user/components/hunt-groups-preview/hunt-groups-preview.component';
import { ParkLocationPreviewComponent } from './user/components/park-location-preview/park-location-preview.component';
//import { PagingGroupsPreviewComponent } from './user/components/paging-groups-preview/paging-groups-preview.component';
import { AutoAttendantsPreviewComponent } from './user/components/auto-attendants-preview/auto-attendants-preview.component';
import { IncomingNumbersPreviewComponent } from './user/components/incoming-numbers-preview/incoming-numbers-preview.component';
import { TimeProfilesPreviewComponent } from './user/components/time-profiles-preview/time-profiles-preview.component';
import { MatBadgeModule } from '@angular/material/badge';
import { CanDeactivateGuardService } from './shared/services/deactivate-guard/can-deactivate-guard.service';
import { MorrisonDataComponentComponent } from './user/components/morrison-data-component/morrison-data-component.component';
import { HelpComponent } from './shared/components/help/help.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ProfileInfoComponent,
    MigrationCardsComponent,
    CustomerCompanyInfoComponent,
    UploadCSVComponent,
    RCAuthComponent,
    MigrationSummaryComponent,
    MigrationPreviewComponent,
    HomeComponent,
    TablePreviewComponent,
    StartMigrationComponent,
    DashboardComponent,
    MatSpinnerOverlayComponent,
    FaqComponent,
    AdminHomeComponent,
    MembersHomeComponent,
    NewRequestsComponent,
    RejectedRequestsComponent,
    ApprovedRequestsComponent,
    AboutComponent,
    ConfirmationDialogComponent,
    PromptDialogComponent,
    ConfigSummaryComponent,
    UploadGreetingsFileComponent,
    UserDataComponent,
    SystemDataComponent,
    NotificationDownloadComponent,
    AdvancedInfoTablePreviewComponent,
    HuntGroupsPreviewComponent,
    ParkLocationPreviewComponent,
   // PagingGroupsPreviewComponent,
    AutoAttendantsPreviewComponent,
    TimeProfilesPreviewComponent,
    MorrisonDataComponentComponent,
    IncomingNumbersPreviewComponent,
    HelpComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatFormFieldModule,
    MatSelectModule,
    MatCardModule,
    MatTableModule,
    MatToolbarModule,
    MatInputModule,
    MatIconModule,
    MatButtonModule,
    MatProgressBarModule,
    MatCheckboxModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFirestoreModule,
    AngularFireAuthModule,
    AngularFireAnalyticsModule,
    FormsModule,
    MatProgressSpinnerModule,
    OverlayModule,    
    MatTooltipModule,
    MatExpansionModule,
    MatDialogModule,
    MatDividerModule,
    HttpClientModule,
    NgxSpinnerModule,
    MatBadgeModule
  ],
  providers: [GSignInService , FireAuthService, LogService, MatSnackBar, NgxLinkifyjsService, CanDeactivateGuardService],
  bootstrap: [AppComponent]
})
export class AppModule { }
