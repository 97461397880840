import { Component, OnInit } from '@angular/core';
import { LogService } from 'src/app/shared/services/logging/log.service';
import { FileUploadService } from 'src/app/shared/services/file-upload/file-upload.service';
import { Subscription } from 'rxjs';
import { SessionHandlerService } from 'src/app/shared/services/sessionHandler/session-handler.service';
import { firestore } from 'firebase';
import * as firebase from 'firebase/app';
import { FirestoreService, TRIGGER_STATUS } from 'src/app/shared/services/firestore/firestore.service';
import { timingSafeEqual } from 'crypto';
import { OverlayService } from 'src/app/shared/services/overlay/overlay.service';
import { MatSpinnerOverlayComponent } from 'src/app/shared/components/mat-spinner-overlay/mat-spinner-overlay.component';
import { TablePreviewService } from 'src/app/shared/services/tablePreview/table-preview.service';
import { NgxSpinnerService } from "ngx-spinner";


const LAST_STATUS = {
      UNKNOWN: 'unknown',
      NOTSTARTED: 'notstarted',
      STARTED: 'started',
      INPROGRESS: 'inprogress',
      COMPLETED: 'completed',
      FAILURE: 'failure',
      ABORTED: 'aborted'
  };

export const FILE_UPLOAD_TYPE = {
  GREETINGS: "greetingsFile",
  SYSMON: "sysmonFile"
}


const MAX_FILE_SIZE = 2147483648;//2 GB
const ZINDEX=1000;
const ALLOWED_GREETINGS_FILE_EXTN_LIST = [ ".zip",".tar.gz"];
const ALLOWED_SYSMON_FILE_EXTN_LIST = [ ".zip",".txt"];

const PHONE_MODELS = ["Avaya J139","Avaya J179","Avaya B199","Avaya J169","Avaya J159", "Avaya J189"];
const J100_PHONE_MAC_ADDRESS_LENGTH = 12;

@Component({
  selector: 'app-upload-greetings-file',
  templateUrl: './upload-greetings-file.component.html',
  styleUrls: ['./upload-greetings-file.component.css']
})

export class UploadGreetingsFileComponent implements OnInit {

  greetingsFileName:string;
  greetingsFile:any;
  lastUploadedGreetingsFileName:string;
  lastUploadedGreetingsFileSize:string;
  greetingsFileSize:number;
  greetingsUploadProgress:number;
  greetingsErrorMsg:string;
  greetingsFileUploadStatus = LAST_STATUS.NOTSTARTED;
  greetingsFileProcessedStatus = LAST_STATUS.NOTSTARTED;
  public allowedGreetingsFileExtns = ALLOWED_GREETINGS_FILE_EXTN_LIST;
  greetingsFileExtnList=ALLOWED_GREETINGS_FILE_EXTN_LIST.map(e=>e.slice(1)).join(' or ');
  isStatusUpdated:boolean;
  id:any;
  greetingsSubscription:Subscription;
  sysmonSubscription:Subscription;
  greetingsDataSubscription:Subscription;
  sysmonDataSubscription:Subscription;
  public status = LAST_STATUS;
  private TIMER_INTERVAL = 30000;
  zIdx=ZINDEX;
  sysmonFileName:string;
  sysmonFile:any;
  lastUploadedSysmonFileName:string;
  lastUploadedSysmonFileSize:string;
  sysmonFileSize:number;
  sysmonUploadProgress:number;
  sysmonErrorMsg:string;
  sysmonFileUploadStatus = LAST_STATUS.NOTSTARTED;
  public allowedSysmonFileExtns = ALLOWED_SYSMON_FILE_EXTN_LIST;
  sysmonFileExtnList=ALLOWED_SYSMON_FILE_EXTN_LIST.map(e=>e.slice(1)).join(' or ');
  duplicateUserEntries:any = [];
  invaliUserEntries:any = [];
  
  constructor( private ngxspinner:NgxSpinnerService,private logger: LogService, private uploader: FileUploadService, private sysmonUploader: FileUploadService,
    private session: SessionHandlerService, private firestoreService: FirestoreService,
    private spinner: OverlayService, private tablePreviewService: TablePreviewService) {
    // this.duplicateUserEntries = [];
    // this.invaliUserEntries = [];
    this.ngxspinner.show();
    this.greetingsFileUploadStatus = LAST_STATUS.NOTSTARTED;
    this.greetingsFileProcessedStatus = LAST_STATUS.NOTSTARTED;
    this.sysmonFileUploadStatus = LAST_STATUS.NOTSTARTED;
    this.id = this.session.cardId;
    this.isStatusUpdated = true;
    this.spinner.openSpinner(this.TIMER_INTERVAL, this.timeupCallbackfunc, MatSpinnerOverlayComponent);
    this.firestoreService.getGreetingsFileDetails(this.id).then((data: any) => {
      this.lastUploadedGreetingsFileName = "--";
      this.lastUploadedGreetingsFileSize = "";
      if (data) {
        this.logger.debug("data: " + JSON.stringify(data));
        let size = data.size;
        if (size && size != 0) {
          this.lastUploadedGreetingsFileName = data.name;
          this.lastUploadedGreetingsFileSize = " (" + this.formatFileSize(size, 2) + ")";
        } else {
          this.lastUploadedGreetingsFileName = data.name;
        }
        if(data.trigger === TRIGGER_STATUS.DONE)
        {
          this.greetingsFileProcessedStatus = LAST_STATUS.COMPLETED;
          this.isStatusUpdated = true;
        }
        else
        { this.isStatusUpdated = false;
          setTimeout(()=>{
           if(data.trigger === TRIGGER_STATUS.DONE)
           {
            this.greetingsFileProcessedStatus = LAST_STATUS.COMPLETED;
            this.isStatusUpdated = true;
          }
          else
          {
            this.greetingsFileProcessedStatus = LAST_STATUS.FAILURE;
            this.isStatusUpdated = true;
          }
          }, 100000);}
        //this.spinner.closeSpinner();
      } else {
        //this.spinner.closeSpinner();
      }
    })
      .then(() => {
        this.firestoreService.getSysmonFileDetails(this.id).then((data: any) => {
          this.lastUploadedSysmonFileName = "";
          this.lastUploadedSysmonFileSize = "";
          if (data) {
            let size = data.size;
            if (size && size != 0) {
              this.lastUploadedSysmonFileName = data.name;
              this.lastUploadedSysmonFileSize = " (" + this.formatFileSize(size, 2) + ")";
            } else {
              this.lastUploadedSysmonFileName = data.name;
            }
            this.spinner.closeSpinner();
          } else {
            this.spinner.closeSpinner();
          }
        }).catch(err => {
          this.logger.error("Error while getting file name:" + err);
          this.spinner.closeSpinner();
        })
      });
    this.greetingsDataSubscription = this.firestoreService.getGreetingsDataAvlblMsg().subscribe((data) => {
      if (data.trigger === TRIGGER_STATUS.START) 
        this.greetingsFileProcessedStatus = LAST_STATUS.STARTED;
      else if (data.trigger === TRIGGER_STATUS.DONE) 
      {
        this.greetingsFileProcessedStatus = LAST_STATUS.COMPLETED;
        this.isStatusUpdated = true
      }
      if (data.trigger == TRIGGER_STATUS.DONE || data.trigger === TRIGGER_STATUS.START) {
        this.updateLastUploadedFileName(data, FILE_UPLOAD_TYPE.GREETINGS);
      }
    });

    this.sysmonDataSubscription = this.firestoreService.getSysmonDataAvlblMsg().subscribe((data) => {
      if (data.trigger == TRIGGER_STATUS.DONE || data.trigger === TRIGGER_STATUS.START) {
        this.updateLastUploadedFileName(data, FILE_UPLOAD_TYPE.SYSMON);
      }
    });

  }

  updateLastUploadedFileName(data, fileUploadType) {
    if (data !== undefined || data !== null) {
      try {
        if (fileUploadType === FILE_UPLOAD_TYPE.GREETINGS) {
          var fileName = data.zipFilePathName, fileSize = data.zipFileSize;
          if (fileName !== undefined && fileName !== null && fileName !== "") {
            this.lastUploadedGreetingsFileName = fileName;
            this.lastUploadedGreetingsFileSize = fileSize;
          }
        } else if (fileUploadType === FILE_UPLOAD_TYPE.SYSMON) {
          var fileName = data.filePathName, fileSize = data.fileSize;
          if (fileName !== undefined && fileName !== null && fileName !== "") {
            this.lastUploadedSysmonFileName = fileName;
            this.lastUploadedSysmonFileSize = fileSize;
          }
        }
      } catch (error) {
        this.logger.error("update last updated file name: Error while parsing uploaded file data: " + error);
      }
    }
    if (fileUploadType === FILE_UPLOAD_TYPE.GREETINGS) {
      this.logger.debug("update last uploaded file name: " + this.lastUploadedGreetingsFileName + "\tsize: " + this.lastUploadedGreetingsFileSize);
    } else if (fileUploadType === FILE_UPLOAD_TYPE.SYSMON) {
      this.logger.debug("update last uploaded file name: " + this.lastUploadedSysmonFileName + "\tsize: " + this.lastUploadedSysmonFileSize);
    }
  }

  ngOnInit(): void {
    this.greetingsSubscription = this.uploader.getProgress(FILE_UPLOAD_TYPE.GREETINGS).subscribe(
      (progress) => {
        if(progress)
          this.greetingsFileUploadStatus = LAST_STATUS.INPROGRESS;
          this.greetingsFileProcessedStatus = LAST_STATUS.NOTSTARTED;
        this.greetingsUploadProgress = progress;
        if(progress == 100)
        {
          if(this.greetingsFile !== undefined && this.greetingsFile.name !== undefined && this.greetingsFile.name !== ""){
              this.lastUploadedGreetingsFileName = this.greetingsFileName;
              this.lastUploadedGreetingsFileSize = " ("+this.formatFileSize(this.greetingsFile.size,2)+")";
          }
          this.isStatusUpdated = false;
          this.logger.debug("File upload completed: "+ this.lastUploadedGreetingsFileName + "\tsize: "+this.lastUploadedGreetingsFileSize);
          setTimeout(()=>{
            this.greetingsFileName = null;
            this.greetingsFileUploadStatus = LAST_STATUS.COMPLETED; 
            if(this.isStatusUpdated === false)           
              this.greetingsFileProcessedStatus = LAST_STATUS.FAILURE;
            this.isStatusUpdated = true;
          }, 100000);
        }        
      },
      (error)=>{
        this.handleUploadFailure(error,LAST_STATUS.FAILURE, FILE_UPLOAD_TYPE.GREETINGS);        
      }
      /*,
      ()=>{
        console.log("File upload completed");
        this.isFileUploading = false;
        this.uploadProgress = 0;
        this.subscription.unsubscribe();
       }*/
      );
      this.sysmonSubscription = this.sysmonUploader.getProgress(FILE_UPLOAD_TYPE.SYSMON).subscribe(
        (progress) => {
          if(progress)
          this.sysmonFileUploadStatus = LAST_STATUS.INPROGRESS;
          this.sysmonUploadProgress = progress;
          if(progress == 100){
            if(this.sysmonFile !== undefined && this.sysmonFile.name !== undefined && this.sysmonFile.name !== ""){
              this.lastUploadedSysmonFileName = this.sysmonFileName;
              this.lastUploadedSysmonFileSize = " ("+this.formatFileSize(this.sysmonFile.size,2)+")";
            }
            this.logger.debug("File upload completed: "+ this.lastUploadedSysmonFileName + "\tsize: "+this.lastUploadedSysmonFileSize);
            setTimeout(() => {
              this.sysmonFileName = null;
              this.sysmonFileUploadStatus = LAST_STATUS.COMPLETED;
            }, 500);
          }
        },
        (error)=>{
          this.handleUploadFailure(error,LAST_STATUS.FAILURE, FILE_UPLOAD_TYPE.SYSMON);
        }
      )
  }

  timeupCallbackfunc(ol){
    ol.showSpinner = false;
    ol.closeSpinner();
  }

  private formatFileSize = (bytes,decimalPoint)=> {
    if(bytes == 0) return '0 Bytes';
    var k = 1024,
        dm = decimalPoint || 2,
        sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
        i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
 }

  onGreetingsFileChanged(event){
    this.greetingsFileUploadStatus = LAST_STATUS.NOTSTARTED;
    this.greetingsFileProcessedStatus = LAST_STATUS.NOTSTARTED;
    this.logger.debug("onFileChanged: " + JSON.stringify(event));
    this.greetingsFile = event.target.files[0];
    this.greetingsFileName = this.greetingsFile.name;
    this.logger.debug("The file selected: ", this.greetingsFileName);
    let ext = ALLOWED_GREETINGS_FILE_EXTN_LIST.find(entry=> this.greetingsFileName.endsWith(entry));
    if(ext !== undefined){
      let size = this.greetingsFile.size;
      if(size === 0){
        this.handleUploadFailure("File is empty",LAST_STATUS.ABORTED, FILE_UPLOAD_TYPE.GREETINGS);
      }else if( size < MAX_FILE_SIZE){
        this.greetingsUploadProgress = 0;
        this.greetingsFileUploadStatus = LAST_STATUS.STARTED;
        this.greetingsFileProcessedStatus = LAST_STATUS.STARTED;
        this.uploader.uploadGreetingsFile(this.greetingsFile,this.id,this.greetingsFileName,size);
      }else{
        this.handleUploadFailure("File size exceeds the maximum file size limit.",LAST_STATUS.ABORTED, FILE_UPLOAD_TYPE.GREETINGS);
      }
          
    }else{
      let extnListLen = ALLOWED_GREETINGS_FILE_EXTN_LIST.length;
      if(extnListLen>0){
        let error = " File should be in ";
        if(extnListLen > 1 ){
          error += ALLOWED_GREETINGS_FILE_EXTN_LIST.slice(0,-1).map(
            el=>
            {
              return "\'"+el+"\'";
            }
            ).join(" / ") + " / ";
        }
        error += "\'"+ ALLOWED_GREETINGS_FILE_EXTN_LIST[extnListLen-1] + "\'" + " format only"
        this.handleUploadFailure(error,LAST_STATUS.ABORTED, FILE_UPLOAD_TYPE.GREETINGS);
      }
    }
  }

  async onSysmonFileChanged(event) {
    this.logger.debug("OnFileChanged : " + JSON.stringify(event));
    this.sysmonFileUploadStatus = LAST_STATUS.NOTSTARTED;
    this.sysmonFile = event.target.files[0];
    this.sysmonFileName = this.sysmonFile.name;
    this.logger.debug("The file selected: ", this.sysmonFileName);
    let ext = ALLOWED_SYSMON_FILE_EXTN_LIST.find(entry => this.sysmonFileName.endsWith(entry));
    if(ext !== undefined) {
      let size = this.sysmonFile.size;
      if(size === 0) {
        this.handleUploadFailure("File is empty: ", LAST_STATUS.ABORTED, FILE_UPLOAD_TYPE.SYSMON);
      } else if (size < MAX_FILE_SIZE) {
        this.sysmonFileSize = size;
        this.sysmonFileName = this.sysmonFile.name;
        this.sysmonUploadProgress = 0;
        this.sysmonFileUploadStatus = LAST_STATUS.STARTED;
        this.spinner.openSpinner(this.TIMER_INTERVAL,this.timeupCallbackfunc,MatSpinnerOverlayComponent);
        this.tablePreviewService.isSysmonFileUploaded = true;
        await this.parseSysmonFile(event);
        //Delete previsouly uploaded sysmon file, will not be applicable for the first upload of sysmon file
        if (this.lastUploadedSysmonFileName !== undefined && this.lastUploadedSysmonFileName !== "") {
          this.sysmonUploader.deleteLastUploadedSysmonFile(this.id, this.lastUploadedSysmonFileName);
        }
        this.spinner.closeSpinner();
        await this.sysmonUploader.uploadSysmonFile(this.sysmonFile, this.id, this.sysmonFileName, size);
      } else {
        this.handleUploadFailure("File size exceeds the maximum file size limit.", LAST_STATUS.ABORTED, FILE_UPLOAD_TYPE.SYSMON);
      }
    } else {
      let extnListLen = ALLOWED_SYSMON_FILE_EXTN_LIST.length;
      if(extnListLen>0){
        let error = " File should be in ";
        if(extnListLen > 1 ){
          error += ALLOWED_SYSMON_FILE_EXTN_LIST.slice(0,-1).map(
            el=>
            {
              return "\'"+el+"\'";
            }
            ).join(" / ") + " / ";
        }
        error += "\'"+ ALLOWED_SYSMON_FILE_EXTN_LIST[extnListLen-1] + "\'" + " format only"
        this.handleUploadFailure(error,LAST_STATUS.ABORTED, FILE_UPLOAD_TYPE.SYSMON);
      }
    }
  }

  async parseSysmonFile(event: any) {
    this.logger.debug("parseSysmonFile");
    var sysmonFiles = event.target.files;
    this.sysmonFile = sysmonFiles[0];
    let sysmonData: any = "";
    let promises = [];
    //this.reset();
    this.logger.debug("File selected: ", this.sysmonFile.name);
    this.sysmonFileName = this.sysmonFile.name;

    if (this.sysmonFileName.endsWith(".zip")) {
      this.logger.debug("Uploaded sysmon zip file : ", this.sysmonFileName);
      const jsZip = require('jszip');
      await jsZip.loadAsync(sysmonFiles[0]).then((zip) => {
        promises = Object.keys(zip.files).map((filename) => {
          return new Promise((resolve, reject) => {
            if (filename.endsWith(".txt")) {
              zip.files[filename].async('string').then((data) => {
                sysmonData = sysmonData + data;
                resolve(sysmonData);
              });
            } else {
              let err = 'Invalid File Format associated with one/more enclosing files, Only .txt/.zip Files Supported';
              this.handleUploadFailure(err, LAST_STATUS.FAILURE, FILE_UPLOAD_TYPE.SYSMON);
              this.spinner.closeSpinner();
              this.tablePreviewService.isSysmonFileUploaded = false;
              reject(err);
            }
          });
        });
      });
      return Promise.all(promises).then(values => {
        this.validateAndExtractSysmonData(sysmonData).then((res) => {
            this.uploader.uploadJsonFile(this.id, '/IpoCfg/sysmon.json', { data: res, totalData: res.length, duplicateUserEntries: this.duplicateUserEntries, invaliUserEntries: this.invaliUserEntries })
            .then(() => {
              this.tablePreviewService._isSysmonDataInitializedFlag = false;
              this.firestoreService.updateSysmonTriggerStatus(this.id, TRIGGER_STATUS.START, this.sysmonFile.name, this.sysmonFileSize);

            });
          });
      });
    } else if (this.sysmonFileName.endsWith(".txt")) {
      event.target.files[0].text().then(data => this.validateAndExtractSysmonData(data)).then((res) => {
        this.uploader.uploadJsonFile(this.id, '/IpoCfg/sysmon.json', { data: res, totalData: res.length, duplicateUserEntries: this.duplicateUserEntries, invaliUserEntries: this.invaliUserEntries })
        .then(() => {
          this.tablePreviewService._isSysmonDataInitializedFlag = false;
          this.firestoreService.updateSysmonTriggerStatus(this.id, TRIGGER_STATUS.START, this.sysmonFile.name, this.sysmonFileSize);
        });
      });
    } else {
      //Test this flow once by uploading a file with other extensions
      this.logger.debug("invalid file format uploaded")
      let err = 'Invalid File Format associated with one/more enclosing files, Only .txt/.zip Files Supported';
      this.handleUploadFailure(err, LAST_STATUS.FAILURE, FILE_UPLOAD_TYPE.SYSMON);
    }
  }

  async validateAndExtractSysmonData(data: any) {
    this.logger.debug("Processing sysmon file data");
    let dataArray = data.split("\n");
    dataArray = dataArray.filter(line => !(line.startsWith("SIP Phones")));

    this.invaliUserEntries = [];
    this.duplicateUserEntries = [];

    let ipoUsers = await this.downloadFileFromBucket(`Greetings/${this.id}/IpoCfg/csv.json`);
    var ipoUserList = ipoUsers['IPOUsers'];

    let sysmonData: any = [];

    dataArray.forEach(entry => {
      if (entry.trim().length > 0) {
        let userSysmonEntry = entry.split(',');
        let userExtension = userSysmonEntry[1];
        let userName = userSysmonEntry[2];
        let phoneModel = userSysmonEntry[9].split(" ")[0] + " " + userSysmonEntry[9].split(" ")[1];
        let macId = userSysmonEntry[9].split(" ")[5];
        if (macId !== undefined && macId.length !== J100_PHONE_MAC_ADDRESS_LENGTH) macId = '';

        let matchedUserExtension = ipoUserList.find((user) => user.Extension.US_Extension.toString().trim() === userExtension.trim());
        if (matchedUserExtension !== undefined) {
          //Before other validations are implemented, check if the user Extension is a valid userExtension along userName.
          if (userName === undefined || userExtension === undefined || !(PHONE_MODELS.includes(phoneModel.trim())) || macId.trim() === undefined) {
            this.logger.debug(`Invalid user entry in sysmon file ${entry} will be ignored`);
            this.invaliUserEntries.push({
              userName: userName ? userName : '',
              userExtension: userExtension ? userExtension : '',
              phoneModel: phoneModel ? phoneModel : '',
              macId: macId ? macId : ''
            });
            return;
          }

          //Filter out duplicates
          let matchedEntry = sysmonData.find(entry => (entry.userExtension === userExtension));
          if (matchedEntry !== undefined) {
            this.duplicateUserEntries.push({
              userName: userName,
              userExtension: userExtension,
              macId: macId,
              phoneModel: phoneModel
            });
          } else {
            let sysmonEntry = {
              userExtension: userExtension,
              userName: userName,
              phoneModel: phoneModel,
              macId: macId,
              canBeSynched: false,
              extensionId: "",
              deviceId: ""
            }
            sysmonData.push(sysmonEntry);
          }
        }
      }
    });
    return sysmonData
  }


  ngOnDestroy(){
    if (this.greetingsSubscription) this.greetingsSubscription.unsubscribe();
    if (this.sysmonSubscription) this.sysmonSubscription.unsubscribe();
    if (this.greetingsDataSubscription) this.greetingsDataSubscription.unsubscribe();
    if (this.sysmonDataSubscription) this.sysmonDataSubscription.unsubscribe();
    this.ngxspinner.hide();
  }

  downloadFileFromBucket(fileName:any){
    var promise = new Promise((resolve, reject) => {
        try {
            var storage = firebase.storage();
            var storageRef = storage.ref(fileName);
            storageRef.getDownloadURL().then((url) => {
                var xhr = new XMLHttpRequest();
                xhr.responseType = 'json';
                xhr.onload = (event) => {
                    if (xhr.status != 200) reject();
                    else resolve(xhr.response);
                };
                xhr.open('GET', url);
                xhr.send();
                xhr.onabort = () => {
                    this.logger.error("Request to fetch document is aborted");
                    reject();
                }
                xhr.onerror = () => {
                    this.logger.error("Error while processing document request");
                    reject();
                }
            })
            .catch((err) => { 
                this.logger.debug('Error getting document', err)
                reject();
            })
        } catch (err) { 
            this.logger.debug('Error, could not execute request', err);  
            reject();
        }
    })
    return promise;
}

  private handleUploadFailure(err,status,uploadType):void{
    if (FILE_UPLOAD_TYPE.GREETINGS === uploadType) {
      this.greetingsFileUploadStatus = status;
      this.greetingsFileProcessedStatus = status;
      this.greetingsErrorMsg = err;
    } else if(FILE_UPLOAD_TYPE.SYSMON === uploadType){
      this.sysmonFileUploadStatus = status;
      this.sysmonErrorMsg = err;
    }
  }
}
