import { Component, OnInit, NgZone, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FirestoreService } from 'src/app/shared/services/firestore/firestore.service';
import { Subscription } from 'rxjs';
import { LogService } from 'src/app/shared/services/logging/log.service';
import { SessionHandlerService } from 'src/app/shared/services/sessionHandler/session-handler.service';
import { DownloadNotificationService, FONT_CLASS, getLineContent, getTextContent, lineStruct, setTextContPr, textStruct } from '../../../../shared/services/downloadNotifications/downloadNotifications.service';
import { TablePreviewService} from 'src/app/shared/services/tablePreview/table-preview.service';

@Component({
    selector: 'app-download-notifications',
    templateUrl: './download-notifications.component.html',
    styleUrls: ['./download-notifications.component.css']
  })
export class NotificationDownloadComponent implements OnInit {
    subscription: Subscription;
    private id:any;
    readonly MAX_CHECK_ITERATION = 3;
    public companyName: string;
    public basicInfoError:any = [];
    public basicInfoWarnings:any = [];
    public advInfoWarnings :any = [];
    public huntGroupWarnings:any = [];
    public pagingGroupWarnings:any = [];
    public parkLocWarnings:any = [];
    public aaWarnings:any = [];
    public tpWarnings:any = [];
    public icrWarnings:any = [];
    public systemSummaryWarning:any = [];
    public downloadTime= new Date();
    isDataReady:boolean=false;
    constructor(public zone: NgZone, 
    private firestoreService: FirestoreService, public _Activatedroute:ActivatedRoute,
    private logger:LogService,
    private session:SessionHandlerService, private downloadNotificationService: DownloadNotificationService, private tablePreviewService:TablePreviewService)
    { 
      this.id = this.session.cardId;  
      this.logger.debug("NotificationDownloadComponent constructor: ", this.id)
      this.companyName = this.session.companyName;
      this.isDataReady = false;
    }
  ngOnInit(): void {
    this.subscription = this.tablePreviewService.getTableDataInitializedObs().subscribe((res)=>{
        this.isDataReady = res;
        this.tablePreviewService.setWarningData();
    })
  }
  triggerDownloadReport() {
        this.logger.debug("triggerNotificationReport");
        this.firestoreService.getNotificationData(this.id)
        .then((doc:any)=>{            
            let compiledReportData = this.buildReportData(doc);
            this.downloadNotificationService.generatePDF("notificationsReport",this.companyName,compiledReportData);
        })
        .catch((error)=>{
            this.logger.error("download report failed :",error);
        })
    }

    buildReportData(data){
      let reportData = data.configSummaryNotifications;  
      let systemSummaryWarning = JSON.parse(data.systemSummaryNotifications);
      let basicInfoError = (reportData.userData.basicInfo.errors !== undefined)?JSON.parse(reportData.userData.basicInfo.errors):[];
      let basicInfoWarnings = (reportData.userData.basicInfo.warnings !== undefined)?JSON.parse(reportData.userData.basicInfo.warnings):[];
      let advInfoWarnings = (reportData.userData.advancedInfo.warnings !== undefined)?JSON.parse(reportData.userData.advancedInfo.warnings):[];
      let hgWarnings = (reportData.systemData.hgWarning !== undefined)?JSON.parse(reportData.systemData.hgWarning.warnings):[];
      let pgWarnings = (reportData.systemData.pgWarning !== undefined)?JSON.parse(reportData.systemData.pgWarning.warnings):[];
      let plWarnings = (reportData.systemData.plWarning !== undefined)?JSON.parse(reportData.systemData.plWarning.warnings):[];
      let tpWarnings =(reportData.systemData.tpWarning !== undefined)?JSON.parse(reportData.systemData.tpWarning.warnings):[];
      let aaWarnings = (reportData.systemData.aaWarning !== undefined)?JSON.parse(reportData.systemData.aaWarning.warnings):[];
      let icrWarnings = (reportData.systemData.icrWarning !== undefined)?JSON.parse(reportData.systemData.icrWarning.warnings):[];
      let features = [
        { 
          name: "Call Queues",
          warnings:hgWarnings
        },
        {
          name:"Paging Groups",
          warnings:pgWarnings
        },
        {
          name:"Park Locations",
          warnings:plWarnings
        },
        {
          name:"Working Hours",
          warnings:tpWarnings
        },
        {
          name:"Auto-Receptionists",
          warnings:aaWarnings
        },
        {
          name:"Company Numbers",
          warnings:icrWarnings
        }
      ];
      let finalData:any=[];
      if(data !== undefined && data != null){                
        let temp:textStruct, line:lineStruct;
        //==========================================================================================
        temp = this.addData("Notifications Report",FONT_CLASS.MAIN_TITLE);
        finalData.push(temp);       
        //==========================================================================================
        temp = getTextContent();
        temp.text.push("Company Name : "+this.companyName);
        temp.text.push("Report Downloaded on : "+this.downloadTime);
        temp.fontClass = FONT_CLASS.MAINCONTENT;
        finalData.push(temp);
        //==========================================================================================
        line = getLineContent();
        finalData.push(line);
        //==========================================================================================
        temp = this.addData("System Summary Warnings",FONT_CLASS.TITLE);
        finalData.push(temp);
        //------------------------------------------------------------------------------------------
        temp = getTextContent();
        temp.x = 20;        
        for(let i=0;i<systemSummaryWarning.length;i++){
          temp.text[i] = String.fromCharCode(0x2022)+" "+systemSummaryWarning[i];
        }
        finalData.push(temp);
        //==========================================================================================
        line = getLineContent();
        finalData.push(line);        
        //==========================================================================================
        temp = this.addData("User Data Notifications",FONT_CLASS.TITLE);
        finalData.push(temp);
        //------------------------------------------------------------------------------------------
        if(basicInfoError.length > 0 || basicInfoWarnings.length > 0){
          temp = this.addData("Basic Information",FONT_CLASS.HEADING);
          finalData.push(temp);
          //----------------------------------------------------------------------------------------
          if(basicInfoError.length > 0){
            temp = this.addData("Errors",FONT_CLASS.ERROR);
            finalData.push(temp);
            //------------------------------------------------------------------------------------------
            for(let i=0;i<basicInfoError.length;i++){
              if(basicInfoError[i][0] !== "")
                temp = this.addData(basicInfoError[i][0],FONT_CLASS.HEADING);
              else 
                temp = this.addData("Main-Site",FONT_CLASS.HEADING);
                if(basicInfoError[i][1].length > 0)
                {
              finalData.push(temp);
              temp = getTextContent();
              temp.x = 20;
              for(let j = 0; j < basicInfoError[i][1].length ; j++)
              {
                temp.text[j] = String.fromCharCode(0x2022)+" "+basicInfoError[i][1][j];
              }
              finalData.push(temp);
            }
            }
          }        
          //------------------------------------------------------------------------------------------
          if(basicInfoWarnings.length > 0){
            temp = this.addData("Warnings",FONT_CLASS.WARNING);
            finalData.push(temp);
            //------------------------------------------------------------------------------------------
            for(let i=0;i<basicInfoWarnings.length;i++){
              if(basicInfoWarnings[i][0] !== "")
              temp = this.addData(basicInfoWarnings[i][0],FONT_CLASS.HEADING);
            else 
              temp = this.addData("Main-Site",FONT_CLASS.HEADING);
              if(basicInfoWarnings[i][1].length > 0)
              {
              finalData.push(temp);
              temp = getTextContent();
              temp.x = 20;
              for(let j = 0; j < basicInfoWarnings[i][1].length ; j++)
              {
                temp.text[j] = String.fromCharCode(0x2022)+" "+basicInfoWarnings[i][1][j];
              }
              finalData.push(temp);
            }
            }
          }            
          //==========================================================================================
        }
        
        line = getLineContent();
        finalData.push(line);
        //==========================================================================================
        temp = this.addData("Advanced Information",FONT_CLASS.HEADING);
        finalData.push(temp);
        //------------------------------------------------------------------------------------------
        temp = this.addData("Warnings",FONT_CLASS.WARNING);
        finalData.push(temp);
        //------------------------------------------------------------------------------------------
        for(let i=0;i<advInfoWarnings.length;i++){
          if(advInfoWarnings[i][0] !== "")
          temp = this.addData(advInfoWarnings[i][0],FONT_CLASS.HEADING);
        else 
          temp = this.addData("Main-Site",FONT_CLASS.HEADING);
          if(advInfoWarnings[i][1].length > 0)
          finalData.push(temp);
          temp = getTextContent();
          temp.x = 20;
          for(let j = 0; j < advInfoWarnings[i][1].length ; j++)
          {
            temp.text[j] = String.fromCharCode(0x2022)+" "+advInfoWarnings[i][1][j];
          }
          finalData.push(temp);
        }
       
        //==========================================================================================
        line = getLineContent();
        finalData.push(line);
        //==========================================================================================
        temp = this.addData("System Data Warnings",FONT_CLASS.TITLE);
        finalData.push(temp);
        //==========================================================================================
        for(let i=0;i<features.length;i++){
          if(features[i].warnings.length > 0) {
            if (features[i].name !== "Park Locations")
            {
              temp = this.addData(features[i].name,FONT_CLASS.HEADING);
              finalData.push(temp);
              //------------------------------------------------------------------------------------------
              temp = this.addData("Warnings",FONT_CLASS.WARNING);
              finalData.push(temp);
              //------------------------------------------------------------------------------------------
              for(let k = 0;features[i].warnings[k]; k ++)
              {
              if(features[i].warnings[k][0] !== "")
                temp = this.addData(features[i].warnings[k][0],FONT_CLASS.HEADING);
              else
                temp = this.addData("Main-Site",FONT_CLASS.HEADING);
              if(features[i].warnings[k][1].length > 0)
                {
                  finalData.push(temp);
                  temp = getTextContent();
                  temp.x = 20;
                  for(let j=0;j<features[i].warnings[k][1].length;j++){
                    temp.text[j] = String.fromCharCode(0x2022)+" "+features[i].warnings[k][1][j];
                  }
                  finalData.push(temp);
                }
                else continue;
              }
            }
            else
            {
            temp = this.addData(features[i].name,FONT_CLASS.HEADING);
            finalData.push(temp);
            //------------------------------------------------------------------------------------------
            temp = this.addData("Warnings",FONT_CLASS.WARNING);
            finalData.push(temp);
            //------------------------------------------------------------------------------------------
            temp = getTextContent();
            temp.x = 20;
            for(let j=0;j<features[i].warnings.length;j++){
              temp.text[j] = String.fromCharCode(0x2022)+" "+features[i].warnings[j];
            }
            finalData.push(temp);
          }
            //==========================================================================================
            line = getLineContent();
            finalData.push(line);
            //==========================================================================================
          }
        }
      }
      return finalData;
    }
    ngOnDestroy(){
        this.subscription.unsubscribe();
      }

    addData(data,fontClass){
        let temp = getTextContent();
        temp.text.push(data);
        temp.fontClass = fontClass;
        return setTextContPr(temp);
    }
    
}


