import { Component, OnInit } from '@angular/core';
import { MatSpinnerOverlayComponent } from '../mat-spinner-overlay/mat-spinner-overlay.component';
import { OverlayService } from '../../services/overlay/overlay.service';
import { LogService } from '../../services/logging/log.service';
import * as firebase from 'firebase';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.css']
})
export class HelpComponent implements OnInit {

  TIMER_INTERVAL:number=30000;
  isGuideInvalid=false;
  userDisplayMsg = "User guide is not available.";
  MIN_VAL=-1;

  constructor(private logger:LogService, private overlay:OverlayService) { }
  

  ngOnInit(): void {
    this.isGuideInvalid = false;
  }

  downloadFileFromFirestore(storageRef,fileName,that){
    that.logger.debug("Downloading user guide: "+fileName);
    storageRef.getDownloadURL().then((url)=>{
      var xhr = new XMLHttpRequest();
      xhr.responseType = 'blob';
      xhr.onload = (event) => {          
        const blob = xhr.response;
        //const fileNameToSaveAs = 'Using_the_ACO_Migration_Tool_Release_.pdf';
        console.debug("User guide fetched");
        saveAs(blob, fileName);
        this.overlay.closeSpinner();   
      };
      xhr.onabort = () => {
        this.overlay.closeSpinner();          
        this.logger.error("Request to fetch user guide is aborted");
      }
      xhr.onerror = () =>{
        this.overlay.closeSpinner();
        this.logger.error("Error while fetching user guide document");
      }
      xhr.open('GET', url);
      xhr.send();
    })
  }

  redirectToOnlineGuide() {
    window.open('https://documentation.avaya.com/bundle/UsingTheAvayaCloudOfficeMigrationTool/page/Purpose.html','_blank');
  }
  /* ACOS- 1739 - Remove help file download button from the tool -------------
    downloadUserGuide() {
    this.overlay.openSpinner(this.TIMER_INTERVAL,this.timeupCallbackfunc,MatSpinnerOverlayComponent);
    var storage = firebase.storage();
    var storageRef= storage.ref('userguide/');
    var that = this;
    var file = {name:"",ref:storageRef},maxVal=that.MIN_VAL;
    storageRef.listAll().then(function(result) {
      if(Array.isArray(result.items) &&  Object.keys(result.items).length !== 0){
        let count =0, len = result.items.length;
        result.items.forEach(async function(fileRef) {    
          let fileName = (await fileRef.getMetadata()).name;
          var regExpr = new RegExp('Using_the_ACO_Migration_Tool_Release_\.*.pdf',"i");
          if(regExpr.test(fileName)){
            console.log("Matched :"+ fileName);
            let temp = fileName.split("Release_")[1];
            temp = temp.substr(0,temp.indexOf("_"));
            let verNum = Number.parseInt(temp);
            if(typeof(verNum) === 'number'){
              if(maxVal<verNum){
                maxVal=verNum;
                file.name = fileName;
                file.ref = fileRef;
              }
            }
          }
          count++;
          if(count == len){
            if(maxVal == that.MIN_VAL){
              that.logger.error("Uploaded user guide document is not named correctly.");
              that.isGuideInvalid = true;
              that.overlay.closeSpinner();
            }else{
              that.isGuideInvalid = false;
              that.downloadFileFromFirestore(file.ref,file.name,that);
            }
          }
        })
      }else{
        that.logger.error("User guide document does not exist.");
        that.isGuideInvalid = true;
        that.overlay.closeSpinner();
      }              
    }).catch(function(error) {
      // Handle any errors
    });
  }
  */
  timeupCallbackfunc(ol){
    ol.showSpinner = false;
    ol.closeSpinner();
  }

}
