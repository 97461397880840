import { CredentialResponse } from 'google-one-tap';
import { FirestoreService } from './../../services/firestore/firestore.service';
import { Subscription } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { GSignInService } from './../../services/googleAPI/g-sign-in.service';
import { Component, OnInit , NgZone} from '@angular/core';
import { FireAuthService } from '../../services/firebaseAuth/fire-auth.service';
import { Router } from '@angular/router';
import { OverlayService } from 'src/app/shared/services/overlay/overlay.service';
import { LogService } from 'src/app/shared/services/logging/log.service';
import { MatSpinnerOverlayComponent } from '../mat-spinner-overlay/mat-spinner-overlay.component';
import { MatSnackBar } from '@angular/material/snack-bar';
//declare var google: any;
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  subscription: Subscription;
  readonly TIMER_INTERVAL = 60000;
  client: any;
  access_token: any;

  constructor(private googleService:GSignInService , private fireauthService:FireAuthService,
    private router: Router , public zone: NgZone  , private firestoreService: FirestoreService,
    private overlay:OverlayService, private logger:LogService , private _snackBar: MatSnackBar) {
    this.logger.info("LoginComponent constructor");
    //this.initClient();
  }
  

  ngOnInit(): void {
      this.googleLoginSubscription();
      let google = this.googleService.getGoogleInstance();
      //ACOS-1624 white screen when GIS library is not loaded
      //automatic reload of page is done to load GIS library
      if(google === undefined || google === null){
        this.logger.debug("reloading page, since GIS not loaded");
        window.location.reload();
      }
      //if google instance is null/undefined, backup logic is to refresh window
      //now automatically white screen is loaded which user has to refresh. This happends only during network fluctuations.
      google.accounts.id.renderButton(document.getElementById("googleBtn"),
      { theme: "filled_blue", size: "large" });
  }

  googleLoginSubscription(){
    this.subscription = this.googleService.getMessage().subscribe(data => {
      this.subscription.unsubscribe();
      this.logger.debug("google login success callback observer");
      this.handleCredentialResponse(data)
    });
  }

  initClient() {
     /*  this.client = google.accounts.oauth2.initTokenClient({
        client_id: '442340770447-b9l7th4gsh3jl1d58tgvhv7ghianurbo.apps.googleusercontent.com',
        scope: 'profile email',
        callback: (tokenResponse) => {
          this.access_token = tokenResponse.access_token;
        },
      }); */
      /* this.zone.run(() => { 
        let client = google.accounts.id.initialize({
          client_id: environment.client_id,
          callback: this.handleCredentialResponse.bind(this)
        });
        google.accounts.id.renderButton(document.getElementById("googleBtn"),
        { theme: "filled_blue", size: "large" });
      }); */ 
   }

  parseJwt (token) {
      var base64Url = token.split('.')[1];
      var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      }).join(''));
    
      return JSON.parse(jsonPayload);
  };
 
  handleCredentialResponse(credentialResponse) {
    // parseJwt() is a custom function defined by you
    // to decode the credential response.
    
    const responsePayload = this.parseJwt(credentialResponse.credential);

    //this.logger.debug("ID: " + responsePayload.sub);
    this.logger.debug('Full Name: ' + responsePayload.name);
    this.logger.debug('Given Name: ' + responsePayload.given_name);
    //this.logger.debug('Family Name: ' + responsePayload.family_name);
    //this.logger.debug("Image URL: " + responsePayload.picture);
    this.logger.debug("Email: " + responsePayload.email);
    //this.triggerFireBaseAuth(credentialResponse.credential);
    this.domainValidation(responsePayload,credentialResponse);
  }

  ngAfterViewInit(){
    //this.initClient();
    //subscribe for googleuser data after succesfull g-signin
    /* this.subscription = this.googleService.getMessage().subscribe(data => {
      this.subscription.unsubscribe();
      this.domainValidation(data);
    }); */

    //this.googleService.googleInit();
  }

  /* doLogin(){
    this.logger.debug("login");
    this.googleService.signIn()
    .then((googleUser:any) =>{
      let profileInfo = googleUser.getBasicProfile();
        //this.logger.debug('Token || ' + googleUser.getAuthResponse().id_token);
        this.logger.debug('ID: ' + profileInfo.getId());
        this.logger.debug('Name: ' + profileInfo.getName());
        this.logger.debug('Name: ' + profileInfo.getEmail());
      this.domainValidation(googleUser);
      this.logger.debug(googleUser);
    }).catch((err) =>{
      this.logger.error("google sigin failed :",err);
    });
  } */

  domainValidation(googleUserData,credentialResponse){
    this.logger.debug("domainValidation");
    if(googleUserData !== null){
      //let userEmail:string = googleUserData.getBasicProfile().getEmail();
      let userEmail:string = googleUserData.email;
      this.getSupportedDomains(userEmail)
      .then(()=>{
        this.triggerFireBaseAuth(credentialResponse.credential);
      }).catch(()=>{
        this.googleLoginSubscription();
        this.logger.debug("Domain validation failed for user: ",userEmail);
        this.openSnackBar("Invalid email address");
      })
    }
  }

  userCreationStatus(data: any) {
    if(data.authorized == 1){
      this.zone.run(() => { this.router.navigate(['/home/dashboard']); });
    }else{
      //currenty if authorized is o|2 , we route to profile
      //TODO:do not route if it is 2 
      this.zone.run(() => { this.router.navigate(['/home/profile']); });
    }
  }

  getSupportedDomains(userEmail){
    var promise = new Promise((resolve, reject) => {

      var xhr = new XMLHttpRequest();
      xhr.onload = (event) => {
        let domainList:string = xhr.responseText;
        this.logger.debug(domainList);
        if(userEmail.includes('@') && domainList.includes(userEmail.split('@')[1])){
          resolve(xhr.response);
        }else{
          reject();
        }  
      };
      xhr.onabort = (err) => {
        this.logger.error("Request to fetch well-known domains is aborted" ,err); 
        reject();
      }
      xhr.onerror = (err) =>{
        this.logger.error("Error while fetching well-known domains" ,err); 
        reject();
      }
      xhr.open('GET', environment.well_known_endpoint );
      xhr.send();

    });
    return promise;
}

  timeupCallbackfunc(ol){
    //this.logger.info("Time up");
    ol.showSpinner = false;
    ol.closeSpinner();
  }

  triggerFireBaseAuth(googleUser){
    this.subscription = this.fireauthService.subscribeFirebaseAuthStateChange().subscribe(data => {
      //fire store user creation is successful
        this.subscription.unsubscribe();
        this.overlay.closeSpinner();
        this.userCreationStatus(data);
    });

    this.overlay.openSpinner(this.TIMER_INTERVAL,this.timeupCallbackfunc,MatSpinnerOverlayComponent);
    this.fireauthService.doFirebaseAuth(googleUser)
    .then((val) => {
      //firebase authentication is successful. But user creation is still in progress.
        this.logger.debug("auth success: ");       
    })
    .catch((err) => {
      this.logger.debug(err)        
    });
  }

  openSnackBar(error) {
    this._snackBar.open(error, '', {verticalPosition: 'bottom', horizontalPosition: 'center',
        duration: 10000
      });
  }

}
