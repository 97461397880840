import { Component, OnInit, NgZone, ChangeDetectorRef } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { FireAuthService } from '../../services/firebaseAuth/fire-auth.service';
import { LogService } from 'src/app/shared/services/logging/log.service';
import { GSignInService } from '../../services/googleAPI/g-sign-in.service';
import { Subscription } from 'rxjs';
import { FirestoreService } from '../../services/firestore/firestore.service';
import { MigrationDataService } from '../../services/migrationDataService/migration-data.service';
import { TablePreviewService } from '../../services/tablePreview/table-preview.service';
import { SessionHandlerService } from '../../services/sessionHandler/session-handler.service';
import { filter } from 'rxjs/operators';
declare var google: any;
const ROLE = {
  MTUSER : "mtuser",
  ADMIN : "admin"
}

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})

export class HomeComponent implements OnInit {
  //default URL for account should be assigned to accountImage string 
  accountImage:string;
  userName:string;
  userCompany:string;
  userEmail:string;
  private currentRoute:string;
  private authStateChange: Subscription;
  private currentRouteStateChange: Subscription;
  public isAuthorized: boolean;
  public isAdminRole:boolean = false;
  
  readonly TABLE_DATA_UNSAVED_PROMPT_MSG = 'Closing the current migration will discard changes in the \
  table.\nAre you sure you want to close current migration?';

  constructor(private router: Router , public zone: NgZone , private firestoreService:FirestoreService,
    private fireauthService:FireAuthService,private googleService:GSignInService,
    private migrationDataService:MigrationDataService,private session:SessionHandlerService,
    private logger:LogService,private tablePreviewService:TablePreviewService) {
      this.logger.info("HomeComponent Constructor");
      this.currentRouteStateChange = this.router.events.pipe(
        filter(event => event instanceof NavigationEnd))
        .subscribe((event:NavigationEnd) => {
              this.currentRoute = event.url;
            });
      this.authStateChange = this.fireauthService.subscribeFirebaseAuthStateChange().subscribe(data => {
        this.authStateChange.unsubscribe();
        if(data !== null){
          this.fetchUserDetails(data);
        }else{
          this.zone.run(() => { this.router.navigate(['/']); });
        } 
      });
      this.fireauthService.authStateChangeListener();
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void{
    this.currentRouteStateChange.unsubscribe();
  }

  viewprofile(){
    this.logger.info("viewprofile");
    this.zone.run(() => { this.router.navigate(['/home/profile']); });    
  }

  navigateToHome(){
    this.logger.debug("navigateToHome");
    if(this.currentRoute.includes('/home/migration/basic-info')){
      this.session.alertModal(this.onCloseRedirectToHome.bind(this), this.TABLE_DATA_UNSAVED_PROMPT_MSG);
    }else{
      this.onCloseRedirectToHome();
    }    
  }

  doLogout(){
    this.logger.info("doLogout");
    if(this.currentRoute.includes('/home/migration/basic-info')){
      this.session.alertModal(this.onCloseLogOut.bind(this), this.TABLE_DATA_UNSAVED_PROMPT_MSG);
    }else{
      this.onCloseLogOut();
    }
  }

  about(){
    this.logger.info("about");
    this.zone.run(() => { this.router.navigate(['/home/about']); });     
  }

  openAdminPage(){
    this.logger.info("openAdminPage");
    var childWindow =  window.open('/admin/members/newrequests','_blank');
  }

  onCloseRedirectToHome() {
    if(this.isAuthorized){
      this.migrationDataService.clearData();
      this.zone.run(() => { this.router.navigate(['/home/migration']);});
    }
    else{
        this.zone.run(() => { this.router.navigate(['/home/profile']); });
    }
  }

  onCloseLogOut() {
    this.fireauthService.logout()
    /*.then((res) =>{
      google.accounts.id.revoke(this.userEmail, done => {
        console.log(done.error);
        google.accounts.id.renderButton();
      });
      //return this.googleService.signOut();
    }) */
    .then((res) =>{
      this.tablePreviewService.sendCardClosureStatus(true);
      this.zone.run(() => { this.router.navigate(['']); });
    }).catch((err) =>{
      this.logger.error("doLogout failed : ",err);
    });
  }

  fetchUserDetails(userDetails) {
    if(userDetails != null){ 
      this.userName = userDetails.displayName;   
      this.userEmail = userDetails.email;         
      this.firestoreService.getUserDetails()
      .then((userDetails:any) => {
        this.userName = userDetails.displayName;
        if(userDetails.authorized == 1){
          this.isAuthorized = true;
        }
        userDetails.role.forEach(element => {
          if(element === ROLE.ADMIN){
            this.isAdminRole = true;
          }
        });
        return this.firestoreService.getUserProfileDetails();
      }).then((doc:any) => {
        this.zone.run(() => {
          this.userCompany = doc.company;
          let googleProfile = this.googleService.getProfileInfo();
          if(googleProfile !== null &&  googleProfile !== undefined){
              this.accountImage = googleProfile.getImageUrl();
          }else{
              this.accountImage = doc.photoUrl;
          }
          
          if(this.accountImage !== doc.photoUrl)
            return this.firestoreService.updateUserProfilePhotoURL(this.accountImage);
        });
      }).then((doc:any) => {
        this.logger.info("user profile photo URL is updated successfully ")
      }).catch((err) => {
        this.logger.error("fetchUserDetails failed");
      })
    }else{
      this.logger.debug("firebase User is Null")
    }
  }

}
