<div class="mainLoginScreen">
    <div class="companyLogoBlock">
        <mat-card class="matCardBlocks" style="background-color: #313133;">
            <mat-card-content>
                <div class="ACOLogoBlock">
                    <img src="../../../../assets/images/aco_logo.png" class="ACOLogo">
                </div>
                <div class="avayaStippedLogoBlock">
                    <img src="../../../../assets/images/avaya-sketch.png" class="avayaStippedLogo">
                </div>
            </mat-card-content>
        </mat-card>
    </div>

    <div class="loginBlock">
        <div style="height: 100%;width: 100%;">
            <div class="background_blur">
            </div>
            <mat-card class="matCardBlocks blurOpacityBlock">
                <mat-card-content class="loginContent">

                    <span class="loginText">
                        Welcome to ACO Migration Tool 
                    </span>
                    <span class="loginText">
                        sign in to get started
                    </span>
                    <!-- <img src="../../../../assets/images/btn_google_signin_dark_pressed_web.svg" id="googleBtn"
                        (click)="doLogin()"> -->

                    <!-- <div id="g_id_onload"
                    data-client_id="442340770447-b9l7th4gsh3jl1d58tgvhv7ghianurbo.apps.googleusercontent.com"
                    data-callback="handleCredentialResponse">
                    </div> -->
                    <!-- <div class="g_id_signin" data-type="standard" data-theme="filled_blue" id="googleBtn"></div> -->
                    <div id="googleBtn"></div>
                </mat-card-content>
            </mat-card>
        </div>
    </div>

</div>