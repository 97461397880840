<div class="contentBlock migrationDataBlock">
    <div class="contentInfo">
        <button class="closeBtn" mat-icon-button matTooltip="close" (click)="closeMigrationActivity()">
            <mat-icon style=" font-size: 0.8vw;line-height: 1vw;">close</mat-icon>
        </button>
        <div class="migrationStepInfo">
            <span class="migrationStep">Step 6/6 : Data Migration</span>
            <span class="migrationCompany">{{companyName}}</span>
        </div>
    </div>
    <div class="contentMain">
        <div class="migrationSummary" *ngIf="!showFailureData">
            <p class="migrationInfo" *ngIf="migrationStatus === status.NOTAVAILABLE && !ifDisplayDelInfo()">{{migrationInfo}}</p>
            <p class="migrationInfo migrationSuccessStatus" *ngIf="!ifDisplayDelInfo() && migrationStatus === status.SUCCESSFUL">
                {{migration_strings.SUCCESSFUL}}
                <!-- <a (click)="getSummary()">{{migration_strings.MORE_OPTIONS}}</a> -->
            </p>
            <p class="migrationInfo migrationErrorStatus" *ngIf="!ifDisplayDelInfo() && migrationStatus === status.FAILURE && isMarkAsComplete">{{migration_strings.FAILURE}}
                <!-- <a (click)="getSummary()">{{migration_strings.MORE_OPTIONS}}</a> -->
            </p>
            <p class="migrationInfo migrationErrorStatus" *ngIf="!ifDisplayDelInfo() && migrationStatus === status.FAILURE && !isMarkAsComplete">{{migration_strings.FAILURE_RETRY}}
                <!-- <a (click)="getSummary()">{{migration_strings.MORE_OPTIONS}}</a> -->
            </p>
            <p class="migrationInfo migrationErrorStatus" *ngIf="migrationStatus === status.TASK_ABORTED">
                {{migration_strings.TASK_ABORTED}}
            </p>
            <p class="migrationInfo" *ngIf="ifDisplayDelInfo() && deletionStatus === status.NOTAVAILABLE">{{deletionInfo}}</p>
            <p class="migrationInfo" *ngIf="ifDisplayDelInfo() && deletionStatus === status.SUCCESSFUL">{{deletion_strings.SUCCESSFUL}}</p>
            <p class="migrationInfo migrationErrorStatus" *ngIf="ifDisplayDelInfo() && deletionStatus === status.FAILURE">{{deletion_strings.FAILURE}}
            </p>
            <p class="migrationInfo migrationErrorStatus" *ngIf="ifDisplayDelInfo() && deletionStatus === status.TASK_ABORTED">
                {{deletion_strings.TASK_ABORTED}}
            </p>

            <span *ngIf="!ifDisplayDelInfo()">
            <mat-progress-bar class="progressBar" mode="determinate" value={{migrationProgress}}></mat-progress-bar>

            <p class="migrationProgressPercentage">
                {{migrationProgress}}% completed
            </p>
           </span>

           <span *ngIf="ifDisplayDelInfo()">
            <mat-progress-bar class="progressBar" mode="determinate" value={{deletionProgress}}></mat-progress-bar>

            <p class="migrationProgressPercentage">
                {{deletionProgress}}% completed
            </p>
            </span>
            
            <button class="tableButton" (click)="downloadExcel()" matTooltip="{{preConfigToolTip}}"
                *ngIf="displayStartBtn()">
                Check Config
            </button>
            <button class="tableButton" (click)="showFeature()" *ngIf="displaySelect()">
                Select Features
            </button>
            <span matTooltip="{{starMigrationTooltipText}}">
                <button id="startMigration" class="tableButton" (click)="startMigration('start')"
                    [disabled]="isMigrationStarted || errorsFound" *ngIf="displayStartBtn()">
                    Start Migration
                </button>
            </span>
            <button class="tableButton" (click)="close()" 
            *ngIf=" migrationStatus === status.SUCCESSFUL" >
                Close
            </button>
            <button class="tableButton" (click)="loginToACOPortal()"
                *ngIf="displayLoginPortalBtn()">Login to ACO Portal</button>

            <button class="tableButton" (click)="startMigration('retry')" [disabled]="isMigrationStarted "
                *ngIf="displayRetryBtn()">Retry</button>
            <button class="tableButton" (click)="markAsComplete()" [disabled]="isMigrationStarted"
                *ngIf="displayMarkAsCompleteBtn()">Mark
                as Complete</button>

            <span matTooltip="{{startDeletionTooltipText}}">
                <button id="configDeletion" class="tableButton" (click)="startDeletion('start')"
                    [disabled]="isDeletionStarted"   
                    *ngIf="displayDeleteBtn()">
                    Clear ACO Config
                </button>
            </span>

            <div class="summaryBlock" *ngIf="showDeletionSummary">
                <div class="migrationStatusIcon" style="background-color: white; border-top-left-radius:1.3vw;border-bottom-left-radius: 1.3vw; height: 100%;width: 7%;">
                    <img src="../../../../assets/images/migration_status/Icon_Green_check_mark.svg" *ngIf="deletionStatus === status.SUCCESSFUL">
                    <img src="../../../../assets/images/migration_status/Icon_user_migration_data_missing.svg"  *ngIf="deletionStatus === status.FAILURE">
                </div>
                <div class="migrationStatusSummary" style="background-color: white;margin-left: 0.6%; border-top-right-radius:1.3vw;border-bottom-right-radius: 1.3vw; height: 100%;width: 92.4%;">
                    <div style="display: flex;flex-direction: column;justify-content: center;margin-left: 1%;">
                        <span class="migrationStatusText" *ngIf="deletionStatus === status.SUCCESSFUL">{{deletion_strings.SUCCESSFUL}}</span>
                        <span class="migrationStatusText" *ngIf="deletionStatus === status.FAILURE">{{deletion_strings.FAILURE}}</span>
                        <span  class="migrationDurationText" *ngIf="showDeletionSummary">Duration : {{deletionStatusDocData.duration}} Minutes</span>
                    </div>
                    <a (click)="triggerDownloadDelReport()">Download Deletion Report</a>
                </div>
            </div>            

            <div class="summaryBlock" *ngIf="showMigrationSummary">
                <div class="migrationStatusIcon" style="background-color: white; border-top-left-radius:1.3vw;border-bottom-left-radius: 1.3vw; height: 100%;width: 7%;">
                    <img src="../../../../assets/images/migration_status/Icon_Green_check_mark.svg" *ngIf="migrationStatus === status.SUCCESSFUL && !isMarkAsComplete">
                    <img src="../../../../assets/images/migration_status/Icon_Green_check_mark_with_warning.svg" *ngIf="isMarkAsComplete">
                    <img src="../../../../assets/images/migration_status/Icon_user_migration_data_missing.svg"  *ngIf="migrationStatus === status.FAILURE && !isMarkAsComplete">
                </div>
                <div class="migrationStatusSummary" style="background-color: white;margin-left: 0.6%; border-top-right-radius:1.3vw;border-bottom-right-radius: 1.3vw; height: 100%;width: 92.4%;">
                    <div style="display: flex;flex-direction: column;justify-content: center;margin-left: 1%;">
                        <span class="migrationStatusText" *ngIf="migrationStatus === status.SUCCESSFUL && !isMarkAsComplete"> {{migration_strings.SUCCESSFUL}}</span>
                        <span class="migrationStatusText" *ngIf="migrationStatus === status.FAILURE  && !isMarkAsComplete">{{migration_strings.FAILURE}}</span>
                        <span class="migrationStatusText" *ngIf="isMarkAsComplete">{{migration_strings.COMPLETED_BY_USER}}</span>
                        <span  class="migrationDurationText" *ngIf="showMigrationSummary">Duration : {{migrationStatusDocData.duration}} Minutes</span>
                    </div>
                    <a (click)="triggerDownloadReport()">Download Report</a>
                    <a (click)="triggerNotificationDownloadReport()">Download Notification Report</a>
                </div>
            </div>

            
            <!-- <div style="position: absolute; opacity: 0; pointer-events:none;width: 70%;height: 0%!important;overflow: hidden;" *ngIf="showMigrationSummary && !isMorrisonProject">
                <div id="migrationStatusReport" style="width: 70%;margin-top: 0.5vw;">
                    <br><br><br><br>
                    <div style="display: flex;flex-direction: row;justify-content: center;align-content: center;">
                        <span style="justify-self: center;align-self: center; font-size: 1.8vw;">Migration Report</span>
                    </div>
                    <br>
                    // Basic migration details like Company name , migration status , migration end time
                    <div class="downloadReportFont_0_9vw downloadReportMargin_0_9vw" style="text-align: left;display: flex;flex-direction: column;">
                        <span class="downloadReportMargin_0_7vw">Company Name : {{companyName}}</span>
                        <span class="downloadReportMargin_0_7vw" *ngIf="migrationStatus === status.SUCCESSFUL">Migration Status : {{migration_strings.SUCCESSFUL}}</span>
                        <span class="downloadReportMargin_0_7vw" *ngIf="migrationStatus === status.FAILURE">Migration Status : {{migration_strings.FAILURE}}</span>
                        <span class="downloadReportMargin_0_7vw">Migration Completed on : {{migrationStatusDocData.endTime}}</span>
                    </div>
                    <br>
                    //This Div will list all the feature executed for this migration
                    <div style="border-top: 1px solid black;display: flex;flex-direction: column;justify-content: left;align-items: center;">
                        <span style="font-size: 1.4vw;margin-top: 1.8vw;margin-bottom: 1vw;">Migration Summary</span>
                        <span class="downloadReportFont_0_9vw downloadReportMargin_0_9vw" *ngIf="migrationSummaryData.total > 0 && migrationSummaryData.failure === 0">Assign Users : Successful</span>
                        <span class="downloadReportFont_0_9vw downloadReportMargin_0_9vw" *ngIf="migrationSummaryData.total > 0 && migrationSummaryData.failure > 0">Assign Users : Failed</span>
                        <span *ngFor="let featureItem of featureDocData" class="downloadReportFont_0_9vw downloadReportMargin_0_9vw">{{featureItem.name}} : {{featureItem.status}}</span>
                    </div>
                    // Adding Assign Users feature as separate div since its not part of feature status Doc
                    <div style="display: flex;flex-direction: column;align-items: flex-start;border-bottom: 1px solid black;">
                        <span class="downloadReportFont_1vw" style="margin-top:1.6vw;">AssignUsers</span>
                        <span class="downloadReportFont_0_9vw downloadReportMargin_1vw" *ngIf="migrationSummaryData.total > 0 && migrationSummaryData.failure === 0">Status : Success</span>
                        <span class="downloadReportFont_0_9vw downloadReportMargin_1vw" *ngIf="migrationSummaryData.total > 0 && migrationSummaryData.failure > 0">Status : Failed</span>
                        <span class="downloadReportFont_0_9vw downloadReportMargin_0_9vw">Total : {{migrationSummaryData.total}}</span>
                        <span class="downloadReportFont_0_9vw downloadReportMargin_0_9vw">Successfully migrated : {{(migrationSummaryData.total - migrationSummaryData.failure)}}</span>
                        <span class="downloadReportFont_0_9vw downloadReportMargin_0_9vw">Failed to migrate : {{migrationSummaryData.failure}}</span>
                        <span class="downloadReportFont_1vw downloadReportMargin_0_9vw" *ngIf="failureUserData.length !== 0">Failures</span>
                        <span class="downloadReportFont_1vw downloadReportMargin_0_9vw" *ngFor="let failureItem of failureUserData">
                        <span class="downloadReportFont_0_9vw downloadReportMargin_0_9vw" >Extension :{{failureItem.extn}} , Fail reason : {{failureItem.message}}</span>
                    //<span class="downloadReportFont_0_9vw downloadReportMargin_0_9vw" >{{failureItem}}</span>
                        </span>
                    <br>
                    <br>
                    </div>
                    //Adding all feature as one div , all data is available in feature status DOc 
                    <div *ngFor="let featureItem of featureDocData">
                        <div style="display: flex;flex-direction: column;align-items: flex-start;border-bottom: 1px solid black;">
                            <span class="downloadReportFont_1vw" style="margin-top:1.6vw;">{{featureItem.name}}</span>
                            <span class="downloadReportFont_0_9vw downloadReportMargin_1vw">Status : {{featureItem.status}}</span>
                            <span class="downloadReportFont_0_9vw downloadReportMargin_0_9vw">Total : {{featureItem.total}}</span>
                            <span class="downloadReportFont_0_9vw downloadReportMargin_0_9vw">Successfully migrated : {{(featureItem.total - featureItem.totalFailed)}}</span>
                            <span class="downloadReportFont_0_9vw downloadReportMargin_0_9vw">Failed to migrate : {{featureItem.totalFailed}}</span>
                            <span class="downloadReportFont_1vw downloadReportMargin_0_9vw" *ngIf="featureItem.failedDesc.length !== 0">Failures</span>
                            <span class="downloadReportFont_1vw downloadReportMargin_0_9vw" *ngFor="let failureItem of featureItem.failedDesc">
                            //<span class="downloadReportFont_0_9vw downloadReportMargin_0_9vw"  *ngIf="failureItem.extn">{{failureItem.extn}} : {{failureItem.error.data.message}}</span>
                            <span class="downloadReportFont_0_9vw downloadReportMargin_0_9vw" *ngIf="failureItem.extId">{{failureItem.extId}} : {{failureItem.error.data.message}}</span>
                            <span class="downloadReportFont_0_9vw downloadReportMargin_0_9vw" >{{failureItem}}</span>
                            </span>
                            <br>
                            <br>
                        </div>
                    </div>
                    <br>
                </div> 
                <div class="tabInfo"> 
                    <div class="NotificationsLabel">                       
                        <div id="notificationsReport" style="width: 70%;margin-top: 0.5vw;">
                            <br><br><br><br>
                            <div style="display: flex;flex-direction: row;justify-content: center;align-content: center;">
                                <span style="justify-self: center;align-self: center; font-size: 1.8vw;">Notifications Report</span>
                            </div>
                            <br>
                            <div class="downloadReportFont_0_9vw" style="text-align: left;display: flex;flex-direction: column">
                                <span class="downloadReportMargin_0_7vw">Company Name : {{companyName}}</span>
                                <span class="downloadReportMargin_0_7vw">Report Downloaded on : {{migrationStatusDocData.endTime}}<hr></span>
                                <br>
                                <br>
                            </div>
                            <div style="display: flex;flex-direction: row;justify-content: center;align-content: center">
                                <span class = "mainTitles">System Summary Warnings</span>
                            </div>
                            <div>
                                <ul>
                                    <span *ngFor="let row of systemSummaryWarning" class="content"><li>{{row}}</li><br></span>                          
                                </ul>
                                <hr>
                                <br>
                            </div>
                            <div style="display: flex;flex-direction: row;justify-content: center;align-content: center;">
                                <span class = "mainTitles">User Data Notifications</span>
                            </div>
                    <div>
                        <div class = "featureTitles">
                            <span class="titles"><u>Basic Information</u></span>
                            <span *ngIf="basicInfoError.length !== 0" class="errors">Errors</span>
                            <ul>
                            <span *ngFor="let row of basicInfoError" class="content"><li>{{row}}</li><br></span>                          
                            </ul>
                            <span *ngIf="basicInfoWarnings.length !== 0" class="warnings">Warnings</span>
                            <ul>
                            <span *ngFor="let row of basicInfoWarnings" class="content"><li>{{row}}</li><br></span>
                            </ul>
                            </div>
                            <div class = "featureTitles">
                                <span class="titles"><u>Advanced Information</u></span>
                                <span *ngIf="advInfoWarnings.length !== 0" class="warnings">Warnings</span>
                                <ul>
                                <span *ngFor="let row of advInfoWarnings" class="content"><li>{{row}}</li><br></span>
                                </ul>  
                            </div>
                            <div style="display: flex;flex-direction: row;justify-content: center;align-content: center;">
                                <span *ngIf="aaWarnings.length !== 0 && huntGroupWarnings.length !== 0 && pagingGroupWarnings.length && tpWarnings.length !== 0 && icrWarnings.length !== 0" class="mainTitles">System Data Warnings</span>
                            </div>
                            <div *ngIf="huntGroupWarnings.length !== 0" class = "featureTitles">
                                <span *ngIf="huntGroupWarnings.length !== 0" class="titles"><u>Call Queues</u></span>
                                <span *ngIf="huntGroupWarnings.length !== 0" class="warnings">Warnings</span>
                                <ul>
                                <span *ngFor="let row of huntGroupWarnings"class="content"><li>{{row}}</li><br></span>
                                </ul>  
                            </div>
                            <div *ngIf="pagingGroupWarnings.length !== 0" class = "featureTitles">
                                <span *ngIf="pagingGroupWarnings.length !== 0"class="titles"><u>Paging Groups</u></span>
                                <span *ngIf="pagingGroupWarnings.length !== 0" class="warnings">Warnings</span>
                                <ul>
                                <span *ngFor="let row of pagingGroupWarnings" class="content"><li>{{row}}</li><br></span>
                                </ul>  
                            </div>
                            <div *ngIf="tpWarnings.length !== 0"  class = "featureTitles">
                                <span *ngIf="tpWarnings.length !== 0" class="titles"><u>Working Hours</u></span>
                                <span *ngIf="tpWarnings.length !== 0" class="warnings">Warnings</span>
                                <ul>
                                <span *ngFor="let row of tpWarnings"  class="content"><li>{{row}}</li><br></span>
                            </ul>  
                            </div>
                            <div  *ngIf="aaWarnings.length !== 0" class = "featureTitles">
                                <span *ngIf="aaWarnings.length !== 0" class="titles"><u>Auto-Receptionists</u></span>
                                <span *ngIf="aaWarnings.length !== 0" class="warnings">Warnings</span>
                                <ul>
                                <span *ngFor="let row of aaWarnings" class="content"><li>{{row}}</li><br></span>
                                </ul>  
                            </div>
                            <div  *ngIf="icrWarnings.length !== 0" class = "featureTitles">
                                <span *ngIf="icrWarnings.length !== 0" class="titles"><u>Company Numbers</u></span>
                                <span *ngIf="icrWarnings.length !== 0" class="warnings">Warnings</span>
                                <ul>
                                <span *ngFor="let row of icrWarnings" class="content"><li>{{row}}</li><br></span>
                                </ul>  
                            </div>
                        </div>          
                    </div>
                        </div>
                    </div>    
                </div>
            </div> -->
            <div class="featureList" id="selectFeatureList" *ngIf= "showFeatureList">
                <div class="featureListCheckBoxBlock">
                    <mat-checkbox [(ngModel)]="featureListValue.USERS.value" [disabled]="true" id="userChkbx"
                        (change)="selectFeatures($event.checked, featureName.USERS)">{{featureList.USERS}}
                    </mat-checkbox>
                    <mat-checkbox [(ngModel)]="featureListValue.USER_CONTACTS.value" [disabled]="featureListValue.USER_CONTACTS.notAvailableInCSV"
                        (change)="selectFeatures($event.checked, featureName.USER_CONTACTS)">
                        {{featureList.USER_CONTACTS}}</mat-checkbox>
                    <mat-checkbox [(ngModel)]="featureListValue.USER_BHOURS.value" [disabled]="featureListValue.USER_BHOURS.notAvailableInCSV"
                        (change)="selectFeatures($event.checked, featureName.USER_BHOURS)">{{featureList.USER_BHOURS}}
                    </mat-checkbox>
                    <mat-checkbox [(ngModel)]="featureListValue.USER_BLF.value" [disabled]="featureListValue.USER_BLF.notAvailableInCSV"
                        (change)="selectFeatures($event.checked, featureName.USER_BLF)">{{featureList.USER_BLF}}
                    </mat-checkbox>
                    <mat-checkbox [(ngModel)]="featureListValue.USER_VM_GREETINGS.value" [disabled]="featureListValue.USER_VM_GREETINGS.notAvailableInCSV"
                        (change)="selectFeatures($event.checked, featureName.USER_VM_WORKHOURS)">{{featureList.USER_VM_GREETINGS}}
                    </mat-checkbox>
                    <mat-checkbox [(ngModel)]="featureListValue.CALL_FORWARD.value" [disabled]="featureListValue.CALL_FORWARD.notAvailableInCSV"
                        (change)="selectFeatures($event.checked, featureName.CALL_FORWARD)">{{featureList.CALL_FORWARD}}
                    </mat-checkbox>
                    <mat-checkbox [(ngModel)]="featureListValue.HUNT_GROUP.value" [disabled]="featureListValue.HUNT_GROUP.notAvailableInCSV"
                        (change)="selectFeatures($event.checked, featureName.HUNT_GROUP)">{{featureList.HUNT_GROUP}}
                    </mat-checkbox>
                    <mat-checkbox [(ngModel)]="featureListValue.PAGING_GROUP.value" [disabled]="featureListValue.PAGING_GROUP.notAvailableInCSV"
                        (change)="selectFeatures($event.checked, featureName.PAGING_GROUP)">{{featureList.PAGING_GROUP}}
                    </mat-checkbox>
                    <mat-checkbox [(ngModel)]="featureListValue.PARK_LOCATION.value" [disabled]="featureListValue.PARK_LOCATION.notAvailableInCSV"
                        (change)="selectFeatures($event.checked, featureName.PARK_LOCATION)">{{featureList.PARK_LOCATION}}
                    </mat-checkbox>
                    <mat-checkbox [(ngModel)]="featureListValue.AUTO_ATTENDANT.value" [disabled]="featureListValue.AUTO_ATTENDANT.notAvailableInCSV"
                        (change)="selectFeatures($event.checked, featureName.AUTO_ATTENDANT)">{{featureList.AUTO_ATTENDANT}}
                    </mat-checkbox>
                    <mat-checkbox [(ngModel)]="featureListValue.COMPANY_RULES.value" [disabled]="featureListValue.COMPANY_RULES.notAvailableInCSV"
                        (change)="selectFeatures($event.checked, featureName.COMPANY_RULES)">{{featureList.COMPANY_RULES}}
                    </mat-checkbox>
                    <mat-checkbox [(ngModel)]="featureListValue.AUTO_ATTENDANT_PROMPTS.value" [disabled]="featureListValue.AUTO_ATTENDANT_PROMPTS.notAvailableInCSV"
                        (change)="selectFeatures($event.checked, featureName.AUTO_ATTENDANT_PROMPTS)">{{featureList.AUTO_ATTENDANT_PROMPTS}}
                    </mat-checkbox>
                    <mat-checkbox [(ngModel)]="featureListValue.COMPLEX_IVR_PROMPTS.value" [disabled]="featureListValue.COMPLEX_IVR_PROMPTS.notAvailableInCSV"
                        (change)="selectFeatures($event.checked, featureName.COMPLEX_IVR_PROMPTS)">{{featureList.COMPLEX_IVR_PROMPTS}}
                    </mat-checkbox>     
                    <mat-checkbox [(ngModel)]="featureListValue.ZERO_DIAL.value" [disabled]="featureListValue.ZERO_DIAL.notAvailableInCSV"
                        (change)="selectFeatures($event.checked, featureName.ZERO_DIAL)">{{featureList.ZERO_DIAL}}
                    </mat-checkbox> 
                    <mat-checkbox [(ngModel)]="featureListValue.MAC_UPDATE.value" [disabled]="featureListValue.MAC_UPDATE.notAvailableInCSV" 
                        (change)="selectFeatures($event.checked, featureName.MAC_UPDATE)">{{featureList.MAC_UPDATE}}
                    </mat-checkbox>
                </div>
                <div class="featureBtnBlock">
                    <button class="configtableButton" (click)="cancelFeaturePopup()">Cancel</button>
                    <button class="configtableButton" (click)="featuresSelected()">Ok</button>
                </div>     
            </div> 
            <div id="errorWarningBlock" class="errorWarningBlock" *ngIf=showFailureData>
                <div style="width: 100%;height: 88%; overflow-x: hidden;overflow-y: auto;">
                    <table style="width: 90%;max-height: 100%;margin-left: 5%;">
                        <tr class="errorWarningRow" *ngFor="let item of failureUserData">
                            <td>
                                Extension :{{item.extn}} , Fail reason : {{item.message}}
                            </td>
                        </tr>
                    </table>
                </div>
                <button class="tableButton " style="margin-top: 1%;" (click)="closeErrorWarningBlock()">
                    Close
                </button>
            </div>
    </div>
    <div class="navigation" *ngIf="displayBackArrow()">
        <button mat-button class="arrowBack" matSuffix mat-icon-button (click)="navigateBack()">
            <mat-icon>arrow_back</mat-icon>
        </button>
    </div>
</div>