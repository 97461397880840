<div class="appBody" >
    <mat-toolbar id="appBar">
        <img src="../../../../assets/images/branding/cloud-ring-central-4color.svg"  alt="" class="appIcon" (click)="navigateToHome()">
        <a routerLink="dashboard" routerLinkActive="active" *ngIf="isAuthorized">Dashboard</a>
        <a routerLink="migration" routerLinkActive="active" *ngIf="isAuthorized">Migration</a>
        <a routerLink="faq" routerLinkActive="active">FAQ</a>
        <a routerLink="help" routerLinkActive="active">Help</a>
    
        <div class="accountsBlock">
            <img [src]=accountImage  class="accountImage" (click)=select.open()>
            <mat-select 
                #select
                class="langSelect">
                <mat-option value="option" (click)="viewprofile()" style="font-size: 1vw;"> <img src="../../../../assets/images/account/profile.svg" class="accountIcons">Profile</mat-option>
                <mat-option value="option" (click)="openAdminPage()" style="font-size: 1vw;" *ngIf="isAdminRole"><img src="../../../../assets/images/account/Admin.svg" class="accountIcons">Admin</mat-option>
                <mat-option value="option" (click)="about()" style="font-size: 1vw;"><img src="../../../../assets/images/account/about.svg" class="accountIcons">About</mat-option>
                <mat-option value="option" (click)="doLogout()" style="font-size: 1vw;"><img src="../../../../assets/images/account/logout.svg" class="accountIcons">Logout</mat-option>
            </mat-select>
            <div style="height:100%;margin-left: 0.5vw;display: flex ;flex-direction: column;justify-content: center;align-items: flex-start;">
                <label class="userName" [style]="(userCompany!='')?'':'height:auto;margin-top:-6%'">{{userName}}</label>
                <label  class="userCompany">{{userCompany}}</label>
            </div>
        </div>

    </mat-toolbar>
    <router-outlet></router-outlet>
</div>
