import { MigrationDataService } from 'src/app/shared/services/migrationDataService/migration-data.service';
import { TablePreviewService, autoAssignName, autoAssignPhone, AutoAssignmentConfig, phoneStatus, SEVERITY, PAGINATION_SIZE } from './../../../shared/services/tablePreview/table-preview.service';
import { FirestoreService, TRIGGER_STATUS} from './../../../shared/services/firestore/firestore.service';
import { Component, OnInit , NgZone, ViewChild, ElementRef, ChangeDetectorRef} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import Tabulator from 'tabulator-tables';
import { OverlayService } from 'src/app/shared/services/overlay/overlay.service';
import { MatSpinnerOverlayComponent } from 'src/app/shared/components/mat-spinner-overlay/mat-spinner-overlay.component';
import { LogService } from 'src/app/shared/services/logging/log.service';
import { SessionHandlerService } from 'src/app/shared/services/sessionHandler/session-handler.service';
import { CanComponentDeactivate } from 'src/app/shared/services/deactivate-guard/can-deactivate-guard.service';
import { Observable} from 'rxjs';
import { ConfirmationDialogComponent } from 'src/app/shared/components/dialog/confirmation-dialog/confirmation-dialog.component';
import { RefreshService } from '../../../shared/services/refresh/refresh.service';
import { FireAuthService } from 'src/app/shared/services/firebaseAuth/fire-auth.service';


export enum autoAssignTypes {
    No_Op,
    Name,
    Phone,
    Email,
    ClearConfig
};

const footer_labels = {
    total :"Total",
    displaying:"Displaying",
    selected:"Selected"
}

@Component({
  selector: 'app-table-preview',
  templateUrl: './table-preview.component.html',
  styleUrls: ['./table-preview.component.css']
})

export class TablePreviewComponent implements OnInit, CanComponentDeactivate {
    selected = 'option2';
    id:any;   
    columnNames: any[] = [];
    migrationPreviewTable: Tabulator;
    showAutoConfigSettings = false;
    showNames = false;
    showPhoneModels = false;
    showTableSettings = true;
    showErrorWarningBlock = false;
    showIPOPhoneType= true;
    showIPOUserType = false;
    showIPOSysmonType = false;
    showGroupBySysmon = false;
    readonly TIMER_INTERVAL = 60000;
    readonly TABLE_PROMPT_MSG = 'Are you sure you want to close current Migration?';
    readonly TABLE_DATA_UNSAVED_PROMPT_MSG = 'Closing the current migration will discard changes in the \
                table.\nAre you sure you want to close current migration?';
    readonly TABLE_DATA_SAVE_FAILED_MSG = 'Changes made in the table could not be saved at this moment.\n';
    readonly CLOSE_MIGRATION_MSG = 'Do you still want to close current migration or wait for sometime and retry?';
    readonly CLOSE_TABLE_MSG = 'Do you still want to close the table view or wait for sometime and retry?';
    notifications = [];
    //dropdown option data binding
    ipoPhoneModelsList:any;
    ipoUsersProfileList:any;
    acoPhoneModelsList:any;
    //drop down options
    acoPhoneModelOption:any;
    ipoPhoneModelOption:any;
    ipoUserProfileOption:any;
    groupOption:any;
    nameOption:any;
    searchOption = "Entire Table";
    searchInput:any;
    autoAssignMethods = autoAssignTypes;
    //assignName enum from service
    assignNameEnum = autoAssignName;
    assignPhoneEnum = autoAssignPhone;
    ClearConfigOptions = AutoAssignmentConfig;

    errorWarningMessage:string;
    allowEdit:boolean = false;
    emailGroupValid:boolean=true;

    //new logic
    showAutoConfigOption = "";

    emailList:string = "";

    @ViewChild('distributnList') distributnListRef:ElementRef;

    //checkbox value;
    clearName=false;
    clearPhone=false;
    clearEmail=false;
    clearAll=false;

    assignedPhoneModelHistory = [];
    clearConfigOptionsChecked = [];

    private _isRowSelected : boolean = false;
    public get isRowSelected() : boolean {
        return this._isRowSelected;
    }
    public set isRowSelected(v : boolean) {
        this._isRowSelected = v;
        if(this._isRowSelected){
            document.getElementById("deleteUserBtn").classList.remove("btn_disabled");
        }else{
            document.getElementById("deleteUserBtn").classList.add("btn_disabled");
        }
    }

    private _isUsersDeleted: boolean = false;

    public get isUsersDeleted(): boolean {
        return this._isUsersDeleted;
    }
    public set isUsersDeleted(v: boolean) {
        this._isUsersDeleted = v;
        if (this._isUsersDeleted) {
            document.getElementById("undoUserBtn").classList.remove("btn_disabled");
        } else {
            document.getElementById("undoUserBtn").classList.add("btn_disabled");
        }
    }
 
    public errorWarningList = [];
    public errorcount = 0;
    SEVERITY = SEVERITY;
    public companyName: string;
    private isReadyToClose: boolean = false;
    private _enableRefreshData: boolean = false;
    public get enableRefreshData(): boolean {
        return this._enableRefreshData;
    }
    public set enableRefreshData(value: boolean) {
        this._enableRefreshData = value;
        if(document.getElementsByClassName("mat-badge-content").length !== 0){
            if(this._enableRefreshData){
                document.getElementsByClassName("mat-badge-content")[0].classList.remove("warningBadge");
            }else{
                document.getElementsByClassName("mat-badge-content")[0].classList.add("warningBadge");
            }
        }
    }

    constructor(private tablePreviewService:TablePreviewService,
        private router: Router ,private fireauthService:FireAuthService,
        public zone: NgZone, public dialog: MatDialog,
        private migrationDataService:MigrationDataService,
        private firestoreService: FirestoreService,
        public _Activatedroute:ActivatedRoute, private session:SessionHandlerService,private RefreshService:RefreshService,
        private overlay:OverlayService, private logger:LogService,private cdr: ChangeDetectorRef) {
            this.logger.debug("TablePreviewComponet constructor ");
            this.searchOption = "Entire Table";
            this.id = this.session.cardId;
            this.allowEdit = true;
            this.isReadyToClose = false;
            this.tablePreviewService.getCardClosureStatus().subscribe((data:boolean)=>{
                this.isReadyToClose = data;
            });
            this.companyName = this.session.companyName;
            if(this.id == null){
                this.logger.warn("CardId does not exist - route to dashboard");
                this.zone.run(() => { 
                    this.isReadyToClose = true;
                    this.router.navigate(['/home/migration']); 
                });
                return;
            } 
            this.enableRefreshData = false;  
        }
    canDeactivate():boolean | Observable<boolean> | Promise<boolean>{
        if(this.isReadyToClose)
         return true;
        const dialogRef = this.dialog.open(ConfirmationDialogComponent,{
            data:{
              message: this.TABLE_DATA_UNSAVED_PROMPT_MSG,
              buttonText: {
                ok: 'Ok',
                cancel: 'Cancel'
              }
            }
          });
        return dialogRef.afterClosed();
    }

    timeupCallbackfunc(ol){
        //this.logger.debug("Time up");
        ol.showSpinner = false;
        ol.closeSpinner();
    }

    selectUser = (e, cell) => {
        let element = cell.getElement();
        let chkbox = element.querySelector('.select-row');
        let selectAllRow = document.querySelector('.select-all-row') as HTMLInputElement;
        if (cell.getData().isSelected) {
          cell.getRow().deselect();
          selectAllRow.checked = false;
        } else {
          cell.getRow().select(); 
          let displayRows = cell.getRow().getTable().rowManager.displayRows[1];
          let selectedRowCountInCurrPg  = displayRows.filter((row:any)=>{return row.getData().isSelected === true}).length;
         //Excluding the selected row
          if(selectedRowCountInCurrPg === displayRows.length - 1){
            selectAllRow.checked = true;
          }
        }
        chkbox.checked = !cell.getData().isSelected;
        cell.getData().isSelected = !cell.getData().isSelected;
        let totalSelectedRows = cell.getRow().getTable().getSelectedRows().length;
        let el = document.getElementById("selectedRowsCount");
        if(totalSelectedRows > 0){
            el.innerHTML = 'Selected : '+ totalSelectedRows;
            this.isRowSelected = true;
        }else{
            el.innerHTML = '';
            this.isRowSelected = false;
        }
      }

    selectUnselectAll = (e, column) => {
        let displayRows = column.getTable().rowManager.displayRows[1], isSelected ;
        let selectedRowCountInCurrPg  = displayRows.filter((row:any)=>{return row.getData().isSelected === true}).length;
        if (selectedRowCountInCurrPg !== displayRows.length && displayRows.length !== 0) {
          document.querySelectorAll('.select-row,.select-all-row').forEach((cb:any) => cb.checked = true);
          column.getTable().selectRow(displayRows);
          isSelected = true;
        } else {
          document.querySelectorAll('.select-row,.select-all-row').forEach((cb:any) => cb.checked = false);
          column.getTable().deselectRow(displayRows);
          isSelected = false;
        }
        displayRows.forEach((row:any) => {
            row.getData().isSelected = isSelected;
        });
        let totalSelectedRows = column.getTable().getSelectedRows().length;
        let el = document.getElementById("selectedRowsCount");
        if(totalSelectedRows > 0){
            el.innerHTML = 'Selected : '+ totalSelectedRows;
            this.isRowSelected = true;
        }else{
            el.innerHTML = '';
            this.isRowSelected = false;
        }
    }

     ngOnInit(): void {
        this.logger.debug("tablePreview: ngOnInit");
        this.columnNames = [
            { title:"<input type='checkbox' class='select-all-row' aria-label='select all rows'>" , field:"isSelected", formatter: function(cell, formatterParams, onRendered) {
                let isSelected = cell.getValue();
                if(isSelected)
                    cell.getTable().selectRow(cell.getRow());
                return '<input type="checkbox" class="select-row" aria-label="select this row" '+(isSelected?'checked':'')+'/>';
            },headerSort: false,headerFilter: false,cssClass: 'text-center',tooltips: false,resizable: false,width:"2%",
            cellClick: this.selectUser,headerClick: this.selectUnselectAll},            
            { title: "User Name", field: "username" , headerSort:true, sorter:"alphanum" },
            { title: "First Name ", field: "firstName", editor:"input" , headerSort:true, sorter:"alphanum" ,  editable:this.editCheck.bind(this)},
            { title: "Last Name ", field: "lastName", editor:"input" , headerSort:true, sorter:"alphanum" ,  editable:this.editCheck.bind(this)},
            { title: "Email ", field: "email",  headerSort:true, 
                formatter:(cell, formatterParams)=>{
                    var value = cell.getValue();
                    var found = this.tablePreviewService.assignedEmailList.includes(value);
                    if(!found && value){
                        found = !(this.tablePreviewService.isEmailIdUnique(value));//find if mail id is duplicate
                    };
                    if(this.validateEmailEntry(cell,value,formatterParams) && found !== true)                   
                    {
                           return value;
                    }
                    else
                    {
                        return "<span class='highlight-bg'>" + value + "</span>";
                    }
                }, 
            //     validator:[
            //     {
            //         type:this.validateEmailEntry.bind(this),
            //         parameters:{  }
            //     }
            // ],
             sorter:"alphanum", 
                editor:"autocomplete", editorParams:{
                    values:true,
                    showListOnEmpty:true, //show all values when the list is empty,
                    freetext:true, //allow the user to set the value of the cell to a free text entry
                    allowEmpty:true, //allow empty string values
                    searchFunc:this.changeEmailAssignment.bind(this)
                },  editable:this.editCheck.bind(this)
            },
            { title: "Extension Number", field: "extension" , headerSort:true, 
            formatter:(cell, formatterParams)=>{
                var value = cell.getValue();
                var res = (this.tablePreviewService.assignedExtnList.includes(JSON.stringify(value)) || this.tablePreviewService.reservedExtensionsList.includes(value));
                if( res !== true )
                {
                       return value;
                }
                else
                {
                    return "<span class='highlight-bg'>" + value + "</span>";
                }
           }, 
            validator:["required","unique"], sorter:"number" },
            { title: "Site Name", field: "siteName", headerSort:true, sorter:"alphanum"},
            { title: "PhoneType", field: "displayPhoneModel", editor:"select", headerSort:true, 
                editorParams: this.phoneModelSelection.bind(this),
                cellEdited: this.changePhoneAssignment.bind(this),
                sorter:"alphanum" ,  editable:this.editCheck.bind(this)},
            { title: "Number(DID)", field: "did" , headerSort:true, sorter:"alphanum" },
            { title: "Serial Number", field: "macId", headerSort:true, sorter:"alphanum" },
          ]; 
    }

    ngAfterViewInit(){
        this.overlay.openSpinner(this.TIMER_INTERVAL,this.timeupCallbackfunc,MatSpinnerOverlayComponent);
        this.tablePreviewService.initializeTableData(this.id)
        .then(()=>{
            this.showGroupBySysmon = this.tablePreviewService.isSysmonFileUploaded;
            this.tablePreviewService.informTableInitialization();
            return this.firestoreService.getUserEmailData(this.id)
        })
        .then((data:string) =>  {
            var emailData ;
            try{
                emailData = JSON.parse(data);
            }catch(error){
                emailData = data;
            }
            if(emailData && emailData !== undefined){
                this.tablePreviewService.autoAssignEmail(emailData,false)
            }
        })
        .then(() => {
            // reference id of div where table is to be displayed (prepend #)
            this.migrationPreviewTable = new Tabulator("#tabulator-div", {	layout:"fitColumns",      //fit columns to width of table
                data:this.tablePreviewService.tableUserList,
                maxHeight:"100%",
                autoResize:false,
                resizableColumns:false,
                resizableRows:false, 
                responsiveLayout:'hide',  //hide columns that dont fit on the table
                tooltips:true,            //show tool tips on cells
                addRowPos:"top",          //when adding a new row, add it to the top of the table
                history:true,             //allow undo and redo actions on the table
                pagination:"local",       //paginate the data
                paginationSize:PAGINATION_SIZE,
                movableColumns:false, 
                footerElement:this.getFooterElement(),
                dataFiltered: this.updateFooterOnFiltered,
                pageLoaded:this.pageLoaded,
                dataEdited:this.validateTableData.bind(this),
                //groupBy: "siteName"        //allow row order to be changed            
            });
            this.migrationPreviewTable.setColumns(this.columnNames);
            this.migrationPreviewTable.setData(this.tablePreviewService.tableUserList);
           // this.migrationPreviewTable.setGroupBy("siteName");
            this.migrationPreviewTable.setSort("username","asc");
            this.fetchErrorWarningData();
            this.enableDisableUndoLabel();
            this.firestoreService.getACOSummaryData(this.id)
            .then((result:any) => {
                if(result.isRefreshed === true)
                {
                    this.doACOPrerequisiteInfo(true);
                    let isRefreshed = false;
                    this.firestoreService.setACOSummaryData(this.id,result.data,isRefreshed);
                }
            })
            this.ipoPhoneModelsList = this.tablePreviewService.ipoUsersByPhoneModel;
            this.ipoUsersProfileList = this.tablePreviewService.ipoUsersByProfile;
            this.acoPhoneModelsList = this.tablePreviewService.acoExtnByPhoneGroup;
            this.overlay.closeSpinner();
        })
        .catch((error) => {
            this.logger.debug(error)
            this.overlay.closeSpinner();
        });
        window.addEventListener('beforeunload', (event) => {
            event.preventDefault();
            event.returnValue = '';
        });
    }

    ngOnDestroy() {
        window.removeEventListener('beforeunload' , (event) => {});
    }   

    closeErrorWarningBlock(){
       this.displayTable(); 
    }

    closeMigrationActivity(){
        this.session.alertModal(this.onClose.bind(this), this.TABLE_PROMPT_MSG);
    }

    onClose() {
        this.logger.debug("TablePreview - Close current Migration Activity");        
        this.tablePreviewService.saveTableData(this.id)
        .then((res) => {            
            this.logger.debug("table data saved");
            this.migrationDataService.clearData();
            this.zone.run(() => { 
                this.isReadyToClose = true;
                this.router.navigate(['/home/migration']);
            });
        }).catch((error) => {
            this.logger.debug("saving table data failed. ",error);
            this.session.alertModal(()=>{
                this.zone.run(() => { 
                    this.isReadyToClose = true;
                    this.router.navigate(['/home/migration']);
                });
            }, 
                this.TABLE_DATA_SAVE_FAILED_MSG + this.CLOSE_MIGRATION_MSG,'Close','Retry');
        });
    }

    getFooterElement(){
        return '<span style="float:left;" class="customFooter" id="totalRowsCount">Total : '+this.tablePreviewService.tableUserList.length+'</span>'+
         '<span style="float:left;margin-left:2%;" class="customFooter" id="displayingRowsCount"></span>'+
         '<span style="float:left;margin-left:2%;" class="customFooter" id="selectedRowsCount"></span>';
    }

    pageLoaded = (pageNum=0) =>{
        let displayRows = [], totalSelectedRows = 0;
        if(this.migrationPreviewTable ){
            let el = document.getElementById("displayingRowsCount");
            el.innerHTML = 'Displaying : '+ this.migrationPreviewTable.rowManager.displayRowsCount;
            
            displayRows = this.migrationPreviewTable.rowManager.displayRows[1];
            totalSelectedRows = this.migrationPreviewTable.getSelectedRows().length;
            
            let selectedRowCountInCurrPg  = displayRows.filter((row:any)=>{return row.getData().isSelected === true}).length;
            let selectedAllRows = document.querySelector('.select-all-row') as HTMLInputElement;
            if(selectedRowCountInCurrPg !== 0 && selectedRowCountInCurrPg === displayRows.length){
                selectedAllRows.checked = true;
            }else{
                selectedAllRows.checked = false;
            }
        }
        let el = document.getElementById("selectedRowsCount");
        if(totalSelectedRows > 0){
            el.innerHTML = 'Selected : '+ totalSelectedRows;
            this.isRowSelected = true;
        }else{
            el.innerHTML = '';
            this.isRowSelected = false;
        }
    }

    updateFooterOnFiltered = (filters, rows)=>{
        var el = document.getElementById("displayingRowsCount");
        if(filters != 0){
            var displayRowsCount = this.migrationPreviewTable.rowManager.displayRowsCount;
            var selectedCount = displayRowsCount < PAGINATION_SIZE ? displayRowsCount : PAGINATION_SIZE;
            el.innerHTML = 'Displaying : '+ selectedCount;
        }else{
            if(this.migrationPreviewTable){
                el.innerHTML = 'Displaying : '+ this.migrationPreviewTable.rowManager.displayRowsCount;
            }
        }
        if(this.migrationPreviewTable){
            let displayRows = this.migrationPreviewTable.rowManager.displayRows[1];
            let selectedRowCountInCurrPg  = displayRows.filter((row:any)=>{return row.getData().isSelected === true}).length;
            let selectedAllRows = document.querySelector('.select-all-row') as HTMLInputElement;
            if(selectedRowCountInCurrPg === displayRows.length && displayRows.length !== 0){
                selectedAllRows.checked = true;
            }else{
                selectedAllRows.checked = false;
            }
            let totalSelectedRows = this.tablePreviewService.tableUserList.filter((user:any)=>{return user.isSelected === true}).length;//this.migrationPreviewTable.getSelectedRows().length;
            let el = document.getElementById("selectedRowsCount");
            if(totalSelectedRows > 0){
                el.innerHTML = 'Selected : '+ totalSelectedRows;
                this.isRowSelected = true;
            }else{
                el.innerHTML = '';
                this.isRowSelected = false;
            }
        }
    }

    getErrorWarningList(){
        this.displayErrorWarningBlock();
    }

    displayErrorWarningBlock(){
        document.getElementById("tabulator-div").style.display = "none";
        document.getElementById("tableEditData").style.display = "none";
        this.showAutoConfigSettings = false;
        this.showErrorWarningBlock = true;
    }

    private validateTableData(){
        this.tablePreviewService.validateTableData();
        this.fetchErrorWarningData();
        this.migrationPreviewTable.replaceData(this.tablePreviewService.tableUserList);
    };

    private editCheck(cell){
        //cell - the cell component for the editable cell
        return this.allowEdit;
    }

    fetchErrorWarningData(){
        this.errorcount = 0;
        let errorFlag = false;
        this.errorWarningList = []; 
        let tempErrorList = Array.from(this.tablePreviewService.notificationsList);
        tempErrorList.forEach((site) =>{
            if(site[1].length > 0)
            this.errorWarningList.push(site);
        })
        this.errorWarningList.forEach((site) =>{
            if(site[0] === "")
                site[0] = "MainSite";
            this.errorcount += site[1].length;
            if(site[1].filter(entry=>entry.severity == SEVERITY.ERROR).length !== 0)
                errorFlag = true;
        })    
         if(!errorFlag){
            document.getElementsByClassName("mat-badge-content")[0].classList.add("warningBadge");
        }
        else{
            document.getElementsByClassName("mat-badge-content")[0].classList.remove("warningBadge");
        }
       
    }

    updateFilter(){
        if(this.searchInput !== undefined){
            var filterVal = this.searchOption;
            var typeVal = "like";
            if(filterVal){
                if(filterVal == "Entire Table"){
                    //TODO: We need to setfilter for all fields fields
                    this.migrationPreviewTable.setFilter([[
                        {field:"username", type:typeVal, value:this.searchInput}, 
                        {field:"firstName", type:typeVal, value:this.searchInput}, 
                        {field:"lastName", type:typeVal, value:this.searchInput},
                        {field:"email", type:typeVal, value:this.searchInput}, 
                        {field:"extension", type:typeVal, value:this.searchInput},
                        {field:"phone", type:typeVal, value:this.searchInput},
                        {field:"did", type:typeVal, value:this.searchInput},
                        {field:"macId", type:typeVal, value:this.searchInput},
                        {field:"siteName", type:typeVal, value:this.searchInput}
                    ]]);
                }else{
                    this.migrationPreviewTable.setFilter(filterVal, typeVal, this.searchInput);
                }
            }
        }
      }

    onGroupChange(event){
         switch (event.value){
            case this.assignPhoneEnum.PhoneType:
                this.showIPOPhoneType= true;
                this.showIPOUserType = false;
                this.showIPOSysmonType = false;
                break;            
            case this.assignPhoneEnum.UserType:
                this.showIPOPhoneType= false;
                this.showIPOUserType = true;
                this.showIPOSysmonType = false;
                break;
            case this.assignPhoneEnum.SysmonType:
                this.showIPOPhoneType= false;
                this.showIPOUserType = false;
                this.showIPOSysmonType = true;
                break;
            default:
                this.logger.debug("Invalid choice passed: ", event); 
        }
    }

    private phoneModelSelection(data){
        var phones = [];
        var cellValue = data._cell.value;
        var extension = data._cell.row.data.extension;
        if(cellValue === 'unassigned' || cellValue === 'unassign'){
            var phoneGroup = this.tablePreviewService.acoExtnByPhoneGroup;
            phoneGroup.forEach(element => {
                //this.logger.debug("PhoneModel: ", element.phoneModel, " count: ", element.extensionList.length);
                if(element.extensionList.length > 0){
                    phones.push(element.phoneModel);
                }                
            });
            if(this.tablePreviewService._originalMsgOnlyExtns.includes(JSON.stringify(extension)))
                phones.push('message-only');
        }
        else if(cellValue === "message-only"){
            var phoneGroup = this.tablePreviewService.acoExtnByPhoneGroup;
            phoneGroup.forEach(element => {
                //this.logger.debug("PhoneModel: ", element.phoneModel, " count: ", element.extensionList.length);
                if(element.extensionList.length > 0){
                    phones.push(element.phoneModel);
                }
            });
        }
        else{
            phones.push('unassign');
            if(this.tablePreviewService._originalMsgOnlyExtns.includes(JSON.stringify(extension)))
                phones.push('message-only');
        }

        return {values:phones};
    }

    private changePhoneAssignment(data){
        var cellValue = data._cell.value;
        var extension = data._cell.row.data.extension;
        this.logger.debug("changePhoneAssignmentTo: ", cellValue, " extension: ", extension);
        if(cellValue === 'unassign'){
           if (this.tablePreviewService.didNotConfiguredTwinList().includes(extension)) {
               let index = this.tablePreviewService.didNotConfiguredTwinList().indexOf(extension);
               this.tablePreviewService.didNotConfiguredTwinList().splice(index, 1);
           }
            this.tablePreviewService.removeAssignedPhone(extension)
            .then(()=>{
                this.migrationPreviewTable.replaceData(this.tablePreviewService.tableUserList)               
                this.fetchErrorWarningData();
            });
        }
        else if(cellValue === "message-only"){
            this.tablePreviewService.reAssignMessageOnly(extension)
            .then(()=>{
                this.migrationPreviewTable.replaceData(this.tablePreviewService.tableUserList)               
                this.fetchErrorWarningData();
            });
        }
        else{
            var extension = data._cell.row.data.extension;
            this.tablePreviewService.assignNewPhone(extension, cellValue)
            .then(()=> {
                this.migrationPreviewTable.replaceData(this.tablePreviewService.tableUserList)
               // this.fetchErrorWarningData();
                this.validateTableData();
            });    
        }
    }

    public changeEmailAssignment(term, values){
        var emailGroup = this.tablePreviewService.emailGroup;
        var matchedEntries = {};
        var matchFound = false;
        for(var key in emailGroup) {
            //value - one of the values from the value property
            let _term = term,_mailGroup = emailGroup[key];
            //To have case insensitive comparison for the names
            if(key && key != _mailGroup){
                _term = term.toUpperCase(),
                _mailGroup = emailGroup[key].toUpperCase();  
            }
            if(key.includes(term) || _mailGroup.includes(_term)){
                matchFound = true;
                matchedEntries[key] = emailGroup[key];
            }
        };

        //ACOS-99 : Auto Assign Email drop down to show only unassigned email options in the list
        for(var val of values){
            if(matchedEntries[val] != null && val != term){
                delete matchedEntries[val];
            }
        }
         
        if(matchFound){
            return matchedEntries;
        }
        else{
            var matchedEntries = {}
            matchedEntries[term] = term;
            return matchedEntries;
        }
    }

    private validateEmailEntry(cell, value, parameters){
        return this.tablePreviewService.validateEmailEntry(value);        
    }

    public autoAssignName() {
        this.logger.debug("autoAssignName: ", this.nameOption);
        this.tablePreviewService.autoAssignFirstAndLastName(this.nameOption)
        .then((res) =>{
            this.fetchErrorWarningData();
            this.migrationPreviewTable.replaceData(this.tablePreviewService.tableUserList);
        }).catch((err) => {

        });    
    }

    private autoAssignEmail(emailList){
        this.logger.debug("autoAssignEmail");
        let patt = /\*@\w+([\.-]?\w+)*(\.\w{2,})+[;,]*/g;
        let matchedEntry = emailList.match(patt);
        let assignDummyEmails =(matchedEntry !== null && matchedEntry.length >= 1)?true:false;
        if(assignDummyEmails){            
            emailList = emailList.replace(patt,"");
        }
        this.emailList = emailList.replace(new RegExp(/</g,'g'),'\&lt');
        var promise = new Promise((resolve,reject)=>{  
            if( this.tablePreviewService.autoAssignEmail(emailList,true)){
                this.emailGroupValid=true;
                this.firestoreService.setUserEmailData(this.id , emailList).then((res)=>{
                    this.logger.debug("Email data stored successfully");
                    if(assignDummyEmails){
                        this.tablePreviewService.addDummyEmailIds(matchedEntry[0]);
                        this.validateTableData();
                    }
                    resolve();
                }).catch((error) => {
                    this.logger.error("Error setting email data");
                    resolve();
                })
            } else{
                this.emailGroupValid=false;
                for(let key of this.tablePreviewService.invalidEmailList){
                    const matchMentions = new RegExp(key.replace(/[.*+\-?^${}()|[\]\\]/g, '\\$&'),'g');
                    this.emailList = this.emailList.replace(matchMentions,'<span class="highlight-bg">'+key+'</span>');        
                }
                reject();
            }
        });
        return promise;
    }

    public autoAssignPhone(phoneModelObj) {
        this.logger.debug("autoAssignByPhoneModel");
        //TODO: should be a common ngModel for both the select options 
        //else move under a swith case , options may increase
        if(phoneModelObj[0].groupOption == this.assignPhoneEnum.SysmonType && phoneModelObj[0].groupType == "Group by Sysmon Model") {
            //this.tablePreviewService._sysmonAutoAssignmentFlag = false;
            this.tablePreviewService._isSysmonDataInitializedFlag = false;
            this.overlay.openSpinner(this.TIMER_INTERVAL,this.timeupCallbackfunc,MatSpinnerOverlayComponent);
            this.firestoreService.readSysmonData(this.id)
            .then((sysmonData:any) => {
                sysmonData.trigger = TRIGGER_STATUS.START;
                this.tablePreviewService.autoAssignSysmonPhoneModel(sysmonData)
                .then((res) => {
                    this.tablePreviewService.informSysmonInitiaization();
                    this.fetchErrorWarningData();
                    this.migrationPreviewTable.replaceData(this.tablePreviewService.tableUserList);
                    this.overlay.closeSpinner();
                    this.displayTable();
                });
            });
        }
        else if(phoneModelObj.length != 0 && phoneModelObj[0].count != 0){
            this.tablePreviewService.autoAssignPhone(phoneModelObj[0].groupOption, phoneModelObj[0].ipoModelOption, phoneModelObj[0].acoModelOption, phoneModelObj[0].count)
            .then((res) =>{
                this.fetchErrorWarningData();
                this.migrationPreviewTable.replaceData(this.tablePreviewService.tableUserList);
            }).catch((err) => {
                this.logger.error(err);
            })  
        }else{
            //TODO: error with no phone models selected
            this.logger.debug("Phone model count is 0");
            this.migrationPreviewTable.replaceData(this.tablePreviewService.tableUserList);
        }
    }

    /* public autoAssignProfile(){
        this.logger.debug("autoAssignByProfileType");
        var mapCount = 2;
        this.tablePreviewService.autoAssignPhone(this.groupOption, "PowerUserPackage", "RingCentral Phone desktop app", mapCount)
        .then((res) =>{
            this.fetchErrorWarningData();
            this.migrationPreviewTable.setData(this.tablePreviewService.tableUserList);
            this.logger.debug("Table Users list: ", this.tablePreviewService.tableUserList);
            this.logger.debug("_ipoUsersByPhone: ", this.tablePreviewService.ipoUsersByPhoneModel);
            this.logger.debug("acoExtsByPhone: ", this.tablePreviewService.acoExtnByPhoneGroup);
            this.logger.debug("_ipoUsersByProfile: ", this.tablePreviewService.ipoUsersByProfile);
        }).catch((err) => {

        });
    } */

    public clearConfiguration(){
        this.logger.debug("clearConfiguration");
        //TODO: check this array length before sending
        //error modal/msg if no options is selected/array is empty
        //send the clearConfigOptionsChecked object as parameter sog clearConfiguration
        //this.tablePreviewService.clearConfiguration(this.clearConfigOptionsChecked)
        
        if(this.clearConfigOptionsChecked.length == 0){
            this.logger.debug("Options to clear auto configuration is not selected")
            return;
        }

        this.tablePreviewService.clearConfiguration(this.clearConfigOptionsChecked)
        .then((res) =>{
            this.ipoPhoneModelsList = this.tablePreviewService.ipoUsersByPhoneModel;
            this.ipoUsersProfileList = this.tablePreviewService.ipoUsersByProfile;
            this.acoPhoneModelsList = this.tablePreviewService.acoExtnByPhoneGroup;
            this.emailList = this.tablePreviewService.emailGroup;
            this.emailGroupValid = (this.tablePreviewService.invalidEmailList.length)?false:true;
            this.fetchErrorWarningData();
            this.migrationPreviewTable.replaceData(this.tablePreviewService.tableUserList);
            //clear the array clearConfigOptionsChecked after promise resolve or reject
            //also make all the checkbox ngModel to false to that previous data is not ,maintainded for next UI render
            this.clearConfigurationHistory();
        }).catch((err) => {
            this.migrationPreviewTable.replaceData(this.tablePreviewService.tableUserList);
        });
    }

    clearConfigurationHistory(){
        this.clearConfigOptionsChecked = [];
        this.clearName=false;
        this.clearPhone=false;
        this.clearEmail=false;
        this.clearAll=false;
    }

    public removeAssignedPhone(){
        this.logger.debug("remove assigned phone");
        this.tablePreviewService.removeAssignedPhone(4001)
        .then((res) =>{
            this.fetchErrorWarningData();
            this.migrationPreviewTable.replaceData(this.tablePreviewService.tableUserList);
        }).catch((err) => {
        });
    }


    displayTable=()=>{
        this.showTableSettings =true;
        this.showErrorWarningBlock = false;
        document.getElementById("tabulator-div").style.display = "block";
        document.getElementById("tableEditData").style.display = "inline-block";
        this.migrationPreviewTable.replaceData(this.tablePreviewService.tableUserList);
    }

    displayDelUsrModal(){
        const dialogRef = this.dialog.open(ConfirmationDialogComponent,{
            data:{
              message: this.migrationPreviewTable.getSelectedRows().length + ' out of '+
                       this.migrationPreviewTable.getDataCount() +
                       ' users are going to be deleted. All references to the user(s) will be removed. This operation cannot be undone. Do you want to continue?',
              buttonText: {
                ok: 'Confirm',
                cancel: 'Cancel'
              },
              isWarning: true
            }
          });
      
          dialogRef.afterClosed().subscribe((confirmed: boolean) => {
            if(confirmed){
              this.deleteUsers();
            }
          });
    }

    deleteUsers(){
        let deleteUsersList = this.migrationPreviewTable.getSelectedRows().map((row)=>{
            return row.getData();
        });

        deleteUsersList.forEach(async (user:any) => {
            let extension = user.extension;
            if(user.acoPhoneStatus === phoneStatus.assigned && user.phone !== "message-only"){
                await this.tablePreviewService.removeAssignedPhone(extension,false)
            }  
            if (this.tablePreviewService.didNotConfiguredTwinList().includes(extension)) {
                let index = this.tablePreviewService.didNotConfiguredTwinList().indexOf(extension);
                this.tablePreviewService.didNotConfiguredTwinList().splice(index, 1);
            }   
        });
        var delList = deleteUsersList.filter((user:any)=>(user.acoPhoneStatus === phoneStatus.unassigned || user.phone === "message-only")).map((user:any)=>{return user.extension});
        this.cleanUpTableData(delList);
    }

    undoUsers(){
        this.tablePreviewService.undoDelUsers().then(() => {
            this.migrationPreviewTable.replaceData(this.tablePreviewService.tableUserList);
            this.ipoPhoneModelsList = this.tablePreviewService.ipoUsersByPhoneModel;
            this.ipoUsersProfileList = this.tablePreviewService.ipoUsersByProfile;
            this.tablePreviewService.validateTableData();
            this.fetchErrorWarningData();
            this.updateFooterLabels();
            this.enableDisableUndoLabel();
        });
    }

    cleanUpTableData = (delList) =>{
        this.tablePreviewService.removeUsersFromTable(delList).then(()=>{
            this.migrationPreviewTable.replaceData(this.tablePreviewService.tableUserList);
            this.ipoPhoneModelsList = this.tablePreviewService.ipoUsersByPhoneModel;
            this.ipoUsersProfileList = this.tablePreviewService.ipoUsersByProfile;
            //Unselect the checkbox in the header after the users are deleted
            let selectAllRow = document.querySelector('.select-all-row') as HTMLInputElement;
            selectAllRow.checked = false;
            this.tablePreviewService.validateTableData();
            this.fetchErrorWarningData();
            this.updateFooterLabels();
            this.enableDisableUndoLabel();
        })        
    }

    enableDisableUndoLabel = () => {
        this.isUsersDeleted = this.tablePreviewService.idx > 0;
    }

    updateFooterLabels = ()=>{
        document.getElementById("totalRowsCount").innerHTML = footer_labels.total + " : " + this.tablePreviewService.tableUserList.length.toString();
        document.getElementById("displayingRowsCount").innerHTML = footer_labels.displaying + " : " + this.migrationPreviewTable.rowManager.displayRowsCount;
        let selectedRowsCount = this.migrationPreviewTable.getSelectedRows().length;
        if(selectedRowsCount > 0){
            document.getElementById("selectedRowsCount").innerHTML = footer_labels.selected + " : " + selectedRowsCount.toString();
        }
    }

    editDone(){
        this.overlay.openSpinner(this.TIMER_INTERVAL,this.timeupCallbackfunc,MatSpinnerOverlayComponent);
        this.tablePreviewService.tableUserList.forEach((user:any)=>{
            user.isSelected = false;
        });
        
        this.tablePreviewService.updateSysmonData()
        .then((sysmonres)=> {
            this.tablePreviewService.saveTableData(this.id)
            .then((res) => {
                this.logger.debug("table data saved");
                this.tablePreviewService.saveParkLocationData(this.id)
                .then((res) => {
                    this.logger.debug("park Location data saved");
                    this.tablePreviewService.updateHGnPGWrapUpTime()
                })
                //.then((res) => {
                this.tablePreviewService.saveHGnPGTableData(this.id)
                .then((res)=>{
                    this.logger.debug("Call Queues table data saved");
                })
                .catch((error) => {
                    this.logger.debug("saving call queues and paging group table data failed. ",error);
                });
                // });
                this.overlay.closeSpinner();
                this.zone.run(() => { 
                    this.isReadyToClose = true;
                    this.router.navigate(['/home/migration/config-summary/user-data']); 
                });
            }).catch((error) => {
                this.logger.debug("saving table data failed. ",error);
                this.overlay.closeSpinner();
                this.session.alertModal(this.onCloseRedirectToSummaryPg.bind(this), 
                    this.TABLE_DATA_SAVE_FAILED_MSG + this.CLOSE_TABLE_MSG ,'Close','Retry');
            });
        })
    }
    onCloseRedirectToSummaryPg(){
        this.zone.run(() => { 
            this.isReadyToClose = true;
            this.router.navigate(['/home/migration/config-summary/user-data']); 
        });
    }

    autoConfigCancel(){
        this.logger.debug("assignAutoFill"); 
        this.displayTable();
        this.showAutoConfigSettings = false;
        switch (this.showAutoConfigOption){
            case "showAssignNames":
                
                break;            
            case "showPhoneModels":
                this.assignedPhoneModelHistory.forEach((element , index) => {
                    this.removeAutoAssignedPhoneModels(element , index);
                });
                this.assignedPhoneModelHistory = [];
                this.migrationPreviewTable.replaceData(this.tablePreviewService.tableUserList);
                break;
            case "showEmail":

                break;            
            case "showClearConfig":
                //clear previous selected checkbox for clear config option
                this.clearConfigurationHistory();
                break;
            default:
                this.logger.debug("Invalid choice passed: ", this.showAutoConfigOption); 
        } 
    }

    assignAutoFill(){
        this.logger.debug("autoConfigDone : "+this.nameOption);

        switch (this.showAutoConfigOption){
            case "showAssignNames":
                this.displayTable();
                this.showAutoConfigSettings = false;
                this.autoAssignName();
                break;            
            case "showPhoneModels":
                this.displayTable();
                this.showAutoConfigSettings = false;
                this.autoAssignPhone(this.getPhoneModelAssignmentObject());
                this.assignedPhoneModelHistory = [];
                break;
            case "showEmail":
                var mailList = this.distributnListRef.nativeElement.textContent;
                if(mailList.trim() !== "" && mailList !== null){
                    this.overlay.openSpinner(this.TIMER_INTERVAL,this.timeupCallbackfunc,MatSpinnerOverlayComponent);
                    this.autoAssignEmail(mailList).then(()=>{
                        this.displayTable();
                        this.showAutoConfigSettings = false;
                        this.migrationPreviewTable.replaceData(this.tablePreviewService.tableUserList);
                    }).catch((err)=>{
                    })
                    .finally(()=>{
                        this.overlay.closeSpinner();
                    })
                }else{
                    this.displayTable();
                    this.showAutoConfigSettings = false;
                }
                break;            
            case "showClearConfig":
                this.displayTable();
                this.showAutoConfigSettings = false;
                this.clearConfiguration();
                break;
            default:
                this.displayTable();
                this.showAutoConfigSettings = false;
                this.logger.debug("Invalid choice passed: ", this.showAutoConfigOption);
        } 
        //this.logger.debug("Table Users list: ", this.tablePreviewService.tableUserList);
    }

    assignName(){
        this.logger.debug("assignName");
        this.showAutoConfigView(); 
        this.showAutoConfigOption = "showAssignNames"; 
    }

    assignPhoneModels(){
        this.logger.debug("assignPhoneModels");
        this.showAutoConfigView();
        this.showAutoConfigOption = "showPhoneModels";
    }

    assignEmail(){
        this.logger.debug("assignEmail");
        this.showAutoConfigView();
        this.showAutoConfigOption = "showEmail";
    }

    clearConfig(){
        this.logger.debug("clearConfig");
        this.showAutoConfigView();
        this.showAutoConfigOption = "showClearConfig"; 
    }

    showAutoConfigView(){
        this.showAutoConfigSettings = true;
        document.getElementById("tabulator-div").style.display = "none";
        document.getElementById("tableEditData").style.display = "none";
    }

    addPhoneModel(){

       /* let ipoModelOption:any , groupType:string , assignedCount:number;
        if(this.groupOption == this.assignPhoneEnum.UserType){
            ipoModelOption = this.ipoUserProfileOption;
            groupType="Group by License";
        }else if(this.groupOption == this.assignPhoneEnum.PhoneType){
            ipoModelOption = this.ipoPhoneModelOption;
            groupType="Group by Phone Model";
        } */
        let phoneModelObject =[];
        phoneModelObject = this.getPhoneModelAssignmentObject();
        if(phoneModelObject.length && phoneModelObject[0].count != 0){
            this.assignedPhoneModelHistory.push({
                group:phoneModelObject[0].groupType,
                ipoModelType: phoneModelObject[0].ipoModelOption,
                acoModelType:phoneModelObject[0].acoModelOption,
                count: phoneModelObject[0].count
            });
            this.logger.debug("addPhoneModel: ", phoneModelObject);
            this.autoAssignPhone(phoneModelObject);
        }
    }

    removeAutoAssignedPhoneModels(item , index){
        this.logger.debug(item);
        this.tablePreviewService.removeAutoAssignedPhones(item.ipoModelType , item.acoModelType , item.count)
        .then(() => {
            this.assignedPhoneModelHistory.splice(index,1);
            this.migrationPreviewTable.replaceData(this.tablePreviewService.tableUserList);
        }).catch(()=>{

        });
    }

    clearConfigOptionsChangeEvent(checked , option){
        if(checked){
           this.clearConfigOptionsChecked.push(option) ;
        }else{
            if(this.clearConfigOptionsChecked.indexOf(option) !== -1){
                this.clearConfigOptionsChecked.splice(this.clearConfigOptionsChecked.indexOf(option) , 1);
            }
        }
    }

    getPhoneModelAssignmentObject(){
        let ipoModelCount:number = 0, acoModelCount:number = 0 ,ipoModelOption:string,  groupType:string , tempList = [];
        if(this.groupOption == this.assignPhoneEnum.UserType){
            ipoModelOption = this.ipoUserProfileOption;
            groupType="Group by License";
            this.ipoUsersProfileList.forEach(element => {
                if(element.profileType == ipoModelOption){
                    ipoModelCount = element.count;
                }
            });
        }else if(this.groupOption == this.assignPhoneEnum.PhoneType){
            groupType="Group by Phone Model";
            ipoModelOption = this.ipoPhoneModelOption;
            this.ipoPhoneModelsList.forEach(element => {
                if(element.phoneModel == ipoModelOption){
                    ipoModelCount = element.count;
                }
            });
        }else if(this.groupOption == this.assignPhoneEnum.SysmonType){
            groupType="Group by Sysmon Model";
            ipoModelOption = "";
        }
        this.acoPhoneModelsList.forEach(element => {
            if(element.phoneModel == this.acoPhoneModelOption){
                acoModelCount = element.count;
            }
        });

        tempList.push({
            groupOption: this.groupOption,
            groupType: groupType,
            ipoModelOption: ipoModelOption,
            acoModelOption : this.acoPhoneModelOption,
            count: Math.min(ipoModelCount , acoModelCount)
        });

        //return (Math.min(ipoModelCount , acoModelCount));
        return tempList;

    }
    prerequisiteFetchFailure(reason){
        var msg = "Unable to fetch ACO prerequisite data - " + reason;
        this.notifications.push({msg: msg, severity: SEVERITY.ERROR});
        this.enableRefreshData = true;
    }

  doACOPrerequisiteInfo(refresh=false){
    this.logger.debug("doACOPrerequisiteInfo");
    if(refresh){
        this.overlay.openSpinner(this.TIMER_INTERVAL,this.timeupCallbackfunc,MatSpinnerOverlayComponent);
        this.notifications = [];
        this.enableRefreshData = false;
    }
    this.RefreshService.fetchpreRequisiteData(this.id)
        .then(() =>{
            this.checkAcoImportStatus();
        })
        .catch((error)=>{
            this.logger.error(error);
            return;
        })
    }
    checkAcoImportStatus(){
    this.firestoreService.checkAcoImportStatus(this.id)
    .then((curdata:any) => {  
        this.logger.debug("checkAcoImportStatus "+curdata.desc);   
        if(curdata.status == 0){
            this.tablePreviewService.fetchrefreshData(this.id)
            .then(() =>
                {   
                    this.tablePreviewService.validateTableData();
                    this.fetchErrorWarningData();
                    this.migrationPreviewTable.setData(this.tablePreviewService.tableUserList);
                    this.ipoPhoneModelsList = this.tablePreviewService.ipoUsersByPhoneModel;
                    this.ipoUsersProfileList = this.tablePreviewService.ipoUsersByProfile;
                    this.acoPhoneModelsList = this.tablePreviewService.acoExtnByPhoneGroup;
                    this.acoPhoneModelreAssignmentforRefreshData();
                    this.overlay.closeSpinner();
                });
         }
        else if(curdata.status == 1){
            this.notifications.push({msg: curdata.desc, severity: SEVERITY.ERROR});
            this.enableRefreshData = true;
            this.overlay.closeSpinner();
        }      
    }).catch((err) => {
        this.overlay.closeSpinner();
    });
}

acoPhoneModelreAssignmentforRefreshData()
{
    this.logger.info("acoPhoneModelreAssignmentforRefreshData");
    var unassignedExtnList = [];
    var removedExtn = [];
    this.tablePreviewService.tableUserList.forEach(extension => {
        if(extension.extensionId !== undefined && !this.tablePreviewService.acoImportData.extns_by_phone.includes(extension.extensionId))
        {
            unassignedExtnList.push(extension.extension);
            removedExtn.push(extension.extensionId);
            var promise = new Promise((resolve, reject) => {
                this.tablePreviewService.removeAssignedPhone(extension.extension)
                .then(()=>{
                    this.migrationPreviewTable.replaceData(this.tablePreviewService.tableUserList);
                    this.tablePreviewService.acoExtnByPhoneGroup.forEach(element => {
                        element.extensionList = element.extensionList.filter(val => !removedExtn.includes(val));
                        element.count = element.extensionList.length
                    }); 
                    this.acoPhoneModelsList = this.tablePreviewService.acoExtnByPhoneGroup;  
                    this.tablePreviewService.validateTableData();
                    this.fetchErrorWarningData();
                });
            })
            //return promise;
        }
    })
    this.overlay.closeSpinner(); 
}
}