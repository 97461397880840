import { ignoreElements, map } from 'rxjs/operators';
import { resolve } from 'dns';
import { IPOUserData } from 'src/app/shared/services/parser/datainterface';
import { Result } from 'src/app/shared/services/firestore/firestore.service';
import { logging, error, element } from 'protractor';
import { MigrationDataService } from './../../../shared/services/migrationDataService/migration-data.service';
import { ErrorData } from './../../../shared/services/parser/datainterface';
import { LogService } from 'src/app/shared/services/logging/log.service';
import { Component, OnInit, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { SessionHandlerService } from 'src/app/shared/services/sessionHandler/session-handler.service';
import { Papa } from 'ngx-papaparse';
const resolves = {}
const rejects = {}

export enum SEVERITY{
  WARNING,
  ERROR
}

//Locale-language-location map used to determine language code and location using locale configured under site in IP Office
const LOCALE_LANGUAGE_LOCATION_MAP = [
  {"localeCode":"des","languageCode":1031,"locationCode":206,},
  {"localeCode":"deu","languageCode":1031,"locationCode":83,},
  {"localeCode":"enu","languageCode":1033,"locationCode":1},
  {"localeCode":"fin","languageCode":1035,"locationCode":74},
  {"localeCode":"frs","languageCode":1036,"locationCode":206},
  {"localeCode":"fro","languageCode":1036,"locationCode":75},
  {"localeCode":"fra","languageCode":1036,"locationCode":75},
  {"localeCode":"frb","languageCode":1036,"locationCode":23},
  {"localeCode":"ita","languageCode":1040,"locationCode":109},
  {"localeCode":"its","languageCode":1040,"locationCode":206},
  {"localeCode":"jpn","languageCode":1041,"locationCode":112},
  {"localeCode":"kor","languageCode":1042},//No 1-1 mapping for korea, since it contains north and south Korea
  {"localeCode":"nlb","languageCode":1043,"locationCode":23},
  {"localeCode":"nld","languageCode":1043,"locationCode":157},
  {"localeCode":"ptb","languageCode":2070,"locationCode":31},
  {"localeCode":"ptg","languageCode":2070,"locationCode":177},
  {"localeCode":"eng","languageCode":2057,"locationCode":224},
  {"localeCode":"esr","languageCode":22538,"locationCode":174},
  {"localeCode":"esm","languageCode":22538,"locationCode":143},
  {"localeCode":"eso","languageCode":22538,"locationCode":49},
  {"localeCode":"esl","languageCode":22538,"locationCode":45},
  {"localeCode":"ess","languageCode":22538,"locationCode":11},
  {"localeCode":"ena","languageCode":3081,"locationCode":15},
  {"localeCode":"esp","languageCode":3082,"locationCode":195},
  {"localeCode":"frc","languageCode":3084,"locationCode":39}
];

@Component({
  selector: 'app-upload-csv',
  templateUrl: './upload-csv.component.html',
  styleUrls: ['./upload-csv.component.css']
})
export class UploadCSVComponent implements OnInit {
  csvFile: any;
  csvfilename: any;
  subscription: Subscription;
  isParseFailed = false;
  errorMsg:string;
  syntaxErrorMsg:string;
  showErrorModal = false;
  parserWorker: Worker;
  morrisonWorker: Worker;
  public companyName:string = "";
  isMorrison = false;
  globalMsgId = 0;
  parsedOutput = [];promises = new Set(); failedFileName = [];
  mergedCSVDatas = {};
  ipoSummaryData:any;
  duplicateData = {};
  notifications = [];
  SEVERITY = SEVERITY;
  showWarning = false;
  primaryNodeId:string;
  systemType = new Map();
  siteDetailsData = [];

  constructor(private router: Router , private  papa: Papa, 
    private migrationDataService:MigrationDataService , public zone: NgZone,
    private logger:LogService , private session: SessionHandlerService) { 
    this.logger.debug("Upload CSV Constructor");
    this.csvfilename = this.migrationDataService.ipoCSVFileName;
    this.companyName = this.session.companyName;
    this.commonDocTemplate();
  }

  commonDocTemplate(){
    this.globalMsgId = 0;
    this.systemType.clear();
    this.siteDetailsData = [];
    this.mergedCSVDatas = {
      ipoCSVFileName : "",
      IPOSummaryData : [],
      IPOCsvData : [],
      IPOTimeProfile : {
        TimeProfile: [] },
      IPOBCAData: {
        BCAData : []},
      IPOUserAppearanceData: {
        AppearanceData : []},
      IPOCallParkData: {
        CallParkData : []},
      IPOHuntGroup : {
        HuntGroup : []},
      AutoAttendant : {
        AutoAttendant :[]},
      incomingCallRoute : {
        IncomingCallRoute :[]},
      UserRestrictionData: {
        UserRestrictionData : []},
      PagingGroup : [],
      shortCodesData : {
        ShortCodesData :[]},
      steeringCodesData : {
        SteeringCodesData :[]},
      IPOUserData : [],
      DTMF0_Breakout : [],
       MessageOnlyData :[],
      
    }

    this.duplicateData = {
      users : [],
      huntGroup : [],
      timeProfile : [],
      bcaConfig : [],
      userAppearanceConfig : []
    }

    this.ipoSummaryData = {
      "IPOSummary" :{
        "Max_Extension_Length": 0,
        "No_Of_Users": 0 , 
        "No_Of_Users_With_Extension": 0,
        "No_Of_Users_Without_email": 0 ,
        "No_of_Hardphone_Users": 0,
        "No_of_Softphone_Users": 0 ,
        "No_of_AutoAttendants": 0,
        "DTMF_Breakout0": [],
        "No_of_Users_Without_ExtensionDetails": "",
        "Users_Without_ExtensionDetails":"",
        "SY_Name": 0 ,
        "SY_Virtualized": "" ,
        "TimeZoneString": "",
        "UN_CLASS": "" ,
        "UN_MAJOR": "" ,
        "UN_MINOR": "" ,
        "UN_TYPE": "" ,
        "User_Profile":{
          "BasicUserPackage": 0,
          "NonLicensedUserPackage": 0,
          "OfficeWorkerUserPackage": 0,
          "PowerUserPackage": 0,
          "TeleworkerUserPackage": 0
        },
        "Duplicate_Extensions" : [],
        "ShortCodes":""
      }
    }

  }

  siteTemplate(csvFileName , csvType = ""){
    let siteDetails = {
      fileName: csvFileName,
      csvType: csvType,
      siteName: csvFileName.split(".csv")[0],
      siteId: "",
      siteExtension: ""
    }
    return siteDetails;
  }
  zeroDialTemplete(csvFileName,destination,isPrimary)
  {
    let zeroDialDetails = {
      fileName: csvFileName,
      siteId :"",
      destination : destination,
      extension : "",
      isPrimary:isPrimary,
    }
    return zeroDialDetails;
  }
  onComplete = (results , filename , msgId) => {
    if(results.data.length == 0){
        let err = {
            Generic_Error:'Invalid CSV File',
            Specific_Error:'File is empty'
        } as ErrorData;
        //reject(err);
        this.failedFileName.push(filename);
        this.handleParserFailure(err , filename);
        //this.notifications.push({msg: `${err.Generic_Error} - ${err.Specific_Error} : ${filename}`, severity: SEVERITY.ERROR});
        return;
    }else{
      let that = this;
      return new Promise(function (resolve, reject) {
        // save callbacks for later
        //const msgId = that.globalMsgId++;
        /* resolves[msgId] = resolve;
        rejects[msgId] = reject; */

        that.validateSCNCsv(results.data)
        .then(()=>{
          that.parserWorker.postMessage({
            csvData:results.data,
            id: msgId,
            fileName: filename
          })
        }).catch((error)=>{
          /* delete resolves[msgId];
          delete rejects[msgId]; */
          /* if(that.promises.indexOf(filename) !== -1){
            that.promises.splice(that.promises.indexOf(filename),1);
          } */

          if(that.promises.has(filename))
            that.promises.delete(filename);
          
          that.failedFileName.push(filename);
          that.handleParserFailure(error , filename);
          //that.notifications.push({msg: `${error.Generic_Error} - ${error.Specific_Error} : ${filename}`, severity: SEVERITY.ERROR});
          that.logger.debug("parsing failed :",error);
          that.logger.debug("onComplete :: parsing failed for :", that.failedFileName.toString());
        })
      }) 
    }
  }

  handleMsg = (msg) => {
    let that = this;
    new Promise(function (resolve, reject) {
      let id = msg.data.id;
      that.parsedOutput.push({data: msg.data, fileName: msg.data.fileName})      
      /* // purge used callbacks
      delete resolves[id]
      delete rejects[id] */
      /* if(that.promises.indexOf(msg.data.fileName) !== -1){
        that.promises.splice(that.promises.indexOf(msg.data.fileName),1);
      } */
      if(that.promises.has(msg.data.fileName))
            that.promises.delete(msg.data.fileName);
      //delete that.promises[id];
      if(that.promises.size === 0){
        that.logger.debug("parsing completed");
        that.logger.debug("handleMsg :: parsing failed for :", that.failedFileName.toString());
                
        let found = that.notifications.find(entry => (
          entry.severity === SEVERITY.ERROR));
        if(!found){
          that.mergeAllParsedOutput();
        }else{
          that.isParseFailed = true;
          that.logger.error("errors found while parsing.")
        }
      }else{
        that.logger.debug("worker thread pending to complete parsing:",that.promises.size);
      }
    });
  }

  handleError = (e)=>{
    var err:ErrorData ={
        Generic_Error:'Invalid CSV File',
        Specific_Error:e.message
    };

    if(e.fileName){
      this.failedFileName.push(e.fileName);
      //this.notifications.push({msg: `${err.Generic_Error} - ${err.Specific_Error} : ${e.fileName}`, severity: SEVERITY.ERROR});
    }
    this.handleParserFailure(err , e.fileName);
  }

  mergeAllParsedOutput(){
    this.logger.debug("mergeAllParsedOutput");
    this.commonDocTemplate();
    let that = this;
    return new Promise(function (resolve, reject) {
      let primaryNodeFound:any = that.parsedOutput.find(csvOutput => 
        that.primaryNodeId === csvOutput.data.summaryData.IPOSummary.UN_MAC)
      if(primaryNodeFound){
        primaryNodeFound.data.timeProfile.TimeProfile.forEach(parsedTP => {
          let duplicateTP = that.mergedCSVDatas['IPOTimeProfile'].TimeProfile.find(mergedTP => mergedTP.TP_Name === parsedTP.TP_Name);
          if(duplicateTP){
            //duplicate TP found
            that.duplicateData['timeProfile'].push({data:parsedTP, fileName: primaryNodeFound.fileName});
            that.notifications.push({msg:`TimeProfile ${parsedTP.TP_Name} is duplicate in file ${primaryNodeFound.fileName}`,severity: SEVERITY.WARNING});
            
          }else{
            that.mergedCSVDatas['IPOTimeProfile'].TimeProfile.push(parsedTP);
          }
        });
       // that.ipoSummaryData.IPOSummary.DTMF_Breakout0 = primaryNodeFound.data.summaryData.IPOSummary.DTMF_Breakout0;
        that.ipoSummaryData.IPOSummary.TimeZoneString = primaryNodeFound.data.summaryData.IPOSummary.TimeZoneString;
        that.ipoSummaryData.IPOSummary.UN_MINOR = primaryNodeFound.data.summaryData.IPOSummary.UN_MINOR;
        that.ipoSummaryData.IPOSummary.UN_MAJOR = primaryNodeFound.data.summaryData.IPOSummary.UN_MAJOR;
      }
      else
      {
     //   that.ipoSummaryData.IPOSummary.DTMF_Breakout0 = that.parsedOutput[0].data.summaryData.IPOSummary.DTMF_Breakout0;
      }
      that.parsedOutput.forEach(singleCsvOutput => {
        //ACOS-1077 csv without users should show an error to MT users as part of notifications
          if(singleCsvOutput.data.ipoUserData.IPOUsers.length !== 0){
            singleCsvOutput.data.ipoUserData.IPOUsers.forEach(parsedUser => {
              let duplicateUser = that.mergedCSVDatas['IPOUserData'].find(mergedUser => mergedUser.Extension.US_Extension === parsedUser.Extension.US_Extension);
              if(duplicateUser){
                //duplicate users found
                that.duplicateData['users'].push({data:parsedUser, fileName: singleCsvOutput.fileName});
                that.notifications.push({msg:`User ${parsedUser.Extension.US_Extension} is duplicate in file ${singleCsvOutput.fileName}`,severity: SEVERITY.WARNING});
              }else{
                that.mergedCSVDatas['IPOUserData'].push(parsedUser);
              }
            });
          }else{
            var err:ErrorData ={
              Generic_Error:'Invalid CSV File',
              Specific_Error:"Users not found in csv"
            };
            that.failedFileName.push(singleCsvOutput.fileName);
            that.handleParserFailure(err , singleCsvOutput.fileName);
          }

          if(that.primaryNodeId !== singleCsvOutput.data.summaryData.IPOSummary.UN_MAC){
            let site = that.siteTemplate(singleCsvOutput.fileName);

            //Configure language, country if locale is configured
            let matchingLocale = LOCALE_LANGUAGE_LOCATION_MAP.find((localeEntry) => localeEntry.localeCode === singleCsvOutput.data.summaryData.IPOSummary.SY_Locale);
            console.log("localeEntry : ", singleCsvOutput.data.summaryData.IPOSummary.SY_Locale, ", matchingLocale >>>>>>>>>>>>>>>>>> : ", matchingLocale);
            if (matchingLocale) {
              site['language'] = matchingLocale.languageCode;
              if (matchingLocale.locationCode) {  // location Code is not included for korea
                site['homeCountry'] = matchingLocale.locationCode;
              }
            }
            if (singleCsvOutput.data.summaryData.IPOSummary.TimeZoneString) {
              site['siteTimeZone'] = singleCsvOutput.data.summaryData.IPOSummary.TimeZoneString
              site['siteTimeZoneDescription']
            }
            that.siteDetailsData.push(site);
          }
          
          if (singleCsvOutput.data.summaryData.IPOSummary.DTMF_Breakout0 !== undefined && singleCsvOutput.data.summaryData.IPOSummary.DTMF_Breakout0 !== ""){
            if(that.primaryNodeId !== ""){
              if(singleCsvOutput.data.summaryData.IPOSummary.UN_MAC === that.primaryNodeId)
                that.mergedCSVDatas['DTMF0_Breakout'].push(that.zeroDialTemplete(singleCsvOutput.fileName,singleCsvOutput.data.summaryData.IPOSummary.DTMF_Breakout0,"yes"));
              else
                that.mergedCSVDatas['DTMF0_Breakout'].push(that.zeroDialTemplete(singleCsvOutput.fileName,singleCsvOutput.data.summaryData.IPOSummary.DTMF_Breakout0,"no"));
            }
            else{
              that.mergedCSVDatas['DTMF0_Breakout'].push(that.zeroDialTemplete(singleCsvOutput.fileName,singleCsvOutput.data.summaryData.IPOSummary.DTMF_Breakout0,"yes"));
            }
            }

          if(singleCsvOutput.data.bcaData && singleCsvOutput.data.bcaData.BridgeCallAppearance && singleCsvOutput.data.bcaData.BridgeCallAppearance.length !== 0) {
            singleCsvOutput.data.bcaData.BridgeCallAppearance.forEach(parsedData => {
              let duplicateBCAConfig = that.mergedCSVDatas['IPOBCAData'].BCAData.find(bca => bca.Secretary_Extn === parsedData.Secretary_Extn);
              if (duplicateBCAConfig) {
                that.duplicateData['bcaConfig'].push({data:parsedData, fileName: singleCsvOutput.fileName});
                that.notifications.push({msg:`BCA ${parsedData.Secretary_Extn} is duplicate in file ${singleCsvOutput.fileName}`,severity: SEVERITY.WARNING});
              } else {
                that.mergedCSVDatas['IPOBCAData'].BCAData.push(parsedData);
              }
            })
          }

          if (singleCsvOutput.data.userAppearancesData && singleCsvOutput.data.userAppearancesData.UserAppearanceData && singleCsvOutput.data.userAppearancesData.UserAppearanceData.length !== 0) {
            singleCsvOutput.data.userAppearancesData.UserAppearanceData.forEach(parsedData => {
              let duplicateAppearanceConfig = that.mergedCSVDatas["IPOUserAppearanceData"].AppearanceData.find(appearance => appearance.User_Extn === parsedData.User_Extn);
              if (duplicateAppearanceConfig) {
                that.duplicateData['userAppearanceConfig'].push({data:parsedData, fileName: singleCsvOutput.fileName});
                that.notifications.push({msg:`User Appearance Config ${parsedData.User_Extn} is duplicate in file ${singleCsvOutput.fileName}`,severity: SEVERITY.WARNING});
              } else {
                that.mergedCSVDatas['IPOUserAppearanceData'].AppearanceData.push(parsedData);
              }
            })
          }

          if (singleCsvOutput.data.callParkData && singleCsvOutput.data.callParkData.CallParkData && singleCsvOutput.data.callParkData.CallParkData.length !== 0) {
            singleCsvOutput.data.callParkData.CallParkData.forEach(parsedData => {
              let duplicateCallParkConfig = that.mergedCSVDatas["IPOCallParkData"].CallParkData.find(callPark => callPark.userExtension === parsedData.UserExtension);
              if (duplicateCallParkConfig) {
                that.duplicateData['callParkConfig'].push({data:parsedData, fileName: singleCsvOutput.fileName});
                that.notifications.push({msg:`Call park Config ${parsedData.UserExtension} is duplicate in file ${singleCsvOutput.fileName}`, severity: SEVERITY.WARNING});
              } else {
                that.mergedCSVDatas['IPOCallParkData'].CallParkData.push(parsedData);
              }
          });
          }

          //huntGroup
          singleCsvOutput.data.huntGroup.HuntGroup.forEach(parsedHG => {
            let duplicateHG = that.mergedCSVDatas['IPOHuntGroup'].HuntGroup.find(mergedHG => mergedHG.Group.GR_Extension === parsedHG.Group.GR_Extension);
            if(duplicateHG){
              //duplicate HG found
              that.duplicateData['huntGroup'].push({data:parsedHG, fileName: singleCsvOutput.fileName});
              that.notifications.push({msg:`HuntGroup ${parsedHG.Group.GR_Extension} is duplicate in file ${singleCsvOutput.fileName}`,severity: SEVERITY.WARNING});
            }else{
              that.mergedCSVDatas['IPOHuntGroup'].HuntGroup.push(parsedHG);
            }
          });

          //TimeProfiles
          singleCsvOutput.data.timeProfile.TimeProfile.forEach(parsedTP => {
            let duplicateTP = that.mergedCSVDatas['IPOTimeProfile'].TimeProfile.find(mergedTP => mergedTP.TP_Name === parsedTP.TP_Name);
            if(duplicateTP){
              //duplicate TP found
              if(that.primaryNodeId !== singleCsvOutput.data.summaryData.IPOSummary.UN_MAC){
                //existing duplicate needs to be replaced with primary node TP 
                that.duplicateData['timeProfile'].push({data:parsedTP, fileName: singleCsvOutput.fileName});
                that.notifications.push({msg:`TimeProfile ${parsedTP.TP_Name} is duplicate in file ${singleCsvOutput.fileName}`,severity: SEVERITY.WARNING});
              }
            }else{
              that.mergedCSVDatas['IPOTimeProfile'].TimeProfile.push(parsedTP);
            }
          });
          
          //AutoAttendant
          singleCsvOutput.data.autoAttendant.AutoAttendant.forEach(parsedAA => {
            let duplicateAA = that.mergedCSVDatas['AutoAttendant'].AutoAttendant.find(mergedTP => mergedTP.AA_Name === parsedAA.AA_Name);
            if(duplicateAA)
            {
              //duplicate AA found
              that.duplicateData['AutoAttendant'].push({data:parsedAA, fileName: singleCsvOutput.fileName});
              that.notifications.push({msg:`AutoAttendant ${parsedAA.AA_Name} is duplicate in file ${singleCsvOutput.fileName}`,severity: SEVERITY.WARNING});
            }else{
              let newParsedData = { callFlowName:parsedAA.AA_Name, siteName:parsedAA.Sitename, Filename:parsedAA.Filename, AA_Name:parsedAA.AA_Name, AA_Actions:parsedAA.AA_Actions, vmProModuleConditions:[]}
              that.mergedCSVDatas['AutoAttendant'].AutoAttendant.push(newParsedData);
            }
          });
          //UserRestrictionData
          if(singleCsvOutput.data.userRestrictionsData.UserRestrictionsData !== undefined && singleCsvOutput.data.userRestrictionsData.UserRestrictionsData.length > 0 &&singleCsvOutput.data.userRestrictionsData.UserRestrictionsData !== "NOT FOUND")
          singleCsvOutput.data.userRestrictionsData.UserRestrictionsData.forEach(parsedData => {
            let newParsedData = {name : parsedData.USR_Name , Button_Programming : parsedData.USR_ButtonProgramming ,Filename: singleCsvOutput.fileName, Sitename :parsedData.USR_Sitename }
            that.mergedCSVDatas['UserRestrictionData'].UserRestrictionData.push(newParsedData);
          });

          that.mergedCSVDatas['steeringCodesData'].SteeringCodesData = [...that.mergedCSVDatas['steeringCodesData'].SteeringCodesData , ...singleCsvOutput.data.steeringCodesData.SteeringCodesData];
          that.mergedCSVDatas['shortCodesData'].ShortCodesData = [...that.mergedCSVDatas['shortCodesData'].ShortCodesData , ...singleCsvOutput.data.shortCodesData.ShortCodesData];
          that.mergedCSVDatas['incomingCallRoute'].IncomingCallRoute = [...that.mergedCSVDatas['incomingCallRoute'].IncomingCallRoute , ...singleCsvOutput.data.incomingCallRoute.IncomingCallRoute];
          that.mergedCSVDatas['MessageOnlyData'] = [...that.mergedCSVDatas['MessageOnlyData'] , ...singleCsvOutput.data.summaryData.IPOSummary.Users_Without_ExtensionDetails];
          //TODO: other features key should be identified and same logic has to be implemented for finding duplicates
          that.ipoSummaryData.IPOSummary.Max_Extension_Length = (singleCsvOutput.data.summaryData.IPOSummary.Max_Extension_Length > that.ipoSummaryData.IPOSummary.Max_Extension_Length) 
          ?singleCsvOutput.data.summaryData.IPOSummary.Max_Extension_Length:that.ipoSummaryData.IPOSummary.Max_Extension_Length;
          that.ipoSummaryData.IPOSummary.No_Of_Users += singleCsvOutput.data.summaryData.IPOSummary.No_Of_Users;
          that.ipoSummaryData.IPOSummary.No_Of_Users_With_Extension += singleCsvOutput.data.summaryData.IPOSummary.No_Of_Users_With_Extension;
          that.ipoSummaryData.IPOSummary.No_Of_Users_Without_email += singleCsvOutput.data.summaryData.IPOSummary.No_Of_Users_Without_email;
          that.ipoSummaryData.IPOSummary.No_of_Hardphone_Users += singleCsvOutput.data.summaryData.IPOSummary.No_of_Hardphone_Users;
          that.ipoSummaryData.IPOSummary.No_of_Softphone_Users += singleCsvOutput.data.summaryData.IPOSummary.No_of_Softphone_Users;
          that.ipoSummaryData.IPOSummary.No_of_AutoAttendants += singleCsvOutput.data.summaryData.IPOSummary.No_of_AutoAttendants;
          that.ipoSummaryData.IPOSummary.SY_Name += 1;

          that.ipoSummaryData.IPOSummary.User_Profile.BasicUserPackage += (singleCsvOutput.data.summaryData.IPOSummary.User_Profile.BasicUserPackage !== undefined) ? singleCsvOutput.data.summaryData.IPOSummary.User_Profile.BasicUserPackage : 0;
          that.ipoSummaryData.IPOSummary.User_Profile.NonLicensedUserPackage += (singleCsvOutput.data.summaryData.IPOSummary.User_Profile.NonLicensedUserPackage !== undefined) ? singleCsvOutput.data.summaryData.IPOSummary.User_Profile.NonLicensedUserPackage : 0;
          that.ipoSummaryData.IPOSummary.User_Profile.OfficeWorkerUserPackage += (singleCsvOutput.data.summaryData.IPOSummary.User_Profile.OfficeWorkerUserPackage !== undefined) ? singleCsvOutput.data.summaryData.IPOSummary.User_Profile.OfficeWorkerUserPackage : 0;
          that.ipoSummaryData.IPOSummary.User_Profile.PowerUserPackage += (singleCsvOutput.data.summaryData.IPOSummary.User_Profile.PowerUserPackage !== undefined) ? singleCsvOutput.data.summaryData.IPOSummary.User_Profile.PowerUserPackage : 0;
          that.ipoSummaryData.IPOSummary.User_Profile.TeleworkerUserPackage += (singleCsvOutput.data.summaryData.IPOSummary.User_Profile.TeleworkerUserPackage !== undefined) ? singleCsvOutput.data.summaryData.IPOSummary.User_Profile.TeleworkerUserPackage : 0;

          /* if(that.primaryNodeId === singleCsvOutput.data.summaryData.IPOSummary.UN_MAC){
            that.ipoSummaryData.IPOSummary.TimeZoneString = singleCsvOutput.data.summaryData.IPOSummary.TimeZoneString;
            that.ipoSummaryData.IPOSummary.UN_MINOR = singleCsvOutput.data.summaryData.IPOSummary.UN_MINOR;
            that.ipoSummaryData.IPOSummary.UN_MAJOR = singleCsvOutput.data.summaryData.IPOSummary.UN_MAJOR;
          } */
          if(that.ipoSummaryData.IPOSummary.UN_MAJOR === ""){
            that.ipoSummaryData.IPOSummary.TimeZoneString = singleCsvOutput.data.summaryData.IPOSummary.TimeZoneString;
            that.ipoSummaryData.IPOSummary.UN_MINOR = singleCsvOutput.data.summaryData.IPOSummary.UN_MINOR;
            that.ipoSummaryData.IPOSummary.UN_MAJOR = singleCsvOutput.data.summaryData.IPOSummary.UN_MAJOR;
          }
          //to capture 
          let systemTypecount = 1;
          if(that.systemType.has(singleCsvOutput.data.summaryData.IPOSummary.UN_TYPE)){
            systemTypecount+= that.systemType.get(singleCsvOutput.data.summaryData.IPOSummary.UN_TYPE)
          }
          that.systemType.set(singleCsvOutput.data.summaryData.IPOSummary.UN_TYPE , systemTypecount);
          that.ipoSummaryData.IPOSummary.Duplicate_Extensions = [...that.ipoSummaryData.IPOSummary.Duplicate_Extensions, ...singleCsvOutput.data.summaryData.IPOSummary.Duplicate_Extensions];          
      });
      
      let systemTypeString = "";
      for (let entry of that.systemType.entries()) { 
        systemTypeString+= `${entry[0]} (${entry[1]})`; 
        if(that.systemType.size > 1)
          systemTypeString+= " , ";
      }
      that.ipoSummaryData.IPOSummary.Users_Without_ExtensionDetails  =  [...new Set(that.mergedCSVDatas['MessageOnlyData'])];
      that.ipoSummaryData.IPOSummary.No_of_Users_Without_ExtensionDetails = that.ipoSummaryData.IPOSummary.Users_Without_ExtensionDetails.length;
      that.ipoSummaryData.IPOSummary.ShortCodes = that.parsedOutput[0].data.shortCodesData;
      that.ipoSummaryData.IPOSummary.UN_TYPE = systemTypeString;
      that.ipoSummaryData.IPOSummary.SY_Name = that.ipoSummaryData.IPOSummary.SY_Name.toString();
      that.migrationDataService.ipoCSVFileName = that.csvfilename;
      that.migrationDataService.IPOSummaryData = that.ipoSummaryData;
      let tempData = [];
      tempData.push({
        'IPOUsers': that.mergedCSVDatas['IPOUserData']
      });
      that.migrationDataService.IPOCsvData = tempData[0];
      that.migrationDataService.IPOTimeProfile = that.mergedCSVDatas['IPOTimeProfile'];
      that.migrationDataService.IPOHuntGroup = that.mergedCSVDatas['IPOHuntGroup'];
      that.migrationDataService.IPOBCAData = that.mergedCSVDatas['IPOBCAData'];
      that.migrationDataService.IPOUserAppearanceData = that.mergedCSVDatas['IPOUserAppearanceData'];
      that.migrationDataService.IPOCallParkData = that.mergedCSVDatas['IPOCallParkData'];
      
      that.migrationDataService.AutoAttendant = that.mergedCSVDatas['AutoAttendant'];
      //that.migrationDataService.IncomingCallRoute = that.parsedOutput[0].data.incomingCallRoute;
      //that.migrationDataService.ShortCodes = that.parsedOutput[0].data.shortCodesData;
      that.migrationDataService.IncomingCallRoute = that.mergedCSVDatas['incomingCallRoute'];
      that.migrationDataService.ShortCodes = that.mergedCSVDatas['shortCodesData'];
      that.migrationDataService.SteeringCodes = that.mergedCSVDatas['steeringCodesData'];
      that.migrationDataService.IPOUserData = that.mergedCSVDatas['IPOUserData'];
      that.migrationDataService.SiteDetails = that.siteDetailsData;
      that.migrationDataService.ZeroDialData = that.mergedCSVDatas['DTMF0_Breakout'];
      that.migrationDataService.UserRestrictionData = that.mergedCSVDatas['UserRestrictionData'];
      if(!that.isParseFailed && (that.duplicateData['users'].length !== 0 || that.duplicateData['huntGroup'].length !== 0 ||
        that.duplicateData['timeProfile'].length !== 0 )){
          //TODO: UI has to be designed to show list of duplicates along with the fileNames
          that.logger.error("duplicates found");
          that.showWarning = true;
          //that.zone.run(() => {that.router.navigate(['/home/migration/rcauth']); });
      }else if(!that.isParseFailed){
        that.zone.run(() => {that.router.navigate(['/home/migration/rcauth']); });
      }
    }) 
  }

  ngOnInit(): void {
    if(typeof Worker !== 'undefined'){
      this.parserWorker = new Worker('src/app/shared/services/parser/ipodatacsvparser.worker', { type: 'module' })
        /* this.parserWorker.onmessage = (res)=>{
          //event.currentTarget.value='';
          this.isParseFailed = false;
          this.migrationDataService.ipoCSVFileName = this.csvfilename;
          this.migrationDataService.IPOSummaryData = res.data.summaryData;
          this.migrationDataService.IPOCsvData = res.data.ipoUserData;
          this.migrationDataService.IPOTimeProfile = res.data.timeProfile;
          this.migrationDataService.IPOHuntGroup = res.data.huntGroup;
          this.migrationDataService.AutoAttendant = res.data.autoAttendant;
          this.migrationDataService.IncomingCallRoute = res.data.incomingCallRoute;
         // this.migrationDataService.PagingGroup = res.data.pagingGroup;
          this.migrationDataService.ShortCodes = res.data.shortCodesData;
          this.migrationDataService.SteeringCodes = res.data.steeringCodesData;
          this.migrationDataService.IPOUserData = this.migrationDataService.IPOCsvData['IPOUsers'];
          this.zone.run(() => {this.router.navigate(['/home/migration/rcauth']); });
         // this.parserWorker.terminate();
        } */
        this.parserWorker.onmessage = this.handleMsg;
        this.parserWorker.onerror = this.handleError;
    }
  }

  ngOnDestroy(){
    this.parserWorker.terminate();
    if(this.isMorrison === true)
    this.morrisonWorker.terminate();
  }

  navigateBack(){
    this.logger.debug(" navigateBack");
    this.zone.run(() => { this.router.navigate(['/home/migration/company-info']); }); 
  }

  navigateNext(){
    //TODO: if users enters 
    //required when user comes back from RC auth page and he has IPO csv file uploaded previously
    if(this.csvfilename != null && this.csvfilename != undefined && !this.isParseFailed)
    {
      this.zone.run(() => {this.router.navigate(['/home/migration/rcauth']);})
    }
  }

  closeMigrationActivity(){
    this.session.alertModal(this.onClose.bind(this));
  }

  onClose() {
    this.logger.debug("upload csv - close current Migration Activity");
    this.migrationDataService.clearData();
    this.zone.run(() => { this.router.navigate(['/home/migration']);});
  }

  onFileChanged(event: any) {
    this.logger.debug("onFileChanged: " + event);
    var csvfiles = event.target.files;
    this.csvFile = csvfiles[0];
    this.reset();
    this.logger.debug("The file selected: ", this.csvFile.name);
    this.csvfilename = this.csvFile.name;
    if(this.csvfilename.endsWith(".zip")){
      this.logger.debug("Zip file uploaded :",this.csvfilename);
      const jsZip = require('jszip');
      jsZip.loadAsync(csvfiles[0]).then((zip) => {
        //find cli file from the extracted zip file
        //if found get the primary node Serial Number
        //else throw error for missing cli file 
        let cliFileFound = Object.keys(zip.files).find(file => file.includes('.cfi'));
        if(cliFileFound){
          this.parseCfiFile(zip.files , cliFileFound)
          .then((res:any) =>{
            //need to get only the id, from cfi file we get .cfg extension also
            //split and get only the id of primary node
            this.primaryNodeId = res.split('.')[0];
            Object.keys(zip.files).forEach((filename) => {
              if(!filename.includes('.cfi') && filename.endsWith('.csv')){
                zip.files[filename].async('string').then((fileData) => {
                  const msgId = this.globalMsgId++;
                  //this.promises[msgId] = filename;
                  this.promises.add(filename);
                  this.papa.parse(fileData , {
                    header: false,
                    delimiter: ",",
                    quoteChar:"",
                    worker:true,
                    complete: (result) => {
                      this.onComplete(result ,filename , msgId);
                    }
                  });
                });
              }
            });
          }).catch((error) =>{
            this.logger.debug("invalid .cfi file")
            let err = {
              Generic_Error:'Invalid zip file uploaded',
              Specific_Error:'Invalid .cfi file".'
            } as ErrorData;
            this.handleParserFailure(err ,this.csvfilename);
          }); 
        }else{
          this.logger.debug(".cfi file missing")
          let err = {
            Generic_Error:'Invalid zip file uploaded',
            Specific_Error:'.cfi file is missing.'
          } as ErrorData;
          this.handleParserFailure(err,this.csvfilename);
        } 
      });

    }else if(this.csvfilename.endsWith(".csv")){
      this.logger.debug("csv file uploaded :",this.csvfilename);
      this.parse(event)
      .then(()=>{})
      .catch((err:ErrorData)=>{
        this.handleParserFailure(err , this.csvfilename);
      })
    }else{
      this.logger.debug("invalid file format uploaded")
      let err = {
        Generic_Error:'Invalid File Format',
        Specific_Error:'Only zip/csv File Supported'
      } as ErrorData;
      this.handleParserFailure(err , this.csvfilename);
    }
  }

  parseCfiFile(zipFileContent , cfiFileName){
    //let that = this;
    return new Promise(function(resolve,reject){
      //that.logger.debug(zipFileContent); 
      zipFileContent[cfiFileName].async('string').then((fileData) => {
        if(fileData !== undefined && fileData !== ""){
          //that.logger.debug(fileData);
          let primaryNodedata = fileData.split("\n")[0].split(',')[0];
          resolve(primaryNodedata);
        }else{
          reject();
        }  
      }).catch(()=>{
        reject();
      }); 
    });
  }

  reset(){
    this.notifications = [];
    this.parsedOutput = [];
    this.isParseFailed = false;
    this.showErrorModal = false;
    this.showWarning = false;
    this.mergedCSVDatas = {};
    this.duplicateData = {};
    this.promises.clear();
    this.primaryNodeId = "";
    //this.migrationDataService.clearData();
    this.commonDocTemplate();
  }

  closeErrorWarningBlock(){
    this.showErrorModal = false;
  }

  viewErrorModal(){
    this.showErrorModal = true;
  }

  sendErrorReport(){
    this.showErrorModal = false;
  }

  closeErrorModal(){
    this.showErrorModal = false;
  }


  private handleParserFailure(err : ErrorData , filename = ""):void{
      //event.currentTarget.value='';
      this.isParseFailed = true;
      this.errorMsg = err.Generic_Error;
      this.syntaxErrorMsg = err.Specific_Error;
      this.logger.debug("parser error :"+err.Generic_Error+ " \nSyntax error: "+err.Specific_Error);4
      this.notifications.push({msg: `${err.Generic_Error} , ${err.Specific_Error} : ${filename}`, severity: SEVERITY.ERROR});

  }

  private parse(event: any):Promise<string>{
    let that = this;
    this.csvFile = event.target.files[0];
    this.logger.debug("The file selected: ", this.csvFile.name);
    var splitfile = this.csvFile.name.split(".");
    if (splitfile.length != 0)
    {
        var fileformat = splitfile[splitfile.length - 1];
        this.logger.debug("format is  ",fileformat);
    }
    return new Promise(function(resolve,reject){
      if(fileformat == 'csv')
      {
        if(that.csvFile.size > 0){
            that.papa.parse(that.csvFile, {
              header: false,
              delimiter: ",",
              quoteChar:"",
              worker:true,
              complete: function(results) {
                if(results.data.length == 0){
                    let err = {
                        Generic_Error:'Invalid CSV File',
                        Specific_Error:'File is empty'
                    } as ErrorData;
                    reject(err);
                    return;
                }
                var err = that.validateCSVFile(results.data , that.csvfilename);
                if( err == null)
                {
                  if(that.isMorrison === true)
                  {
                    that.morrisonWorker = new Worker('src/app/shared/services/parser/morrisoncsvparser.worker', { type: 'module' })
                    that.morrisonWorker.onmessage = (res)=>{
                      //event.currentTarget.value='';
                      that.isParseFailed = false;
                      that.migrationDataService.ipoCSVFileName = that.csvfilename;
                      that.migrationDataService.Morrison = res.data.morrisonData;
                      that.zone.run(() => {that.router.navigate(['/home/migration/rcauth']); });
                    // this.parserWorker.terminate();
                    }
                    that.morrisonWorker.onerror = (e) => { 
                      var err:ErrorData ={
                          Generic_Error:'Invalid CSV File',
                          Specific_Error:e.message
                      } ;
                      that.handleParserFailure(err);
                    // this.parserWorker.terminate();
                    }
                    that.morrisonWorker.postMessage({
                      csvData:results.data,
                      isMorrison:that.isMorrison
                    });
                  }else{
                    that.parserWorker.postMessage({
                      csvData:results.data,
                      id: 0,
                      fileName: that.csvfilename
                    });
                  }  
                }
                else{ 
                    reject(err);
                }
              }
            });
        }else{
          var err = {
                  Generic_Error:'Invalid File uploaded',
                  Specific_Error:'File does not exist '
            } as ErrorData;
          reject(err);
        }
      }else{
        var err = {
            Generic_Error:'Invalid File Format',
            Specific_Error:'File Uploaded is not a csv file'
                } as ErrorData;
        reject(err);
      }      
    });
  }

  private validateCSVFile(data , fileName = " "):ErrorData{
    var fields = ['User_Info','System_Info','Extension_Info'];
    var genric_error = "Invalid CSV File";
    var specific_error = "";
    var flag = 0;
    if(fileName.startsWith('morrison') || fileName.startsWith('Morrison'))
    {
      this.isMorrison = true;
      let err = {
        Generic_Error:genric_error,
        Specific_Error:specific_error
      } as ErrorData;
      if(data && Array.isArray(data) && data.length > 1)
      {
      if(data[0][0] !== "AccessCode" || data[1][0] == "AccessCode")
        return err;
        if(data[0][1] === "" || isNaN(data[0][1]) === true)
        return err;
      }
    } 
    else
    {
      this.isMorrison = false;
      for(let fld of fields){
        specific_error = fld +" not found"
        let err = {
          Generic_Error:genric_error,
          Specific_Error:specific_error
        } as ErrorData;
        flag = 0;
        for(let i=0;i<data.length;i++){
          //Check if User_Info_Start , System_Info_Start,Extension_Info_Start , User_Info_End , System_Info_End,Extension_Info_End are also present 
          if(data[i][0].includes(fld+"_Start"))
            flag++;
          else if(data[i][0].includes(fld+"_End")){
            flag++;
            break;
          }
        }
        if(flag != 2)
          return err;
      } 
    }
    return null; 
  }

  validateSCNCsv(data , fileName = ""){
    return new Promise(function(resolve,reject){
      var fields = ['User_Info','System_Info','Extension_Info'];
      var genric_error = "Invalid CSV File";
      var specific_error = "";
      var flag = 0;
      //this.isMorrison = false;
      for(let fld of fields){
        specific_error = fld +" not found"
        let err = {
          Generic_Error:genric_error,
          Specific_Error:specific_error
        } as ErrorData;
        flag = 0;
        for(let i=0;i<data.length;i++){
          //Check if User_Info_Start , System_Info_Start,Extension_Info_Start , User_Info_End , System_Info_End,Extension_Info_End are also present 
          if(data[i][0].includes(fld+"_Start"))
            flag++;
          else if(data[i][0].includes(fld+"_End")){
            flag++;
            break;
          }
        }
        if(flag != 2)
          reject(err);
      } 
      resolve(null);
    });
  }
}
