import { Component, OnInit, NgZone, ChangeDetectorRef, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { SessionHandlerService } from 'src/app/shared/services/sessionHandler/session-handler.service';
import { LogService } from 'src/app/shared/services/logging/log.service';
import { TablePreviewService , SEVERITY } from 'src/app/shared/services/tablePreview/table-preview.service';
import { Subscription } from 'rxjs';
import { FirestoreService, MIGRATION_STAGE } from 'src/app/shared/services/firestore/firestore.service';
import { MatTooltip } from '@angular/material/tooltip';
import { MatSpinnerOverlayComponent } from 'src/app/shared/components/mat-spinner-overlay/mat-spinner-overlay.component';
import { OverlayService } from 'src/app/shared/services/overlay/overlay.service';


const TOOLTIPTXT = {
  DATA_NOT_READY : "Preparing data, try later",
  //ACOS-1133 Implementation in Migration tool to download premigration report- User data
  //user will be allowed to navigate to start migration page even if error are found in table preview UI
  //ERRORS : "Fix errors to proceed"
  PROCEED: "Click to proceed"
}
@Component({
  selector: 'app-config-summary',
  templateUrl: './config-summary.component.html',
  styleUrls: ['./config-summary.component.css']
})
export class ConfigSummaryComponent implements OnInit {

  id:any;
  isTableDataInitialized:boolean=false;

  public companyName: string;
  //Setting the timer value to 60s since it takes around more than 15sec when navigating to start migration page
  readonly TIMER_INTERVAL = 60000;

  constructor(private cdr: ChangeDetectorRef, public zone: NgZone, 
    private router: Router , private session:SessionHandlerService, 
    private logger:LogService, private tablePreviewService:TablePreviewService,
    private firestoreService:FirestoreService, private overlay:OverlayService) { 
      this.logger.debug("Configuration Summary Component constructor");
      this.id = this.session.cardId;
      this.companyName = this.session.companyName;
      if(this.id == null){
          this.logger.warn("CardId does not exist - route to dashboard");
          this.zone.run(() => { this.router.navigate(['/home/migration']); });
          return;
      }
    }

  ngOnInit(): void { 
    this.firestoreService.fetchGreetingsData(this.id);
    this.tablePreviewService.initializeTableData(this.id)
    .then(()=>{
      //this.firestoreService.fetchSysmonData(this.id);
      this.tablePreviewService.informTableInitialization();
      this.isTableDataInitialized = true;  
      this.tablePreviewService.setWarningData();
    })
    .catch((error) => {
      this.logger.debug(error);
    });    
  }

  ngAfterViewInit():void {
    this.firestoreService.updateStage(this.id, MIGRATION_STAGE.PREPARETABLE)
    .then(()=>{
     })
    .catch((err)=>{
        this.logger.debug(err);
    });
  }

  ngOnDestroy(){
  }

  timeupCallbackfunc(ol){
    ol.showSpinner = false;
    ol.closeSpinner();
  }


  navigateNext(){
    if(this.isTableDataInitialized){
      /* let found = this.tablePreviewService.notificationsList.find(entry => (
        entry.severity === SEVERITY.ERROR));
        if(!found){ */
          this.overlay.openSpinner(this.TIMER_INTERVAL,this.timeupCallbackfunc,MatSpinnerOverlayComponent);
          this.tablePreviewService.saveTableData(this.id)
          .then(() =>{
            return this.tablePreviewService.setACOMigrationData(this.id);
          }).then((res) => {
            this.logger.debug("ACOExport data set in firestore");
            this.firestoreService.updateStage(this.id, MIGRATION_STAGE.DATAMIGRATION).catch();
            this.overlay.closeSpinner();
            this.zone.run(() => { this.router.navigate(['/home/migration/startMigration']); }); 
          }).catch((error)=>{
            this.overlay.closeSpinner();
            this.logger.debug("setting ACOExport data failed. ",error);
          })
        //}
      }      
    }

  getTooltipContent(){
    if(!this.isTableDataInitialized){
      return TOOLTIPTXT.DATA_NOT_READY
    }else{
      
       this.tablePreviewService.notificationsList.forEach(element => {
        element.forEach(data =>{
          let found =  data.severity === SEVERITY.ERROR;
          if(found){
            return TOOLTIPTXT.PROCEED;
          }else{
            return null;
          }
        })
      });
     
    }
  }

  navigateBack(){
    this.zone.run(() => { this.router.navigate(['/home/migration/summary']); });
  }

  closeMigrationActivity(){
    this.session.alertModal(this.onClose.bind(this));
  }

  onClose() {
    this.logger.debug("Migration summary - close current Migration Activity");
    this.zone.run(() => { this.router.navigate(['/home/migration']);});
  }

}
