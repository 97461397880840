import { Component, OnInit, NgZone } from '@angular/core';
import { LogService } from 'src/app/shared/services/logging/log.service';
import { TablePreviewService, AA_ERROR_MSG } from 'src/app/shared/services/tablePreview/table-preview.service';
import { Router } from '@angular/router';
import { SessionHandlerService } from 'src/app/shared/services/sessionHandler/session-handler.service';
import { Subscription } from 'rxjs';
import { NgxSpinnerService } from "ngx-spinner";
import { TRIGGER_STATUS } from 'src/app/shared/services/firestore/firestore.service';
import { FirestoreService } from 'src/app/shared/services/firestore/firestore.service';

const TIMER_LIMIT=100000;
const ZINDEX=1000;
@Component({
  selector: 'app-system-data',
  templateUrl: './system-data.component.html',
  styleUrls: ['./system-data.component.css']
})
export class SystemDataComponent implements OnInit {
  id:any;
  public greetingsTriggerStatus = TRIGGER_STATUS;
  numberOfHuntGroups:any="-";
  numberOfPagingGroups:any="-";
  numberOfParkLocations:any="-";
  numberOfAutoAttendants:any="-";
  numberOfTimeProfiles:any="-";
  numberofIncomingNumbers:any="-";
  isDataReady:boolean=false;
  isCompanyNumberAssigned:boolean= false;
  isGreetingsFileUploaded=TRIGGER_STATUS.NOTYET;
  isMixedTPFound:boolean;
  isAAWarnFound:boolean=false;
  isPGWarnFound:boolean;
  isHGWarnFound:boolean;
  isParkLocationWarnFound:boolean;
  subscription:Subscription;
  greetingsSubscription:Subscription;
  aaToolTipText:string;
  wait:any;
  zIdx=ZINDEX;
  parkLocSubscription:Subscription;
  parkLocDataPrepared = false;
  

  constructor(private tablePreviewService:TablePreviewService,
    private router: Router,public zone: NgZone,
    private session:SessionHandlerService,
    private spinner:NgxSpinnerService, private logger:LogService,private firestoreService:FirestoreService) {
      this.logger.debug("System Data component constructor");
      this.id = this.session.cardId;
      this.isDataReady = false;
      this.isCompanyNumberAssigned = false;
      //this.isTableUpdated();
      this.spinner.show();
      this.greetingsSubscription = this.tablePreviewService.getGreetingsDocStatus().subscribe((status)=>{
        if(status == TRIGGER_STATUS.START){          
          this.wait = setTimeout(()=>{
            clearTimeout(this.wait);
            this.isGreetingsFileUploaded = TRIGGER_STATUS.UNKNOWN;
          },TIMER_LIMIT)
        }else if(status == TRIGGER_STATUS.DONE) {
          clearTimeout(this.wait); 
          this.updateFeatureDetails();
        }
        this.isGreetingsFileUploaded = status;
      }) 
      
      this.parkLocSubscription = this.tablePreviewService.getParkLocDataStatus().subscribe((status:any) => {
        if (status == false){
          this.wait = setTimeout(() => {
              clearTimeout(this.wait);
            }, TIMER_LIMIT)
        } else if(status == true) {
          clearTimeout(this.wait);
        }
        this.parkLocDataPrepared = status;
        if (this.parkLocDataPrepared) {
          this.numberOfParkLocations = this.tablePreviewService.tableParkLocationList.length.toString();
        }
      })
      
     }

  ngOnInit(): void { 
    this.subscription = this.tablePreviewService.getTableDataInitializedObs().subscribe((res)=>{
    this.isDataReady = res;
    this.isCompanyNumberAssignemntModified()
    .then(() =>{
      this.logger.debug("updated Company Numbers");
      this.numberOfHuntGroups = this.tablePreviewService.tableHuntGroupList.filter(el=>!el.isPagingEnabled).length.toString();
      this.numberOfPagingGroups = (this.tablePreviewService.tableHuntGroupList.length- this.numberOfHuntGroups).toString();
      this.numberOfTimeProfiles = this.tablePreviewService.tableTimeProfileList.length.toString();
      this.isMixedTPFound = this.tablePreviewService.timeProfilesWarningsList.length>0?true:false; 
      this.updateFeatureDetails();
    })
  })    
  }

  ngOnDestroy(){
    this.subscription.unsubscribe();
    this.greetingsSubscription.unsubscribe();
    if (this.parkLocSubscription) this.parkLocSubscription.unsubscribe();
    this.spinner.hide();
    clearTimeout(this.wait);
  }

  showHuntGroups(){
    if(this.isDataReady && this.isGreetingsFileUploaded !== this.greetingsTriggerStatus.START)
      this.zone.run(() => { this.router.navigate(['/home/migration/hunt-group-info']); });
  }

  showParkLocations(){
    if(this.isDataReady)
      this.zone.run(() => { this.router.navigate(['/home/migration/park-location-info']); });
  }

  showAutoAttendants(){
    if(this.isDataReady && this.isGreetingsFileUploaded !== this.greetingsTriggerStatus.START)
      this.zone.run(() => { this.router.navigate(['/home/migration/auto-attendants-info']); });
  }
 
  showTimeProfiles(){
    if(this.isDataReady)
        this.zone.run(() => { this.router.navigate(['/home/migration/time-profiles-info']); });
  }
  
  showIncomingNumbers(){
    if(this.isDataReady && this.isGreetingsFileUploaded !== this.greetingsTriggerStatus.START)
      this.zone.run(() => { this.router.navigate(['/home/migration/incoming-numbers-info']); });
  }
  getAutoAttendantToolTipText(){
    var toolTipwarnList=[];
    let aaWarnings = this.tablePreviewService.autoAttendantsWarningsList;
    if(aaWarnings.get("MainSite") !== undefined)
    {
    if(aaWarnings.get("MainSite").find(entry=>entry.includes(AA_ERROR_MSG.UNMATCHED_KEY_ACTIONS)) != undefined){
      toolTipwarnList.push(AA_ERROR_MSG.UNMATCHED_KEY_ACTIONS);
    }
    if(aaWarnings.get("MainSite").find(entry=>entry.includes(AA_ERROR_MSG.PROMPTS_MISSING))!= undefined) {
      toolTipwarnList.push(AA_ERROR_MSG.PROMPTS_MISSING);
    }  
    }  
    return toolTipwarnList.join('\n');
  }


  updateFeatureDetails(){
    this.numberOfAutoAttendants = this.tablePreviewService.tableAutoAttendantList.length; 
    this.numberofIncomingNumbers = this.tablePreviewService.incomingNumbersCount;
    if(this.tablePreviewService.autoAttendantsWarningsList.get("MainSite") !== undefined)
    {
      this.isAAWarnFound = (this.tablePreviewService.autoAttendantsWarningsList.get("MainSite").length>0?true:false) ;
      this.aaToolTipText = this.getAutoAttendantToolTipText();
    }
    this.isHGWarnFound = this.tablePreviewService.huntGroupWarningsList.length>0?true:false;
    this.isPGWarnFound = this.tablePreviewService.pagingGroupsWarningsList.length>0?true:false;
    this.isParkLocationWarnFound = this.tablePreviewService.parkLocationWarningsList.length>0?true:false;
  }

  isCompanyNumberAssignemntModified(){
    var promise = new Promise<void>((resolve, reject) => {
      this.firestoreService.readAA_ICRData(this.id)
      .then((aaICRData:any) => {
        this.isCompanyNumberAssigned = JSON.parse(aaICRData.isUpdated);
        resolve();
      })
      .catch((error) => {
        this.logger.error(error);
        reject(error);
      });
    })
    return promise;
  }
}
