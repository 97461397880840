import { FireAuthService } from './../firebaseAuth/fire-auth.service';
import { environment } from '../../../../environments/environment';
import { LogService } from '../logging/log.service';
import { Injectable, NgZone } from '@angular/core';
import { Subject, Observable } from 'rxjs';
declare var gapi: any;
declare var google: any;
@Injectable({
  providedIn: 'root'
})
export class GSignInService {

  private gToken:any;
  private profileInfo:any;
  private subject = new Subject<any>();
  
  constructor(private fireauthService:FireAuthService,
    private logger:LogService,public zone: NgZone ) {
    this.logger.info("GSignInService constructor");
    //this.googleInit();
    this.initClient()
  }

  sendMessage(data) {
    this.subject.next(data);
  }

  clearMessage() {
    this.subject.next();
  }

  getMessage(): Observable<any> {
    return this.subject.asObservable();
  }

  public auth2: any;
  
  public googleInit() {
    gapi.load('auth2', () => {
      this.auth2 = gapi.auth2.init({
        client_id: environment.client_id,
        cookie_policy: environment.cookie_policy,
        scope: environment.scope
      });
      //this.attachSignin(document.getElementById('googleBtn'));
    });
  }

  initClient() {
    /*  this.client = google.accounts.oauth2.initTokenClient({
       client_id: '442340770447-b9l7th4gsh3jl1d58tgvhv7ghianurbo.apps.googleusercontent.com',
       scope: 'profile email',
       callback: (tokenResponse) => {
         this.access_token = tokenResponse.access_token;
       },
     }); */
    this.zone.run(() => {
      try{
        let client = google.accounts.id.initialize({
          client_id: environment.client_id,
          callback: this.handleCredentialResponse.bind(this)
        });
        /* google.accounts.id.renderButton(document.getElementById("googleBtn"),
        { theme: "filled_blue", size: "large" }); */
      }catch(err){
        this.logger.debug("reloading page, since GIS not loaded");
        window.location.reload();
      }
    }); 
  }

  getGoogleInstance(){
    return google;
  }

  handleCredentialResponse(credentialResponse) {
   this.logger.debug("google service - handleCredentialResponse");
    this.sendMessage(credentialResponse);
  }

  public attachSignin(element) {
    this.auth2.attachClickHandler(element, {},
      (googleUser) => {

        this.profileInfo = googleUser.getBasicProfile();
        //this.logger.debug('Token || ' + googleUser.getAuthResponse().id_token);
        this.logger.debug('ID: ' + this.profileInfo.getId());
        this.logger.debug('Name: ' + this.profileInfo.getName());
        this.logger.debug('Email: ' + this.profileInfo.getEmail());

        //Broadcast with data 
        this.sendMessage(googleUser);

      }, (error) => {
        this.logger.error(JSON.stringify(error, undefined, 2));
      });
  }

  public signIn(){
    this.logger.debug("signIn");
    var promise = new Promise((resolve, reject) => {
      this.auth2.signIn().then((user) => {
        this.logger.debug("google signin successfull")
        this.profileInfo = user.getBasicProfile();
        resolve(user);
      }).catch((err) =>{
        this.logger.error("google signin failed :"+err)
        reject(err);
      });
    });

    return promise;
    
  }

  public signOut(){
    var promise = new Promise((resolve, reject) => {
      this.auth2.signOut().then((data) => {
        this.logger.info("google logout successfull")
        resolve(data);
      }).catch((err) =>{
        this.logger.error("google logout failed :"+err)
        reject(err);
      });
    });

    return promise;
    
  }

  public getProfileInfo(){
      return this.profileInfo;
  }
}
