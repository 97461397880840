import { Injectable } from '@angular/core';
import { LogService } from '../logging/log.service';
import { FirestoreService, TRIGGER_STATUS } from '../firestore/firestore.service';
import { SessionHandlerService } from 'src/app/shared/services/sessionHandler/session-handler.service';
import { OverlayService } from 'src/app/shared/services/overlay/overlay.service';
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';
import { MatSpinnerOverlayComponent } from 'src/app/shared/components/mat-spinner-overlay/mat-spinner-overlay.component';
import * as firebase from 'firebase/app';
import { TablePreviewService } from 'src/app/shared/services/tablePreview/table-preview.service';
import { ParkLocationPreviewComponent } from 'src/app/user/components/park-location-preview/park-location-preview.component';

const tldjs = require('tldjs');

const GROUP_TYPE = {
    CALL_QUEUE: "Call-queue",
    PAGING_GROUP: "Paging Group"
}
const MAX_CALLERS =25, MAX_HOLD_TIME = 900, PICKUP_MEMBER_LIMIT = 25;
const HG_PG_WARNING = {
    PG_AA_KEYS : "Paging group(s) not considered for ACO IVR menu keys",
    PG_NO_DEVICE_ASSIGNED : "Page capable device is not assigned for extension number",
    PG_NO_DEVICE_ASSIGNED_MULTIPLE : "Page capable devices are not assigned for number",
    HG_QUEUE_LENGTH_NO_LIMIT : "* Call Queues MaxCallers will be set to 25 calls and HoldTime of Queue will be set to 15min (900sec) since Queue Length is set to ‘No Limit’.\n",
    HG_OVERFLOW_REJECTED_GROUPS: "* Only first 3 viable overflow groups will be migrated into call queue overflow section. ",
    HG_ROUTING_REJECTED_GROUPS: "* Only first viable overflow group will be migrated into the routing section. ",
    HG_NOT_MIGRATING_OVERFLOW_GROUPS: "Not migrating overflow group(s): ",
    HG_OVERFLOW_REJECT_SIMULTANEOUS_RING_TYPE:"* Overflow groups cannot be configured into call queue's overflow section with ring type Simultaneous\n",
    HG_OVERFLOW_WAIT_TIME_EXCEEDED: "* Exceeded maximum overflow time of 15minutes. Maximum value will be set to 15 minutes.\n",
    HG_OVERFLOW_MAX_CALLERS_EXCEEDED: "* Exceeded maximum callers value of 25 calls. Maximum callers will be set to 25\n",
    //HG_OVERFLOW_WAIT_TIME_NOT_SET: "* Call queue wait time will be set to 15 minutes (900sec) since overflow time is set to 0\n",
    HG_OVERFLOW_MODE_UNSUPPORTED:"* Configured Group overflow mode is not supported in ACO\n",
    HG_OVERFLOW_FEATURE_NOT_SUPPORTED:"* ACO is standard licensed. Call-queue overflow functionality is not supported\n",
    HG_OVERFLOW_PAGING_GRP_SKIPPED:"* Overflow group(s) which is/are converted to paging group(s) is/are skipped: ",
    HG_OVERFLOW_RESERVED_EXTN_SKIPPED:"* Overflow group(s)  which is/are reserved extension(s) is/are skipped:",
    HG_OVERFLOW_EMPTY_GROUP:"* Migrating call-queues with no viable groups in overflow groups lists\n",
    VM_ENABLED_NO_OVERFLOW: "* Not migrating overflow groups into routing section as voicemail is enabled\n"
   // HG_OVERFLOW_TIME_DISABLED: "* Not migrating overflow groups as overflow time is disabled\n",
};

const PARK_LOCATION_WARNING = {
    PARK_LOCATION_EXTENSION_EXISTS: "Park Location extension(s) already in use by ACO\n",
    PARK_LOCATION_RESERVED_EXTENSION: "Park Location(s) assigned with reserved extension\n",
    PARK_LOCATION_BTN_PRGM_NOT_CONFIGURABLE : "There is no API support to configure Park Locations under button programming currently, it must be configured manually to Park Location members in ACO.\n",
    PARK_LOCATION_EXT_EMPTY: "\nCall Park configured with empty extension number under users will not be migrated to ACO : ",
    SKIP_MESSAGE_ONLY_PARK_LOCATION: "\nPark Locations under message-only users will be skipped and following message-only users will not be configured as Park Location members : ",
    SKIP_LIMITED_EXTENSION_PARK_LOCATION: "\nPark Locations under limited extension users will be skipped and following limited extension users will not be configured as Park Location members : ",
    DUPLICATE_EXTENSION_PARK_LOCATION: "\nPark Locations configured with duplicate extensions in current migration will not be migrated"
}

export enum phoneStatus{
    unassigned,
    assigned
}
export enum SEVERITY{
    WARNING,
    ERROR,
    INFO
}

const GREETINGS_TYPE = {
    DEFAULT : "Default",
    CUSTOM : "Custom"
}
const PAGE_DEV_LIMIT = 25;

export const LIMITED_EXTENSION = "Limited Extension";
export const XLSX_SHEETNAME = {
    USER_DATA : "User List and Email",
    TWINNING_DATA : "Ring Group FWD Twinning",
    CALL_QUEUES : "Call Queues & Paging Only",
    PARK_LOCATION : "Park Location & Members",
    AUTO_RECEPTIONIST : "Auto Receptionist",
    ALL_NUMBERS :"All Numbers",
    BUTTON_PROGRAMMING : "Button Programming",
    KEY_TEMPLATES : "Key_Templates",
}
    const MIGRATION_SUMMARY_STRINGS = {
    INITIAL : "Please click 'Start Migration' to start.",
    INPROGRESS : "Migration in progress...",
    SUCCESSFUL : "Migration Successful.",
    FAILURE : "Migration Failed.",
    FAILURE_RETRY : "Migration Failed. Click 'Retry' to try again.",
    MORE_OPTIONS : "(Click Here for more details)",
    STATUS: "Status :",
    START_TIME: "Start Time :",
    END_TIME : "End Time :",
    DURATION : "Duration :",
    TOTAL_USERS : "Total Users :",
    SUCCESSFUL_USERS : "Successful Users :",
    FAILURE_USERS : "Failure Users :",
    FAILURE_USER_DATA_GENERIC : "Connection Error",
    FAILURE_USER_DATA_ACOERROR : "acoerror",
    COMPLETED_BY_USER : "Migration is completed manually.", 
    TASK_ABORTED: "Oops! Something went wrong. Retry or if error persist, please contact Administrator",
    ERRORS_FOUND_IN_TABLE: "Resolve errors to Start Migration",
    PRE_CONFIG_TOOLTIP: "Click to download Pre-config",
    PRE_CONFIG_TOOLTIP_ERROR: "User Data contents are missing"
}
export const XLSX_ERROR = {
    FIRST_LAST_NAME: "* First and last name is not assigned\n",
    RESERVED_EXTENSION: "* Reserved Extension\n",
    GRP_RESERVED_EXTENSION: "extension is a reserved extension\n",
    DUPLICATE_EXTENSION_ACO: "* Duplicate Extenison in ACO\n",
    DUPLICATE_GRP_EXTENSION_ACO : "extension is already in use by ACO\n",
    EMAIL_NOT_CONFIGURED: "* Email not configured\n",
    DUMMY_EMAIL: "* Default Email assigned\n",
    INVALID_SYSMON_COMBINATION_ASSIGNED: "* Invalid Phone Model or MAC Id assigned as per sysmon file\n",
    INVALID_EMAIL: "* Invalid Email configured\n",
    DUPLICATE_EMAIL_ACO: "* Duplicate Email in ACO\n",
    DUPLICATE_EMAIL_CSV: "* Duplicate Email in CSV\n",
    ACO_PHONE_MODEL: "* ACO Phone Types are not assigned\n",
    TWINNING_NOT_CONFIGURED: "* Unchecked Mobile User Ring Group configuration will be disabled after migration\n",
    TWINNING_DID_MISSING: "* Assigned phone model does not have DID number\n",
    LE_TWIN_NOT_MIGRATED: "* Not migrating ring group number for Limited Extension\n",
    MO_TWIN_NOT_MIGRATED: "* Not migrating ring group number for MessageOnly Extension\n",
    NO_MEMBERS: "* Empty group will be migrated\n",
    MISSING_EXTENSION_CQ: "* Extension number is missing, Call Queue will not be migrated\n",
    MISSING_EXTENSION_PAGING: "* Extension number is missing, Paging group will not be migrated\n",
    MULTIPLE_ANNOUNCEMENTS: "* Multiple announcement files for VMPro call flows\n",
    VOICEMAIL_TIMEOUT: "* Voicemail answer time exceeding ACO maximum hold time of 900s. Setting maximum of 900s for voicemail answer time\n",
    COLLECTIVE_CALLWAITING: "* Call Pickup feature is enabled for this call queue since Ringmode is set to Collective Call Waiting\n",
    PICKUP_MEMBERS_LIMIT_EXCEEDED : "* Maximum of 25 pickup members can be configured per call queue group. The limit is exceeded for this call queue",
    EMPTY_MEMBERS: "* No pickup members set as the call queue is empty\n",
    MAX_LIMIT: "* Max limit of "+PAGE_DEV_LIMIT+" paging devices reached in the paging group. Not migrating ",
    ANNOUNCEMENTS_NOTMIGRATED: "* Announcement not migrated for Call Queue\n",
    VM_NOTMIGRATED: "* Voicemail Greeting not migrated for Call Queue\n",
    LIMITED_EXTENSION: "* Assigned with Limited Extension\n",
    HG_LIM_EXTN_UNSUPPORTED: "* Call Queue member(s) configured as Limited Extension will not be migrated\n" ,
    USER_BUSINESS_HOURS: "* User custom hours, business hours, after hours will not be migrated for this Limited Extension\n",
    MO_USER_BUSINESS_HOURS: "* User custom hours, business hours, after hours will not be migrated for this MessageOnly Extension\n",
    LE_WITH_BLF_UNSUPPORTED: "* Configured Presence will not be migrated for this Limited Extension\n",
    MO_WITH_BLF_UNSUPPORTED: "* Configured Presence will not be migrated for Message Only extensions\n",
    VM_UNSUPPORTED_FOR_LE : "* User Voicemail greeting will not be migrated for this Limited Extension user\n",
    VM_PARTIAL_USERS : "* Voicemail greetings is partially uploaded\n",
    VM_IGNORED_USERS : "* Ignoring the uploaded voicemail greeting as file size is greater than max limit of 20MB\n",
    CQ_MESSAGEONLY : "\n* List of member(s) configured as Message Only extension will not be migrated for the Call Queue :",
    PG_MESSAGEONLY : "\n* List Of Paging Only Group member(s) configured as Messge Only Extension will not be migrated for the Paging Only Group :",
    VM_DISABLED_WAITTIME_UPDATED_OVERFLOW_SUPPORT : "* Call Queue Voicemail status is disabled and ACO is SERVICE_EDITION licensed. \"Maximum caller wait time in queue\" is set to 15 minutes and overflow group can be configured under routing section\n",
    VM_DISABLED_WAITTIME_UPDATED: "* Call Queue Voicemail status is disabled and ACO is SERVICE_EDITION licensed. \"Maximum caller wait time in queue\" is set to 15 minutes\n",
    ICR_NO_DEST: "* No Destination set\n",
    ICR_NO_CUST_HOURS: "* No After hours Rules set\n",
    ICR_EXTERNAL_NUMBER: "* Forward to external number\n",
    ICR_CONFERENCE: "* This is an external conference number,please discuss with customer\n",
    //NO_GREETINGS: "* Greetings file is not uploaded \n"
    NO_DESTINATION: "* Auto Receptionist destination not available for the configuration\n",
    CQ_MEMBERLIMIT_EXCEEDED: "* Manual configuration of 'Route call to members' field has to be done for Call Queue (Collective Call Waiting or Simultaneous) with more than ",
    PICKUP_MEMBERS_NOT_MIGRATED: "* Pickup Members not migrated for this Call Queues since the member limit exceeds ACO Simultaneous CQ Member Limit.\n",
    PICKUP_MEMBER_CQ_LIMIT_EXCEEDED: "* Pickup member(s) for the Call Queue is/are part of more than 10 Call Queues"
}  

const ACO_SERVICE_EDITION_LIST = ['premium', 'enterprise', 'standard', 'ultimate'];

interface pagingGroupData {
    name: string, 
    extension:string,
    devices:Array<string>,
}
interface hgTableData {
    name :string,
    wrapUpTime:number,
    overflowGroup:string,
    extension:number,
    isOverflowMigrationEnabled:boolean,
    isRoutingMigrationEnabled:boolean
}
@Injectable({
    providedIn: 'root'
  })
export class PreConfigDownloadService {
    private readonly TIMER_INTERVAL=90000;
    private id:any;
    private hgVMComplexList;
    private hgVMMissingList;
    private missingHGAnnList;
    private unsupportedHGAnnList;
    private chainedAnnHGList;
    private ignoredUsersList;
    private partialUsersList;
    public reservedExtensionsList = [];
    private callQueueData;
    private parkLocationData = [];
    private parkLocDupExtnList = [];
    private parkLocResvExtnList = [];
    private emptyParkLocExtUserList = [];
    private skippedMessageOnlyUserExtns:any;
    private skippedLimitedExtnUserExtns:any;
    private timeProfilesData;
    private extensionList;
    private pageGroupData = [];
    private hgTableEntryData = [];
    public preConfigToolTip = MIGRATION_SUMMARY_STRINGS.PRE_CONFIG_TOOLTIP;
    private aaMenuData:any = [];
    private buttonProgData:any = [];
    private userRestrictionData:any = [];
    private userTemplatesData:any = [];
    private aaWarningsData = [] ;
    private aaNameWithKeysWarning = [];
    private workbook = new Workbook();
    private sysmonData = [];
    private messageOnlyData;
    private preConfigICRData = [];
    private userTableData = [];
    private aaICRData = [];
    private conferenceMeetMeList;
    private isRootVmpFileFound;
    
    constructor(private logger: LogService, private firestoreService:FirestoreService,private session:SessionHandlerService,private overlay:OverlayService, private tablePreviewService:TablePreviewService) {
        this.logger.debug("Pre Configuration Download service");
        //this.id = this.session.cardId;
    }
    isLimitedExtn(acoPhoneModel: string) {
        var reg = new RegExp(".* "+LIMITED_EXTENSION+"$","i");
        return reg.test(acoPhoneModel);
    }
 
    downloadExcel(companyName,id){       
        this.overlay.openSpinner(this.TIMER_INTERVAL,this.timeupCallbackfunc,MatSpinnerOverlayComponent);
        let acoAccountId:string = "",countryName:string ="",assignedExtnList = [],assignedMailList = [] , callFwdData = [], serviceEdition:string = "", cqMemberLimit, deletedUserList:any=[];
        
        this.firestoreService.readSysmonData(id)
        .then((sysmonStatus:any) => {
            if (sysmonStatus !== undefined && sysmonStatus.trigger !== TRIGGER_STATUS.NOTYET) {
                return this.downloadFileFromBucket(`Greetings/${id}/IpoCfg/sysmon.json`);
            } else {
                return [];
            }
        })
        .then((sysmonData:any) => {
            if (sysmonData !== undefined && sysmonData.data !== undefined && sysmonData.data.length > 0) {
                this.sysmonData = sysmonData.data;
            }
            return this.firestoreService.getAssignedExtensions(id);
        })
        .then((doc:any) => {   
            let ExtnDetails  = JSON.parse(doc.data);
            ExtnDetails.forEach(extnId => {
                if(extnId.extensionNumber){
                    assignedExtnList.push(extnId.extensionNumber);
                }    
                if(extnId.contact && extnId.contact.email){
                    assignedMailList.push(extnId.contact.email);
                }  
            });
            return this.firestoreService.getCardDetails(id);
        })
        .then((acoDetails:any) =>{
            acoAccountId = acoDetails.accountId.toString();
            countryName = acoDetails.country;
            serviceEdition = acoDetails.serviceEdition; 
            if(acoDetails.cqMemberlimitAvaialbility === true)
                cqMemberLimit = acoDetails.cqMemberLimit;      
            return this.firestoreService.readCallFwdData(id);
        })
        .then((callFwdDocData:any) =>{
            if(callFwdDocData){
                callFwdData = JSON.parse(callFwdDocData.data);
            }
            return this.firestoreService.getGreetingsData(id)
            .then((greetingsData:any) =>{
                if(greetingsData){
                    this.hgVMComplexList = JSON.parse(greetingsData.hgVMComplexList);
                    this.hgVMMissingList = JSON.parse(greetingsData.hgVMMissingList);
                    this.missingHGAnnList = JSON.parse(greetingsData.missingHGAnnList);
                    this.unsupportedHGAnnList = JSON.parse(greetingsData.unsupportedHGAnnList);
                    this.chainedAnnHGList = JSON.parse(greetingsData.unsupportedHGAnnList);
                    this.ignoredUsersList = greetingsData.ignoredUsers !== undefined ? JSON.parse(greetingsData.ignoredUsers):undefined;
                    this.partialUsersList = greetingsData.partialUsers !== undefined ? JSON.parse(greetingsData.partialUsers):undefined;
                }
            })
            .catch((error)=>{
                this.logger.debug(error);
            })
        })
        .then(() =>{
            return this.downloadFileFromBucket('reservedExtensions/reservedExtensions.json')
        })      
        .then((reservedExtnList:any) =>{
            //reserved extension handling
            let reservedList = reservedExtnList.find(data=> data.country == countryName);
            if(reservedList){
                this.reservedExtensionsList = reservedList.extensions;                
            }
            return this.firestoreService.readHGnPGTableData(id);
        })
        .then((pgData:any) =>{
            this.pageGroupData = [];
            this.hgTableEntryData = [];
            if(pgData !== "")
            {
                let pagingGroupList = JSON.parse(pgData);
                if (pagingGroupList !== undefined && pagingGroupList.length > 0) {
                    pagingGroupList.forEach(element => {
                        if (element.isPagingEnabled === true) {
                            let pagingEntry: pagingGroupData = {} as any;
                            pagingEntry.name = element.name;
                            pagingEntry.extension = element.extension;
                            pagingEntry.devices = element.devices;
                            this.pageGroupData.push(pagingEntry);
                        }
                        else {
                            let callQueueEntry: hgTableData = {} as any;
                            callQueueEntry.name = element.name;
                            callQueueEntry.wrapUpTime = element.wrapUpTime;
                            callQueueEntry.extension = element.extension;
                            callQueueEntry.overflowGroup = element.overflowGroup;
                            callQueueEntry.isOverflowMigrationEnabled = element.isCallQueueMigrationEnabled;
                            callQueueEntry.isRoutingMigrationEnabled = element.isRoutingMigrationEnabled;
                            this.hgTableEntryData.push(callQueueEntry);
                        }
                    });
                }
            }
            return this.downloadFileFromBucket(`Greetings/${id}/IpoCfg/huntGroup.json`);       
        })
        .then((callQueueList:any) =>{ 
            this.callQueueData = callQueueList.data; 
            return this.downloadFileFromBucket(`Greetings/${id}/IpoCfg/parkLocation.json`)
        })
        .then((parkLocationList:any) => {
            if (parkLocationList != null && parkLocationList.data) {
                this.parkLocationData = parkLocationList.data;

                if (parkLocationList.duplicateExtnList) {
                    this.parkLocDupExtnList = parkLocationList.duplicateExtnList;
                }
                if (parkLocationList.reservedExtnList) {
                    this.parkLocResvExtnList = parkLocationList.reservedExtnList;
                }
                if (parkLocationList.emptyParkLocConfigUserList) {
                    this.emptyParkLocExtUserList = parkLocationList.emptyParkLocConfigUserList;
                }
                if (parkLocationList.skippedMessageOnlyUserExtns) {
                    this.skippedMessageOnlyUserExtns = new Map(parkLocationList.skippedMessageOnlyUserExtns);
                }
                if (parkLocationList.skippedLimitedExtensionUserExtns) {
                    this.skippedLimitedExtnUserExtns = new Map(parkLocationList.skippedLimitedExtensionUserExtns);
                }
            }
            return this.downloadFileFromBucket(`Greetings/${id}/AcoExportCfg/timeProfiles.json`)
        })
        .then((timeProfilesList:any) =>{
            this.timeProfilesData = (timeProfilesList !== undefined && timeProfilesList.data !== undefined) ? timeProfilesList.data : [];
            return this.firestoreService.getMessageOnlyData(id);
        })
        .then((messageOnlyList:any) =>{
            if(Array.isArray(messageOnlyList.extensions) && messageOnlyList.extensions.length > 0)
                this.messageOnlyData = messageOnlyList.extensions;
                return this.firestoreService.getVMProDocData(id)
        })
        .then((data:any) =>{
            if(data !== null && data !== undefined){
                this.isRootVmpFileFound = (data.rootVmpFileFound !== undefined && data.rootVmpFileFound !== null && 
                    data.rootVmpFileFound !== "") ? data.rootVmpFileFound : false;
                    if(this.isRootVmpFileFound){
                    this.conferenceMeetMeList =  JSON.parse(data.conferenceMeetMeList);
                    }
            }
            return this.firestoreService.getPreconfig_ICRData(id)
        })
        .then((preConfigICR:any) =>{    
            let tempData =(preConfigICR !== null)? JSON.parse(preConfigICR.data): [];
            let tempPreconfigData = [];
            this.preConfigICRData = [];
            tempData.forEach(data => {
                data.forEach(element => {
                    let list = {
                    destination:element.name,
                    timeProfile: element.timeProfile,
                    icrNumber: element.icrNumber,
                    siteName: element.siteName
                }           
                 tempPreconfigData.push(list);
                });
            });
            let count = 0 , finalList;
            for(let i = 0 ; i < tempPreconfigData.length ; i++)
            {
                let companyHoursData = [];
                for(let j = i+1 ; j < tempPreconfigData.length ; j++)
                {   
                    let data;
                
                    if(tempPreconfigData[j].icrNumber === tempPreconfigData[i].icrNumber)
                    {
                        data = {
                            destination:  tempPreconfigData[j].destination,
                            timeProfile:  tempPreconfigData[j].timeProfile,
                        }
                        companyHoursData.push(data);
                        count = j;
                        //tempPreconfigData.splice(j,1);
                    }
                   
                }
                if(companyHoursData.length > 0)
                {
                companyHoursData.forEach(element => {
                    finalList = {
                        ICRNumber :  tempPreconfigData[i].icrNumber,
                        AfterHours : tempPreconfigData[i].destination,
                        CompanyHours :element.destination,
                        TimeProfiles :element.timeProfile,
                        siteName: tempPreconfigData[i].siteName
                    }
                    this.preConfigICRData.push(finalList);
                })
                    i = count;
                }
                else{
                    finalList = {
                        ICRNumber :  tempPreconfigData[i].icrNumber,
                        AfterHours : tempPreconfigData[i].destination,
                        CompanyHours :'',
                        TimeProfiles :tempPreconfigData[i].timeProfile,
                        siteName: tempPreconfigData[i].siteName
                    }
                    this.preConfigICRData.push(finalList);
                }
               
            }
            return this.firestoreService.readOriginalAA_ICRData(id)
        })
        .then((ICRData:any)=>{
               let data = JSON.parse(ICRData.data);
               let aaList = [];
               data.forEach(element => {
                   element.forEach(data => {
                    aaList.push(data.name); 
                   });                
               });
               this.aaICRData =  [...new Set(aaList)];
               return (this.firestoreService.readDeletedUsersCfgData(id));
        }) 
        .then(async (deletedUsersDocData:any) => {
            deletedUserList = (deletedUsersDocData !== null)? JSON.parse(deletedUsersDocData.data): [];
            return this.firestoreService.getNotificationData(id)
        })          
        .then((notificationData:any)=>{
            this.aaWarningsData= JSON.parse(notificationData.configSummaryNotifications.systemData.aaWarning.warnings);
            this.aaWarningsData.forEach(siteArray => {
                if (siteArray[1] !== undefined && siteArray[1].length !== 0) {
                    let aaNameWithWarning = siteArray[1].toString().split(":");
                    //let [first, ...rest] = aawarning.split(":");
                    if (aaNameWithWarning !== undefined && aaNameWithWarning.length === 2) {
                        //AA name will be aaNameWithWarning[0]
                        //all keys and warnings will be in aaNameWithWarning[1]
                        //complex flow warning needs to be ignored
                        //Call flows with timeout entries needs to be ignoredaa
                        let warningList = aaNameWithWarning[1].split("\n\t");
                        let tempList = this.aaWarningFilteringTemplate();
                        let generalWarningList = [];
                        let dialByExtnWarningList = [];
                        warningList.forEach(warningMsg => {
                            if (warningMsg.trim() === "IVR prompt needs to be re-recorded" || warningMsg.trim() === "Prompts missing" || warningMsg.trim() === "No keys to match") {
                                generalWarningList.push(warningMsg);
                            } else if (warningMsg.includes("Mapped to Replay Greeting on ACO")) {
                                tempList.warningList.push({ key: warningMsg.split("-")[0].trim(), warningMsg: warningMsg.split("-")[1].trim() });
                            } else if (warningMsg.includes("No matching Key Press action for")) {
                                let actionMissing = warningMsg.split(",");
                                actionMissing.forEach((element, index) => {
                                    if (index === 0) {
                                        let firstkey = element.replace("No matching Key Press action for ", "").split("-")[0].trim();
                                        tempList.warningList.push({ key: firstkey, warningMsg: "No matching Key Press action" });
                                    } else {
                                        tempList.warningList.push({ key: element.split("-")[0].trim(), warningMsg: "No matching Key Press action" });
                                    }
                                });
                            }
                            else if (warningMsg.includes('Dial by extension is supported')) {
                                dialByExtnWarningList.push(warningMsg);
                            }
                        });
                        tempList.aaName = aaNameWithWarning[0].trim();
                        tempList.warningList.push()
                        tempList.generalWarnings = generalWarningList.join(',');
                        tempList.dialByExtnWarning = dialByExtnWarningList.join(',');
                        this.aaNameWithKeysWarning.push(tempList);
                    }
                }
            })
            return this.downloadFileFromBucket(`Greetings/${id}/IpoCfg/buttonProgramming.json`);   
        }).then((buttonData:any)=>{
            //this.logger.debug("fetched AAMenu data successfully",aaData);
            this.buttonProgData= buttonData.data;
            return this.downloadFileFromBucket(`Greetings/${id}/IpoCfg/userRestrictionData.json`);
        }).then((userRestrictionData:any)=>{
            //this.logger.debug("fetched AAMenu data successfully",aaData);
            this.userRestrictionData= userRestrictionData.data;
            return this.downloadFileFromBucket(`Greetings/${id}/IpoCfg/autoAttendant.json`);
        })
        .catch((error) =>{
            this.logger.debug("downloading userRestriction data failed. " ,error);
        })
        .then((aaData)=>{
            //this.logger.debug("fetched AAMenu data successfully",aaData);
            this.aaMenuData = aaData;
            return this.downloadFileFromBucket(`Greetings/${id}/IpoCfg/tableData.json`);
        })
        .then(async (result:any) => {

            if(Object.keys(result).length !== 0){
                this.userTableData = result;
                await this.resetWorkSheets();
                let worksheet = this.workbook.addWorksheet(XLSX_SHEETNAME.USER_DATA);
                worksheet.properties.defaultColWidth = 20;
            
                //add column name
                let accountheader=["ACO Account ID", "Download time"];
                let accountDeatials = [acoAccountId,Date()];
                let userInfoHeader = ["Can be migrated?","Extension Number","Site Name","User Name","Email","ACO Phone Types","Serial Number","Native Phone Number","Presence","Contacts","Voicemail Greeting","Errors","Warnings"];
                let headerRow = worksheet.addRow(accountheader);
                worksheet.getRow(1).font = { size: 14, bold: true };
                worksheet.addRow(accountDeatials);
                const bgColorRow = worksheet.addRow([]);
            
                //let bgRow = worksheet.addRow([]);
                worksheet.addRow([]).fill = {
                    type: 'pattern',
                    pattern:'solid',
                    fgColor:{argb:'FFFFFF00'},
                    bgColor:{argb:'FF0000FF'}
                };    
                worksheet.addRow(userInfoHeader);
                worksheet.getRow(5).font = { size: 11, bold: true };            
                let twinningDataSheet = this.workbook.addWorksheet(XLSX_SHEETNAME.TWINNING_DATA);
                let twinningInfoHeader = ["Can be migrated?","Extension Number","User Name","Call handling and Forwarding","Final User Ring Group Number","Errors","Warnings"];
                twinningDataSheet.addRow(twinningInfoHeader);
                twinningDataSheet.getRow(1).font = { size: 14, bold: true };
                twinningDataSheet.properties.defaultColWidth = 30;    
                let callQueueDataSheet =this.workbook.addWorksheet(XLSX_SHEETNAME.CALL_QUEUES);
                let callQueueInfoHolder = ["Can be migrated?","is Paging Only","Call Queue Name","Call Queue Extension Number","Site Name","Queue Manager Email","Call Queue Members (Ext)","Page Capable Devices","Working Hours","Interrupt Audio","Interrupt Prompt","Ring Type","IPO Collective Call Waiting in ACO=Call Queue Pickup","User Ring Time","Total Ring Time","Voicemail Greeting","Is Overflow Migration Enabled?","Is Routing Overflow Migration Enabled?","Call Queue Overflow","WrapUp Time","Errors","Warnings"];
                callQueueDataSheet.addRow(callQueueInfoHolder);
                callQueueDataSheet.getRow(1).font = { size: 14, bold: true };
                callQueueDataSheet.properties.defaultColWidth = 35;
                this.xlsxCSSFontWeight(callQueueDataSheet.getRow(1), 13);
                this.xlsxCSSFontWeight(callQueueDataSheet.getRow(1), 18);
                 let allNumbersDataSheet =this.workbook.addWorksheet(XLSX_SHEETNAME.ALL_NUMBERS);
                let allNumbersInfoHolder = ["Can be migrated?","Company Numbers","Site Name","After Hours Destination","Business Hours destination","Is the Business Hours Destination An Auto-Receptionist/Call Queue/User Ext/Message Only?","Is After Hours Destination An Auto-Receptionist/Call Queue/User Ext/Message Only?","Working Hours Name","Working Hours details","Errors", "Warnings"];
                allNumbersDataSheet.addRow(allNumbersInfoHolder);
                allNumbersDataSheet.getRow(1).font = { size: 14, bold: true };
                allNumbersDataSheet.properties.defaultColWidth = 30;
                let limitedExtnList=[];
                result.forEach((doc,index) => {
                    let temp=[] ,twinTempData =[];
                    temp.push("Yes");
                    temp.push(doc.extension.toString());
                    temp.push(doc.siteName);
                    temp.push(doc.username);
                    temp.push(doc.email);
                    temp.push(doc.displayPhoneModel);
                    temp.push(doc.macId)
                    temp.push(doc.did);
                    temp.push(doc.blfList.length);
                    temp.push(doc.contactList.length);
                    
                    let isLimited = this.isLimitedExtn(doc.acoPhoneModel);
                    let isIgnored , isPartialUpload;
                    if(this.ignoredUsersList !== undefined){
                        this.ignoredUsersList = this.ignoredUsersList.filter(entry=>entry); 
                        isIgnored = this.ignoredUsersList.find((entry)=>entry === doc.ipoUserName);
                    }
                    if(this.partialUsersList !== undefined){
                        this.partialUsersList = this.partialUsersList.filter(entry=>entry);
                        isPartialUpload = this.partialUsersList.find(entry=>entry === doc.ipoUserName);
                    }                    
                    
                    let vmGreetings = GREETINGS_TYPE.DEFAULT;
                    //VM greetings is set to custom if .wav file is uploaded for the user
                    let isGreetingsUploaded = doc.vmGreetings || (isIgnored !== undefined) || (isPartialUpload !== undefined);
                    if(isGreetingsUploaded){
                        vmGreetings = GREETINGS_TYPE.CUSTOM;
                    }
                    temp.push(vmGreetings);
                    let errors = "" , warnings="";

                    if(doc.firstName === "" && doc.lastName === ""){
                        errors = XLSX_ERROR.FIRST_LAST_NAME;
                    }
                    if(this.reservedExtensionsList.includes(doc.extension)){
                        errors += XLSX_ERROR.RESERVED_EXTENSION;
                    }else if(assignedExtnList.includes(doc.extension.toString())){
                        errors += XLSX_ERROR.DUPLICATE_EXTENSION_ACO;
                    }    
                    if(doc.email === ""){
                        errors += XLSX_ERROR.EMAIL_NOT_CONFIGURED;
                    }else if(assignedMailList.includes(doc.email)){
                        errors += XLSX_ERROR.DUPLICATE_EMAIL_ACO;
                    }else if(result.filter((user:any)=>user.email === doc.email).length > 1){
                        errors += XLSX_ERROR.DUPLICATE_EMAIL_CSV;
                    }else if(!this.validateEmailEntry(doc.email)){
                        errors += XLSX_ERROR.INVALID_EMAIL;
                    }else if(doc.email.split('@')[0] === doc.extension.toString()){
                        warnings += XLSX_ERROR.DUMMY_EMAIL;
                    }    
                    if(doc.acoPhoneStatus === phoneStatus.unassigned){
                        errors += XLSX_ERROR.ACO_PHONE_MODEL;
                    }
                    if (this.sysmonData.length > 0) {
                        let matchedEntry = this.sysmonData.find(sysmonEntry => sysmonEntry.userExtension === doc.extension.toString());
                        if (matchedEntry !== undefined) {
                            if (matchedEntry.phoneModel !== doc.displayPhoneModel || matchedEntry.macId.toUpperCase() !== doc.macId.toUpperCase()) {
                                warnings += XLSX_ERROR.INVALID_SYSMON_COMBINATION_ASSIGNED;
                            }
                        }
                    }
                    if(doc.blfList.length > 0)
                    {
                        let count = 0;
                        doc.blfList.forEach(element => {
                            if(this.messageOnlyData !== undefined && this.messageOnlyData.includes(element.US_Extension))
                                count ++;
                        });
                        if (count > 0)
                        warnings += XLSX_ERROR.MO_WITH_BLF_UNSUPPORTED;
                    }
                    if(this.messageOnlyData !== undefined && this.messageOnlyData.length > 0 && doc.phone === "message-only")
                        warnings += XLSX_ERROR.MO_USER_BUSINESS_HOURS;
                    if(isLimited){
                        warnings += XLSX_ERROR.LIMITED_EXTENSION;
                        warnings += XLSX_ERROR.LE_WITH_BLF_UNSUPPORTED;
                        warnings += XLSX_ERROR.USER_BUSINESS_HOURS;
                        limitedExtnList.push(doc.extension);
                        if(isGreetingsUploaded)
                        warnings += XLSX_ERROR.VM_UNSUPPORTED_FOR_LE;
                    }
                    if(isIgnored !== undefined){
                        warnings += XLSX_ERROR.VM_IGNORED_USERS;                            
                    }
                    if(isPartialUpload !== undefined){
                        warnings += XLSX_ERROR.VM_PARTIAL_USERS;
                    }

                    temp.push(errors);
                    temp.push(warnings);
    
                    let twinError = "", twinWarning = "";
                    if(isLimited)
                        twinTempData.push("No");
                    else
                        twinTempData.push("Yes");
                    twinTempData.push(doc.extension.toString());
                    twinTempData.push(doc.username);
                    twinTempData.push(doc.twinMob);
                    let twinnednumberAfterSteeringRemoval = "";
                    let twinnedData = callFwdData.find((element) => element.extId === doc.extensionId)
                    if(twinnedData && twinnedData.data && twinnedData.data.phoneNumber){
                        twinnednumberAfterSteeringRemoval = twinnedData.data.phoneNumber;
                    }
                    twinTempData.push(twinnednumberAfterSteeringRemoval);
                    if(isLimited){
                        twinWarning = XLSX_ERROR.LE_TWIN_NOT_MIGRATED;
                    }else if(doc.twinMob !== "" && doc.twinType === "None"){
                    //unchecked mobile twinning but twinning number configured
                        twinWarning = XLSX_ERROR.TWINNING_NOT_CONFIGURED;
                    }
                    if(this.messageOnlyData !== undefined && this.messageOnlyData.length > 0 && doc.phone === "message-only")
                        twinWarning += XLSX_ERROR.MO_TWIN_NOT_MIGRATED;
                    //twinning number configured but phone model is not assigned or not a DID number
                    if(doc.twinMob !== "" && (doc.acoPhoneStatus === phoneStatus.unassigned || doc.did === "")){
                        twinError = XLSX_ERROR.TWINNING_DID_MISSING;
                    }
                    
                    twinTempData.push(twinError);
                    twinTempData.push(twinWarning);    
                    worksheet.addRow(temp); 
                    const row = worksheet.lastRow;
                    this.xlsxCSSFontWeight(row ,4);
                    this.xlsxCSSFontWeight(row ,5);
                    this.xlsxCSSFontWeight(row ,6);
                    this.xlsxCSSFontWeight(row ,12);
                    this.xlsxCSSFontWeight(row ,13); 
                    this.xlsxCSSFontAlignemnt(row,9);
                    this.xlsxCSSFontAlignemnt(row,10);              
                    if(errors != ""){
                        this.xlsxCSSFontColor(row , 12);
                        this.xlsxCSSFontColor(row , 1);
                        row.getCell(1).value = "No"
                    }    
                    if(doc.twinMob !== ""){
                        twinningDataSheet.addRow(twinTempData);
                        const twinningDataRow = twinningDataSheet.lastRow;
                        this.xlsxCSSFontWeight(twinningDataRow , 6);
                        this.xlsxCSSFontWeight(twinningDataRow , 7);
    
                        if(twinError != ""){
                            this.xlsxCSSFontColor(twinningDataRow , 6);
                            this.xlsxCSSFontColor(twinningDataRow , 1);
                            twinningDataRow.getCell(1).value = "No"
                        }
                    }
                });

                let pickupmemberCQlimit = [];
                if(this.callQueueData !== undefined && this.callQueueData.length > 0){
                    this.userTableData.forEach(user =>{
                        let count = 0;
                            this.callQueueData.forEach(element => {
                            if(element.Group.GR_Extensions.length > 0)
                            {
                                if(element.Group.GR_Extensions.includes(JSON.stringify(user.extension)) && element.Group.GR_RingMode === "RingGroup" && element.Group.GR_CallWaiting.toLowerCase() == "true")
                                    count ++;
                            }
                        });
                        if(count > 10)
                            pickupmemberCQlimit.push(JSON.stringify(user.extension));
                    })
                        
                let reservedGroupsList = this.callQueueData.filter(entry=>{
                         return entry.Group.GR_Extension !== "" && this.reservedExtensionsList.includes(parseInt(entry.Group.GR_Extension));
                    }).map((entry)=>entry.Group.GR_Name);
                this.callQueueData.forEach(element => {
                    let cqtemp=[],ringModeFlag,isMemberEmpty = "N",interruptAudioFlag,hours,VMGreeting,isPaging = "No",deviceCount,extension,aaWarningString,pgWarningString,wrapUpTime, interruptTime = 15, finalString,ringMode,remainingDev=[], hgMessageOnlyMembers =[] , pgMessageOnly =[], pickupMemberLengthLimit,CQwithUserLimitExceeded=[], memberExtnLimit;
                    this.extensionList = [];
                    let overflowGroup = "";
                    let isOverflowMigrationEnabled;
                    let isRoutingMigrationEnabled;
                    this.hgTableEntryData.forEach((data) =>{
                        if(data.name === element.Group.GR_Name)
                        {
                            wrapUpTime = data.wrapUpTime;
                            overflowGroup = data.overflowGroup.join(", ");
                            isOverflowMigrationEnabled = data.isOverflowMigrationEnabled == true? "Yes": "No";
                            isRoutingMigrationEnabled = data.isRoutingMigrationEnabled == true? "Yes": "No";
                        }
                    })
                        
                    let pgLEWarningStr, hgLEWarningStr;
                    if(element.Group.GR_RingMode == "RingGroup" && element.Group.GR_CallWaiting.toLowerCase() === "true")
                    {
                        ringModeFlag = "Y";
                        if(element.Group.GR_Extensions.length === 0)
                        isMemberEmpty = "Y";
                        if(element.Group.GR_Extensions.length > PICKUP_MEMBER_LIMIT)
                            pickupMemberLengthLimit = "Y";
                        else
                            pickupMemberLengthLimit = "N";
                        if(pickupmemberCQlimit.length > 0)
                        {
                            let found = element.Group.GR_Extensions.some(value=> pickupmemberCQlimit.includes(value))
                            if(found)
                                memberExtnLimit = "Y";
                            else
                                memberExtnLimit = "N";

                        }       
                    }
                    else
                        ringModeFlag = "N";
                    if(this.missingHGAnnList !== undefined && this.hgVMMissingList !== null)
                    {
                        if(this.missingHGAnnList.includes(element.Group.GR_Name))
                        interruptAudioFlag = "No";
                        else
                        interruptAudioFlag = "Yes";
                    }
                    else 
                        interruptAudioFlag = "No";
                    if(element.Fallback.GR_TimeProfile !== "")
                    {
                        this.timeProfilesData.forEach(tpElement => {
                            if(tpElement.name === element.Fallback.GR_TimeProfile)
                            {
                                if(tpElement.profiles.data.schedule.weeklyRanges!== "")
                                hours = "Custom";  
                                else
                                hours = "Default"; 
                            }
                            else 
                                hours = "Default";
                        });
                    }
                    else
                      hours = "Default"
                    if(this.hgVMMissingList !== undefined && this.hgVMMissingList !== null)
                    {
                        if(this.hgVMMissingList.includes(element.Group.GR_Name) || this.hgVMComplexList.includes(element.Group.GR_Name))
                        VMGreeting = "Default";
                        else
                        VMGreeting = "Custom";
                    }
                    else
                    VMGreeting = "NA";
                    let hgLEList = [];
                    if(element.Group.GR_Extensions.length > 0)
                    {
                        element.Group.GR_Extensions.forEach(extension => {
                         try{
                            let extn = parseInt(extension);
                            if(!limitedExtnList.includes(extn))
                                this.extensionList.push(extension);
                            else
                                hgLEList.push(extn);
                         }catch(error){
                             //do nothing
                         }                            
                        });                       
                    }
                    let arrayString = JSON.stringify(this.extensionList);
                    finalString  = arrayString.slice(1, arrayString.length-1);
                    if(hgLEList.length > 0){
                        hgLEWarningStr = XLSX_ERROR.HG_LIM_EXTN_UNSUPPORTED + " : " + hgLEList.join(" , ");
                    }                    
                    let overflowList = [];
                    if(element.Overflow.GR_Overflow.length > 0)
                    {
                        overflowList = element.Overflow.GR_Overflow;                        
                    }
                    if(!overflowGroup || overflowGroup === ""){
                        overflowGroup = "NA";
                    }

                    let grBetweenAnnTime = parseInt(element.Announcements.GR_BetweenAnnTime);
                    if(grBetweenAnnTime <=15 && grBetweenAnnTime !== 0)
                        interruptTime = 15;
                    else if (grBetweenAnnTime > 15 && grBetweenAnnTime <=20) 
                        interruptTime = 20;
                    else if(grBetweenAnnTime > 20 && grBetweenAnnTime <= 25) 
                        interruptTime = 25;
                    else if ((grBetweenAnnTime >25 && grBetweenAnnTime <=30) || (grBetweenAnnTime >30 && grBetweenAnnTime <= 35)) 
                        interruptTime = 30;
                    else if ((grBetweenAnnTime >35 && grBetweenAnnTime <= 40) || (grBetweenAnnTime >40 && grBetweenAnnTime <= 45)) 
                        interruptTime = 40;
                    else if ((grBetweenAnnTime >45 && grBetweenAnnTime <= 50) || grBetweenAnnTime >50) 
                        interruptTime = 50;
                    if (this.pageGroupData !== undefined && this.pageGroupData.length > 0) {
                        this.pageGroupData.forEach(Data => {
                            if (Data.name === element.Group.GR_Name) {
                                isPaging = "Yes";
                                deviceCount = Data.devices.length;
                                remainingDev = Data.devices.slice(PAGE_DEV_LIMIT);
                                extension = Data.extension;
                                this.tablePreviewService.pagingGroupsWarningsList.forEach(warningData => {
                                    if (warningData.includes(HG_PG_WARNING.PG_AA_KEYS)) {
                                        let splitString = warningData.split("-");
                                        if (splitString[1].trim() === Data.name) {
                                            let keyNamesList = splitString[0].split(":");
                                            aaWarningString = "Paging group not considered for ACO IVR menu key press destination - " + keyNamesList[1];
                                        }
                                    }
                                    if (warningData.includes(HG_PG_WARNING.PG_NO_DEVICE_ASSIGNED) || warningData.includes(HG_PG_WARNING.PG_NO_DEVICE_ASSIGNED_MULTIPLE)) {
                                        let splitString = warningData.split(":");
                                        if (splitString[0].trim() === Data.name)
                                            pgWarningString = splitString[1].trim();
                                    }
                                });
                            }
                        });
                    }
                    
                    let ipoHGAnn2Unchecked = false, annUnchecked = false;
                    if(element.Announcements.GR_FollowAnnouncements.toLowerCase() !== 'true') ipoHGAnn2Unchecked = true;
                    if(element.Announcements.GR_Announcements.toLowerCase() !== 'true') annUnchecked = true;
                    if(element.Group.GR_RingMode !== undefined && element.Group.GR_RingMode === "RingGroup")
                        ringMode = "Simultaneous";
                    if(element.Group.GR_RingMode !== undefined && element.Group.GR_RingMode === "RingHunt")
                        ringMode = "Sequential"; 
                    if(element.Group.GR_RingMode !== undefined && element.Group.GR_RingMode === "RingRotary")
                        ringMode = "Rotating";
                    if(element.Group.GR_RingMode !== undefined && element.Group.GR_RingMode === "RingIdle")
                        ringMode = "Rotating";
                    if(isPaging === "No")
                    {
                        cqtemp.push("Yes");
                        cqtemp.push(isPaging);
                        cqtemp.push(element.Group.GR_Name);
                        cqtemp.push(element.Group.GR_Extension);
                        cqtemp.push(element.Sitename);
                        cqtemp.push(element.Group.GR_VoicemailEmail);
                        cqtemp.push(finalString);
                        cqtemp.push("NA");
                        cqtemp.push(hours);
                        //cqtemp.push("N");
                        cqtemp.push(interruptTime);
                        cqtemp.push(interruptAudioFlag);
                        cqtemp.push(ringMode);
                        cqtemp.push(ringModeFlag);
                        cqtemp.push(element.Group.GR_NoAnswer);
                        cqtemp.push(element.Group.GR_VoicemailTime);
                        cqtemp.push(VMGreeting);
                        cqtemp.push(isOverflowMigrationEnabled);
                        cqtemp.push(isRoutingMigrationEnabled);
                        cqtemp.push(overflowGroup);
                        cqtemp.push(wrapUpTime);
                    }
                    else
                    {
                        cqtemp.push("Yes");
                        cqtemp.push(isPaging);
                        cqtemp.push(element.Group.GR_Name);
                        cqtemp.push(element.Group.GR_Extension);
                        cqtemp.push("");
                        cqtemp.push("NA");
                        cqtemp.push("NA");
                        cqtemp.push(deviceCount);
                        cqtemp.push("NA");
                        cqtemp.push("NA");
                        cqtemp.push("NA");
                        cqtemp.push("NA");
                        cqtemp.push("NA");
                        cqtemp.push("NA");
                        cqtemp.push("NA");
                        cqtemp.push("NA"); 
                        cqtemp.push("NA");
                        cqtemp.push("NA");                     
                    }
                    
                    let errors = "" , warnings="";
                    if(assignedExtnList.includes(element.Group.GR_Extension)){
                        if(isPaging === "No")
                            errors = "* "+GROUP_TYPE.CALL_QUEUE + "  " + XLSX_ERROR.DUPLICATE_GRP_EXTENSION_ACO;
                        else if(isPaging === "Yes")
                            errors = "* "+GROUP_TYPE.PAGING_GROUP +" "+XLSX_ERROR.DUPLICATE_GRP_EXTENSION_ACO;
                    }
                        
                    let grpExtn = parseInt(element.Group.GR_Extension);
                    if(grpExtn !== undefined && this.reservedExtensionsList.includes(grpExtn)){
                        if(isPaging === "No"){
                            errors += "* "+GROUP_TYPE.CALL_QUEUE + " " + XLSX_ERROR.GRP_RESERVED_EXTENSION;
                        }else if(isPaging === "Yes")
                            errors += "* "+GROUP_TYPE.PAGING_GROUP +" "+ XLSX_ERROR.GRP_RESERVED_EXTENSION;
                    }
                    if(element.Group.GR_Extension === ""){
                        if(isPaging === "No"){
                            errors += XLSX_ERROR.MISSING_EXTENSION_CQ;
                        }else  if(isPaging === "Yes"){
                            errors += XLSX_ERROR.MISSING_EXTENSION_PAGING;
                        }
                    }
                    if(cqMemberLimit !== undefined && element.Group.GR_Extensions.length > cqMemberLimit && isPaging === "No")
                    {
                        warnings += XLSX_ERROR.CQ_MEMBERLIMIT_EXCEEDED + cqMemberLimit +" members\n" ;
                        warnings += XLSX_ERROR.PICKUP_MEMBERS_NOT_MIGRATED;
                    }
                    if(isPaging === "No" && element.Group.GR_Voicemail.toLowerCase() === 'false' && ACO_SERVICE_EDITION_LIST.includes(serviceEdition.toLowerCase()) && (serviceEdition.toLowerCase() === "standard")) {
                        warnings += XLSX_ERROR.VM_DISABLED_WAITTIME_UPDATED.replace("SERVICE_EDITION", serviceEdition);
                    } else if (isPaging === "No" && element.Group.GR_Voicemail.toLowerCase() === 'false' && ACO_SERVICE_EDITION_LIST.includes(serviceEdition.toLowerCase())
                          && (!element.Overflow  ||  !element.Overflow.GR_Overflow || element.Overflow.GR_Overflow.length === 0)) {
                        warnings += (XLSX_ERROR.VM_DISABLED_WAITTIME_UPDATED_OVERFLOW_SUPPORT.replace("SERVICE_EDITION", serviceEdition));
                    }
                    //ACOS-1467 queue length set to No Limit , maxCallers will be set with 25 and holdTIme will be set to 900
                    if(isPaging === "No" && element.Queue.GR_Queueing.toLowerCase() === "true" && element.Queue.GR_QueueingLimit === '0' && element.Group.GR_Voicemail.toLowerCase() === 'false'){
                        warnings += HG_PG_WARNING.HG_QUEUE_LENGTH_NO_LIMIT;
                    }

                    if(isPaging === "No"){
                        if(element.Group.GR_Voicemail.toLowerCase() === 'false'){
                            if(serviceEdition === "standard"){
                                warnings += HG_PG_WARNING.HG_OVERFLOW_FEATURE_NOT_SUPPORTED;
                            }else{
                                if(element.Overflow && element.Overflow.GR_OverflowMode === 'CALLANDGROUP'){
                                    warnings += HG_PG_WARNING.HG_OVERFLOW_MODE_UNSUPPORTED;
                                }

                                let overflowTime = 0;
                                if(element.Overflow && element.Overflow.GR_OverflowTime) 
                                    overflowTime = parseInt(element.Overflow.GR_OverflowTime) || 0;
                                /*if(overflowTime === 0)
                                        warnings += HG_PG_WARNING.HG_OVERFLOW_WAIT_TIME_NOT_SET;*/
                                
                                if(overflowList && overflowList.length > 0){
 
                                    overflowList = Array.from(new Set(overflowList));                                  
                                    
                                    if(overflowTime > MAX_HOLD_TIME)
                                        warnings += HG_PG_WARNING.HG_OVERFLOW_WAIT_TIME_EXCEEDED;
 
                                    if(element.Queue){
                                        let queuingLimit = parseInt(element.Queue.GR_QueueingLimit ) || 0;
                                        if(queuingLimit > MAX_CALLERS)
                                            warnings += HG_PG_WARNING.HG_OVERFLOW_MAX_CALLERS_EXCEEDED;
                                    }
                                   
                                    let skippedPagingGroups  = overflowList.filter((el)=>{
                                        return (this.pageGroupData.find((entry)=>entry.name === el) !== undefined);
                                    });
                                    skippedPagingGroups = Array.from(new Set(skippedPagingGroups));
                                    if(skippedPagingGroups && skippedPagingGroups.length > 0){
                                        warnings += HG_PG_WARNING.HG_OVERFLOW_PAGING_GRP_SKIPPED + skippedPagingGroups.join(",") + "\n" ;
                                    }
                                    
                                    let skippedReservedGroups =overflowList.filter((entry)=>{
                                        if (reservedGroupsList !== undefined && reservedGroupsList.length > 0)
                                            return (reservedGroupsList.find((el)=>el === entry));
                                    });
                                    skippedReservedGroups =  Array.from(new Set(skippedReservedGroups));
                                    if(skippedReservedGroups && skippedReservedGroups.length > 0){
                                        warnings += HG_PG_WARNING.HG_OVERFLOW_RESERVED_EXTN_SKIPPED +skippedReservedGroups.join(",")+ "\n" ;
                                    }
                                    
                                    
                                    let rejectedGroups = overflowList.filter(entry=>{
                                        return entry !== overflowGroup 
                                        && !skippedReservedGroups.includes(entry)
                                        && !skippedPagingGroups.includes(entry);
                                    });
                                    rejectedGroups =  Array.from(new Set(rejectedGroups));
                                    if(rejectedGroups !== undefined && rejectedGroups.length > 0)
                                    if (isOverflowMigrationEnabled && isRoutingMigrationEnabled) {
                                        warnings += HG_PG_WARNING.HG_OVERFLOW_REJECTED_GROUPS + 
                                        HG_PG_WARNING.HG_ROUTING_REJECTED_GROUPS.substring(1, HG_PG_WARNING.HG_ROUTING_REJECTED_GROUPS.length) + 
                                        HG_PG_WARNING.HG_NOT_MIGRATING_OVERFLOW_GROUPS + rejectedGroups.join(",") + "\n";
                                    } else if (isOverflowMigrationEnabled) {
                                        warnings += HG_PG_WARNING.HG_OVERFLOW_REJECTED_GROUPS + HG_PG_WARNING.HG_NOT_MIGRATING_OVERFLOW_GROUPS + rejectedGroups.join(",") + "\n";
                                    } else if (isRoutingMigrationEnabled) {
                                        warnings += HG_PG_WARNING.HG_ROUTING_REJECTED_GROUPS + 
                                        HG_PG_WARNING.HG_NOT_MIGRATING_OVERFLOW_GROUPS + rejectedGroups.join(",") + "\n";
                                    }

                                    if (ringMode === "Simultaneous") {
                                        warnings += HG_PG_WARNING.HG_OVERFLOW_REJECT_SIMULTANEOUS_RING_TYPE
                                    }

                                    if(overflowGroup ===  "" || overflowGroup === "NA"){
                                        warnings += HG_PG_WARNING.HG_OVERFLOW_EMPTY_GROUP;
                                    }

                                }
                            }
                        }else{
                            if(element.Overflow && element.Overflow.GR_Overflow.length > 0){
                                warnings += HG_PG_WARNING.VM_ENABLED_NO_OVERFLOW;
                            }
                        }
                        
                    }
                    
                    
                    if(errors === ""){

                        if(isPaging === "Yes" && deviceCount > 25)
                        warnings+= XLSX_ERROR.MAX_LIMIT + remainingDev.join(" , ")+"\n";
                
                        if(isPaging === "No")
                        {
                            if((this.missingHGAnnList !== undefined && this.missingHGAnnList.includes(element.Group.GR_Name)) ||
                            (this.unsupportedHGAnnList !== undefined && this.unsupportedHGAnnList.includes(element.Group.GR_Name)) || 
                             annUnchecked || ipoHGAnn2Unchecked)
                                warnings += XLSX_ERROR.ANNOUNCEMENTS_NOTMIGRATED;
                        if((this.hgVMMissingList !== undefined && this.hgVMMissingList.includes(element.Group.GR_Name)) || 
                            (this.hgVMComplexList!== undefined && this.hgVMComplexList.includes(element.Group.GR_Name)))
                            warnings += XLSX_ERROR.VM_NOTMIGRATED;
                            if(this.messageOnlyData !== undefined)
                            {
                                this.messageOnlyData.forEach(moe=> {
                                if(this.extensionList.includes(JSON.stringify(moe.extnId)))
                                    hgMessageOnlyMembers.push(JSON.stringify(moe.extnId)); 
                                });
                            }
                        //hgMessageOnlyMembers = this.extensionList.filter(data => (this.messageOnlyData !== undefined  && this.messageOnlyData.includes(data)));
                        if(hgMessageOnlyMembers !== undefined && hgMessageOnlyMembers.length > 0)
                        {
                            warnings += XLSX_ERROR.CQ_MESSAGEONLY + hgMessageOnlyMembers + "\n";
                        }
                        }
                        if(isPaging === "No" && element.Group.GR_Extensions.length === 0)
                        warnings += XLSX_ERROR.NO_MEMBERS;
                        if(isPaging === "No" && this.chainedAnnHGList !== undefined && this.chainedAnnHGList.includes(element.Group.GR_Name))
                        warnings+= XLSX_ERROR.MULTIPLE_ANNOUNCEMENTS;
                        if(isPaging === "No" && JSON.parse(element.Group.GR_VoicemailTime) > 900)
                        warnings+=XLSX_ERROR.VOICEMAIL_TIMEOUT;
                        if(isPaging === "No" && ringModeFlag === "Y")
                        warnings+=XLSX_ERROR.COLLECTIVE_CALLWAITING;
                        if(isPaging === "No" && pickupMemberLengthLimit=== "Y")
                        warnings+=XLSX_ERROR.PICKUP_MEMBERS_LIMIT_EXCEEDED;
                        if(isPaging === "No" && isMemberEmpty === "Y")
                        warnings+=XLSX_ERROR.EMPTY_MEMBERS;
                        if(isPaging === "No" && memberExtnLimit=== "Y")
                        warnings+=XLSX_ERROR.PICKUP_MEMBER_CQ_LIMIT_EXCEEDED;
                        if(isPaging === "Yes" && deviceCount === 0)
                        warnings+=XLSX_ERROR.NO_MEMBERS;
                        if(isPaging === "Yes" && aaWarningString !== undefined)
                        warnings+= "* "+aaWarningString;
                        if (isPaging === "Yes" && pgWarningString !== undefined)
                        warnings += "\n * " + pgWarningString;
                        if(isPaging === "Yes" && pgLEWarningStr !== undefined)
                        warnings +=  pgLEWarningStr;
                        if(isPaging === "No" && hgLEWarningStr !== undefined)
                        warnings += hgLEWarningStr;
                        if(isPaging === "Yes")
                        {
                            if(this.messageOnlyData !== undefined)
                            {
                                this.messageOnlyData.forEach(moe=> {
                                if(this.extensionList.includes(JSON.stringify(moe.extnId)))
                                    pgMessageOnly.push(JSON.stringify(moe.extnId)); 
                                });
                            }
                            if(pgMessageOnly !== undefined && pgMessageOnly.length > 0)
                        {
                            warnings += XLSX_ERROR.PG_MESSAGEONLY + pgMessageOnly + "\n";
                        }
                        }
                    }
                    cqtemp.push(errors);
                    cqtemp.push(warnings);
                    callQueueDataSheet.addRow(cqtemp);
                    const row = callQueueDataSheet.lastRow;
                    this.xlsxCSSFontWeight(row ,19);
                    this.xlsxCSSFontWeight(row ,22);
                    this.xlsxCSSFontAlignemnt(row,8);
                    this.xlsxCSSFontAlignemnt(row,10);
                    this.xlsxCSSFontAlignemnt(row,18); 
                    /*callQueueDataSheet.getCell('P1').fill = {
                        type: 'pattern',
                        pattern:'solid',
                        fgColor:{argb:'FFFFFF00'},
                        bgColor:{argb:'FF0000FF'}
                      };  */          
                    if(errors != ""){
                        this.xlsxCSSFontColor(row , 19);
                        this.xlsxCSSFontColor(row , 1);
                        row.getCell(1).value = "No"
                    }                
                });
            }
                if(this.preConfigICRData !== undefined && this.preConfigICRData.length > 0) {
                 this.preConfigICRData.forEach(element => {
                 let icrTemp = [], afterHoursType,companyHoursType,afterHoursaaName,companyHoursaaName,schedules= "" ,isAutoreceptionist = "no";
                     if (this.callQueueData !== undefined && this.callQueueData.length > 0) {
                         this.callQueueData.forEach(data => {
                             if (data.Group.GR_Name === element.AfterHours)
                                 afterHoursType = "Call Queue Extn " + data.Group.GR_Extension;
                             if (data.Group.GR_Name === element.CompanyHours)
                                 companyHoursType = "Call Queue Extn " + data.Group.GR_Extension;
                         });
                     }
                
                     if(element.AfterHours.includes("AA:"))
                        afterHoursaaName = element.AfterHours.split('AA:').pop()
                     else if(element.AfterHours.includes("VM:"))
                        afterHoursaaName = element.AfterHours.split('VM:').pop()
                     else 
                     afterHoursaaName = element.AfterHours;
                    if(element.CompanyHours.includes("AA:"))
                        companyHoursaaName = element.CompanyHours.split('AA:').pop()
                    else if(element.CompanyHours.includes("VM:"))
                        companyHoursaaName = element.CompanyHours.split('VM:').pop()
                    else
                        companyHoursaaName = element.CompanyHours;
                    if(this.aaICRData !== undefined && this.aaICRData.includes(afterHoursaaName))
                        afterHoursType = "Auto-Receptionist";  
                    if(this.aaICRData !== undefined && this.aaICRData.includes(companyHoursaaName)) 
                        companyHoursType = "Auto-Receptionist";                    
                 this.userTableData.forEach(data =>{
                    if(element.AfterHours === JSON.stringify(data.extension))
                    afterHoursType = "User Extension" +element.AfterHours ;
                    if(element.CompanyHours === JSON.stringify(data.extension))
                    companyHoursType = "User Extension" +element.AfterHours ;
                 })
                 if(this.messageOnlyData !== undefined && this.messageOnlyData.includes(element.AfterHours))
                    afterHoursType = "Message Only User Extension" +element.AfterHours ;  
                if(this.messageOnlyData !== undefined && this.messageOnlyData.includes(element.CompanyHours))
                    companyHoursType = "Message Only User Extension" +element.CompanyHours ;
                 if(/^\d/.test(element.AfterHours) && afterHoursType === undefined )
                 afterHoursType = "Forward to external number";  
                 if(/^\d/.test(element.CompanyHours) && companyHoursType === undefined )
                 companyHoursType = "Forward to external number";  
                this.timeProfilesData.forEach(data => {
                    if(data.name === element.TimeProfiles)
                        {
                            if(data.profiles.data.schedule.weeklyRanges !== undefined)
                                {
                                        if(data.profiles.data.schedule.weeklyRanges['monday'])
                                        schedules = "monday : from " + JSON.stringify(data.profiles.data.schedule.weeklyRanges['monday'][0].from) + " to " + JSON.stringify(data.profiles.data.schedule.weeklyRanges['monday'][0].to) +'\n';
                                        if(data.profiles.data.schedule.weeklyRanges['tuesday'])
                                        schedules += "tuesday : " + JSON.stringify(data.profiles.data.schedule.weeklyRanges['tuesday'][0].from) + " to " + JSON.stringify(data.profiles.data.schedule.weeklyRanges['tuesday'][0].to) +'\n';
                                        if(data.profiles.data.schedule.weeklyRanges['wednesday'])
                                        schedules += "wednesday : " + JSON.stringify(data.profiles.data.schedule.weeklyRanges['wednesday'][0].from) + " to " + JSON.stringify(data.profiles.data.schedule.weeklyRanges['wednesday'][0].to) +'\n';
                                        if(data.profiles.data.schedule.weeklyRanges['thursday'])
                                        schedules += "thursday : " + JSON.stringify(data.profiles.data.schedule.weeklyRanges['thursday'][0].from) + " to " + JSON.stringify(data.profiles.data.schedule.weeklyRanges['thursday'][0].to) +'\n';
                                        if(data.profiles.data.schedule.weeklyRanges['friday'])
                                        schedules += "friday : " + JSON.stringify(data.profiles.data.schedule.weeklyRanges['friday'][0].from) + " to " + JSON.stringify(data.profiles.data.schedule.weeklyRanges['friday'][0].to) +'\n';
                                        if(data.profiles.data.schedule.weeklyRanges['saturday'])
                                        schedules += "saturday : " + JSON.stringify(data.profiles.data.schedule.weeklyRanges['saturday'][0].from) + " to " + JSON.stringify(data.profiles.data.schedule.weeklyRanges['saturday'][0].to) +'\n';
                                        if(data.profiles.data.schedule.weeklyRanges['sunday'])
                                        schedules += "sunday : " + JSON.stringify(data.profiles.data.schedule.weeklyRanges['sunday'][0].from) + " to " + JSON.stringify(data.profiles.data.schedule.weeklyRanges['sunday'][0].to) +'\n';
                                    
                                }
                                if(data.profiles.data.schedule.ranges !== undefined)
                                {
                                    data.profiles.data.schedule.ranges.forEach(element => {
                                        schedules+= JSON.stringify(element) +'\n';
                                    });
                                }
                            
                        } 
                });
                 icrTemp.push("Yes");
                 icrTemp.push(element.ICRNumber);
                 icrTemp.push(element.siteName);
                 icrTemp.push(element.AfterHours);
                 if(element.CompanyHours === "")
                 icrTemp.push(element.AfterHours);
                 else
                 icrTemp.push(element.CompanyHours);
                 if(companyHoursType === undefined)
                 icrTemp.push(afterHoursType);
                 else
                 icrTemp.push(companyHoursType);
                 icrTemp.push(afterHoursType);
                 icrTemp.push(element.TimeProfiles);
                 icrTemp.push(schedules);
                 let warnings = "",errors = "";
                 if(element.AfterHours === "" && element.CompanyHours === "")
                    errors+=XLSX_ERROR.ICR_NO_DEST;
                 if(element.CompanyHours === "")
                    warnings+=XLSX_ERROR.ICR_NO_CUST_HOURS;
                 if(afterHoursType === "Forward to external number")
                    warnings+= XLSX_ERROR.ICR_EXTERNAL_NUMBER;
                 if(companyHoursType === "Forward to external number")
                    warnings+= XLSX_ERROR.ICR_EXTERNAL_NUMBER;
                 if(this.conferenceMeetMeList !== undefined && (this.conferenceMeetMeList.includes(companyHoursaaName) || this.conferenceMeetMeList.includes(afterHoursaaName)))
                    warnings+= XLSX_ERROR.ICR_CONFERENCE;                    
                 if(this.aaMenuData !== undefined && this.aaMenuData.length > 0)
                {
                    this.aaMenuData.forEach(element => {
                        if(element.AA_Name === afterHoursaaName || element.AA_Name === companyHoursaaName || 
                           element.callFlowName === afterHoursaaName || element.callFlowName === companyHoursaaName)
                            isAutoreceptionist = "yes"
                    });
                }
                 if(isAutoreceptionist === "no")
                    errors += XLSX_ERROR.NO_DESTINATION;
                 icrTemp.push(errors);
                 icrTemp.push(warnings);
                 allNumbersDataSheet.addRow(icrTemp);
                 const row = allNumbersDataSheet.lastRow;
                  if(companyHoursType !== "Auto-Receptionist" && afterHoursType !== "Auto-Receptionist")
                  { 
                    this.xlsxCSSFontColor(row , 1);
                    row.getCell(1).value = "No"
                 } 
                 this.xlsxCSSFontWeight(row ,9);
                 this.xlsxCSSFontWeight(row ,10);
                 this.xlsxCSSFontWeight(row ,11);            
                 if(errors != ""){
                    this.xlsxCSSFontColor(row , 10); 
                    this.xlsxCSSFontColor(row , 1);
                    row.getCell(1).value = "No"
                }                 
                 });
                }

                await this.buildParkLocationData();
                await this.buildAAData(deletedUserList);
                await this.buildButtonProgrammingData(deletedUserList);
                await this.buildUserRestrictionData();

                //set downloadable file name
                let fname=companyName+"_config";
            
                //add data and file name and download
                this.workbook.xlsx.writeBuffer().then((data) => {
                    let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                    fs.saveAs(blob, fname+'.xlsx');
                    //this.overlay.closeSpinner();
                });
                //ACOS-1153, same ledger entry function will be called to update preconfig download count
                //return this.firestoreService.updateMarkAsCompleteToAnalytics(this.id);
                return this.firestoreService.readBigQueryCardDetails(id);
            }else{
                this.preConfigToolTip = MIGRATION_SUMMARY_STRINGS.PRE_CONFIG_TOOLTIP_ERROR;
            }
        })
        .then((cardData:any)=>{
            let downloadCount = 0;
            if(cardData !== null && cardData.downloadPreConfigCount !== undefined){
                downloadCount = cardData.downloadPreConfigCount +1;
            }
            return this.firestoreService.updatePreConfigDownloadCount(id , downloadCount);
        })
        .then(()=>{
            return this.firestoreService.updateMarkAsCompleteToAnalytics(id);
        })
        .then((cardData:any)=>{
            this.overlay.closeSpinner();
        })
        .catch((error) =>{
            this.overlay.closeSpinner();
            this.logger.debug("downloading pre config failed. " ,error);
        })
      }

    async resetWorkSheets() {
        this.logger.debug("resetWorkSheets");
        //ACOS-1339 need to remove already existing worksheet if create before
        //we can not create a new workbook instance since memory issues and performance issues migth occurs
        //if already existing worksheets are not deleted, new worksheets with the same name can not be created
        //this will cause downloading to fail:wq!
        this.workbook.eachSheet((worksheet, sheetId) => {
            this.logger.debug(`removing worksheet:${worksheet.name} from workbook"`);
            this.workbook.removeWorksheet(sheetId);
        });
        return ;
    }

    timeupCallbackfunc(ol){
        //this.logger.debug("Time up");
        ol.showSpinner = false;
        ol.closeSpinner();
    }

    aaWarningFilteringTemplate(){
        let aaFilteredData = {
            aaName : "",
            warningList : [],
            generalWarnings : "",
            dialByExtnWarning : "",
        }
        return aaFilteredData;
    }

    downloadFileFromBucket(fileName:any){
        var promise = new Promise((resolve, reject) => {
            try {
                var storage = firebase.storage();
                var storageRef = storage.ref(fileName);
                storageRef.getDownloadURL().then((url) => {
                    var xhr = new XMLHttpRequest();
                    xhr.responseType = 'json';
                    xhr.onload = (event) => {
                        if (xhr.status != 200) reject();
                        else resolve(xhr.response);
                    };
                    xhr.open('GET', url);
                    xhr.send();
                    xhr.onabort = () => {
                        this.logger.error("Request to fetch document is aborted");
                        reject();
                    }
                    xhr.onerror = () => {
                        this.logger.error("Error while processing document request");
                        reject();
                    }
                })
                .catch((err) => { 
                    this.logger.debug('Error getting document', err)
                    reject();
                })
            } catch (err) { 
                this.logger.debug('Error, could not execute request', err);  
                reject();
            }
        })
        return promise;
    }

    xlsxCSSFontColor(row , cellNumber){
        const fontColor = { color: {argb:'FFFF0000'}};
        row.getCell(cellNumber).font = fontColor;
    }
    
    xlsxCSSFontWeight(row , cellNumber ){
        row.getCell(cellNumber).alignment = { wrapText: true };
    } 

    xlsxCSSFontAlignemnt(row , cellNumber ){
        row.getCell(cellNumber).alignment = {vertical: 'bottom', horizontal: 'left'};
    }

    public validateEmailEntry(value){
        var result = false;
        if(value === ''){
            return true;
        }
        result = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/.test(value);
        return result && tldjs.tldExists(value);
    }

    buildAAData(deletedUserList:any) {
        let aaDataSheet = this.workbook.addWorksheet(XLSX_SHEETNAME.AUTO_RECEPTIONIST);
        //ws.properties.defaultColWidth = 25;
        aaDataSheet.getRow(2).height = 60;
        aaDataSheet.getColumn(2).width = 15;
        aaDataSheet.getColumn(3).width = 15;
        aaDataSheet.getColumn(4).width = 20;
        aaDataSheet.getColumn(5).width = 20;
        aaDataSheet.getColumn(6).width = 20;
        aaDataSheet.getColumn(7).width = 20;
        aaDataSheet.getColumn(8).width = 30;
        aaDataSheet.getColumn(9).width = 30;
        aaDataSheet.getColumn(10).width = 30;
        aaDataSheet.getColumn(11).width = 30;
        aaDataSheet.getColumn(12).width = 30;
        aaDataSheet.mergeCells('B2:J2');
        aaDataSheet.getCell('B2').value = XLSX_SHEETNAME.AUTO_RECEPTIONIST
        aaDataSheet.getCell('B2').alignment = { vertical: 'middle', horizontal: 'center' };
        aaDataSheet.getRow(2).font = { size: 20, bold: true };

        try{
            this.aaMenuData.forEach(async (aaData,index) => {
                //if(Array.isArray(aaData.AA_Actions) && aaData.AA_Actions.length !== 0){
                    let tableName = aaData.AA_Name.split(" ").join("_");

                    //12 is the static table row + 1 extra row for genral warning
                    //5 is the row from which the first table needs to start
                    let tableIndex = 17; //this is default value 

                    let tableStartRef = (index*tableIndex)+5;
                    aaDataSheet.addTable({
                        name: "aaKeysTable"+index,
                        ref: 'B'+tableStartRef,
                        headerRow: true,
                        //totalsRow: true,
                        /* style: {
                            theme: 'TableStyleDark4',
                            showRowStripes: false,
                        }, */
                        columns: [
                            {name: "Callflow : "+aaData.callFlowName, filterButton: false},
                            {name: "IVR : "+aaData.AA_Name, filterButton: false},
                            {name: "Site Name : "+aaData.siteName, filterButton: false},
                            {name: 'Option', filterButton: false },
                            {name: 'Key Presses', filterButton: false },
                            {name: 'Destination', filterButton: false },
                            {name: 'Warnings', filterButton: false },
                            {name: 'Time Condition Name', filterButton:false},
                            {name: 'Time Condition Values', filterButton:false},
                            {name: 'Errors', filterButton: false },
                            {name: 'Notes', filterButton: false },
                        ],
                        rows: [
                            ["","","",'0',"Not_defined","","","",""],
                            ["","","",'1',"Not_defined","","","",""],
                            ["","","",'2',"Not_defined","","","",""],
                            ["","","",'3',"Not_defined","","","",""],
                            ["","","",'4',"Not_defined","","","",""],
                            ["","","",'5',"Not_defined","","","",""],
                            ["","","",'6',"Not_defined","","","",""],
                            ["","","",'7',"Not_defined","","","",""],
                            ["","","",'8',"Not_defined","","","",""],
                            ["","","",'9',"Not_defined","","","",""],
                            ["","","",'*',"Not_defined","","Mapped to Replay Greeting on ACO","",""],
                            ["","","",'#',"Not_defined","","Mapped to Replay Greeting on ACO","",""]
                        ],
                    });
                    let aaSpecificWarning = this.aaWarningFilteringTemplate();
                    let aaWarningsFound = this.aaNameWithKeysWarning.find(aawarning => aawarning.aaName === aaData.AA_Name)
                    if(aaWarningsFound){
                        aaSpecificWarning = aaWarningsFound;
                    }
                    let res = await this.buildAAKeysMenu(aaData , aaDataSheet, index , aaSpecificWarning, deletedUserList);

                /* }else{
                    this.logger.debug("actions array is empty :",aaData.AA_Name);
                } */
            });
        }catch(error){
            this.logger.error("error while creating IVR tables");
        }
        return;
    }

    buildButtonProgrammingData (deletedUserList:any)
    {
        let buttonProgDataSheet = this.workbook.addWorksheet(XLSX_SHEETNAME.BUTTON_PROGRAMMING);
        buttonProgDataSheet.getRow(2).height = 60;
        buttonProgDataSheet.getColumn(2).width = 20;
        buttonProgDataSheet.getColumn(3).width = 10;
        buttonProgDataSheet.getColumn(4).width = 20;
        buttonProgDataSheet.getColumn(5).width = 20;
        buttonProgDataSheet.getColumn(6).width = 20;
        buttonProgDataSheet.getColumn(7).width = 25;
        buttonProgDataSheet.getColumn(8).width = 35;
        buttonProgDataSheet.getColumn(9).width = 20;
        buttonProgDataSheet.mergeCells('B2:J2');
        buttonProgDataSheet.getCell('B2').value = XLSX_SHEETNAME.BUTTON_PROGRAMMING
        buttonProgDataSheet.getCell('B2').alignment = { vertical: 'middle', horizontal: 'center' };
        buttonProgDataSheet.getRow(2).font = { size: 20, bold: true };
        try
        {   let tableLen = 0,tableStartRef, startIndex = 5 ;
            this.buttonProgData.forEach(async(element,index) => {
            if(element.ButtonProgrammingList.length > 0)
            {
		    let displayModel;
            this.userTableData.forEach(data =>{
                if(element.User === data.ipoUserName)
                    displayModel = data.displayPhoneModel;
            })
                   // tableStartRef = (index*35)+5;
                   if(index === 0)
                   tableStartRef  = startIndex;
                   else 
                   tableStartRef = tableLen + tableStartRef + 2;
                    buttonProgDataSheet.addTable({
                        name: "ButtonProgtable"+index,
                        ref: 'B'+tableStartRef,
                        headerRow: true,
                        columns: [
                            {name: "User : " + element.User, filterButton: false},
                            {name: "Key", filterButton: false},
                            {name: "Label", filterButton: false},
                            {name: "KeyType", filterButton: false },
                            {name: "ActionData", filterButton: false },
                            {name: "ACO PhoneModel : " + displayModel, filterButton: false },
                            {name: "Feature Mapping", filterButton: false },
                            {name: "Can be Migrated on ACO?", filterButton: false },
                           
                        ],
                        rows: [["","","","","","","",""],
                    ],
                    });
                    tableLen = element.ButtonProgrammingList.length;
                   let res = await this.buildButtonProgTable(element , buttonProgDataSheet, index, deletedUserList)    
            }
            });
        }
        catch(error){
            this.logger.error("error while creating Button Programming Tables");
        }
        return;
            
    }

    buildUserRestrictionData()
    {
        let keyTemplatesSheet = this.workbook.addWorksheet(XLSX_SHEETNAME.KEY_TEMPLATES); 
        keyTemplatesSheet.getRow(2).height = 60;
        keyTemplatesSheet.getColumn(2).width = 30;
        keyTemplatesSheet.getColumn(3).width = 30;
        keyTemplatesSheet.getColumn(4).width = 20;
        keyTemplatesSheet.getColumn(5).width = 20;
        keyTemplatesSheet.getColumn(6).width = 20;
        keyTemplatesSheet.getColumn(7).width = 20;
        keyTemplatesSheet.getColumn(8).width = 20;
        keyTemplatesSheet.getColumn(3).alignment = { wrapText: true };
        keyTemplatesSheet.mergeCells('B2:J2');
        keyTemplatesSheet.getCell('B2').value = XLSX_SHEETNAME.KEY_TEMPLATES;
        keyTemplatesSheet.getCell('B2').alignment = { vertical: 'middle', horizontal: 'center' };
        keyTemplatesSheet.getRow(2).font = { size: 20, bold: true };  
        try
        {
            let tableStartRef, startIndex = 5, tableLen = 0;
            if(this.userRestrictionData !== undefined  && this.userRestrictionData.length !== 0)
            {
            this.userRestrictionData.forEach(async(element,index) => {
            if(element.ButtonProgrammingList.length > 0)
            {
                    if(index === 0)
                    tableStartRef  = startIndex;
                    else 
                    tableStartRef = tableLen + tableStartRef + 2;
                    keyTemplatesSheet.addTable({
                        name: "keyTemptable"+index,
                        ref: 'B'+tableStartRef,
                        headerRow: true,
                        columns: [
                            {name: "Name of User Rights : " + element.Name, filterButton: false},
                            {name: "Members of User Rights", filterButton: false , },
                            {name: "key", filterButton: false},
                            {name: "Label", filterButton: false },
                            {name: "KeyType", filterButton: false },
                            {name: "Action Data", filterButton: false },
                            {name: "Site : "+element.Site, filterButton: false },
                          
                           
                        ],
                        rows: [["","","","","","",""],
                    ],
                    });
                    tableLen = element.ButtonProgrammingList.length;
                   let res = await this.buildkeyTemplatesTable(element , keyTemplatesSheet, index)    
            }
            });
            }
        }
        catch(error){
            this.logger.error("error while creating Button Programming Tables");
        }
        return;
          

    }

    async buildAAKeysMenu(aaData , ws , tableIndex , aaWarning, deletedUserList:any){
        let aaDatafiltered = [], unsupportedKeysList = [];
        const table = ws.getTable('aaKeysTable'+tableIndex);
        if(table !== undefined && Array.isArray(aaData.AA_Actions) && aaData.AA_Actions.length != 0){
            aaData.AA_Actions.forEach((aakey:any,index) => {
                let tempAAkeyData = []
                let warning = ""
                let userDeletedWarning:any;
                if (deletedUserList !== undefined && deletedUserList.length > 0) {
                    const isDeletedUser = deletedUserList.find((user: any) => (user.extension.toString() === aakey.Destination.toString() ||
                        user.username.toString() === aakey.Destination.toString() ||
                        user.ipoUserName.toString() === aakey.Destination.toString()))
                    if (isDeletedUser !== undefined) userDeletedWarning = 'User deleted from the user assignment section';
                }
                switch(aakey.Key){
                    case "0":
                        //tempAAkeyData.push(["","", '0' , aakey.Action , aakey.Destination]);
                        table.removeRows(0,1);
                        table.addRow(["","","", "0" , aakey.Action , aakey.Destination, this.fetchKeySpecificWarning("0",aaWarning, userDeletedWarning), "","","",""], 0);
                        break;
                    case "1":
                        //tempAAkeyData.push(["","", '1' , aakey.Action , aakey.Destination]);
                        //warning = this.fetchKeySpecificWarning("1",aaWarning);
                        table.removeRows(1,1);
                        table.addRow(["","","", "1" , aakey.Action , aakey.Destination, this.fetchKeySpecificWarning("1",aaWarning, userDeletedWarning), "","","",""], 1);
                        break;
                    case "2":
                        //tempAAkeyData.push(["","", '2' , aakey.Action , aakey.Destination]);
                        table.removeRows(2,1);
                        table.addRow(["","","", "2" , aakey.Action , aakey.Destination, this.fetchKeySpecificWarning("2",aaWarning, userDeletedWarning), "","","",""], 2);
                        break;
                    case "3":
                        //tempAAkeyData.push(["","", '3' , aakey.Action , aakey.Destination]);
                        table.removeRows(3,1);
                        table.addRow(["","","", "3" , aakey.Action , aakey.Destination, this.fetchKeySpecificWarning("3",aaWarning, userDeletedWarning), "","","",""], 3);
                        break;
                    case "4":
                        //tempAAkeyData.push(["","", '4' , aakey.Action , aakey.Destination]);
                        table.removeRows(4,1);
                        table.addRow(["","","", "4" , aakey.Action , aakey.Destination, this.fetchKeySpecificWarning("4",aaWarning, userDeletedWarning), "","","",""], 4);
                        break;
                    case "5":
                        //tempAAkeyData.push(["","", '5' , aakey.Action , aakey.Destination]);
                        table.removeRows(5,1);
                        table.addRow(["","","", "5" , aakey.Action , aakey.Destination, this.fetchKeySpecificWarning("5",aaWarning, userDeletedWarning), "","","",""], 5);
                        break;
                    case "6":
                        //tempAAkeyData.push(["","", '6' , aakey.Action , aakey.Destination]);
                        table.removeRows(6,1);
                        table.addRow(["","","", "6" , aakey.Action , aakey.Destination, this.fetchKeySpecificWarning("6",aaWarning, userDeletedWarning), "","","",""], 6);
                        break;
                    case "7":
                        //tempAAkeyData.push(["","", '7' , aakey.Action , aakey.Destination]);
                        table.removeRows(7,1);
                        table.addRow(["","","", "7" , aakey.Action , aakey.Destination, this.fetchKeySpecificWarning("7",aaWarning, userDeletedWarning), "","","",""], 7);
                        break;
                    case "8":
                        //tempAAkeyData.push(["","", '8' , aakey.Action , aakey.Destination]);
                        table.removeRows(8,1);
                        table.addRow(["","","", "8" , aakey.Action , aakey.Destination, this.fetchKeySpecificWarning("8",aaWarning, userDeletedWarning), "","","",""], 8);
                        break;
                    case "9":
                        //tempAAkeyData.push(["","", '9' , aakey.Action , aakey.Destination]);
                        table.removeRows(9,1);
                        table.addRow(["","","", "9" , aakey.Action , aakey.Destination, this.fetchKeySpecificWarning("9",aaWarning, userDeletedWarning), "","","",""], 9);
                        break;
                    //* and # will have "Mapped to Replay Greeting on ACO" as default warning irrespective of action configured.
                    case "*":
                        //tempAAkeyData.push(["","", '*' , aakey.Action , aakey.Destination]);
                        table.removeRows(10,1);
                        table.addRow(["","","", "*" , aakey.Action , aakey.Destination, "Mapped to Replay Greeting on ACO", "","","",""],10);
                        break;
                    case "#":
                        //tempAAkeyData.push(["","", '#' , aakey.Action , aakey.Destination]);
                        table.removeRows(11,1);
                        table.addRow(["","","", "#" , aakey.Action , aakey.Destination, "Mapped to Replay Greeting on ACO", "","","",""],11);
                        break;
                    default:
                        this.logger.debug("Invalid keys",aakey.Key);
                        if (!aakey.Key.includes('?')) unsupportedKeysList.push(aakey.Key);
                        break;
                }  
                //aaDatafiltered.push(tempAAkeyData);
                //table.commit();
            })
            //if its an empty menu , * and # with default warning will be added
            if(table.table.rows.length === 0){
                table.addRow(["","","","*","","", "Mapped to Replay Greeting on ACO", "","","",""]); 
                table.addRow(["","","","#","","", "Mapped to Replay Greeting on ACO", "","","",""]);  
            }
            //all the unsupported keys warning will be joined and added in separate row
            if(unsupportedKeysList.length !== 0){
                table.addRow(["Keys "+unsupportedKeysList.join(", ")+" are not supported in ACO. These keys will not be migrated to ACO."]);
            }
            //all the generic warning will be joined and added in separate row
            if(aaWarning.generalWarnings !== ""){
                    table.addRow([aaWarning.generalWarnings]);  
            }
            if (aaWarning.dialByExtnWarning !== ""){
                    table.addRow([aaWarning.dialByExtnWarning]);
            }

            if (aaData.vmProModuleConditions !== undefined && aaData.vmProModuleConditions.length > 0) {
                aaData.vmProModuleConditions.forEach((eachTP: any) => {
                    if (eachTP.profiles.data.schedule.ranges !== undefined)
                        table.addRow(["", "", "", "", "", "", "", eachTP.name, eachTP.profiles.data.schedule.ranges, "", ""])
                    else if (eachTP.profiles.data.schedule.weeklyRanges !== undefined)
                        table.addRow(["", "", "", "", "", "", "", eachTP.name, eachTP.profiles.data.schedule.weeklyRanges, "", ""])
                })
            }

            table.commit();
        }
        return aaDatafiltered;
    }
    async buildButtonProgTable (buttonProgData , ws , progindex, deletedUserList)
    {
        if(Array.isArray(buttonProgData.ButtonProgrammingList) && buttonProgData.ButtonProgrammingList.length != 0){
            const table = ws.getTable('ButtonProgtable'+progindex);
            if(table !== undefined)
            {
            let isDeletedUser:any;
            table.removeRows(0,1);
            buttonProgData.ButtonProgrammingList.forEach((key, index) => {
                if (deletedUserList !== undefined && deletedUserList.length > 0) {
                    isDeletedUser = deletedUserList.find((user: any) => (user.ipoUserName === buttonProgData.User))                  
                }
                if(isDeletedUser !== undefined && index === 0)
                {
                    table.addRow(["","","","","" ,"","User deleted from the user assignment section","No"]);
                    table.addRow(["",key.key,key.Label,key.Action , key.ActionData ,"", key.FeatureMapping, key.MigrationStatus]);
                }
                else
                    table.addRow(["",key.key,key.Label,key.Action , key.ActionData ,"", key.FeatureMapping, key.MigrationStatus]);
            });
            }
            table.commit();
        }
        return;
    }
    async buildkeyTemplatesTable (keyTempData , ws , progindex)
    {
        if(Array.isArray(keyTempData.ButtonProgrammingList) && keyTempData.ButtonProgrammingList.length != 0){
            const table = ws.getTable('keyTemptable'+progindex);    
            table.removeRows(0,1);
            keyTempData.ButtonProgrammingList.forEach((key,index) => {
                    if(index === 0)
                    table.addRow(["",keyTempData.Members.toString(),key.key,key.Label , key.keyType ,key.ActionData, ""]);
                    else
                    table.addRow(["","",key.key,key.Label , key.keyType ,key.ActionData, ""]);
                });
            table.commit();
        }
        return;
    }

    async buildParkLocationData() {

        let isParkLocDataPresent = false;
        let parkLocDataSheet = this.workbook.addWorksheet(XLSX_SHEETNAME.PARK_LOCATION);

        parkLocDataSheet.getColumn(1).width = 20;
        parkLocDataSheet.getColumn(2).width = 20;
        parkLocDataSheet.getColumn(3).width = 30;
        parkLocDataSheet.getColumn(4).width = 50;
        parkLocDataSheet.getColumn(5).width = 50;
        parkLocDataSheet.getColumn(6).width = 50;

        //User extension list in current migration
        let userExtensions = this.userTableData.map((user) => {
            if (user !== undefined && user.extension !== undefined) {
                return user.extension;
            }
        });
        
        //hunt group extension list in current migration
        let hgExtensions:any;
        if (this.callQueueData !== undefined && this.callQueueData.length > 0) {
            hgExtensions = this.callQueueData.map((callQueue) => {
                if (callQueue !== undefined && callQueue.Group !== undefined && callQueue.Group.GR_Extension !== undefined) {
                    return callQueue.Group.GR_Extension;
                }
            })
        }

        let parkLocInfoHolder = ["Can be migrated?","Extension Number","Group Name","Park Location Members (Ext)","Errors","Warnings"];
        parkLocDataSheet.addRow(parkLocInfoHolder);
        parkLocDataSheet.getRow(1).font = { size:14, bold:true };

        if(this.parkLocationData !== undefined && this.parkLocationData.length > 0){
        this.parkLocationData.forEach(async (parkLocation) => {
            let parkLocTemp = [];
            let warnings = "", errors = "";
            if (parkLocation.groupExtension !== undefined && parkLocation.groupName !== undefined && parkLocation.members !== undefined) {
                
                if (this.parkLocDupExtnList.includes(parkLocation.groupExtension.toString())) {
                    errors += PARK_LOCATION_WARNING.PARK_LOCATION_EXTENSION_EXISTS;
                }

                if (this.parkLocResvExtnList.includes(parkLocation.groupExtension.toString())) {
                    errors += PARK_LOCATION_WARNING.PARK_LOCATION_RESERVED_EXTENSION;
                }
                
                if (this.skippedMessageOnlyUserExtns.size > 0) {
                    this.skippedMessageOnlyUserExtns.forEach((value, key) => {
                        if (key == parkLocation.groupExtension) {
                            warnings += PARK_LOCATION_WARNING.SKIP_MESSAGE_ONLY_PARK_LOCATION + value.join(", ");
                        }
                    })
                }

                if (this.skippedLimitedExtnUserExtns.size > 0) {
                    this.skippedLimitedExtnUserExtns.forEach((value, key) => {
                        if (key == parkLocation.groupExtension) {
                            warnings += PARK_LOCATION_WARNING.SKIP_LIMITED_EXTENSION_PARK_LOCATION + value.join(", ");
                        }
                    })
                }

                if (userExtensions.includes(parseInt(parkLocation.groupExtension)) || (hgExtensions !==undefined &&hgExtensions.includes(parseInt(parkLocation.groupExtension)))) {
                    warnings += PARK_LOCATION_WARNING.DUPLICATE_EXTENSION_PARK_LOCATION;
                }

                if ((errors !== "") || 
                userExtensions.includes(parseInt(parkLocation.groupExtension)) || (hgExtensions !== undefined && hgExtensions.includes(parseInt(parkLocation.groupExtension)))) {
                    parkLocTemp.push("No");
                } else if (errors.length === 0) {
                    parkLocTemp.push("Yes")
                }
                parkLocTemp.push(parkLocation.groupExtension);
                parkLocTemp.push(parkLocation.groupName);
                parkLocTemp.push(parkLocation.members.join(","));
                parkLocTemp.push(errors);
                parkLocTemp.push(warnings);

                parkLocDataSheet.addRow(parkLocTemp);
                isParkLocDataPresent = true;
                const row = parkLocDataSheet.lastRow;
                this.xlsxCSSFontWeight(row, 4);
                this.xlsxCSSFontWeight(row, 5);
                this.xlsxCSSFontWeight(row, 6);

                if (errors != "") {
                    this.xlsxCSSFontColor(row , 1);
                    this.xlsxCSSFontColor(row , 5);
                }

                if (row.getCell(1).value === "No") {
                    this.xlsxCSSFontColor(row , 1);
                }
            }
        });
       }
        
        //Adding all generic warnings seperately
        let genericWarnings = [];
        parkLocDataSheet.addRow({});
        let lastRow = parkLocDataSheet.lastRow;
        let lastRowNumber = lastRow.number + 2;

        let mergeParameter = 'A'+lastRowNumber+':F'+lastRowNumber;
        parkLocDataSheet.mergeCells(mergeParameter);

        parkLocDataSheet.getCell('A'+lastRowNumber).value = "Generic Warnings";
        parkLocDataSheet.getRow(lastRowNumber).font = { size: 12, bold: true };

        if (isParkLocDataPresent) {
            genericWarnings.push(PARK_LOCATION_WARNING.PARK_LOCATION_BTN_PRGM_NOT_CONFIGURABLE);
        }

        if (this.emptyParkLocExtUserList.length > 0) {
            genericWarnings.push(PARK_LOCATION_WARNING.PARK_LOCATION_EXT_EMPTY + this.emptyParkLocExtUserList.join(", "));
        }

        genericWarnings.forEach((genericWarn) => {
            lastRowNumber++;
            parkLocDataSheet.mergeCells('A'+lastRowNumber+':F'+lastRowNumber);
            parkLocDataSheet.getCell('A'+lastRowNumber).value = genericWarn;
        });
    }

    fetchKeySpecificWarning(key , aaSpecificWarning, userDeletedWarning){
        let warning = ""
        if (userDeletedWarning !== undefined) warning = userDeletedWarning;
        else {
            let keySpecificWarningFound = aaSpecificWarning.warningList.find(warning => warning.key === key)
            if (keySpecificWarningFound) {
                warning = keySpecificWarningFound.warningMsg;
            }
        }
        return warning;
    }
}



