import { Component, OnInit, NgZone } from '@angular/core';
import { LogService } from 'src/app/shared/services/logging/log.service';
import { Router } from '@angular/router';
import { TablePreviewService, SEVERITY, VM_ERROR_MSG ,TWIN_CONTACT_NUMBERS } from 'src/app/shared/services/tablePreview/table-preview.service';
import { SessionHandlerService } from 'src/app/shared/services/sessionHandler/session-handler.service';
import { NgxSpinnerService } from "ngx-spinner";
import { Subscription } from 'rxjs';
import { TRIGGER_STATUS } from 'src/app/shared/services/firestore/firestore.service';
import { FirestoreService } from 'src/app/shared/services/firestore/firestore.service';

const TIMER_LIMIT=100000;
const ZINDEX = 1000;

@Component({
  selector: 'app-user-data',
  templateUrl: './user-data.component.html',
  styleUrls: ['./user-data.component.css']
})
export class UserDataComponent implements OnInit {

  id:any;
  public greetingsTriggerStatus = TRIGGER_STATUS;
  companyName:string;
  isReadyToMigrate:boolean;
  isAdvUserInfoWarnFound:boolean;
  isDataReady:boolean=false;
  isSysmonDataReady:boolean=false;
  readonly TIMER_INTERVAL = 60000;
  subscription:Subscription;
  newSubscription:Subscription;
  sysmonSubscription:Subscription;
  isGreetingsFileUploaded=TRIGGER_STATUS.NOTYET;
  isSysmonFileUploaded=TRIGGER_STATUS.NOTYET;
  greetingsSubscription:Subscription;
  advInfoToolTipText:string;
  wait:any;
  zIdx=ZINDEX;

  constructor(private logger:LogService, private zone:NgZone,
    private router:Router,private tablePreviewService:TablePreviewService,
    private session:SessionHandlerService,private spinner:NgxSpinnerService,
    private firestoreService:FirestoreService) {
    this.logger.debug("User Data Component constructor");
    this.id = this.session.cardId;
    this.companyName = this.session.companyName;
    this.spinner.show();
    this.greetingsSubscription = this.tablePreviewService.getGreetingsDocStatus().subscribe((status)=>{
      if(status == TRIGGER_STATUS.START){          
        this.wait = setTimeout(()=>{
          clearTimeout(this.wait);
          this.isGreetingsFileUploaded = TRIGGER_STATUS.UNKNOWN;
        },TIMER_LIMIT)
      }else if(status == TRIGGER_STATUS.DONE) {
        clearTimeout(this.wait); 
        this.updateWarnings();
      }
      this.isGreetingsFileUploaded = status;
    })
    
    this.sysmonSubscription = this.tablePreviewService.getSysmonDocStatus().subscribe((status) => {
      if ((status == TRIGGER_STATUS.START) || (status == TRIGGER_STATUS.NOTYET)){
        this.wait = setTimeout(() => {
            clearTimeout(this.wait);
            this.isSysmonFileUploaded = TRIGGER_STATUS.UNKNOWN;
          }, TIMER_LIMIT)
      } else if(status == TRIGGER_STATUS.DONE) {
        clearTimeout(this.wait);
      }
      this.isSysmonFileUploaded = status;
    })
   }

  ngOnInit(): void {
    this.subscription = this.tablePreviewService.getTableDataInitializedObs().subscribe((res)=>{
      this.isDataReady = res;
      this.tablePreviewService.notificationsList.forEach(siteNotifications => {
        this.isReadyToMigrate = !(siteNotifications.find(entry => (
          entry.severity === SEVERITY.ERROR)));
      });
      this.updateWarnings();
      this.firestoreService.fetchSysmonData(this.id);
    })
    if (this.tablePreviewService.isSysmonFileUploaded) {
      this.newSubscription = this.tablePreviewService.getSysmonDataInitializedObs().subscribe((res)=>{
        this.isSysmonDataReady = res;
      });
    } else {
      this.isSysmonDataReady = true;
    }
  }

  ngOnDestroy(){
    if (this.subscription) this.subscription.unsubscribe();
    if (this.greetingsSubscription) this.greetingsSubscription.unsubscribe();
    if (this.sysmonSubscription) this.sysmonSubscription.unsubscribe();
    if (this.newSubscription) this.newSubscription.unsubscribe();
    this.spinner.hide();
    clearTimeout(this.wait);
  }

  showBasicUserData(){
    if(this.isDataReady && this.isSysmonDataReady)
      this.zone.run(() => { this.router.navigate(['/home/migration/basic-info']); });
  }

  showAdvancedUserData(){
    if(this.isDataReady && this.isGreetingsFileUploaded !== this.greetingsTriggerStatus.START)
      this.zone.run(() => { this.router.navigate(['/home/migration/advanced-info']); });
  }

  private updateWarnings(){
    this.isAdvUserInfoWarnFound = this.tablePreviewService.advncdUserInfoWarningsList.length>0?true:false;
    this.advInfoToolTipText = this.getAdvInfoToolTipText();
  }
  private getAdvInfoToolTipText(){
    if(this.tablePreviewService.advncdUserInfoWarningsList.length > 0){
      let warningsList = [];
      if(this.tablePreviewService.advncdUserInfoWarningsList.length === 1 && 
        this.tablePreviewService.advncdUserInfoWarningsList[0] === VM_ERROR_MSG.GREETINGS_MISSING){
          warningsList.push(VM_ERROR_MSG.GREETINGS_MISSING);
      }else if(this.tablePreviewService.advncdUserInfoWarningsList.length === 2 && 
        this.tablePreviewService.advncdUserInfoWarningsList[0] === VM_ERROR_MSG.GREETINGS_MISSING && 
        this.tablePreviewService.advncdUserInfoWarningsList[1].includes(TWIN_CONTACT_NUMBERS.STEERING_CODES_REMOVAL)){
          warningsList.push(VM_ERROR_MSG.GREETINGS_MISSING);
      }else {
        if(this.tablePreviewService.advncdUserInfoWarningsList
          .find(entry=>entry.includes(VM_ERROR_MSG.GREETINGS_MISSING)) !== undefined){
            warningsList.push(VM_ERROR_MSG.GREETINGS_MISSING+" for few users");
        }
        if(this.tablePreviewService.advncdUserInfoWarningsList
          .find(entry=>entry.includes(VM_ERROR_MSG.IGNORED_USERS)) !== undefined){
            warningsList.push("Voicemail greetings ignored for few users");
        }
        if(this.tablePreviewService.advncdUserInfoWarningsList
          .find(entry=>entry.includes(VM_ERROR_MSG.PARTIAL_USERS)) !== undefined){
            warningsList.push(VM_ERROR_MSG.PARTIAL_USERS+" for few users");
        }
      }
      return  warningsList.join("\n");
    }
    return "";
  }
}
