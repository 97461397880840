import { Injectable } from '@angular/core';
import { LogService } from '../logging/log.service';
import { FirestoreService } from '../firestore/firestore.service';
import { SessionHandlerService } from 'src/app/shared/services/sessionHandler/session-handler.service';
import { OverlayService } from 'src/app/shared/services/overlay/overlay.service';
import { MigrationDataService } from './../../../shared/services/migrationDataService/migration-data.service';


export enum SEVERITY{
    WARNING,
    ERROR,
    INFO
}
export const ZERODIAL_NOTIFICATIONS = {
    ZERODIAL_INFO : "\nZero Dialing is set to ",
    PARK_PAGE : "\nZero Dialing cannot be set to 'Park & Page' on ACO.",
    CENTREX_TRANSFER : "\nZero Dialing cannot be set to External Transfer on ACO.",
    SHORT_CODES : "\nZero Dialing cannot be set to 'Short Codes' on ACO.",
}
@Injectable({
    providedIn: 'root'
  })
export class RefreshService {
    private id:any;
    public notifications = [];
    private _enableRefreshData: boolean = false;
    public get enableRefreshData(): boolean {
        return this._enableRefreshData;
    }
    public set enableRefreshData(value: boolean) {
        this._enableRefreshData = value;
        if(document.getElementsByClassName("mat-badge-content").length !== 0){
            if(this._enableRefreshData){
                document.getElementsByClassName("mat-badge-content")[0].classList.remove("warningBadge");
            }else{
                document.getElementsByClassName("mat-badge-content")[0].classList.add("warningBadge");
            }
        }
    }
    readonly MAX_IPO_EXTNS = 3500;
    constructor(private logger: LogService, private firestoreService:FirestoreService,private session:SessionHandlerService,private overlay:OverlayService,private migrationDataService:MigrationDataService) {
    this.logger.debug("File Upload service");
    this.enableRefreshData = false;
}
fetchpreRequisiteData(id){
    console.log("fetchupdatedACOData");
    var promise = new Promise((resolve, reject) => {
    this.firestoreService.getPrerequisiteInfo(id)
    .then(() =>{
        resolve();
    });
  });
  return promise;
}
validatePrerequisiteData(){
    console.log("validate Prerequisite data");
    this.notifications = [];
    var msg, zeroDialMsg = [];
    if((this.migrationDataService.ACOSummaryData === undefined ||
        this.migrationDataService.ACOSummaryData === null) || 
        (this.migrationDataService.IPOSummaryData === undefined || 
        this.migrationDataService.IPOSummaryData === null)){
            this.logger.warn("Ignore validating prerequisite data with aco, ipo summary");
            return;
    }
    var ipoLen = parseInt(this.migrationDataService.IPOSummaryData.IPOSummary.Max_Extension_Length);
    var acoLen = parseInt(this.migrationDataService.ACOSummaryData.maxExtensionLen);
    if(ipoLen > acoLen){
        msg = "ACO max extension number length (" 
        + acoLen
        + ")  is lesser than IP Office max extension number length(" 
        + ipoLen + ")";
        this.notifications.push({msg: msg, severity: SEVERITY.ERROR});
    }
    if( this.migrationDataService.ACOSummaryData.cqMemberlimitAvaialbility !== true)
    {
        msg = "Maximum Agents in Simultaneous Call Queue feature is not supported for this account.";
        this.notifications.push({msg: msg, severity: SEVERITY.INFO});
    }

    if(this.migrationDataService.ZeroDialData !== null && this.migrationDataService.ZeroDialData !== undefined)
    {
        if(this.migrationDataService.ZeroDialData.length !== 0)
        {
        let list = [];
        this.migrationDataService.ZeroDialData.forEach(element => {
            if(element.destination !== "")
                list.push(element.destination + " in "+ element.fileName.split(".csv")[0]);
        if(element.destination === "Park & Page")
        {
            zeroDialMsg.push(ZERODIAL_NOTIFICATIONS.PARK_PAGE);
        }
        if(element.destination === "Centrex Transfer")
        {
            zeroDialMsg.push(ZERODIAL_NOTIFICATIONS.CENTREX_TRANSFER);;
        }
        if(this.migrationDataService.ShortCodes === undefined || this.migrationDataService.ShortCodes === null) 
        {
            console.log("Short codes not present");
        }
        else
        {
            this.migrationDataService.ShortCodes.ShortCodesData.forEach(element => {
                if(element.SC_ShortCode === element.destination)
                {
                    zeroDialMsg.push(ZERODIAL_NOTIFICATIONS.SHORT_CODES);
                }
            }); 
        }             
        }); 
        if (list.length !== 0)
            zeroDialMsg.push(ZERODIAL_NOTIFICATIONS.ZERODIAL_INFO + list);
        this.notifications.push({msg: zeroDialMsg, severity: SEVERITY.INFO});
    }
    }
    if(this.migrationDataService.IPOSummaryData.IPOSummary.No_of_Users_Without_ExtensionDetails > 0)
    {
        msg = "Users with Message Only extension(s): "+this.migrationDataService.IPOSummaryData.IPOSummary.Users_Without_ExtensionDetails.join( " , ");
        this.notifications.push({msg: msg, severity: SEVERITY.INFO});
    }
    var ipoUsers = parseInt(this.migrationDataService.IPOSummaryData.IPOSummary.No_Of_Users);
    if(ipoUsers > this.MAX_IPO_EXTNS){
        msg = "Migration attempt for " + ipoUsers  
        + " IP Office users not allowed. Max allowed limit is for 1000 users only";
        this.notifications.push({msg: msg, severity: SEVERITY.ERROR});
    }
    if(ipoUsers > this.migrationDataService.ACOSummaryData.total){
        msg = "IP Office contains " 
        + ipoUsers
        + " user(s) but the targeted ACO has only " 
        + this.migrationDataService.ACOSummaryData.total 
        + " unassigned Extension number(s)";
        this.notifications.push({msg: msg, severity: SEVERITY.WARNING});
    }
    var ipoUserNoEmails = parseInt(this.migrationDataService.IPOSummaryData.IPOSummary.No_Of_Users_Without_email);
    if(ipoUserNoEmails){
        msg = ipoUserNoEmails + " users in the CSV file do not have the email(s) associated with them";
        this.notifications.push({msg: msg, severity: SEVERITY.WARNING});
    }
    var ipoDuplicateExtns = this.migrationDataService.IPOSummaryData.IPOSummary.Duplicate_Extensions;
    //this.logger.debug("IPO duplicate extensions: ", ipoDuplicateExtns);
    if(ipoDuplicateExtns.length){
        msg = "Remove duplicate extension number(s) ";
        ipoDuplicateExtns.forEach(element => {
            msg = msg + element + ", ";
        });
        msg = msg.slice(0,-2) + " in the csv and upload again";
        this.notifications.push({msg: msg, severity: SEVERITY.ERROR});
    }
    if(this.migrationDataService.ACOSummaryData.total === 0){
        msg = "No unassigned Extension number(s)available in ACO system, please contact Administrato";
        this.notifications.push({msg: msg, severity: SEVERITY.ERROR});
    }
    if(this.migrationDataService.ACOSummaryData.dl_count === 0){
        msg = "ACO system does not contain any hardware endpoints associated with it";
        this.notifications.push({msg: msg, severity: SEVERITY.WARNING});
    }
    if(ipoUsers > this.migrationDataService.ACOSummaryData.dl_count){
        msg = "ACO system has only " 
            + this.migrationDataService.ACOSummaryData.dl_count 
            + " Extension number(s) with Digital Lines";
        this.notifications.push({msg: msg, severity: SEVERITY.WARNING});
    }
    else if(ipoUsers > this.migrationDataService.ACOSummaryData.did_count){
        msg = "ACO system has only " 
        + this.migrationDataService.ACOSummaryData.did_count 
        + " Extension number(s) with Direct Numbers";
        this.notifications.push({msg: msg, severity: SEVERITY.WARNING});
    }
    else if(ipoUsers > this.migrationDataService.ACOSummaryData.count){
        msg = "ACO system has only " 
        + this.migrationDataService.ACOSummaryData.did_count 
        + "  Extension number(s)";
        this.notifications.push({msg: msg, severity: SEVERITY.WARNING});
    }
    var errorFound = this.notifications.find(entry => entry.severity === SEVERITY.ERROR)
    if(errorFound !== undefined){
        this.enableRefreshData = true;
    }
    else{
        this.enableRefreshData = false;
    }
    this.logger.debug("VALIDATION result: ", this.notifications);
    this.overlay.closeSpinner();
  }

}
