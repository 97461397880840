<div class="contentBlock migrationDataBlock">
    <div id="contentInfo" class="contentInfo">
        <button class="closeBtn" mat-icon-button matTooltip="close" (click)="closeMigrationActivity()">
            <mat-icon style=" font-size: 0.8vw;line-height: 1vw;">close</mat-icon>
        </button>
    </div>
    <div class="contentMain">
        <div id="tabulatorSettings" *ngIf=showTableSettings>
            <div style="width: 50%;display: flex;height: 100%;">

                <mat-form-field class="searchFormField"
                    [ngClass]="showErrorWarningBlock ? 'searchHide' : 'searchShow'">

                    <button mat-button matPrefix mat-icon-button>
                        <mat-icon>search</mat-icon>
                    </button>
                    <mat-label>{{searchOption}}</mat-label>
                    <input matInput type="text" [(ngModel)]="searchInput" (keyup)="updateFilter()">
                    <button mat-button  matSuffix mat-icon-button (click)=search.open()>
                        <mat-icon>expand_more</mat-icon>
                    </button>
                    <mat-select [(ngModel)]="searchOption" #search class="langSelect">
                        <mat-option value="Entire Table">Entire Table</mat-option>
                        <mat-option value="username">User Name</mat-option>
                        <mat-option value="email">Email</mat-option>
                        <mat-option value="extension">Extension Number</mat-option>
                        <mat-option value="phone">PhoneType</mat-option>
                        <mat-option value="macId">Serial Number</mat-option>
                        <mat-option value="siteName">Site Name</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div style="float:right;width: 50%;height: 100%;display: flex;align-items: center;">

                <span  matTooltip="Notifications" style="margin-left:72%;margin-top: 0.5%;" (click)="getErrorWarningList()" matBadge={{errorcount}} 
                    matBadgeSize="small" matBadgeColor="warn">
                    <img src="../../../../assets/images/notification/Notification_thicker.svg" class="notificationIcon">
                </span>

                <button mat-icon-button matTooltip="Delete" style="float:right;margin-left: 3%;margin-bottom: 0.25%;" [disabled]="!isRowSelected" (click)="displayDelUsrModal()">
                    <mat-icon style="font-size: 1vw;" id="deleteUserBtn" class="btn_disabled">delete</mat-icon>
                </button>

                <button mat-icon-button matTooltip="Undo" style="float:right;margin-left: auto;margin-bottom: 0.25%;" [disabled]="!isUsersDeleted" (click)="undoUsers()">
                    <mat-icon style="font-size: 1vw;" id="undoUserBtn" class="btn_disabled">undo</mat-icon>
                </button>

                <button [ngClass]="showErrorWarningBlock ? 'searchHide' : 'searchShow'" mat-icon-button
                    matTooltip="Settings" (click)=select.open() style="float:right;margin-right: 2%;margin-left: auto;"
                    *ngIf="allowEdit">                    

                    <mat-icon style=" font-size: 1vw;">more_vert</mat-icon>

                    <mat-select #select class="langSelect">
                        <mat-option value="option" (click)="assignName()">Assign Names</mat-option>
                        <mat-option value="option" (click)="assignPhoneModels()">Assign Phone Type</mat-option>
                        <mat-option value="option" (click)="assignEmail()">Assign Email</mat-option>
                        <mat-option value="option" (click)="clearConfig()">Clear Configuration</mat-option>
                    </mat-select>
                </button>

            </div>
        </div>
        <div id="tabulator-div">

        </div>
        <div id="errorWarningBlock" class="errorWarningBlock" *ngIf=showErrorWarningBlock style= "overflow-y:auto;">
            <!--errorWarningBlock and autoConfigSettings same css , so move it to common class -->
            <div matTooltip="Refresh" style="margin:2% 95% 0% auto;" (click)="doACOPrerequisiteInfo(true)">
                <img src="../../../../assets/images/notification/refresh.svg" style="width:1.5vw;">
              </div>
            <p class="errorTitle">
                Notifications :
            </p>
            <mat-accordion style = "overflow-y:auto;">
                  <mat-expansion-panel  class="errorWarningRow" *ngFor="let item of errorWarningList">     
                    <mat-expansion-panel-header>
                        <mat-panel-description>               
                    {{item[0]}}
                </mat-panel-description>
            </mat-expansion-panel-header>
            <div style="width: 100%;height: 75%; overflow-x: hidden;overflow-y:auto;">
                <table style="width: 90%;max-height: 100%;">
                 <tr class="errorWarningRow" *ngFor="let value of item[1]">  
                    <td>                
                    {{value.msg}} 
                    </td>                   
                    <td *ngIf="value.severity == SEVERITY.INFO">
                        <mat-icon style=" font-size: 1.1vw;color: green;">
                          warning
                        </mat-icon>
                    </td>
                    <td *ngIf="value.severity == SEVERITY.WARNING">
                      <mat-icon style=" font-size: 1.1vw;color: #FF8200F7;">
                        warning
                      </mat-icon>
                    </td>
                    <td *ngIf="value.severity == SEVERITY.ERROR">
                      <mat-icon style=" font-size: 1.1vw;color: #DA291C;">
                        error
                      </mat-icon>
                    </td>
                </tr>
            </table>
        </div>
           </mat-expansion-panel>
        </mat-accordion>
            <button class="tableButton " style="margin-top: 1%;align-self: center;" (click)="closeErrorWarningBlock()">
                Close
            </button>
        </div>
        <div id="autoConfigSettings" class="autoConfigSettings" *ngIf=showAutoConfigSettings>
            <p class="auotConfigTitle">Auto Configuration Settings</p>
            <hr>
            <div id="assignName" class="assignSettings" *ngIf="showAutoConfigOption == 'showAssignNames'">

                <label class="autoAssignLabel">First & Last Name</label>

                <mat-form-field class="autoAssignNameFormField">
                    <mat-label>Assign Name</mat-label>
                    <mat-select [(ngModel)]="nameOption">
                        <mat-option [value]="assignNameEnum.Fname_Lname">Fname , Lname</mat-option>
                        <mat-option [value]="assignNameEnum.Lname_Fname">Lname , Fname</mat-option>
                        <mat-option [value]="assignNameEnum.Fname">Fname</mat-option>
                        <mat-option [value]="assignNameEnum.Lname">Lname</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div id="assignPhoneModels" class="assignSettings" *ngIf="showAutoConfigOption == 'showPhoneModels'">
                <label class="autoAssignLabel" >Assign Phone Types</label>
                <div class="phoneModelTable" style="width: 78%;flex-direction: column;display: flex;overflow-y: auto;">
                    <div style="flex-direction: row;display: flex;align-items: center;width: 100%;">
                        <mat-form-field class="autoAssignPhonemodelFormField">
                            <mat-label>IPO Group Type</mat-label>
                            <mat-select [(ngModel)]="groupOption" (selectionChange)="onGroupChange($event)">
                                <mat-option [value]=assignPhoneEnum.PhoneType>Group by Phone Model</mat-option>
                                <mat-option [value]="assignPhoneEnum.UserType">Group by License</mat-option>
                                <mat-option *ngIf="showGroupBySysmon" [value]="assignPhoneEnum.SysmonType">Group by Sysmon</mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field class="autoAssignPhonemodelFormField" *ngIf=showIPOPhoneType>
                            <mat-label>IPO Phone Type</mat-label>
                            <mat-select [(ngModel)]="ipoPhoneModelOption">
                                <mat-option *ngFor="let phone of ipoPhoneModelsList" [value]="phone.phoneModel">
                                    ({{phone.count}}) {{phone.phoneModel}} </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field class="autoAssignPhonemodelFormField"
                            *ngIf=showIPOUserType>
                            <mat-label>IPO User Type</mat-label>
                            <mat-select [(ngModel)]="ipoUserProfileOption">
                                <mat-option *ngFor="let user of ipoUsersProfileList" [value]="user.profileType">
                                    ({{user.count}}) {{user.profileType}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field class="autoAssignPhonemodelFormField" *ngIf=!showIPOSysmonType>
                            <mat-label>ACO Phone Type</mat-label>
                            <mat-select [(ngModel)]="acoPhoneModelOption">
                                <mat-option *ngFor="let phone of acoPhoneModelsList" [value]="phone.phoneModel">
                                    ({{phone.count}}) {{phone.phoneModel}} </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <img src="../../../../assets/images/Add.svg" (click)="addPhoneModel()" style="height: 1.2vw;margin-left: 3%;">
                    </div>
                    <div class="assignPhoneTable" *ngFor="let item of assignedPhoneModelHistory; index as i" >
                        <label class="autoAssignPhonemodelFormField ">{{item.group}}</label>
                        <label class="autoAssignPhonemodelFormField">{{item.ipoModelType}} ({{item.count}})</label>
                        <label class="autoAssignPhonemodelFormField">{{item.acoModelType}} ({{item.count}})</label>
                        <img src="../../../../assets/images/remove.svg" style="height: 1.2vw;margin-left: 3%;"
                            (click)="removeAutoAssignedPhoneModels(item,i)">
                    </div>
                </div>
            </div>
            <div id="assignEmail" class="assignSettings" *ngIf="showAutoConfigOption == 'showEmail'">
                <label class="autoAssignLabel">Assign Email</label>
                <div class="emailTextArea"  contenteditable="true" #distributnList [innerHTML]="emailList" spellcheck="false" data-text="SUPPORTED FORMATS &#10;&#10;Option [1] - semicolon as delimiter between email addresses&#10;Alice <alice@example.com>; bob@example.com; Alice, Eve <alice.eve@example.com>; Chuck, Dan(HRS-Dept) <chuckd@example.com> &#10;&#10;Option [2] - Comma as delimiter between email addresses&#10;Alice <alice@example.com>, bob@example.com, “Pat Olivia” <patolivia@example.com>, Chuck Dan(HRS-Dept) <chuckd@example.com> &#10;&#10;Option [3] - Auto fill email address with wildcard extension numbers *@company_domain&#10;[For example: To autofill email id for users with extensions 9999 as 9999@example.com and 9990 as 9990@example.com , enter *@example.com]
                    "></div>
                
            </div>
            <div class="emailErrorBlock" *ngIf="showAutoConfigOption == 'showEmail' && !emailGroupValid">
                <mat-icon style=" font-size: 130%;color: #DA291C;">error</mat-icon>                
                 Found invalid email address. Update with valid email addresses to proceed
            </div>
            <div id="clearConfig" class="assignSettings" *ngIf="showAutoConfigOption == 'showClearConfig'">
                <label class="autoAssignLabel">Clear Configuration</label>
                <div id="checkboxList">
                    <mat-checkbox [(ngModel)]="clearName"
                        (change)="clearConfigOptionsChangeEvent($event.checked, ClearConfigOptions.NameAssignment)">
                        First & Last Name</mat-checkbox>
                    <!--mat-checkbox [(ngModel)]="clearName" (change) = "clearConfigOptionsChangeEvent($event.checked, ClearConfigOptions.Fname)">Fname</mat-checkbox>
                    <mat-checkbox [(ngModel)]="clearName" (change) = "clearConfigOptionsChangeEvent($event.checked, ClearConfigOptions.Lname)">Lname</mat-checkbox-->
                    <mat-checkbox [(ngModel)]="clearPhone"
                        (change)="clearConfigOptionsChangeEvent($event.checked, ClearConfigOptions.phoneAssignment)">
                        Phone Model Assignment</mat-checkbox>
                    <mat-checkbox [(ngModel)]="clearEmail"
                        (change)="clearConfigOptionsChangeEvent($event.checked, ClearConfigOptions.email)">Email
                    </mat-checkbox>
                    <mat-checkbox [(ngModel)]="clearAll"
                        (change)="clearConfigOptionsChangeEvent($event.checked, ClearConfigOptions.All)">Reset (as in
                        csv file)</mat-checkbox>
                </div>
            </div>
            <div class="configSettingButton">
                <button id="AssignDone" class="configtableButton" (click)="autoConfigCancel()">Close</button>
                <button id="assignAutoFill" class="configtableButton" (click)="assignAutoFill()"
                    *ngIf="showAutoConfigOption != 'showClearConfig'" 
                    >Auto Fill</button>
                <button id="assignAutoFill" class="configtableButton" (click)="assignAutoFill()"
                    *ngIf="showAutoConfigOption == 'showClearConfig'">Done</button>
            </div>
        </div>
        <button id="tableEditData" class="tableButton " (click)="editDone()">Done</button>
    </div>
</div>