import { Injectable } from '@angular/core';
import { Observable, Subject} from "rxjs";
import * as firebase from 'firebase';
import { LogService } from '../logging/log.service';
import { FirestoreService, TRIGGER_STATUS } from '../firestore/firestore.service';

const FILE_UPLOAD_TYPE = {
  GREETINGS: "greetingsFile",
  SYSMON: "sysmonFile"
}

const GREETINGS_FOLDER_PATH = "Greetings/";
const SYSMON_FOLDER_PATH = "/SysmonCfg";

@Injectable({
  providedIn: 'root'
})

export class FileUploadService {

  private greetingsProgressSource = new Subject<number>();
  private sysmonProgressSource = new Subject<number>();
  cardId:any;

  constructor(private logger: LogService, private firestoreService:FirestoreService) {
    this.logger.debug("File Upload service");
   }

  uploadGreetingsFile(file: File, id: string, filename: string, fileSize: number) {
    let uploadPath = GREETINGS_FOLDER_PATH+id+"/"+filename;
    var storageRef = firebase.storage().ref();
    var uploadTask = storageRef.child(uploadPath).put(file);
    this.cardId = id;

    var  next = (snapshot) => {
      var progress= snapshot.bytesTransferred / snapshot.totalBytes * 100;
      progress = Math.ceil(progress);
      this.sendProgress(progress, FILE_UPLOAD_TYPE.GREETINGS);
    }
    var error = (err) => {
      unsubscribe();
      this.greetingsProgressSource.error(err.message);
      this.clearProgress(FILE_UPLOAD_TYPE.GREETINGS);
    }

    var complete = () => {
      // handle successful upload on complete
      unsubscribe();
      this.firestoreService.updateGreetingsTriggerStatus(this.cardId, TRIGGER_STATUS.START, filename, fileSize).then(() => {
        this.logger.debug("Updated the greetings status document to START");
      }).catch((err) => {
        this.logger.error("Error updating the greetings status document to start: " + err);
        error(err);
      })
      // setTimeout(()=>{this.clearProgress();},1000);      
      /* uploadTask.snapshot.ref.getDownloadURL()
         .then((downloadURL) => {
           this.logger.debug('File available at', downloadURL);
           unsubscribe();
           //this.progressSource.complete();
         })*/
    }
    var unsubscribe = uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED,
      next,error,complete);
  }

  async uploadSysmonFile(file: File, id: string, filename: string, fileSize: number) {
    let uploadPath = GREETINGS_FOLDER_PATH + id + SYSMON_FOLDER_PATH + "/" + filename;
    var storageRef = firebase.storage().ref();
    var uploadTask = storageRef.child(uploadPath).put(file);
    this.cardId = id;

    var next = (snapshot) => {
      var progress = snapshot.bytesTransferred / snapshot.totalBytes * 100;
      progress = Math.ceil(progress);
      this.sendProgress(progress, FILE_UPLOAD_TYPE.SYSMON);
    }
    var error = (err) => {
      unsubscribe();
      this.sysmonProgressSource.error(err.message);
      this.clearProgress(FILE_UPLOAD_TYPE.SYSMON);
    }

    var complete = () => {
      //handle successful upload on complete
      unsubscribe();
    }
    var unsubscribe = uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED,
      next, error, complete);
  }

  deleteLastUploadedSysmonFile(id: string, filename: string) {
    let deletePath = GREETINGS_FOLDER_PATH + id + SYSMON_FOLDER_PATH + "/" + filename;
    var storageRef = firebase.storage().ref();
    storageRef.child(deletePath).delete().then(() => {
      this.logger.debug(`last uploaded sysmon file ${filename} deleted successfully`);
    }).catch((error) => {
      this.logger.debug(`Exception occurred while deleting last uploaded sysmon file`);
    });
  }

  uploadJsonFile(id: string, filename: string, data: any) {
    let _promise = new Promise((resolve, reject) => {
      try {
        const uploadPath = GREETINGS_FOLDER_PATH + id + filename;
        let storageRef = firebase.storage().ref();
        this.logger.debug('upload file:', uploadPath)
        let jsonBlob = new Blob([JSON.stringify(data)], { type: 'application/json' })
        storageRef.child(uploadPath).put(jsonBlob, {contentType:'application/json'})
        .then((snapshot) => {resolve(snapshot);})
        .catch((err) => (reject(err)));
      }
      catch (err) { this.logger.debug('cannot upload file'); reject(err); }
    });
    return _promise;
  }

  getProgress(fileUploadType): Observable<number> {
    if (FILE_UPLOAD_TYPE.GREETINGS === fileUploadType) {
      return this.greetingsProgressSource.asObservable();
    } else if (FILE_UPLOAD_TYPE.SYSMON === fileUploadType) {
      return this.sysmonProgressSource.asObservable();
    }
  }

  private sendProgress(data, fileUploadType) {
    if (FILE_UPLOAD_TYPE.GREETINGS === fileUploadType) {
      this.greetingsProgressSource.next(data);
    } else if (FILE_UPLOAD_TYPE.SYSMON === fileUploadType) {
      this.sysmonProgressSource.next(data);
    }
  }

  private clearProgress(fileUploadType) {
    if (FILE_UPLOAD_TYPE.GREETINGS === fileUploadType) {
      this.greetingsProgressSource.next();
    } else if (FILE_UPLOAD_TYPE.SYSMON === fileUploadType) {
      this.sysmonProgressSource.next();
    }
  }
}
