<div class="contentBlock">
    <mat-form-field class="searchFormField" >            
        <button mat-button  matPrefix mat-icon-button>
            <mat-icon>search</mat-icon>
        </button>
        <mat-label>Search Migration</mat-label>
        <input matInput type="text" matTooltip="Search by company name (case sensitive)" [(ngModel)]="searchInput" (change) = "updateSearchFilter()" >
    </mat-form-field >
    <div  class="cardList">

        <mat-card class="migrationCard" style="float: left;background-color:white;">
            <div class="cardContent" >
                <!-- <button mat-button class="addCardsBtn" matSuffix mat-icon-button aria-label="Clear" (click)="addCard()">
                    <mat-icon>add_circle_outline</mat-icon>
                </button> -->
                <img class="addCardsBtn" src="../../../../assets/images/Add.svg" (click)="addCard()">
            </div > 
         </mat-card>

        <mat-card class="migrationCard" *ngFor = "let card of migrationCardsDetails"  (click)="showCardSummary(card.id ,card.stage , card.company)">
            <div class="parent-flex-row" style="margin-top: 5%;">
                <label class="companyTitle clickable" style="width: 80%;">
                    {{card.company}}
                </label>
                <div class="dropdown parent-flex-row">
                    <!--  <button  (click) ="openDropDown(card.id)" class="dropbtn" mat-icon-button> -->
                        <mat-icon class="dropbtn" (click) ="openDropDown($event ,card.id)">more_horiz</mat-icon>
                    <!-- </button> -->
                    <div id={{card.id}} class="dropdown-content">
                       <!--  <a class="dropdownOptions" (click) = "markAsComplete($event,card.id)">Mark as Complete</a> -->
                        <a class="dropdownOptions" *ngIf="(card.lastStatus === status.FAILURE || card.lastStatus === status.ABORTED)&& !card.markAsCompleted" (click) = "markAsComplete($event,card.id)">Mark as Complete</a>
                        <!-- <a class="dropdownOptions disabled" *ngIf="card.lastStatus !== status.FAILURE">Mark as Complete</a> -->   
                        <a class="dropdownOptions" (click) = "deleteCard($event , card.id , card.company )" *ngIf="card.stage !== stage.COMPLETE">Delete</a> 
                    </div>
                </div>
            </div>
            <label class="cardInfo clickable" style="width: 85%;word-wrap: break-word;">
            {{card.users}} Users, {{card.date}}
            </label>
             
            <div style="position: absolute;width: 100%;bottom: 1.5vh;">
                 <label class="status clickable" *ngIf="card.lastStatus !== status.SUCCESS && !card.markAsCompleted ">{{card.percentage}}</label>
                 <img class="status migrationStatusIcon" *ngIf="card.lastStatus === status.SUCCESS" src="../../../../assets/images/migration_status/completed.svg" >
                 <img class="status migrationStatusIcon" *ngIf="card.markAsCompleted && card.lastStatus !== status.SUCCESS" src="../../../../assets/images/migration_status/completed-manual.svg" >
            </div>
         </mat-card>

    </div>
    <a class="moreIcon" href="javascript:void(null);" role="button" (click)= "fetchUserCardDetails()" *ngIf="!allRead">Load more
        <mat-icon class="expandIcon">keyboard_arrow_right</mat-icon>
    </a>

</div>
