import { FirestoreService, MIGRATION_STAGE } from './../../../shared/services/firestore/firestore.service';
import { MigrationDataService } from './../../../shared/services/migrationDataService/migration-data.service';
import { Component, OnInit ,NgZone, ChangeDetectorRef } from '@angular/core';
import { Router , ActivatedRoute } from '@angular/router';
import { AngularFirestore } from '@angular/fire/firestore';
import 'firebase/firestore';
import { OverlayService } from 'src/app/shared/services/overlay/overlay.service';
import { MatSpinnerOverlayComponent } from 'src/app/shared/components/mat-spinner-overlay/mat-spinner-overlay.component';
import { LogService } from 'src/app/shared/services/logging/log.service';
import { SessionHandlerService } from 'src/app/shared/services/sessionHandler/session-handler.service';
import { RefreshService } from '../../../shared/services/refresh/refresh.service';
import { TablePreviewService, NAR_COUNTRIES_LIST } from './../../../shared/services/tablePreview/table-preview.service';
import { FileUploadService } from 'src/app/shared/services/file-upload/file-upload.service';


export enum SEVERITY{
    WARNING,
    ERROR,
    INFO
}

const SUMMARY_DATA = {
    ACO_SUMMARY_INFORAMTION : "Avaya Cloud Office",
    IPO_SUMMARY_INFORAMTION : "Avaya IP Office",
    SYSTEM_NAME: "System Name :",
    SYSTEM_TYPE: "System Type :",
    RELEASE: "Release : ",
    MAX_EXTN_LEN: "Maximum Extension Number Length :",
    TOTAL_EXTENSION: "Extension numbers (Total) :",
    PHONES: "Phones :",
    PROFILES: "Profiles :",
    USERS_WITHOUT_EMAIL: "Users w/o Email :",
    COMPANY: "Company Name :",
    COMPANY_MAIN_NUMBER: "Company Main :",
    COMPANY_EMAIL: "Company Email :",
    TOTAL_DL: "Available Digital Lines (Devices + DID) :",
    TOTAL_DID: "Available DID (Direct Numbers) :",
    DEVICE: "Available Device Type :",
    TOTAL_LE: "Available Limited Extension numbers :",
    LIMITED_EXTENSION: "Available Limited Extension Models :",
    SOFTPHONES : "Softphones",
    HARDPHONES : "Hardphones",
    AUTOATTENDANTS: "Auto-Receptionists :",
    BREAKOUT: "Breakout(DTMF 0) :",
    COMPANYNUMBERS: "Available Company Numbers :",
    SERVICEEDITION: "Service Edition :",
    No_of_Users_Without_ExtensionDetails: "Users w/o Physical Extensions(Total) :",
    maximumAgentsInSimultaneousCQ: "Maximum Agents in Simultaneous Call Queue :"
}

const COMP_NUM_TYPE = {
    MAIN_COM_NUM: "MainCompanyNumber",
    ADD_COM_NUM: "AdditionalCompanyNumber",
    COM_NUM: "CompanyNumber"
}

@Component({
  selector: 'app-migration-summary',
  templateUrl: './migration-summary.component.html',
  styleUrls: ['./migration-summary.component.css']
})
export class MigrationSummaryComponent implements OnInit {
  ipoSummaryData:any;
  ipoUserData:any;
  aaICRData:any;
  acoPhData:any;
  acoSummaryData; 
  notifications = [] ;
  SEVERITY = SEVERITY;
  SUMMARY_STRINGS = SUMMARY_DATA;
  readonly TIMER_INTERVAL = 120000;
  //ACOS-903 1000+ users migration support 
  readonly MAX_IPO_EXTNS = 3500;
  id:any;
  private isRefreshed = false;
  private isAAKeySteeringCodeProcessed = false;
  private _enableRefreshData: boolean = false;
    public get enableRefreshData(): boolean {
        return this._enableRefreshData;
    }
    public set enableRefreshData(value: boolean) {
        this._enableRefreshData = value;
        if(document.getElementsByClassName("mat-badge-content").length !== 0){
            if(this._enableRefreshData){
                document.getElementsByClassName("mat-badge-content")[0].classList.remove("warningBadge");
            }else{
                document.getElementsByClassName("mat-badge-content")[0].classList.add("warningBadge");
            }
        }
    }
  showSummary = true;
  public companyName: string;
  constructor(private cdr: ChangeDetectorRef,public zone: NgZone , 
    public _Activatedroute:ActivatedRoute , public db:AngularFirestore,
    private router: Router,
    private migrationDataService:MigrationDataService , private firestoreService:FirestoreService,private RefreshService:RefreshService,
    private overlay:OverlayService, private logger:LogService, private tablePreviewService:TablePreviewService, private fileUploadService:FileUploadService,
    private session:SessionHandlerService
    ) {
    this.logger.debug("MigrationSummaryComponent constructor");
    this.id = this.session.cardId;
    this.companyName = this.session.companyName;
    if(this.id == null){
        this.logger.warn("CardId does not exist - route to dashboard");
        this.zone.run(() => { this.router.navigate(['/home/migration']); });
        return;
    }
    this.enableRefreshData = false;

    this.acoSummaryData = {
        "dl_count": "" ,
        "did_count": "",
        "count": "",
        "total": "",
        "company": "",
        "main_number": "",
        "accountID": "",
        "companyEmailId": "",
        "maxExtensionLen": "",
        "totalLELen":"",
        "devicemodels": "",
        "limExtnModels":"",
        "companyNumbers":"",
        "timezone":"",
        "serviceEdition":"", 
        "maximumAgentsInSimultaneousCQ":""
    };

    this.ipoSummaryData = {
      "IPOSummary" :{
          "Max_Extension_Length": "",
          "No_Of_Users": "" , 
          "No_Of_Users_With_Extension": "",
          "No_Of_Users_Without_email": "" ,
          "No_of_Hardphone_Users": "" ,
          "No_of_Softphone_Users": "" ,
          "No_of_Auto-Receptionists":"",
          "DTMF_Breakout0": "",
          "No_of_Users_Without_ExtensionDetails": "",
          "SY_Name": "" ,
          "SY_Virtualized": "" ,
          "TimeZoneString": "",
          "UN_CLASS": "" ,
          "UN_MAJOR": "" ,
          "UN_MINOR": "" ,
          "UN_TYPE": "" ,
          "User_Profile":{
            "BasicUserPackage": "",
            "NonLicensedUserPackage": "",
            "OfficeWorkerUserPackage": "",
            "PowerUserPackage":  "",
            "TeleworkerUserPackage":  ""
          }
      }
    }
    
    this.firestoreService.updateStage(this.id, MIGRATION_STAGE.PREVIEWSUMMARY).catch();
    if(this.migrationDataService.IPOSummaryData !== undefined && this.migrationDataService.IPOSummaryData !== null){
        //this.logger.debug("IPO Summary data from migration ds: ", this.migrationDataService.IPOSummaryData);
        this.ipoSummaryData = this.migrationDataService.IPOSummaryData;
        this.getUserProfiles();
        this.overlay.openSpinner(this.TIMER_INTERVAL,this.timeupCallbackfunc,MatSpinnerOverlayComponent);
        this.firestoreService.setIPOSummaryData(this.id, this.migrationDataService.IPOSummaryData)
        .then(() => this.firestoreService.setIPOCardInfoData(this.id, this.migrationDataService.IPOSummaryData))
        .then(() => {
          if(this.migrationDataService.ACOSummaryData === null)
          {
            this.RefreshService.fetchpreRequisiteData(this.id)
            .then(() =>{
              this.getAcoImportCfg()
              .then(() =>{
                this.fetchACOdata();
              })
            }) 
          }
        else
        {
          this.getAcoImportCfg()
            .then(() =>{
                this.fetchACOdata();
            })
        }
    })
        .then(() => {
            this.logger.debug("ACO summary data fetched");
        })
        .catch((error) => {
            this.prerequisiteFetchFailure(error);
            this.logger.error("Unable to fetch ACO summary data");
            this.overlay.closeSpinner();
        })
    }
    else{
      this.overlay.openSpinner(this.TIMER_INTERVAL,this.timeupCallbackfunc,MatSpinnerOverlayComponent);
        this.firestoreService.getIPOSummaryData(this.id)
        .then((result:any) => {          
            this.migrationDataService.IPOSummaryData = JSON.parse(result);
            this.ipoSummaryData = this.migrationDataService.IPOSummaryData;
            this.migrationDataService.ShortCodes = this.migrationDataService.IPOSummaryData.IPOSummary.ShortCodes;
            this.getUserProfiles();
            this.logger.debug("IPO SUmmary data from firestore db: ", this.migrationDataService.IPOSummaryData);
            return;                      
        })
        .then(() => {
          if(this.migrationDataService.ACOSummaryData === null)
          {
            this.RefreshService.fetchpreRequisiteData(this.id)
            .then(() =>{
              this.getAcoImportCfg()
              .then(() =>{
                this.fetchACOdata();
              })
            }) 
          }
        else
        {
          this.getAcoImportCfg()
            .then(() =>{
                this.fetchACOdata();
            })
        }
        })
        .then(() => {
            this.logger.debug("ACO summary data fetched");
        })
        .catch((error) => {
            this.prerequisiteFetchFailure(error);
            this.overlay.closeSpinner();
            this.logger.error("Unable to fetch IPO, ACO summary data");
        })
    }
  }

  getUserProfiles(){
    let profiles = "";
    let obj = this.migrationDataService.IPOSummaryData.IPOSummary.User_Profile;
    for (let key in obj){
      if(profiles !== ""){
        profiles = profiles + ", ";
      }
      profiles = profiles + key + " ("+ obj[key] + ") ";
    }
    this.ipoSummaryData.IPOSummary['Profiles'] = profiles;
  }

  timeupCallbackfunc(ol){
    //this.logger.debug("Time up");
    //this.prerequisiteFetchFailure('timed out');
    ol.showSpinner = false;
    ol.closeSpinner();
  }

  ngOnInit(): void {
  }

  prerequisiteFetchFailure(reason){
    var msg = "Unable to fetch ACO prerequisite data - " + reason;
    this.RefreshService.notifications.push({msg: msg, severity: SEVERITY.ERROR});
    this.enableRefreshData = true;
  }

  navigateNext(){
    let found = this.RefreshService.notifications.find(entry => (
      entry.severity === SEVERITY.ERROR));
    if(found === undefined){ // allow navigation 
        let systemSymmaryNotifications = [];
        this.RefreshService.notifications.forEach(element => {
            if(element.severity == 0 || element.severity == 2 )
            systemSymmaryNotifications.push(element.msg)
        });
        this.firestoreService.setNotificationData(this.id,JSON.stringify(systemSymmaryNotifications),null)
        .then(() => {         
            this.zone.run(() => { this.router.navigate(['/home/migration/config-summary']); });
        })
        .catch(() => {
            this.logger.error("setNotificationData failed");
        })
      }
    else
    {
      this.getErrorWarningList();
    }
  }

  navigateBack(){
    //this.router.navigate(['/home/migration/upload-csv']);
  }

  closeMigrationActivity(){
    this.session.alertModal(this.onClose.bind(this));
  }

  onClose() {
    this.logger.debug("Migration summary - close current Migration Activity");
    this.migrationDataService.clearData();
    this.zone.run(() => { this.router.navigate(['/home/migration']);});
  }

  fetchACOdata(){
    if(this.migrationDataService.ACOSummaryData !== undefined && this.migrationDataService.ACOSummaryData !== null){
        this.logger.debug("ACO SUmmary data from migration ds: ", this.migrationDataService.ACOSummaryData);
        this.acoSummaryData = this.migrationDataService.ACOSummaryData;
        this.firestoreService.setACOSummaryData(this.id, this.migrationDataService.ACOSummaryData, this.isRefreshed)
        .then(() => this.firestoreService.setMTUserCardData(this.id, this.migrationDataService.IPOSummaryData, this.migrationDataService.ACOSummaryData))
        .then(() => {
            this.acoSummaryData = this.migrationDataService.ACOSummaryData;
            this.RefreshService.validatePrerequisiteData();
            this.notifications = this.RefreshService.notifications;
            this.cdr.detectChanges();
        })
        .catch((error) => {
            this.logger.error("Update failure in ACO or IPO data: ", error);
        })
    }
    else{
        this.doACOPrerequisiteInfo();
    }
  }

  doACOPrerequisiteInfo(refresh=false){
    this.logger.debug("doACOPrerequisiteInfo");
    if(refresh){
        this.overlay.openSpinner(this.TIMER_INTERVAL,this.timeupCallbackfunc,MatSpinnerOverlayComponent);
        this.RefreshService.notifications = [];
        this.enableRefreshData = false;
        this.isRefreshed = true;
    }
        this.RefreshService.fetchpreRequisiteData(this.id)
        .then(() =>{
            this.checkAcoImportStatus();
        })
    }
  checkAcoImportStatus(){
        this.firestoreService.checkAcoImportStatus(this.id)
        .then((curdata:any) => {  
            this.logger.debug("checkAcoImportStatus "+curdata.desc);   
            if(curdata.status == 0){
                this.getAcoImportCfg();
            }else if(curdata.status == 1){
                this.RefreshService.notifications.push({msg: curdata.desc, severity: SEVERITY.ERROR});
                this.enableRefreshData = true;
                this.overlay.closeSpinner();
            }      
        }).catch((err) => {
            this.overlay.closeSpinner();
        });
    }

  getAcoImportCfg() {
    this.logger.debug("getAcoImportCfg "); 
    var promise = new Promise((resolve, reject) => {
    this.firestoreService.getAcoImportData(this.id)
    .then((doc:any) => {
        if (doc.exists) {
          var curdata = doc.data();
          var phoneGroup = '', leGroup = '';
          var ext_cnt = 0, le_cnt = 0;
          let totalLimExtn = 0;
          if (curdata.total) {
            ext_cnt = curdata.total - (curdata.dl_cnt + curdata.did_cnt);

            this.migrationDataService.ACOImportData = curdata;
            var acoExtnByPhoneGroup = JSON.parse(curdata['extns_by_phone']);

            var idx=0;
            var acoLimExtnByPhoneGroup  = acoExtnByPhoneGroup.filter((entry)=>{
              return (entry.phoneModel.indexOf("Limited Extension") !== -1) ;
            });
            acoExtnByPhoneGroup = acoExtnByPhoneGroup.filter((entry)=>{
              return !acoLimExtnByPhoneGroup.includes(entry); 
            });            
            acoLimExtnByPhoneGroup.forEach(element => {
              if(idx !== 0){
                  leGroup = leGroup + ", ";
              }
              leGroup = leGroup + element.phoneModel + "(" +
              element.extensionList.length + ")";
              totalLimExtn += element.extensionList.length;
              idx++;
            });
            this.logger.debug("limited extension Phone Group Map: ", leGroup);
            idx = 0;
            acoExtnByPhoneGroup.forEach(element => {
                if(idx !== 0){
                    phoneGroup = phoneGroup + ", ";
                }
                phoneGroup = phoneGroup + element.phoneModel + "(" +
                element.extensionList.length + ")";
                idx++;
            });
            this.logger.debug("phoneGroupMap: ", phoneGroup);
          }
        this.getCardInfo(curdata.dl_cnt, curdata.did_cnt , ext_cnt, phoneGroup, leGroup,totalLimExtn);   
        resolve();     
        }
    }).catch((error)=> {
        this.logger.debug("Error getting document:", error);
        this.overlay.closeSpinner();
        this.prerequisiteFetchFailure(error);
    });
    })
    return promise;
  }

  getErrorWarningList(){
    if(this.RefreshService.notifications.length != 0){
        this.showSummary = false;
    }else{
        this.logger.debug("Notification is empty");
    }
    }

  closeErrorWarningBlock(){
    this.showSummary = true;
  }

getCardInfo(dl_cnt, did_cnt , extn, phoneGroup, leGroup, le_count) {
  this.logger.debug("getCardInfo");
  let memberLimit = '';
    this.firestoreService.getCardDetails(this.id)
    .then((curdata:any) => {
        if(curdata.cqMemberlimitAvaialbility !== undefined && curdata.cqMemberlimitAvaialbility === true)
          memberLimit = curdata.cqMemberLimit;
        else
          memberLimit = 'NONE';
        var total = dl_cnt + did_cnt + extn;
        var local = {
          "dl_count": dl_cnt ,
          "did_count": did_cnt,
          "count": extn,
          "total": total,
          "company": curdata.company,
          "main_number": curdata.mainNumber,
          "accountID": curdata.accountId,
          "companyEmailId": curdata.companyEmailId,
          "maxExtensionLen": curdata.maxExtensionLen,
          "totalLELen":le_count,
          "devicemodels": phoneGroup,
          "limExtnModels":leGroup,
          "country": curdata.country,
          "companyNumbers":"",
          "timezone":curdata.timezone,
          "serviceEdition":curdata.serviceEdition,
          "maximumAgentsInSimultaneousCQ":curdata.cqMemberLimit,
          "cqMemberlimitAvaialbility" : curdata.cqMemberlimitAvaialbility       
        };
        this.firestoreService.readAA_ICRData(this.id)
        .then((aaICRData:any) => {
            this.aaICRData = (aaICRData.data)?JSON.parse(aaICRData.data):[];
            return;
        })
        .then(() => this.firestoreService.readComPhNumberData(this.id))
        .then((comPhData:any) => {
            this.acoPhData = (comPhData && comPhData.data)?JSON.parse(comPhData.data):[];         
            local.companyNumbers = comPhData.total;
            if(this.aaICRData.length){
                let isUpdated = 'false';
                let _updateAAICRList = this.aaICRData.map((icrEntries, idx) => {
                    let _updateICREntries = icrEntries.map((icrEntry, dstIdx) => {
                         //if only one Company Number exists
                         if(local.companyNumbers == '1')
                         {
                             let mainPh = this.acoPhData.find((phEntry:any) => phEntry.usageType == COMP_NUM_TYPE.MAIN_COM_NUM);
                            if(mainPh){
                                 icrEntry.phoneNumber = mainPh.phoneNumber;
                                  icrEntry.phoneId = mainPh.id;
                              }
                          }
                     else{
                        //For first index always as main phone number
                        if(idx == 0){
                            let mainPh = this.acoPhData.find((phEntry:any) => phEntry.usageType == COMP_NUM_TYPE.MAIN_COM_NUM);
                            if(mainPh){
                                icrEntry.phoneNumber = mainPh.phoneNumber;
                                icrEntry.phoneId = mainPh.id;
                            }
                        }
                        else{
                            let phEntryList = this.acoPhData.filter((phEntry:any) => phEntry.usageType != COMP_NUM_TYPE.MAIN_COM_NUM);
                            if(phEntryList.length){
                                let phEntry = phEntryList.pop();
                                icrEntry.phoneNumber = phEntry.phoneNumber;
                                icrEntry.phoneId = phEntry.id;
                            }
                        }
                    }
                        return icrEntry;
                    });
                    //console.log("_updateICREntries - ", _updateICREntries);
                    return _updateICREntries;
                });
                //console.log("_updateAAICRList: ", _updateAAICRList);
                this.firestoreService.setAA_ICRData(this.id, _updateAAICRList);
                return this.firestoreService.updateAA_ICRData(this.id, _updateAAICRList,isUpdated);
            }
            return;
        })
        .then(() => {
            return this.firestoreService.setACOSummaryData(this.id, local, this.isRefreshed)
        })
        .then(() => {
            // this.overlay.closeSpinner();
            this.migrationDataService.ACOSummaryData = local;
            this.acoSummaryData = this.migrationDataService.ACOSummaryData;
            this.cdr.detectChanges();
            this.logger.debug("aco Summary data :", this.acoSummaryData);
            this.filterSteeringCodes(this.acoSummaryData.country)
            .then (() => this.logger.debug("filtered AA keys steering codes"))
            .catch(() => this.logger.debug("Unable to filter AA keys steering codes"))
            return;
        })
        .then(() => this.firestoreService.setMTUserCardData(this.id, this.migrationDataService.IPOSummaryData, this.migrationDataService.ACOSummaryData))
        .then(() => this.firestoreService.triggerFeaturePrerequisites(this.id))
       .then(() => {this.RefreshService.validatePrerequisiteData();
                    this.notifications = this.RefreshService.notifications;
            })
        .catch(error => {
          this.logger.error(error);
          this.prerequisiteFetchFailure(error);
          this.overlay.closeSpinner();
        });
    }).catch((error)=> {
      this.logger.debug("Error getting document:", error);
      this.prerequisiteFetchFailure(error);
      this.overlay.closeSpinner();
    });

  }

  filterSteeringCodes(acoCountry:any) {
    this.logger.debug("filterSteeringCodes ", acoCountry);
    var promise = new Promise((resolve, reject) => {
      if (this.migrationDataService.AutoAttendant &&
        this.migrationDataService.AutoAttendant.AutoAttendant &&
        this.migrationDataService.AutoAttendant.AutoAttendant.length && !this.isAAKeySteeringCodeProcessed) {
        let steeringCodeFilteredAAList: any = [];
        if (this.migrationDataService.SteeringCodes &&
          this.migrationDataService.SteeringCodes.SteeringCodesData &&
          this.migrationDataService.SteeringCodes.SteeringCodesData.length) {
          let tempArr = [];
          let steeringCodes = [];
          this.logger.debug('filter AA keys steering code', acoCountry)
          this.migrationDataService.SteeringCodes.SteeringCodesData.forEach(element => {
            let SteeringCode = {
              Steering_Code: element.Steering_Code,
              SC_Telephone: element.SC_Telephone,
              FileName: element.filename
            }
            tempArr.push(SteeringCode);
          });
          steeringCodes = [...new Set(tempArr)];
          this.migrationDataService.AutoAttendant.AutoAttendant.map((eachAA: any) => {
            let keyList: any = [];
            eachAA.AA_Actions.forEach(keyEntry => {
              if (keyEntry.Action === "CentrexTransfer" && keyEntry.Destination !== '') {
                let filteredNumber = this.tablePreviewService.removeSteeringCodes(keyEntry.Destination, null, steeringCodes);
                if (filteredNumber !== undefined) {
                  let phoneNumberDigits = filteredNumber.replace(/[^a-zA-Z0-9]/g, '');
                  filteredNumber = (phoneNumberDigits !== undefined && phoneNumberDigits.length === 10) ? filteredNumber : keyEntry.Destination;

                  //If twin mobile number does not match any steering codes, if they are from NAR region countries 
                  //and if they are present in these formats: '91XXXXXXXXXX', '1XXXXXXXXXX', '9XXXXXXXXXX', '81XXXXXXXXXX', '8XXXXXXXXXX', 
                  //then the preceeding digits needs to be trimmed. 
                  if (acoCountry !== null && NAR_COUNTRIES_LIST.includes(acoCountry) && filteredNumber !== undefined &&
                    filteredNumber.length !== 10 && filteredNumber === keyEntry.Destination) {
                    filteredNumber = this.tablePreviewService.removeCustomSteeringCodes(filteredNumber);
                  }

                  filteredNumber = (filteredNumber !== undefined && filteredNumber.length === 10) ? filteredNumber : keyEntry.Destination;
                } else {
                  filteredNumber = keyEntry.Destination;
                }

                if (filteredNumber !== undefined) keyList.push({ Key: keyEntry.Key, Action: keyEntry.Action, Destination: filteredNumber });
                else keyList.push({ Key: keyEntry.Key, Action: keyEntry.Action, Destination: keyEntry.Destination });
              }
              else keyList.push(keyEntry)
            });
            steeringCodeFilteredAAList.push({callFlowName:eachAA.callFlowName, siteName:eachAA.siteName, AA_Name: eachAA.AA_Name, AA_Actions: keyList });
          })
          this.isAAKeySteeringCodeProcessed = true;
          this.fileUploadService.uploadJsonFile(this.id, '/IpoCfg/autoAttendant.json', steeringCodeFilteredAAList)
        }
        else {
          this.fileUploadService.uploadJsonFile(this.id, '/IpoCfg/autoAttendant.json', this.migrationDataService.AutoAttendant.AutoAttendant)
        }
      }
      resolve();
    })
    return promise;
  }
}