<div class="tabInfo">    
    <!-- <div *ngIf="!showErrorModal" style="margin-right:-15vw;margin-left:4%"> -->
        <mat-label class="greetingsLabel">
            Greetings
        </mat-label>
        <p>Upload User Voicemail Greetings, Call Queue Announcements and Auto-Receptionists
            Prompts in one {{allowedGreetingsFileExtns}} file. Post upload you can verify them in the next data sections
            before migration. This step is optional if none of these configurations are to be migrated.
        </p>

        <div style="display:flex;align-items:center;">
            <mat-form-field >
                <mat-label class="uploadFileLabel">Upload Greetings File ({{allowedGreetingsFileExtns.join(', ')}})</mat-label>
                <input accept="{{allowedGreetingsFileExtns.join(',')}}" style="display: none" type="file" multiple (click)="greetingsFileInput.value = null" (change)="onGreetingsFileChanged($event)" #greetingsFileInput> 
                <input matInput type="text"  style="display:flex;text-overflow: ellipsis;" [(ngModel)]="greetingsFileName">
                <button mat-button  matSuffix mat-icon-button (click)="greetingsFileInput.click()">
                    <img src="../../../../../assets/images/config-summary/Icon_upload_file.svg">
                </button>
            </mat-form-field>
            <mat-progress-bar class="progressBar" mode="determinate"  *ngIf="(greetingsFileUploadStatus == status.INPROGRESS || greetingsFileUploadStatus == status.STARTED) && isStatusUpdated && !(greetingsFileProcessedStatus === status.COMPLETED || greetingsFileProcessedStatus == status.FAILURE || greetingsFileProcessedStatus == status.ABORTED) "  value={{greetingsUploadProgress}}></mat-progress-bar>
            <p class="uploadProgressPercentage" *ngIf="greetingsFileUploadStatus == status.INPROGRESS || greetingsFileUploadStatus == status.STARTED"> 
                {{greetingsUploadProgress}}%
            </p>
        </div>

        <span class="lastUpdatedLabelField" *ngIf="(greetingsFileUploadStatus != status.FAILURE && greetingsFileUploadStatus != status.ABORTED)">
            <img  *ngIf="greetingsFileUploadStatus != status.COMPLETED" src="../../../../../assets/images/config-summary/Icon_Info_Greetings_last_file_uploaded.svg" class="lastUploadedFileIcon">
            <img  *ngIf="greetingsFileUploadStatus == status.COMPLETED" src="../../../../../assets/images/config-summary/Icon_Green_check_mark.svg" class="lastUploadedFileIcon">
            <span class="lastUpdatedFileLabelText">Last Uploaded :  {{lastUploadedGreetingsFileName}}</span>
            <span style="white-space: break-spaces;">{{lastUploadedGreetingsFileSize}}</span>
            <span style="position:relative;margin-left:2%" *ngIf = "!isStatusUpdated">
                <ngx-spinner size="small" color="black"  type="ball-spin-clockwise" [fullScreen]="false" [zIndex]=zIdx>
                  <p class="spinnerLabel" matTooltip="Working on Migration data collection...">Preparing...</p>
                </ngx-spinner>
            </span>
        </span>
        <span class="lastUpdatedLabelField" *ngIf="(greetingsFileProcessedStatus != status.FAILURE && greetingsFileProcessedStatus != status.ABORTED)">
            <img  *ngIf="greetingsFileProcessedStatus == status.COMPLETED" src="../../../../../assets/images/config-summary/Icon_Green_check_mark.svg" class="lastUploadedFileIcon">
            <span *ngIf="greetingsFileProcessedStatus == status.COMPLETED"> Greetings File Successfully Processed</span>
        </span>

        <span class="errorMsg" *ngIf="(greetingsFileUploadStatus == status.FAILURE || greetingsFileUploadStatus == status.ABORTED)">
            <mat-icon class="errorIcon">error</mat-icon>
            Upload Failed,{{greetingsErrorMsg}}
        </span>

        <span class="errorMsg" *ngIf="(greetingsFileProcessedStatus == status.FAILURE || greetingsFileProcessedStatus == status.ABORTED) && this.greetingsFile.size !== 0">
            <mat-icon class="errorIcon">error</mat-icon>
             Failed to Process the Greetings File
        </span>
    <!-- </div> -->
    <br>
    <br>
    <!-- <div *ngIf="!showErrorModal" style="margin-right:-15vw;margin-left:4%"> -->
        <mat-label class="sysmonLabel">
            Sysmon - IP Office SysMonitor SIP Phone Status File
        </mat-label>
        <p>Upload sysmon file consisting of device type and serial Id details of users in
             one txt or zip file. Post upload, auto assignment of phone models is done and devices sync is performed during user data migration.
             This step is optional if none of these configurations are to be migrated.
        </p>

        <div style="display:flex;align-items:center;">
            <mat-form-field >
                <mat-label class="uploadFileLabel">Upload sysmon File ({{allowedSysmonFileExtns.join(', ')}})</mat-label>
                <input accept="{{allowedSysmonFileExtns.join(',')}}" style="display: none" type="file" multiple (click)="fileInput.value = null" (change)="onSysmonFileChanged($event)" #fileInput> 
                <input matInput type="text"  style="display:flex;text-overflow: ellipsis;" [(ngModel)]="sysmonFileName">
                <button mat-button  matSuffix mat-icon-button (click)="fileInput.click()">
                    <img src="../../../../../assets/images/config-summary/Icon_upload_file.svg">
                </button>
            </mat-form-field>
            <mat-progress-bar class="progressBar" mode="determinate"  *ngIf="sysmonFileUploadStatus == status.INPROGRESS || sysmonFileUploadStatus == status.STARTED"  value={{sysmonUploadProgress}}></mat-progress-bar>
            <p class="uploadProgressPercentage" *ngIf="sysmonFileUploadStatus == status.INPROGRESS || sysmonFileUploadStatus == status.STARTED"> 
                {{sysmonUploadProgress}}%
            </p>

        </div>

        <span class="lastUpdatedLabelField" *ngIf="(sysmonFileUploadStatus != status.FAILURE && sysmonFileUploadStatus != status.ABORTED)">
            <img  *ngIf="sysmonFileUploadStatus != status.COMPLETED" src="../../../../../assets/images/config-summary/Icon_Info_Greetings_last_file_uploaded.svg" class="lastUploadedFileIcon">
            <img  *ngIf="sysmonFileUploadStatus == status.COMPLETED" src="../../../../../assets/images/config-summary/Icon_Green_check_mark.svg" class="lastUploadedFileIcon">
            <span class="lastUpdatedFileLabelText">Last Uploaded :  {{lastUploadedSysmonFileName}}</span>
            <span style="white-space: break-spaces;">{{lastUploadedSysmonFileSize}}</span>
        </span>
        

        <span class="errorMsg" *ngIf="(sysmonFileUploadStatus == status.FAILURE || sysmonFileUploadStatus == status.ABORTED)">
            <mat-icon class="errorIcon">error</mat-icon>
            Upload Failed,{{sysmonErrorMsg}}
        </span>

</div>