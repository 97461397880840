import { Component, OnInit, NgZone} from '@angular/core';
import Tabulator from 'tabulator-tables';
import { LogService } from 'src/app/shared/services/logging/log.service';
import { TablePreviewService , PAGINATION_SIZE} from 'src/app/shared/services/tablePreview/table-preview.service';
import { Router} from '@angular/router';
import { MigrationDataService } from 'src/app/shared/services/migrationDataService/migration-data.service';
import { SessionHandlerService } from 'src/app/shared/services/sessionHandler/session-handler.service';
import { OverlayService } from 'src/app/shared/services/overlay/overlay.service';
import { MatSpinnerOverlayComponent } from 'src/app/shared/components/mat-spinner-overlay/mat-spinner-overlay.component';

@Component({
  selector: 'app-advanced-info-table-preview',
  templateUrl: './advanced-info-table-preview.component.html',
  styleUrls: ['../../../../assets/customcss/table-component.css']
})
export class AdvancedInfoTablePreviewComponent implements OnInit {

  searchInput:any;
  searchOption = "username";
  advncdInfoTable : Tabulator;
  readonly TABLE_PROMPT_MSG = 'Are you sure you want to close current Migration?';
  id:any;
  companyName:string;
  showErrorWarningBlock = false;  
  columnNames: any[] = [];
  readonly TIMER_INTERVAL = 60000;
  public errorWarningList = [];
  public errorCount = 0;
  constructor(private tablePreviewService:TablePreviewService,
    private router: Router , 
    public zone: NgZone,
    private migrationDataService:MigrationDataService, private session:SessionHandlerService,
    private overlay:OverlayService, private logger:LogService) {
      this.id = this.session.cardId;
      this.companyName = this.session.companyName;
     }

  ngOnInit(): void {
    this.logger.debug("advanced tablePreview: ngOnInit");
    this.columnNames = [
        { title: "User Name", field: "username" , headerSort:true, sorter:"alphanum" },
        
        { title: "Working Hours", field: "timeZoneName" , headerSort:true, sorter:"alphanum", formatter:this.stringFormatter, variableHeight:true },
        { title: "Contacts", field: "contactList.length" , headerSort:true, sorter:"number", formatter:this.numberFormatter ,align:"center"},
        { title: "Presence", field: "blfList" , headerSort:true, sorter:"number", 
            tooltip:this.getBLFCountForToolTip, formatter:this.blfFormatter,align:"center"},
        { title: "Voicemail Greeting", field: "vmGreetings" , headerSort:true, sorter:"boolean",formatter:this.tickFormatter ,align:"center"},
        { title: "User Ring Group", field:"twinMob",headerSort:true, sorter:"alphanum",
            formatter:this.tickFormatter,align:"center"
        },
        { title: "Site Name", field: "siteName", headerSort:true, sorter:"alphanum",formatter:(cell, formatterParams, onRendered)=>{
          if(cell.getValue() === "")
           return '<span">MainSite</span>';
          else
            return '<span>'+cell.getValue()+'</span>';
         }
        }
    ];    
  }

  private getBLFCountForToolTip = (cell)=>{
    return this.getBLFCount(cell.getValue());
  }

  private getBLFCount = (blfList)=>{
    var blfLen = 0;
    var tableUserList = this.tablePreviewService.tableUserList;
    if(blfList){
      blfList.forEach(blfData => {
        let blfExtnId= tableUserList.find(entry => entry.extension === parseInt(blfData.US_Extension));
        if ((blfData.BLF_Key !== "1" && blfData.BLF_Key !== "2" && blfData.BLF_Key !== "3")) {
            blfLen++;
        }
      });
    }
    return blfLen;
  }

  private blfFormatter = (cell, formatterParams, onRendered)=>{
    var blfLen = this.getBLFCount(cell.getValue());
    return '<span style="margin-left:-10%;">'+blfLen+'</span>';
  }

  private tickFormatter = (cell, formatterParams, onRendered)=>{
    var val = cell.getValue();
    if( val == undefined || val == null ||
      (typeof(val) == "boolean" &&  val == false) || 
      (typeof(val) == "string" && val.trim() == ""))
        return '<i class="material-icons" style="font-size:16px;margin-left:-10%;color:rgb(49,49,49,.59);">remove</i>';
    else
        return '<i class="material-icons" style="font-size:16px;margin-left:-10%;color:rgb(49,49,49,.59);">check</i>';
  }

  private stringFormatter = (cell, formatterParams, onRendered)=>{
    cell.getElement().style.whiteSpace = "pre-wrap";
    var val = cell.getValue();    
    if( val == undefined || val == null || val.trim() == "")
      return '<i class="material-icons" style="font-size:16px;margin-left:10%;color:rgb(49,49,49,.59);">remove</i>';
    return val;  
  }

  private numberFormatter = (cell, formatterParams, onRendered)=>{
    var val = cell.getValue();
    if( val == undefined || val == null || val == 0)
      return '<i class="material-icons" style="font-size:16px;margin-left:-10%;color:rgb(49,49,49,.59);">remove</i>';
    else{
      return '<span style="margin-left:-10%;">'+val+'</span>';
    }
  }

  ngAfterViewInit(){
    this.overlay.openSpinner(this.TIMER_INTERVAL,this.timeupCallbackfunc,MatSpinnerOverlayComponent);
    this.tablePreviewService.initializeTableData(this.id).then(()=>{
      this.advncdInfoTable = new Tabulator("#advcd-info-tabulator-div",{	layout:"fitColumns",      //fit columns to width of table
      data:this.tablePreviewService.tableUserList,
      cellVertAlign:"middle",
      maxHeight:"100%",
      autoResize:true,
      resizableColumns:false,
      resizableRows:false, 
      responsiveLayout:'hide',  //hide columns that dont fit on the table
      tooltips:true,            //show tool tips on cells
      addRowPos:"top",          //when adding a new row, add it to the top of the table
      history:true,             //allow undo and redo actions on the table
      pagination:"local",       //paginate the data
      paginationSize:PAGINATION_SIZE,
      movableColumns:false,
      footerElement:this.getFooterElement(),
      dataFiltered: this.updateFooterOnFiltered,
      pageLoaded: this.updateFooterOnPgLoaded,
      initialSort:[             //set the initial sort order of the data
        {column:"name", dir:"asc"}
      ],
     // groupBy: "siteName"
    });
    this.advncdInfoTable.setColumns(this.columnNames);
    this.advncdInfoTable.setData(this.tablePreviewService.tableUserList);
    this.fetchErrorWarningData();
    this.overlay.closeSpinner();
    // document.getElementsByClassName("mat-badge-content")[0].classList.add("warningBadge");
    let colHeaders = document.getElementsByClassName("tabulator-col");
    for(let i=2;i<colHeaders.length-1;i++){
      colHeaders[i].classList.add("centerAlign");
    }
  }).catch((error)=>{
    this.logger.debug(error)
    this.overlay.closeSpinner();
  });
}

  ngOnDestroy(){
  }

  timeupCallbackfunc(ol){
    ol.showSpinner = false;
    ol.closeSpinner();
  }

  fetchErrorWarningData(){ 
    let tempErrorList = this.tablePreviewService.advncdUserInfoWarningsList;
    tempErrorList.forEach((value:[], key: string) =>{
        if(value.length === 0)
        tempErrorList.delete(key);
  })
     this.errorCount = 0;
    this.errorWarningList = Array.from(tempErrorList)
    this.errorWarningList.forEach((site) =>{
      if(site[0] === "")
          site[0] = "MainSite";
      this.errorCount += site[1].length;
    })
  }

  updateFilter(){
    if(this.searchInput !== undefined){
      var typeVal = "like";
      this.advncdInfoTable.setFilter(this.searchOption, typeVal, this.searchInput);        
    }
  }

  getFooterElement(){
    return '<span style="float:left;" class="customFooter" id="footerNote"></span>';
  }

  updateFooterOnPgLoaded = (pageNum)=>{
    if( this.advncdInfoTable){
      var el = document.getElementById("footerNote");
      let currPageNum = this.advncdInfoTable.getPage();
      if(currPageNum){
        let startIdx = ((currPageNum-1)*PAGINATION_SIZE)+1;
        let endIdx = startIdx + this.advncdInfoTable.rowManager.displayRowsCount - 1;
        el.innerHTML = startIdx + ' - '+ endIdx +' of '+this.tablePreviewService.tableUserList.length +' user configurations';
      }
    }
  }

  updateFooterOnFiltered = (filters, rows)=>{
    var el = document.getElementById("footerNote");
    if(rows.length){      
      if(filters.length != 0 ){
        var displayRowsCount = this.advncdInfoTable.rowManager.displayRowsCount;
        if(filters[0].value.trim() =="" || displayRowsCount >  rows.length){
          displayRowsCount =  rows.length;
        }
        var selectedCount = displayRowsCount < PAGINATION_SIZE ? displayRowsCount : PAGINATION_SIZE;
        el.innerHTML = '1-'+ selectedCount +' of '+rows.length +' user configurations';    
      }else{
        if( this.advncdInfoTable){
          let currPageNum = this.advncdInfoTable.getPage();
          if(currPageNum){
            el.innerHTML = '1-'+this.advncdInfoTable.rowManager.displayRowsCount+' of '+this.tablePreviewService.tableUserList.length +' user configurations';
          }
        }
      }
    }else{
      el.innerHTML = "";
    }    
  }

  closeMigrationActivity(){
    this.session.alertModal(this.onClose.bind(this), this.TABLE_PROMPT_MSG);
  }

  onClose() {
    this.logger.debug("TablePreview - Close current Migration Activity");
    this.migrationDataService.clearData();
    this.tablePreviewService.saveTableData(this.id)
    .then((res) => {
        this.logger.debug("table data saved");
        this.zone.run(() => { this.router.navigate(['/home/migration']);});
    }).catch((error) => {
        this.logger.debug("saving table data failed. ",error);
    });
  }


  getErrorWarningList(){
    if(this.errorWarningList.length != 0){
        this.displayErrorWarningBlock();
    }else{
        this.logger.debug("Warning list is empty");
    }
  }

  displayErrorWarningBlock(){
    document.getElementById("advcd-info-tabulator-div").style.display = "none";
    document.getElementById("tableDoneBtn").style.display = "none";
    this.showErrorWarningBlock = true;
  } 

  displayTable(){
    document.getElementById("advcd-info-tabulator-div").style.display = "block";
    document.getElementById("tableDoneBtn").style.display = "inline-block";
    this.showErrorWarningBlock = false;
  }

  closeErrorWarningBlock(){
    this.displayTable(); 
  }

  done (){
    this.logger.debug("done");
    this.zone.run(() => { this.router.navigate(['/home/migration/config-summary/user-data']); });    
  }
 

}

