<div class="contentBlock migrationDataBlock">
  <div class="contentInfo">
    <button class="closeBtn" mat-icon-button matTooltip="close" (click)="closeMigrationActivity()">
      <mat-icon style=" font-size: 0.8vw;line-height: 1vw;">close</mat-icon>
    </button>

    <span  matTooltip="Notifications" style="margin-left:70%;" (click)="getErrorWarningList()" matBadge={{notifications.length}} 
    matBadgeSize="small"  matBadgeColor="warn" [matBadgeHidden]="notifications.length == 0">
      <img src="../../../../assets/images/notification/Notification_thicker.svg" style="width:1vw;">
   </span>
   
   <div class="migrationStepInfo">
      <span class="migrationStep">Step 4/6 : System Summary</span>
      <span class="migrationCompany">{{companyName}}</span>
    </div>
  </div>
  <div class="contentMain " style="display: flex;align-items: center;justify-content: center;">
    <div class="summaryCard" *ngIf=showSummary>
      <mat-card class="example-card" style="float: right;">
        <mat-card-header>
          <mat-card-title>
            {{SUMMARY_STRINGS.IPO_SUMMARY_INFORAMTION}}
          </mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <div>
            <table cellspacing="10">
              <tr class="summaryTableRow">
                <td>{{SUMMARY_STRINGS.SYSTEM_NAME}}</td>
                <td>{{ipoSummaryData.IPOSummary.SY_Name}}</td>
              </tr>
              <tr class="summaryTableRow">
                <td>{{SUMMARY_STRINGS.SYSTEM_TYPE}}</td>
                <td>{{ipoSummaryData.IPOSummary.UN_TYPE}}</td>
              </tr>
              <tr class="summaryTableRow">
                <td>{{SUMMARY_STRINGS.RELEASE}}</td>
                <td>{{ipoSummaryData.IPOSummary.UN_MAJOR}}.{{ipoSummaryData.IPOSummary.UN_MINOR}}</td>
              </tr>
              <tr class="summaryTableRow">
                <td>{{SUMMARY_STRINGS.MAX_EXTN_LEN}}</td>
                <td>{{ipoSummaryData.IPOSummary.Max_Extension_Length}}</td>
              </tr>
              <tr class="summaryTableRow">
                <td>{{SUMMARY_STRINGS.TOTAL_EXTENSION}}</td>
                <td>{{ipoSummaryData.IPOSummary.No_Of_Users}}</td>
              </tr>
              <tr class="summaryTableRow">
                <td>{{SUMMARY_STRINGS.PHONES}}</td>
                <td>{{SUMMARY_STRINGS.SOFTPHONES}}({{ipoSummaryData.IPOSummary.No_of_Softphone_Users}}) ,
                  {{SUMMARY_STRINGS.HARDPHONES}}({{ipoSummaryData.IPOSummary.No_of_Hardphone_Users}})</td>
              </tr>
              <tr class="summaryTableRow">
                <td>{{SUMMARY_STRINGS.PROFILES}}</td>
                <td>{{ipoSummaryData.IPOSummary.Profiles}}</td>
              </tr>
              <tr class="summaryTableRow">
                <td>{{SUMMARY_STRINGS.USERS_WITHOUT_EMAIL}}</td>
                <td>{{ipoSummaryData.IPOSummary.No_Of_Users_Without_email}}</td>
              </tr>
              <tr class="summaryTableRow">
                <td>{{SUMMARY_STRINGS.AUTOATTENDANTS}}</td>
                <td>{{ipoSummaryData.IPOSummary.No_of_AutoAttendants}}</td>
              </tr>
                <tr class="summaryTableRow">
                  <td>{{SUMMARY_STRINGS.No_of_Users_Without_ExtensionDetails}}</td>
                  <td>{{ipoSummaryData.IPOSummary.No_of_Users_Without_ExtensionDetails}}</td>
                </tr>
            </table>
          </div>
        </mat-card-content>
      </mat-card>
    </div>

    <div class="summaryCard" style="margin-left: 0.3%;" *ngIf=showSummary>
      <mat-card class="example-card" style="float: left;">
        <mat-card-header>
          <mat-card-title>
            {{SUMMARY_STRINGS.ACO_SUMMARY_INFORAMTION}}
          </mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <div>
            <table cellspacing="10">
              <tr class="summaryTableRow">
                <td>{{SUMMARY_STRINGS.COMPANY}}</td>
                <td>{{acoSummaryData.company}}</td>
              </tr>
              <tr class="summaryTableRow">
                <td>{{SUMMARY_STRINGS.COMPANY_MAIN_NUMBER}}</td>
                <td>{{acoSummaryData.main_number}}</td>
              </tr>
              <tr class="summaryTableRow">
                <td>{{SUMMARY_STRINGS.COMPANY_EMAIL}}</td>
                <td>{{acoSummaryData.companyEmailId}}</td>
              </tr>
              <tr class="summaryTableRow">
                <td>{{SUMMARY_STRINGS.MAX_EXTN_LEN}}</td>
                <td>{{acoSummaryData.maxExtensionLen}}</td>
              </tr>
              <tr class="summaryTableRow">
                <td>{{SUMMARY_STRINGS.TOTAL_DL}}</td>
                <td>{{acoSummaryData.dl_count}}</td>
              </tr>
              <tr class="summaryTableRow">
                <td>{{SUMMARY_STRINGS.TOTAL_DID}}</td>
                <td>{{acoSummaryData.did_count}}</td>
              </tr>
              <tr class="summaryTableRow">
                <td>{{SUMMARY_STRINGS.TOTAL_LE}}</td>
                <td>{{acoSummaryData.totalLELen}}</td>
              </tr>
              <tr class="summaryTableRow">
                <td>{{SUMMARY_STRINGS.DEVICE}}</td>
                <td>{{acoSummaryData.devicemodels}}</td>
              </tr>              
              <tr class="summaryTableRow">
                <td>{{SUMMARY_STRINGS.LIMITED_EXTENSION}}</td>
                <td>{{acoSummaryData.limExtnModels}}</td>
              </tr>
              <tr class="summaryTableRow">
                <td>{{SUMMARY_STRINGS.COMPANYNUMBERS}}</td>
                <td>{{acoSummaryData.companyNumbers}}</td>
              </tr>
              <tr>
                <td>{{SUMMARY_STRINGS.SERVICEEDITION}}</td>
                <td>{{acoSummaryData.serviceEdition}}</td>
              </tr>
              <tr>
                <td>{{SUMMARY_STRINGS.maximumAgentsInSimultaneousCQ}}</td>
                <td>{{acoSummaryData.maximumAgentsInSimultaneousCQ}}</td>
              </tr>
            </table>
          </div>
        </mat-card-content>
      </mat-card>
    </div>

    <div id="errorWarningBlock" class="errorWarningBlock" *ngIf=!showSummary>
      
      <div matTooltip="Refresh" style="margin:2% 95% 0% auto;" (click)="doACOPrerequisiteInfo(true)">
        <img src="../../../../assets/images/notification/refresh.svg" style="width:1.5vw;">
      </div>

      <div style="width: 100%;height: 88%; overflow-x: hidden;overflow-y: auto;">
        <table style="width: 90%;max-height: 100%;margin-top: 0%;">
          <tr class="errorWarningRow" *ngFor="let item of notifications">
            <td>
              {{item.msg}}
            </td>
            <td *ngIf="item.severity == SEVERITY.INFO">
               <mat-icon style=" font-size: 1.1vw;color: green;">
                warning
              </mat-icon>
              </td>
            <td *ngIf="item.severity == SEVERITY.WARNING">
              <mat-icon style=" font-size: 1.1vw;color: #FF8200F7;">
                warning
              </mat-icon>
            </td>
            <td *ngIf="item.severity == SEVERITY.ERROR">
              <mat-icon style=" font-size: 1.1vw;color: #DA291C;">
                error
              </mat-icon>
            </td>

          </tr>
        </table>
      </div>
      <button class="tableButton " style="margin-top: 1%;" (click)="closeErrorWarningBlock()">
        Close
      </button>

    </div>
  </div>

  <div class="navigation" *ngIf=showSummary>
    <!--button mat-button class="arrowBack" matSuffix mat-icon-button aria-label="Clear" (click)= "navigateBack()">
            <mat-icon>arrow_back</mat-icon>
        </button-->
    <button mat-button class="arrowForward" matSuffix mat-icon-button aria-label="Clear" (click)="navigateNext()">
      <mat-icon>arrow_forward</mat-icon>
    </button>
  </div>
</div>