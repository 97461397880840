import { Component, OnInit, NgZone} from '@angular/core';
import Tabulator from 'tabulator-tables';
import { TablePreviewService,PAGINATION_SIZE } from 'src/app/shared/services/tablePreview/table-preview.service';
import { Router } from '@angular/router';
import { MigrationDataService } from 'src/app/shared/services/migrationDataService/migration-data.service';
import { SessionHandlerService } from 'src/app/shared/services/sessionHandler/session-handler.service';
import { OverlayService } from 'src/app/shared/services/overlay/overlay.service';
import { LogService } from 'src/app/shared/services/logging/log.service';
import { MatSpinnerOverlayComponent } from 'src/app/shared/components/mat-spinner-overlay/mat-spinner-overlay.component';


@Component({
  selector: 'app-time-profiles-preview',
  templateUrl: './time-profiles-preview.component.html',
  styleUrls: ['../../../../assets/customcss/table-component.css']
})
export class TimeProfilesPreviewComponent implements OnInit {

  searchInput:any;
  searchOption = "name";
  timeProfilesTable : Tabulator;
  readonly TABLE_PROMPT_MSG = 'Are you sure you want to close current Migration?';
  id:any;
  companyName:string;
  showErrorWarningBlock = false;  
  columnNames: any[] = [];
  readonly TIMER_INTERVAL = 60000;
  public errorWarningList = [];
  public errorcount = 0;
  constructor(private tablePreviewService:TablePreviewService,
    private router: Router , 
    public zone: NgZone,
    private migrationDataService:MigrationDataService, private session:SessionHandlerService,
    private overlay:OverlayService, private logger:LogService) {
      this.id = this.session.cardId;
      this.companyName = this.session.companyName;
  }

  ngOnInit(): void {
    this.logger.debug("Working Hours tablePreview: ngOnInit");
    this.columnNames = [        
        { title: "Working Hours", field: "name" , headerSort:true, sorter:"alphanum", width:"17%" , formatter:(cell, formatterParams, onRendered)=>{
          return '<span style="margin-left:10%;">'+cell.getValue()+'</span>';
        }},        
        { title: "Calendar", field: "calendar" , headerSort:true, sorter:"number", width:"15%",formatter:this.tickFormatter, align:"center" },
        { title: "Recurrence", field: "recurrence" ,headerSort:true, sorter:"number",width:"15%",formatter:this.tickFormatter, align:"center"  },
        { title: "Site Name", field: "siteName", headerSort:true, sorter:"alphanum",align:"center",formatter:(cell, formatterParams, onRendered)=>{
          if(cell.getValue() === "")
           return '<span">MainSite</span>';
          //to be removed 
          else if(cell.getValue() === undefined){
            return "";
          }
          else
            return '<span>'+cell.getValue()+'</span>';
         }
        }
    ];    
  }

  private tickFormatter = (cell, formatterParams, onRendered)=>{
    var val = cell.getValue();
    if(val)
      return '<i class="material-icons" style="font-size:16px;margin-left:-10%;color:rgb(49,49,49,.59);">check</i>';
    else
      return '<i class="material-icons" style="font-size:16px;margin-left:-10%;color:rgb(49,49,49,.59);">remove</i>';
  }

  ngAfterViewInit(){
        this.overlay.openSpinner(this.TIMER_INTERVAL,this.timeupCallbackfunc,MatSpinnerOverlayComponent);
        this.tablePreviewService.initializeTableData(this.id).then(()=>{
        this.timeProfilesTable = new Tabulator("#time-profiles-tabulator-div",{	layout:"fitDataFill",      //fit columns to width of table
          data:this.tablePreviewService.tableTimeProfileList,
          maxHeight:"100%",
          autoResize:true,
          resizableColumns:false,
          resizableRows:false, 
          responsiveLayout:'hide',  //hide columns that dont fit on the table
          tooltips:true,            //show tool tips on cells
          addRowPos:"top",          //when adding a new row, add it to the top of the table
          history:true,             //allow undo and redo actions on the table
          pagination:"local",       //paginate the data
          paginationSize:PAGINATION_SIZE,
          footerElement:this.getFooterElement(),
          dataFiltered: this.updateFooterOnFiltered,
          pageLoaded: this.updateFooterOnPgLoaded,
          movableColumns:false,
          initialSort:[             //set the initial sort order of the data
            {column:"name", dir:"asc"}
          ],
         // groupBy: "siteName"
        });
        this.timeProfilesTable.setColumns(this.columnNames);
        this.timeProfilesTable.setData(this.tablePreviewService.tableTimeProfileList);
        this.fetchErrorWarningData();
        this.overlay.closeSpinner();
        let colHeaders = document.getElementsByClassName("tabulator-col");
        for(let i=1;i<colHeaders.length-1;i++){
          colHeaders[i].classList.add("centerAlign");
        }
      }).catch((error)=>{
        this.logger.debug(error)
        this.overlay.closeSpinner();
      });
  }

  ngOnDestroy(){
  }

  timeupCallbackfunc(ol){
    ol.showSpinner = false;
    ol.closeSpinner();
  }

  fetchErrorWarningData(){ 
    this.errorcount = 0;
    this.errorWarningList = []; 
    let tempErrorList =  Array.from(this.tablePreviewService.timeProfilesWarningsList);
    tempErrorList.forEach((site) =>{
      if(site[1].length > 0)
      this.errorWarningList.push(site);
  })
    this.errorWarningList.forEach((site) =>{
      if(site[0] === "")
      site[0] = "MainSite";
      this.errorcount += site[1].length;
    })      

  }

  updateFilter(){
    if(this.searchInput !== undefined){
      var typeVal = "like";
      this.timeProfilesTable.setFilter(this.searchOption, typeVal, this.searchInput);        
    }
  }

  getFooterElement(){
    return '<span style="float:left;" class="customFooter" id="footerNote"></span>';
  }

  updateFooterOnPgLoaded = (pageNum)=>{
    if( this.timeProfilesTable){
      var el = document.getElementById("footerNote");
      let currPageNum = this.timeProfilesTable.getPage();
      if(currPageNum){
        let startIdx = ((currPageNum-1)*PAGINATION_SIZE)+1;
        let endIdx = startIdx + this.timeProfilesTable.rowManager.displayRowsCount - 1;
        el.innerHTML = startIdx + ' - '+ endIdx +' of '+this.tablePreviewService.tableTimeProfileList.length +' Working Hours';
      }
    }
  }

  updateFooterOnFiltered = (filters, rows)=>{
    var el = document.getElementById("footerNote");
    if(rows.length){      
      if(filters.length != 0 ){
        var displayRowsCount = this.timeProfilesTable.rowManager.displayRowsCount;
        if(filters[0].value.trim() =="" || displayRowsCount >  rows.length){
          displayRowsCount =  rows.length;
        }
        var selectedCount = displayRowsCount < PAGINATION_SIZE ? displayRowsCount : PAGINATION_SIZE;
        el.innerHTML = '1-'+ selectedCount +' of '+rows.length +' Working Hours';
      }else{
        if( this.timeProfilesTable){
          let currPageNum = this.timeProfilesTable.getPage();
          if(currPageNum){
            el.innerHTML = '1-'+this.timeProfilesTable.rowManager.displayRowsCount+' of '+this.tablePreviewService.tableTimeProfileList.length +' Working Hours';
          }
        }
      }
    }else{
      el.innerHTML = "";
    }
  }

  closeMigrationActivity(){
    this.session.alertModal(this.onClose.bind(this), this.TABLE_PROMPT_MSG);
  }

  onClose() {
    this.logger.debug("TablePreview - Close current Migration Activity");
    this.migrationDataService.clearData();
    this.tablePreviewService.saveTableData(this.id)
    .then((res) => {
        this.logger.debug("table data saved");
        this.zone.run(() => { this.router.navigate(['/home/migration']);});
    }).catch((error) => {
        this.logger.debug("saving table data failed. ",error);
    });
  }


  getErrorWarningList(){
    if(this.errorWarningList.length != 0){
        this.displayErrorWarningBlock();
    }else{
        this.logger.debug("Warning list is empty");
    }
  }

  displayErrorWarningBlock(){
    document.getElementById("time-profiles-tabulator-div").style.display = "none";
    document.getElementById("tableDoneBtn").style.display = "none";
    this.showErrorWarningBlock = true;
  } 

  displayTable(){
    document.getElementById("time-profiles-tabulator-div").style.display = "block";
    document.getElementById("tableDoneBtn").style.display = "inline-block";
    this.showErrorWarningBlock = false;
  }

  closeErrorWarningBlock(){
    this.displayTable(); 
  }

  done (){
    this.logger.debug("done");
    this.zone.run(() => { this.router.navigate(['/home/migration/config-summary/system-data']); });    
  }

}